import React, { useState } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import "../../../views/Dashboard/org.css";
import Container from "react-bootstrap/Container";
import { Modal, Row, Col } from "react-bootstrap";
import FileViewer from "react-file-viewer";
import Loader from "../../../views/Dashboard/Loader";
import NotFound from "assets/svg/not-found.svg";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";

function ResumePreview({
  objectURL,
  setObjectURL,
  file,
  setFile,
  resumeFilePath,
  setResumeFilePath,
  submission,
  setSubmission,
  submCandidate,
  setSubmCandidate,
  submissionPreview,
  setSubmissionPreview,
}) {
 const [viewerError,setViewerError]=useState(null)
  const handleSubmissionPreviewClose = () => {
    setSubmissionPreview(false);
    setObjectURL("");
    setFile(null);
    setResumeFilePath("");
  };

  const handleCreateSubmission = () => {
    getJobpostdata(submissionpage, rowsPerSubmissionPage);
    SetSearchJobModel(true);
  };
  const handleCreateSubmissionClose = () => {
    SetSearchJobModel(false);
  };

  return (
    <div>
      <div className="model-box-view">
        <Modal
          show={submissionPreview}
          onHide={handleSubmissionPreviewClose}
          isCentered
          size="xl"
          closeOnOverlayClick={false}
        >
          <Modal.Header closeButton>
            <Modal.Title className="usertitle">Resume Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container>
              <Row>
                <Col lg={8} md={6} sm={6}>
                  {!objectURL ? (
                    <div className="resume_ld">
                      <Loader />
                    </div>
                  ) : objectURL === "no-resume" ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={NotFound}
                        alt="NotFound"
                        style={{ width: "16rem" }}
                      />
                      <p>No Resume Found! Please try again....</p>
                    </div>
                  ) : (
                    <div
                      style={{
                        borderRight: "1px solid rgb(222, 226, 230)",
                        paddingRight: "18px",
                      }}
                    >
                      {file &&
                      file.type ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                        <Box
                          style={{
                            overflow: "auto",
                            userSelect: "text",
                            height: "85vh",
                          }}
                        >
                          <FileViewer
                            fileType="docx"
                            filePath={resumeFilePath}
                            onError={(e) => {
                              setViewerError(e);
                            }}
                          />
                          {viewerError && (
                            <div>Error: {viewerError.message}</div>
                          )}
                        </Box>
                      ) : (
                        file && (
                          <iframe src={objectURL} width="100%" height="800px" />
                        )
                      )}
                    </div>
                  )}
                </Col>
                <Col lg={4} md={6} sm={6}>
                  <div className="resume_pr">
                    <div>
                      {/* <Button
                        style={{
                          color: "white",
                          border: "1px solid #d7d7d7",
                          fontWeight: "500",
                          letterSpacing: "1px",
                          fontSize: "14px",
                        }}
                        className="bg_color"
                        onClick={() => {
                          handleCreateSubmission();
                          setSubmission({
                            ...submission,
                            candidateId: submCandidate?._id,
                            email: submCandidate?.email,
                            file: submCandidate?.file,
                            fileName: submCandidate?.fileName,
                            filePath: submCandidate?.filePath,
                            currentCandidateCTCType:
                              submCandidate?.currentCandidateCTCType ||
                              "Annual Salary",
                            currentCandidateCTC:
                              submCandidate?.currentCandidateCTC || "",
                            expectedCandidateCTCType:
                              submCandidate?.expectedCandidateCTCType ||
                              "Annual Salary",
                            expectedCandidateCTC:
                              submCandidate?.expectedCandidateCTC || "",
                            relevantExperiance: submCandidate?.experiance || "",
                            noticePeriod: submCandidate?.noticePeriod || "",
                            candidate_ownerName:
                              submCandidate?.candidate_ownerName || "",
                          });
                          handleSubmissionPreviewClose();
                        }}
                      >
                        Submit Job
                      </Button> */}
                    </div>
                    <div>
                      <Heading
                        size="md"
                        fontWeight="bold"
                        letterSpacing="tight"
                      >
                        {submCandidate?.firstName || "N/A"}{" "}
                        {submCandidate?.lastName || "N/A"}
                      </Heading>
                      <Text color={useColorModeValue("gray.600", "gray.400")}>
                        <CallOutlinedIcon style={{ fontSize: "medium" }} />{" "}
                        {submCandidate?.phone || "N/A"}
                      </Text>
                      <Text color={useColorModeValue("gray.600", "gray.400")}>
                        <MailOutlineIcon style={{ fontSize: "medium" }} />{" "}
                        {submCandidate?.email || "N/A"}
                      </Text>
                    </div>
                    <div>
                      <Tooltip
                        hasArrow
                        label="Download Resume"
                        bg="gray.300"
                        color="black"
                      >
                        <a
                          href={objectURL === "no-resume" ? "#" : objectURL}
                          download
                        >
                          <button
                            class="btn bg-white border rounded mb-2 text-break"
                            style={{ width: "100%", height: "auto" }}
                          >
                            {submCandidate?.fileName || "N/A"} <VerticalAlignBottomIcon style={{ color: "grey" }} />
                          </button>
                        </a>
                      </Tooltip>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="cancel"
              onClick={() => handleSubmissionPreviewClose()}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default ResumePreview;

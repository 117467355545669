import {
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
} from "@chakra-ui/react";

import AddIcon from "@mui/icons-material/Add";
import NotFound from "assets/svg/not-found.svg";
import Searchicon from "assets/svg/search-icon.svg";
import axios from "axios";
import config from "config";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { default as Loadar } from "../../../views/Dashboard/Loader";
import AddSubmissionNote from "./AddSubmissionNote";
import { useCookies } from "react-cookie";
import { userActivity } from "Utils";
import moment from "moment";
const NoteSubmissionModel = ({
  candidateNote,
  handleCandidateNoteClose,
  note,
  setNote,
  searchNote,
  getnotes,
  setGetNotes,
  setSearchNote,
  getJobpostdata,
  rowsPerPage,
  page,
  moduleData,
  getCandidate,
  setNotesLoading,
  notesLoading,
}) => {
  const [cookie] = useCookies(["role", "id", "token"]);

  const candidate_ownerName = localStorage?.getItem("candidate_ownerName");
  const userId = cookie.id;
  const userRole = cookie?.role;
  const [editedNoteIndex, setEditedNoteIndex] = useState(-1);
  const [noteSubmitLoading, setNoteSubmitLoading] = useState(false);

  const handleActivitySubmit = async (
    _cdid,
    _activityDes,
    _created_at,
    _submittedBy,
    userId
  ) => {
    const response = await axios
      .post(`${config?.apiUrl}/api/Set_Candidateactivities`, {
        candidateId: _cdid,
        jobActivity: _activityDes,
        created_at: _created_at,
        submittedBy: _submittedBy,
        submittedById:userId
      })
      .then((result) => {
        console.log("success activity")
        // toast.success(result.data.msg, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      })
      .catch((err) => {
        toast.error(err || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleAddNotes = () => {
    document.getElementById("add_note").style.display = "block";
    if (getnotes.length == 0) {
      document.getElementById("notes_list").style.display = "none";
    } else {
      document.getElementById("notes_list").style.display = "block";
    }
  };

  const handleNoteChange = (e) => {
    let name, value;
    name = e.target.name;
    value = e.target.value;
    setNote({ ...note, [name]: value });
  };
  const handleCloseAddNotes = () => {
    document.getElementById("add_note").style.display = "none";
    document.getElementById("notes_list").style.display = "block";
    setNote({
      candidateNote: "",
      candidateId: note?.candidateId,
    });
  };

  const getCandidatNotes = async (id) => {
    try {
      setNotesLoading(true);
      if (id) {
        const response = await axios.get(
          `${config?.apiUrl}/api/get_Candidate_Note/${id}`
        );
        const notedata = response.data.data;
        setGetNotes(notedata);
        setNotesLoading(false);
      } else {
        setNotesLoading(false);
      }
    } catch (error) {
      setNotesLoading(false);
      toast.error(
        error?.message ||
          "Something happened wrong, please try again after sometime.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };
  const handleEditNotes = (index) => {
    setEditedNoteIndex(index);
  };
  const handleCandidateNoteSubmit = async () => {
    const login_firstName = localStorage?.getItem("firstname");
    const login_lastName = localStorage?.getItem("lastname");
    if (!note.candidateNote) {
      toast.error("Please Enter Note", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setNoteSubmitLoading(true);
      await axios
        .post(`${config?.apiUrl}/api/Set_Candidate_Note`, {
          candidateId: note.candidateId,
          candidateNote: note.candidateNote,
          noteSubmitter: `Created by ${login_firstName} ${login_lastName}`,
          created_at: moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
        })
        .then((result) => {
          toast.success(result.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          const candidateFullNmae = result?.data?.fullName;
          const activityOn = `Add candidate note for ${candidateFullNmae}`;
          const activityDo = "Add candidate note";
          const component = "note";
          const id = result?.data?.data?.candidateId;
          const reData =  {
            name:candidateFullNmae,
              id,
              action: "addNote",
              type: "candidate",
              note: note.candidateNote,
            }
          userActivity(
            candidate_ownerName,
            userId,
            component,
            activityDo,
            activityOn,
            userRole,
            reData
          );
          handleActivitySubmit(
            result?.data?.data?.candidateId,
            `Note added to ${candidateFullNmae}`,
            new Date().getTime(),
            candidate_ownerName,
            userId
          );
          document.getElementById("notes_list").style.display = "block";
          document.getElementById("add_note").style.display = "none";
          setNote({
            candidateNote: "",
            candidateId: note?.candidateId,
          });
          getCandidatNotes(note?.candidateId);
          getJobpostdata("", page, rowsPerPage);
          getCandidate && getCandidate(note?.candidateId);
          setNoteSubmitLoading(false);;
        })
        .catch((err) => {
          setNoteSubmitLoading(false);
          toast.error(err?.response?.data?.msg || "Please check details", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  const handleCancelEdit = () => {
    setEditedNoteIndex(-1);
  };
  const handleNoteDelete = async (noteId) => {
    const url = `${config?.apiUrl}/api/delete_Candidate_Note/${noteId}`;
    await axios
      .delete(url)
      .then((response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        const candidateFullNmae = response?.data?.fullName;
        const activityOn = `Delete candidate note for ${candidateFullNmae}`;
        const activityDo = "Delete candidate note";
        const component = "note";
        const reData = {
          action: "deleteNote",
          type: "candidate",
          note: null,
        };
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole,
          reData
        );
        handleActivitySubmit(
          response?.data?.data?.candidateId,
          `Note Delete to ${candidateFullNmae}`,
          new Date().getTime(),
          candidate_ownerName,
          userId
        );
        getCandidatNotes(note?.candidateId);
        getJobpostdata("", page, rowsPerPage);
        getCandidate && getCandidate(note?.candidateId);
      })
      .catch((err) => {
        console.log("error::::::::", err);
        toast.error(err?.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handleSaveNoteEdit = async (id) => {
    const login_firstName = localStorage?.getItem("firstname");
    const login_lastName = localStorage?.getItem("lastname");
    const editedNote = getnotes[editedNoteIndex];
    const { candidateNote } = editedNote;
    const url = `${config?.apiUrl}/api/update_Candidate_Note/${id}`;
    const Credentials = {
      candidateNote,
      updated_at: new Date().getTime(),
      noteSubmitter: `Updated by ${login_firstName} ${login_lastName}`,
    };
    setNoteSubmitLoading(true);
    await axios
      .put(url, Credentials)
      .then((result) => {
        toast.success(result.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        const candidateFullNmae = result?.data?.fullName;
        const activityOn = `Update candidate note for ${candidateFullNmae}`;
        const activityDo = "Update candidate note";
        const component = "note";
        const reData =  {
            name:candidateFullNmae,
            id:result?.data?.data?.candidateId,
            action: "editNote",
            type: "candidate",
            note: note.candidateNote,
          }
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole,
          reData
        );
        handleActivitySubmit(
          result?.data?.data?.candidateId,
          `Note update to ${candidateFullNmae}`,
          new Date().getTime(),
          candidate_ownerName,
          userId
        );
        setEditedNoteIndex(-1);
        getCandidatNotes(note?.candidateId);
        getCandidate && getCandidate(note?.candidateId);
        setNoteSubmitLoading(false);;
      })
      .catch((err) => {
        setNoteSubmitLoading(false);
        console.log("error handleSaveNoteEdit:::", err);
        toast.error(err.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handleEditNoteChange = (value, index) => {
    setGetNotes((prevNotes) => {
      const newNotes = [...prevNotes];
      newNotes[index].candidateNote = value;
      return newNotes;
    });
  };
  return (
    <>
      <div className="model-box-view">
        <Modal
          show={candidateNote}
          onHide={handleCandidateNoteClose}
          backdrop="static"
          keyboard={false}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title className="usertitle">Notes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Box>
              <Row>
                <Col lg={6} md={6}>
                  <InputGroup>
                    <InputLeftElement
                      pointerEvents="none"
                      children={
                        <img src={Searchicon} width="18px" height="18px" />
                      }
                    />
                    <Input
                      variant="auth"
                      fontSize="sm"
                      type="text"
                      placeholder="Search With Keyword"
                      mb="24px"
                      size="lg"
                      value={searchNote}
                      onChange={(e) => {
                        setSearchNote(e.target.value);
                        // filterNotesData(e.target.value);
                      }}
                      style={{ height: "37px" }}
                    />
                  </InputGroup>
                </Col>
                <Col
                  lg={6}
                  md={6}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    height: "36px",
                  }}
                >
                  <button className="addnote_btn" onClick={handleAddNotes}>
                    <AddIcon /> Add Note
                  </button>
                </Col>
              </Row>
            </Box>
            <Box id="add_note">
              <Box>
                <Textarea
                  variant="auth"
                  fontSize="sm"
                  ms="4px"
                  type="text"
                  placeholder="Type Note..."
                  mb="24px"
                  size="lg"
                  name="candidateNote"
                  value={note.candidateNote}
                  onChange={handleNoteChange}
                  style={{ border: "1px solid #ececec", height: "163px" }}
                />
              </Box>
              <Box
                style={{
                  display: "flex",
                  gap: "1rem",
                  justifyContent: "center",
                  marginBottom: "10px",
                }}
              >
                <Button className="cancel" onClick={handleCloseAddNotes}>
                  Cancel
                </Button>
                <Button
                  className="addnote_btn"
                  onClick={() => {
                    handleCandidateNoteSubmit();
                  }}
                  isDisabled={noteSubmitLoading}
                  isLoading={noteSubmitLoading}
                >
                  Save
                </Button>
              </Box>
            </Box>
            <Box id="notes_list">
              {notesLoading ? (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Loadar />
                </Box>
              ) : getnotes && getnotes?.length == 0 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={NotFound}
                      alt="NotFound"
                      style={{ width: "16rem" }}
                    />
                    <p>No Records Found! Please try again....</p>
                  </div>
                </>
              ) : (
                <>
                  <AddSubmissionNote
                    handleSaveNoteEdit={handleSaveNoteEdit}
                    getnotes={getnotes}
                    handleEditNotes={handleEditNotes}
                    handleNoteDelete={handleNoteDelete}
                    handleEditNoteChange={handleEditNoteChange}
                    editedNoteIndex={editedNoteIndex}
                    handleCancelEdit={handleCancelEdit}
                    noteSubmitLoading={noteSubmitLoading}
                  />
                </>
              )}
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button className="cancel" onClick={handleCandidateNoteClose}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default NoteSubmissionModel;

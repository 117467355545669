import { Tooltip } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const ActivityMessage = ({ activity }) => {
  // Action phrase mapping
  const actionPhrases = {
    add: "added a new",
    edit: "updated the",
    update: "updated the",
    view: "viewed the",
  };

  // Route mapping for different roles
  const routeMap = {
    candidate: "details/candidateview",
    job: "details/jobpostview",
    account: "details/accountview",
    contact: "details/contactview",
  };

  // Get components from activity
  const userName = activity?.userName || "Unknown User";
  const component = activity?.component?.toLowerCase() || "";
  const actionStr = activity?.activity || "";
  const reData = activity?.reData || {};

  // Extract action and format role
  const action = actionStr.split(" ")[0].toLowerCase();
  const roleName = component.charAt(0).toUpperCase() + component.slice(1);
  const actionPhrase = actionPhrases[action] || "interacted with";
  const formatCamelCase = (text) => {
    return text
      .replace(/([A-Z])/g, " $1") // Add space before uppercase letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
  };
  // Check if reData is valid and has an ID
  const hasValidRedata = reData && reData.id;

  // Determine the link URL if reData is valid
  const linkUrl = hasValidRedata
    ? `/${routeMap[reData.type]}/${reData.id}`
    : null;

  return (
    <div style={{ display: "flex", gap: "4px", alignItems: "center" }}>
      <span>{userName} </span>
      {hasValidRedata ? (
        <div>
          <span>{actionPhrase} </span>
          <span style={{fontWeight:"bold" }}> {formatCamelCase(reData.type)} </span>
          <Link
            target="_blank"
            to={linkUrl}
            style={{ color: "#023f61", fontWeight: "bold" }}
          >
            {reData.name}
          </Link>
          {reData.note && (
            <span style={{marginLeft:"10px"}}>
              <Tooltip label={reData.note}>
                <button class="note_btn" style={{ color: "rgb(0, 0, 0)" }}>
                  N
                </button>
              </Tooltip>{" "}
            </span>
          )}
        </div>
      ) : (
        <span>{activity?.activityOn}</span>
      )}
    </div>
  );
};

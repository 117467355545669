import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import OtpInput from "react-otp-input";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Logo from "../../assets/img/logo.png";
import signInImage from "../../assets/img/signInImage.png";
import axios from "axios";
import config from "../../config";
import { useFormik } from "formik";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { NavLink, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import "../Dashboard/org.css";
import { userActivity } from "Utils";
import {
  useTwoFactorApiMutation,
  useLoginApiMutation,
} from "../../redux/api/auth";

function SignIn() {
  
  const history = useHistory();
  const [triggerTwoFactorApi] = useTwoFactorApiMutation();
  const [triggerLoginApi] = useLoginApiMutation();
  const textColor = useColorModeValue("gray.700", "white");
  const bgForm = useColorModeValue("white", "navy.800");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookie] = useCookies(["token", "id", "role"]);
  const [otp, setOtp] = useState("");
  const [formState, setFormState] = useState(false);
  const adduser = Yup.object().shape({
    email: Yup.string().email().required("Please Enter Email"),
    password: Yup.string().required("Please Enter Password"),
    otp: formState ? Yup.string().required("Please Enter OTP") : Yup.string(),
    token:Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      otp: "",
      token:cookies?.token || ""
    },
    validationSchema: adduser,
    onSubmit: async (item) => {
      setIsLoading(true);
      try {
      console.log(cookies)
        const inputData = formState
          ? {
              email: item.email.toLowerCase(),
              otp: otp,
              password: item.password,
              token:cookies?.token || ""
            }
          : { email: item.email.toLowerCase(), password: item.password };
        let data;
        if (formState) {
          data = await triggerLoginApi(inputData).unwrap();

        } else {
          data = await triggerTwoFactorApi(inputData).unwrap();
        }

        if (data?.data?.accessToken) {
          setCookie("token", data.data.accessToken, {
            secure: false,
            maxAge: 43200,
            path: "/",
          });
          setCookie("id", data.data._id, {
            secure: false,
            maxAge: 43200,
            path: "/",
          });
          setCookie("role", data.data.role, {
            secure: false,
            maxAge: 43200,
            path: "/",
          });
          localStorage.setItem("role", data.data.role);
          localStorage.setItem("firstname", data.data.firstName);
          localStorage.setItem("lastname", data.data.lastName);
          localStorage.setItem("email", data.data.email);
          localStorage.setItem("mobileNo", data.data.phone);
          localStorage.setItem(
            "candidate_ownerName",
            data.data.firstName + " " + data.data.lastName
          );
          setTimeout(() => {
            history.replace("/admin/dashboard");
          }, 1000);
          userActivity(
            `${data.data.firstName} ${data.data.lastName} `,
            data.data._id,
            "Login",
            `Login`,
            `${data.data.firstName} ${data.data.lastName} logged in`,
            data.data.role
          );
        } else if (data?.success) {
          setFormState(true);
        }

        toast.success(data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

        setIsLoading(false);
      } catch (error) {
        console.log("login err===========", error);

        toast.error(
          error?.data?.msg || error?.data?.message || "Please check details",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setIsLoading(false);
      }
    },
  });

  const [otpSendLoading, setOtpSendLoading] = useState(false);
  const resendOTP = async () => {
    try {
      setOtpSendLoading(true);
      // const { data } = await axios.post(`${config?.apiUrl}/two-fector`, {
      //   email: formik.values.email.toLowerCase(),
      //   password: formik.values.password,
      // });

      const data = await triggerTwoFactorApi({
        email: formik.values.email.toLowerCase(),
        password: formik.values.password,
      }).unwrap();
      toast.success(data?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.log(error);
    } finally {
      setOtpSendLoading(false);
    }
  };

  return (
    <Flex
      h="100%"
      w="100%"
      style={{ position: "fixed", top: "0px", bottom: "0px" }}
    >
      <Flex
        w="100%"
        h="100%"
        alignItems="center"
        justifyContent="center"
        mb="60px"
        mt={{ base: "50px", md: "20px" }}
      >
        <Flex
          zIndex="2"
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          bg={bgForm}
          className="box_flex"
          boxShadow={useColorModeValue(
            "0px 5px 14px rgba(0, 0, 0, 0.05)",
            "unset"
          )}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "1.5rem",
            }}
          >
            <Image src={Logo} alt="Logo" style={{ width: "7.5rem" }} />
          </Box>
          <FormControl>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ mb: "24px" }}>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  variant="auth"
                  fontSize="sm"
                  ms="4px"
                  type="email"
                  placeholder="Your email address"
                  size="lg"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  style={{
                    height: "37px",
                    border:
                      formik.touched.email && formik.errors.email
                        ? "1px solid red"
                        : "",
                  }}
                />
                {formik.touched.email && formik.errors.email && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginTop: "6px",
                    }}
                  >
                    {formik.errors.email}
                  </div>
                )}
              </Box>

              <Box>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Password
                </FormLabel>
                <InputGroup>
                  <Input
                    variant="auth"
                    fontSize="sm"
                    ms="4px"
                    type={isRevealPwd ? "text" : "password"}
                    placeholder="Your Password"
                    size="lg"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    style={{
                      height: "37px",
                      border:
                        formik.touched.password && formik.errors.password
                          ? "1px solid red"
                          : "",
                    }}
                  />
                  <InputRightElement
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                  >
                    {isRevealPwd ? (
                      <VisibilityIcon
                        style={{
                          color: "grey",
                          fontSize: "18px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        style={{
                          color: "grey",
                          fontSize: "18px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </InputRightElement>
                </InputGroup>
                {formik.touched.password && formik.errors.password && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "14px",
                      marginTop: "6px",
                    }}
                  >
                    {formik.errors.password}
                  </div>
                )}
              </Box>
              {formState && (
                <Box sx={{ mt: "24px" }}>
                  <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                    OTP
                  </FormLabel>
                  <OtpInput
                    value={otp}
                    onChange={(e) => {
                      setOtp(e);
                      formik.setFieldValue("otp", e);
                    }}
                    numInputs={5}
                    inputStyle={{
                      width: "100%",
                      paddingBlock: "10px",
                      border: "1px solid",
                      borderRadius: "5px",
                    }}
                    renderSeparator={<span>-</span>}
                    renderInput={(props) => <input {...props} />}
                  />
                  {formik.touched.otp && formik.errors.otp && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginTop: "6px",
                      }}
                    >
                      {formik.errors.otp}
                    </div>
                  )}
                </Box>
              )}
              <HStack
                justifyContent={formState ? "space-between" : "flex-end"}
                my={2}
              >
                {formState && (
                  <Button
                    variant={"link"}
                    type="button"
                    style={{
                      display: "flex",
                      justifyContent: "right",
                      padding: "4px",
                    }}
                    onClick={resendOTP}
                    isLoading={otpSendLoading}
                  >
                    Resend OTP
                  </Button>
                )}
                <NavLink
                  to="/forgot"
                  style={{
                    display: "flex",
                    justifyContent: "right",
                    padding: "4px",
                    textAlign: "left",
                  }}
                >
                  Forgot Password ?
                </NavLink>
              </HStack>

              <Button
                fontSize="15px"
                variant="dark"
                fontWeight="bold"
                w="100%"
                h="45"
                mb="24px"
                type="submit"
                isDisabled={isLoading}
                isLoading={isLoading}
                className="bg_color"
              >
                {formState ? "Sign In" : "Get OTP"}
              </Button>
            </form>
            <ToastContainer />
          </FormControl>
        </Flex>
      </Flex>
      <Box
        overflowX="hidden"
        h="100%"
        w="100%"
        left="0px"
        position="absolute"
        bgImage={signInImage}
        backgroundSize={"cover"}
      >
        <Box w="100%" h="100%" bgSize="cover" bg="blue.500" opacity="0.8"></Box>
      </Box>
    </Flex>
  );
}

export default SignIn;

import { BellIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ForgotIcon from "assets/svg/forgot-icon.svg";
import SignOutIcon from "assets/svg/signout-icon.svg";
import UserIcon from "assets/svg/user-icon.svg";
import axios from "axios";
import {
  ArgonLogoDark,
  ArgonLogoLight,
  ChakraLogoDark,
  ChakraLogoLight,
} from "components/Icons/Icons";
import { ItemContent } from "components/Menu/ItemContent";
import { useEffect, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import routes from "routes.js";
import config from "../../config";
import { SidebarResponsive } from "../Sidebar/Sidebar";
import { userActivity } from "Utils";

export default function HeaderLinks(props) {
  const [candidates, setCandidates] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [profileImage, setProfileImage] = useState("");
  const { variant, children, fixed, scrolled, secondary, onOpen, ...rest } =
    props;
  const [cookie, setCookie, removeCookie] = useCookies(["id", "token", "role"]);

  const [moduleData, setModuleData] = useState([]);
  const userRole = localStorage.getItem("role");

  const email = localStorage.getItem("email");

  const fetchModuleData = async () => {
    try {
      const response = await axios.get(
        `${config.apiUrl}/api/getpermissions/${cookie.role}`
      );
      const modules = response.data.modules;

      const moduleData = {};
      modules.forEach((module) => {
        moduleData[module.module] = module?.permissions.component;
      });
      setModuleData(moduleData);
    } catch (error) {
      console.error("Error while fetching module data:", error);
    }
  };

  useEffect(() => {
    if (cookie?.role && cookie?.role !== "Admin") {
      fetchModuleData();
    }
  }, [cookie?.role]);
  const [filterRoute, setFilterRoutes] = useState([]);
  const filterRoutes = () => {
    let filteredRoutes = [...routes]; // Copying the original routes array

    if (moduleData.submission == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/submission")
      );
    }
    if (moduleData.Jobs == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/jobpost")
      );
    }
    if (moduleData.candidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/candidate")
      );
    }
    if (moduleData.candidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/pendingcandidate")
      );
    }
    if (moduleData.client == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/accounts")
      );
    }
    if (moduleData.contact == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/contact")
      );
    }
    if (moduleData.interviewattained == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewattained")
      );
    }
    if (moduleData.interviewcomplete == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewcomplete")
      );
    }
    if (moduleData.interviewschedule == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/interviewschedule")
      );
    }
    if (moduleData.joined == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/joined")
      );
    }
    if (moduleData.qacheckcandidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/qacheckcandidate")
      );
    }
    if (moduleData.selected == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/selected")
      );
    }
    if (moduleData.setting == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/setting")
      );
    }
    if (moduleData.simpleform == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/simpleform")
      );
    }
    if (moduleData.useractivity == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/useractivity")
      );
    }
    if (moduleData.rejected == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/rejected")
      );
    }
    if (moduleData.backoutcandidate == false) {
      filteredRoutes = filteredRoutes.filter(
        (route) => !route.path.includes("/backoutcandidate")
      );
    }

    setFilterRoutes(filteredRoutes);
  };

  useEffect(() => {
    cookie.role !== "Admin" && filterRoutes();
    cookie.role == "Admin" && setFilterRoutes(routes);
  }, [moduleData]);

  const { colorMode } = useColorMode();
  const history = useHistory();

  const [message, setMessage] = useState("");

  const candidateView = (id) => {
    history.push(`/details/candidateview/${id}`);
  };

  const getWhatsappMassageData = async () => {
    try {
      const response = await axios.get(
        `${config?.apiUrl}/api/getwhatsappmessages`
      );

      if (response.data.status === "success") {
        const birthdayMessages = response.data.data
          .filter((message) => message.messagetype === "birthdaymessage")
          .map((message) => message.message)
          .join(" ");

        console.log("Birthday Messages:", birthdayMessages);
        setMessage(birthdayMessages);
      } else {
        toast.error(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      console.error("Error getting WhatsApp messages:", error.message);
    }
  };

  let navbarIcon =
    fixed && scrolled
      ? useColorModeValue("gray.700", "gray.200")
      : useColorModeValue("white", "gray.200");
  let menuBg = useColorModeValue("white", "navy.800");
  let itemBg = useColorModeValue("white", "navy.800");
  if (secondary) {
    navbarIcon = "white";
  }

  const handleClick = () => {
    userActivity(localStorage.getItem("candidate_ownerName"),localStorage.getItem("id"),'Log out',`Log out`,`${localStorage.getItem("candidate_ownerName")} logged out`,localStorage.getItem("role"))
    localStorage.removeItem("role");
    localStorage.removeItem("candidate_ownerName");
    localStorage.removeItem("firstname");
    localStorage.removeItem("email");
    localStorage.removeItem("mobileNo");
    localStorage.removeItem("lastname");
    removeCookie("token", { path: "/admin" });
    removeCookie("token", { path: "/" });
    removeCookie("id", { path: "/admin" });
    removeCookie("id", { path: "/" });
    removeCookie("role", { path: "/admin" });
    removeCookie("role", { path: "/" });
   
  };

  var text = email;
  var avtar = text?.slice(0, 1);

  const getCandidatedata = () => {
    axios
      .get(`${config?.apiUrl}/api/get_all_search_candidate`, {
        headers: {
          Authorization: `Bearer ${cookie.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const candidateData = response.data.data;
        setCandidates(candidateData);
      })
      .catch((err) => {
        toast.error(
          "Something happened wrong, please try again after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const birthdayNotification = () => {
    const date = new Date();
    let currentDay = String(date.getDate()).padStart(2, "0");
    let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
    let currentDate = `${currentMonth}-${currentDay}`;

    const filterBday = candidates.filter((val) => {
      if (val.dateofBirth) {
        // Convert candidate's dateofBirth to MM-DD format
        let [day, month, year] = val.dateofBirth.split("/");
        let candidateDate = `${month}-${day}`;
        return candidateDate === currentDate;
      }
      return false;
    });

    if (filterBday.length > 0) {
      setNotifications(filterBday);
    }
  };

  const getProfileImage = async (id) => {
    try {
      const response = await axios.get(
        `${config?.apiUrl}/api/get_profileImage/${id}`,
        {
          responseType: "blob",
        }
      );
      setProfileImage(URL.createObjectURL(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (cookie?.token?.length) {
      getCandidatedata();
      getWhatsappMassageData();
      cookie?.id && getProfileImage(cookie?.id);
    } else {
      history.replace("/");
    }
  }, [cookie]);

  useEffect(() => {
    candidates && birthdayNotification();
  }, [candidates]);
  var whatsapp_text = "Happy Birthday";

  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      <Button
        ms="0px"
        px="0px"
        // me={{ sm: "2px", md: "16px" }}
        color={navbarIcon}
        variant="no-effects"
        style={{ gap: "1rem" }}
      >
        <Menu>
          <MenuButton>
            <BellIcon color={navbarIcon} w="25px" h="25px" />
            <div
              class="badge badge-light"
              style={{
                color: "black",
                height: "14px",
                width: "14px",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                position: "absolute",
                top: "5px",
                left: "10px",
              }}
            >
              <span>{notifications.length}</span>
            </div>
          </MenuButton>
          <MenuList p="16px 8px" bg={menuBg}>
            <Flex flexDirection="column">
              {notifications == "" ? (
                <>
                  <span style={{ color: "rgb(113, 128, 150)" }}>
                    <NotificationsOffIcon
                      style={{ color: "rgb(113, 128, 150)", fontSize: "20px" }}
                    />{" "}
                    No Notification
                  </span>
                </>
              ) : (
                <>
                  {notifications?.map((noti) => {
                    return (
                      <>
                        <MenuItem borderRadius="8px" mb="10px">
                          <Box onClick={() => candidateView(noti._id)}>
                            <ItemContent
                              time={noti.dateofBirth}
                              info={`Happy birthday ${noti.firstName} ${noti.lastName}`}
                              aName={noti.firstName}
                              id="notification"
                            />
                          </Box>
                          <Box>
                            <a
                              href={`https://api.whatsapp.com/send/?phone=${noti?.phone}&text=${encodeURIComponent(`Hello ${noti?.firstName} ${noti.lastName}, ${message}`)}`}
                              target="_blank"
                            >
                              <WhatsAppIcon
                                style={{
                                  padding: "1px",
                                  marginLeft: "10px",
                                  color: "green",
                                  fontSize: "36px",
                                  cursor: "pointer",
                                  zIndex: "1001",
                                }}
                              />
                            </a>
                          </Box>
                        </MenuItem>
                      </>
                    );
                  })}
                </>
              )}
            </Flex>
          </MenuList>
        </Menu>

        <Link to="/admin/profile?index=0">
          {profileImage == "" ? (
            <>
              <img
                src={UserIcon}
                alt="usericon"
                width="25px"
                height="25px"
                style={{ margin: "12px" }}
              />
            </>
          ) : (
            <img
              src={profileImage}
              alt=""
              width="30px"
              height="30px"
              style={{ borderRadius: "15px" }}
            />
          )}
        </Link>
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          overlay={
            <Popover id="popover-basic">
              <Popover.Header id="userdetailtitle" color="#627D8C">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Link to="/admin/profile?index=0">
                    <div id="emailavtar">
                      <span
                        style={{ textTransform: "uppercase", fontSize: "16px" }}
                      >
                        {avtar}
                      </span>
                    </div>
                  </Link>
                  <Link to="/admin/profile?index=0">
                    <span className="navtext">{email}</span>
                  </Link>
                </div>
              </Popover.Header>
              <Popover.Header id="userdetailtitle" color="#627D8C">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <Link to="/admin/profile?index=1">
                    <img src={ForgotIcon} alt="Forgoticon" />{" "}
                  </Link>
                  <Link to="/admin/profile?index=1">
                    <span className="navtext">Change Password</span>
                  </Link>
                </div>
              </Popover.Header>
              <Popover.Header id="userdetailtitle" color="#627D8C">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                >
                  <img src={SignOutIcon} alt="signouticon" />
                  <span className="navtext">Log Out</span>
                </div>
              </Popover.Header>
            </Popover>
          }
          rootClose
        >
          <ExpandMoreIcon style={{ color: "white", fontSize: "32px" }} />
        </OverlayTrigger>
      </Button>
      <SidebarResponsive
        hamburgerColor={"white"}
        logo={
          <Stack direction="row" spacing="12px" align="center" justify="center">
            {colorMode === "dark" ? (
              <ArgonLogoLight w="82px" h="27px" />
            ) : (
              <ArgonLogoDark w="82px" h="27px" />
            )}
            <Box
              w="10px"
              h="20px"
              bg={colorMode === "dark" ? "white" : "gray.700"}
            />
            {colorMode === "dark" ? (
              <ChakraLogoLight w="82px" h="21px" />
            ) : (
              <ChakraLogoDark w="82px" h="21px" />
            )}
          </Stack>
        }
        colorMode={colorMode}
        secondary={props.secondary}
        routes={filterRoute}
        {...rest}
      />
    </Flex>
  );
}

import { Button, Stack } from "@chakra-ui/react";
import { TablePagination } from "@material-ui/core";
import React from "react";


function CommonPagination({
  page,
  totalPages,
  rowsPerPage,
  count,
  handleChangePage,
  handleChangeRowsPerPage,
}) {
  const getPageNumbers = () => {
    const pages = [];
    const maxVisiblePages = 5;
    if (totalPages <= maxVisiblePages) {
      for (let i = 0; i < totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (page < maxVisiblePages - 2) {
        for (let i = 0; i < maxVisiblePages - 1; i++) {
          pages.push(i);
        }
        pages.push("...");
        pages.push(totalPages - 1);
      } else if (page > totalPages - (maxVisiblePages - 1)) {
        pages.push(0);
        pages.push("...");
        for (let i = totalPages - (maxVisiblePages - 1); i < totalPages; i++) {
          pages.push(i);
        }
      } else {
        pages.push(0);
        pages.push("...");
        pages.push(page - 1);
        pages.push(page);
        pages.push(page + 1);
        pages.push("...");
        pages.push(totalPages - 1);
      }
    }
    return pages;
  };
  if (totalPages <= 1) {
    return <div className="flex items-center justify-between p-2" style={{ height: "50px" }}></div>;
  }
  return (
    <div>
      <div className="flex items-center justify-between p-2">
        <TablePagination
          component="div"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
          labelRowsPerPage="Rows per page"
          style={{ color: "#718096" }}
          ActionsComponent={() => null}
        />
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          justifyContent={"center"}
        >
          <Button
            onClick={(e) => handleChangePage(e, page - 1)}
            disabled={page === 0}
            style={{
              minWidth: "30px",
              padding: "6px",
              color: page === 0 ? "#718096" : "#023f61",
            }}
          >
            Prev
          </Button>
          {getPageNumbers().map((pageNum, index) => (
            <Button
              key={index}
              onClick={
                pageNum != page &&
                pageNum !== "..."
                  ? (e) => handleChangePage(e, pageNum)
                  : undefined
              }
              style={{
                minWidth: "30px",
                padding: "6px",
                backgroundColor: page === pageNum ? "#023f61" : "transparent",
                color:
                  page === pageNum
                    ? "white"
                    : pageNum === "..."
                      ? "#718096"
                      : "#023f61",
              }}
              disabled={pageNum === "..."}
            >
              {pageNum === "..." ? "..." : pageNum + 1}
            </Button>
          ))}
          <Button
            onClick={(e) => handleChangePage(e, page + 1)}
            disabled={page === totalPages - 1}
            style={{
              minWidth: "30px",
              padding: "6px",
              color: page === totalPages - 1 ? "#718096" : "#023f61",
            }}
          >
            Next
          </Button>
        </Stack>
      </div>
    </div>
  );
}

export default CommonPagination;
  


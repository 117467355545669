import {
  Avatar,
  Box,
  Button,
  Checkbox,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Tooltip,
  useColorModeValue,
  Icon,
  Spinner,
} from "@chakra-ui/react";
import { RiUserForbidFill } from "react-icons/ri";
import moment from "moment";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterListIcon from "@mui/icons-material/FilterList";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import NotFound from "assets/svg/not-found.svg";
import Searchicon from "assets/svg/search-icon.svg";
import axios from "axios";
import CommonSubmissionFile from "components/CommonSubmissionFile/CommonSubmissionFile";
import AccountPreviewModel from "components/Commoncomponent/AccountPreviewModel";
import JobDescriptionEditor from "components/Commoncomponent/JobDescriptionEditor/JobDescriptionEditor";
import NoteModel from "components/Commoncomponent/Note/NoteModel";
import NoteSubmissionModel from "components/Commoncomponent/Note/NoteSubmissionModel";
import CustomizeColumns from "components/CustomizeColumns/CustomizeColumns";
import EditSubmissionStatusModel from "components/EditSubmissionStatusModel/EditSubmissionStatusModel";
import SearchCandidateModel from "components/SearchCandidateModel/SearchCandidateModel";
import SearchJobModel from "components/SearchJobModel/SearchJobModel";
import { City, Country, State } from "country-state-city";
import * as FileSaver from "file-saver";
import { replace, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";
import { FiSearch } from "react-icons/fi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useParams } from "react-router";
import { Link, useHistory, useLocation } from "react-router-dom";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CheckPicker from "rsuite/CheckPicker";
import * as Yup from "yup";
import {
  DEFAULT_PAGE,
  DEFAULT_ROW_PER_PAGE,
  generateQueryParams,
  getAllCities,
  getAllStates,
  getContactNotes,
  getJobNotes,
  getOptionById,
  handleGenerateJD,
  userActivity,
} from "../../Utils";
import config from "../../config";
import {
  defaultSubmHeaderOrders,
  FunnelChartOptions,
  submCandidateHeaderShow,
  ColumnChartsObject,
} from "../../constant";
import ComposeMailForm from "./ComposeMailForm";
import { default as Loadar, default as Loader } from "./Loader";
import Submissionform from "./Submissionform";
import "./org.css";
import ReactSelect from "react-select";
import InputTextSearchBox from "components/InputTextSearchBox/InputTextSearchBox";
import "rsuite/DateRangePicker/styles/index.css";
import { DateRangePicker } from "rsuite";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  parse,
  startOfDay,
  startOfMonth,
  startOfWeek,
  sub,
  subMonths,
} from "date-fns";
import CommonPagination from "../../components/CommonPagination/CommonPagination";
import CountCard from "components/Dashboard/CountCard";
import ReactApexChart from "react-apexcharts";
import CommonCheckPicker from "components/CommonCheckPicker/CommonCheckPicker";
import {
  useAddContactApiMutation,
  useUpdateContactApiMutation,
} from "../../redux/api/accountContact";
import { useSelector } from "react-redux";
import { useDeleteJobApiMutation } from "../../redux/api/jobPost";
import CheckFilter from "../../components/CheckFilter/CheckFilter";
const addJobs = (isEdit) =>
  Yup.object().shape({
    jobtitle: Yup.string().required("Please enter job title"),

    country: Yup.string().required("Please select country"),
    targetdate: Yup.string().required("Please select target date"),
    status: Yup.string().required("Please select status"),
    jobtype: Yup.string().required("Please select Job type"),
    city: Yup.string().required("Please select city"),
    state: Yup.string().required("Please select state"),
    remotestatus: Yup.string().required("Please select remort status"),
    jobdescription: Yup.string().required("Please add job description"),
    experience: Yup.string().required("Please select experience"),
    clientId: Yup.string(),

    numberofPosition: Yup.number().required(
      "Please add number of poistion for job"
    ),
    skill: Yup.string().required("Please add required skill for job"),
    question: Yup.string(),
    placementType: Yup.string().required("Please select placement type"),
    clientpayType: Yup.string().required("Please select clinent pay type"),
    ctc: Yup.string().required("Please add ctc"),
    onetimeplacementbillType: Yup.string(),
    onetimeplacementbillValue: Yup.number(),
    accountmanager: Yup.string(),
    teamleader: Yup.string(),
    recruiter: Yup.string(),
    clientContactName: Yup.string().required(
      "Please select client contact person"
    ),
    email: Yup.string(),
    industry: Yup.string().required("Please select industry"),
    ownerPhone: Yup.string().required("Please add phone number"),
  });
const addContactSchema = Yup.object().shape({
  accountName: Yup.string(),
  accountOwnerName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Account Owner Name Is Required"),
  email: Yup.string().email("Invalid Email").required("Email Is Required"),

  phone: Yup.string().required("Mobile Number Is Required"),

  designation: Yup.string().required("Owner Designation Required"),
  department: Yup.string().required("Please Enter A Department"),
  street: Yup.string().required("Please Provid Street Name "),
  pincode: Yup.string().required("Please Enter Pincode"),
  externalphone: Yup.string(),
  country: Yup.string().required("Please Select Country"),
  state: Yup.string().required("Please Select State"),
  city: Yup.string().required("Please Select City"),
  accountId: Yup.string(),
  clientemail: Yup.string(),
});

const editClient = Yup.object().shape({
  accountName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Account Name Is required"),
  accountOwner: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Account owner Name Is required"),
  email: Yup.string().email("Invalid email").required("Email is Required"),

  phone: Yup.string().required("Mobile number is required"),
  website: Yup.string().required("Website link is required"),
  designation: Yup.string().required("Owner designation required"),
  status: Yup.string(),
  industry: Yup.string(),
  description: Yup.string(),
  contractEnddate: Yup.string(),
  msa: Yup.mixed(),
  signedMsa: Yup.mixed(),
  gstCertificate: Yup.mixed(),
  panCard: Yup.mixed(),
  accountOwnerId: Yup.string(),
});

function Accountview() {
  let { id, sd } = useParams();
  const { userNames: reduxUserNames } = useSelector(
    (state) => state.usersSlice
  );
  const [cookie] = useCookies(["role", "id", "token"]);
  const userId = cookie.id;
  const [totalContactsPages,setTotalContatsPages]=useState(1)
  const [totalPages,setTotalPages]=useState(1)
 const getCurrentLoggedInUserName = getOptionById(userId,reduxUserNames);
  const location = useLocation();
  const queryParams = new URLSearchParams(location?.search);
  const [searchSourceData, setSearchSourceData] = useState([]);
  const [addContactApi, addContactApiResult] = useAddContactApiMutation();
  const [updateContactApi, updateContactApiResult] =
    useUpdateContactApiMutation();
  const [deleteJobApi, deleteJobApiResult] = useDeleteJobApiMutation();
  const [isFilterActiveSourceSearch, setIsFilterActiveSourceSearch] =
    useState(false);
  const iParam = queryParams?.get("i");
  const [uniqueCandidateId,setUniqueCandidateId] = useState("")
  const selectedStartDate = queryParams.get("sd");
  const selectedEndDate = queryParams.get("ed");
  const selectedUserNames = queryParams.get("userNames");
  const selectedStatus = queryParams.get("status");
  const [path, setPath] = useState("");
  const history = useHistory();
  const handleGoBack = () => {
    history?.goBack();
  };
  const userRole = cookie?.role;
  const candidate_ownerName = localStorage?.getItem("candidate_ownerName");

  const [popoverVisible, setPopoverVisible] = useState({});
  // Close filter modal popover
  const handlePopOverClose = (key, visibility) => {
    const newPopoverVisible = key ? { [key]: visibility } : {};
    setPopoverVisible(newPopoverVisible);
  };

  const popOverConfig = useCallback(
    (key) => {
      return {
        trigger: "click",
        placement: "auto",
        rootClose: true,
        show: !!popoverVisible?.[key],
        onToggle: (isShow) => handlePopOverClose(key, isShow),
      };
    },
    [popoverVisible]
  );

  const [note, setNote] = useState({});
  const [submissionModuleData, setSubmissionModuleData] = useState({});
  const [getnotes, setGetNotes] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [searchNote, setSearchNote] = useState("");
  const [notesLoading, setNotesLoading] = useState(false);
  const [jobNote, setJobNote] = useState(false);
  const handleJobNoteShow = () => {
    setJobNote(true);
  };
  const handleJobNoteClose = () => {
    setJobNote(false);
  };
  const [searchSubmissionId, setSearchSubmissionId] = useState([]);
  const [isFilterActiveSubmissionId,setIsFilterActiveSubmissionId]=useState(false)
  const [oldJobId, setOldJobId] = useState("");
  const [jobLoading, setJobLoading] = useState(false);
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(null);
  const [selectedRecruiter, setSelectedRecruiter] = useState(null);
  const [selectedAccountManager, setSelectedAccountManager] = useState(null);
  const [selectedClientContactName, setSelectedClientContactName] =
    useState(null);
  const [searchCandidateOwnerName, setSearchCandidateOwnerName] =
    useState(null);
  const [Id, setId] = useState("");
  const [jdLoading, setJdLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [displayAccount, setDisplayAccount] = useState([]);
  const [applicationStatus, setApplicationStatus] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [billingAddress, setBillingAddress] = useState([]);
  const [shippingAddress, setShippingAddress] = useState([]);
  const [interviewAddress, setInterviewAddress] = useState([]);
  const [clientJobs, setClientJobs] = useState([]);
  const [displayClientJobs, setDisplayClientJobs] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [displaycontacts, setDisplayContacts] = useState([]);
  const [page, setPage] = useState(0);
  const [tablePage, setTablePage] = useState({
    job: 0,
    contact: 0,
  });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [tableRowsPerPage, setTableRowsPerPage] = useState({
    job: 10,
    contact: 10,
  });
  const [searchJobId,setSearchJobId] =useState("")
  const [isFilterActiveJobId,setIsFilterActiveJobId]=useState(false)
  const [clientSubmissionPage, setClientSubmissionPage] = useState(0);
  const [clientSubmissionPageRowsPerPage, setClientSubmissionPageRowsPerPage] =
    useState(10);
  const [searchContactPerson, setSearchContactPerson] = useState("");
  const [searchJobOwner, setSearchJobOwner] = useState([]);
  const [parseAge, setParseAge] = useState("");
  const [parsePercentage, setParsePercentage] = useState("");
  const [matchingSkills, setMatchingSkills] = useState([]);
  const [missingSkills, setMissingSkills] = useState([]);
  const [ParsedSkills, setParsedSkills] = useState([]);
  const [loadingResumeScreening, setLoadingResumeScreening] = useState(false);
  const [parseResumeConclusion, setparseResumeConclusion] = useState("");
  const parts = location.pathname.split("/");
  const router = parts[parts.length - 1];
  const [account, setAccount] = useState({
    accountName: "",
    accountOwner: "",
    status: "",
    designation: "",
    website: "",
    phone: "",
    email: "",
    industry: "",
    description: "",
    contractEnddate: "",
    billingaddress: [],
    shippingaddress: [],
    interviewaddress: [],
  });
  const [editAccountShow, SetEditAccountShow] = useState(false);
  const [accountId, setAccountId] = useState("");
  const [billingFields, setBillingFields] = useState([
    {
      street: "",
      pincode: "",
      nameofBillingAddress: "",
      GSTIN: "",
      country: null,
      countryCode: "",
      state: null,
      stateCode: "",
      city: null,
    },
  ]);

  const [selectedShippingCountry, setSelectedShippingCountry] = useState({
    name: "India",
    isoCode: "IN",
  });
  const [selectedShippingState, setSelectedShippingState] = useState(null);
  const [selectedShippingCity, setSelectedShippingCity] = useState(null);
  const [shippingFields, setShippingFields] = useState([
    {
      street: "",
      pincode: "",
      nameofShippingAddress: "",
      GSTIN: "",
      country: null,
      countryCode: "",
      state: null,
      stateCode: "",
      city: null,
    },
  ]);

  const [selectedInterviewCountry, setSelectedInterviewCountry] = useState({
    name: "India",
    isoCode: "IN",
  });
  const [selectedInterviewState, setSelectedInterviewState] = useState(null);
  const [selectedInterviewCity, setSelectedInterviewCity] = useState(null);
  const [InterviewFields, setInterviewFields] = useState([
    {
      street: "",
      pincode: "",
      country: null,
      state: null,
      city: null,
      timezone: "",
      countryCode: "",
      stateCode: "",
    },
  ]);

  const [selectedCountry, setSelectedCountry] = useState({
    name: "India",
    isoCode: "IN",
  });
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [ViewPost, SetPostShow] = useState(false);
  const [ViewEdit, SetEditShow] = useState(false);
  const [deleteJob, setDeleteJob] = useState(false);
  const [jobid, setJobId] = useState("");
  const [ViewEditStatus, SetEditStatusShow] = useState(false);

  const [searchAllfields, setSearchAllfields] = useState("");
  const [textSearchSubmission, setTextSearchSubmission] = useState("");
  const [searchContactfields, setSearchContactfields] = useState("");
  const [jobpost, setJobpost] = useState({
    jobtitle: "",
    customer: "",
    country: "India",
    targetdate: "",
    recruiter: "",
    teamleader: "",
    status: "",
    jobtype: "",
    city: "",
    state: "",
    remotestatus: "",
    accountmanager: "",
    jobdescription: "",
    experience: "",
    clientContactName: "",
    accountName: "",
    email: "",
    numberofPosition: "",
    skill: "",
    question: "",
    placementType: "",
    clientpayType: "",
    ctc: "",
    onetimeplacementbillType: "",
    onetimeplacementbillValue: "",
  });
  const [searchCandidateLoading, setSearchCandidateLoading] = useState(false);

  const [appliedJob, setAppliedJob] = useState([]);
  const [displayAppliedJob, setDisplayAppliedJob] = useState([]);
  const [deleteSubmission, setDeleteSubmission] = useState(false);
  const [submissionid, setSubmissionId] = useState("");
  const [editSubmission, SetEditSubmission] = useState(false);
  const [editSubmissionStatus, SetEditSubmissionStatus] = useState(false);
  const [submission, setSubmission] = useState({
    status: "Client - Submitted",
    jobId: "",
    clientId: "",
    candidateId: "",
    Questions: "",
    email: "",
    clientemail: "",
    currentCandidateCTCType: "",
    currentCandidateCTC: "",
    expectedCandidateCTCType: "",
    expectedCandidateCTC: "",
    noticePeriod: "",
    relevantExperiance: "",
    availability: "",
    engagement: "",
    locationPreference: "",
    QnA: [],
    file: "",
    fileName: "",
    filePath: "",
  });
  const [pipelinedCandidates, setPipelinedCandidates] = useState([]);
  const [interviewedCandidates, setInterviewedCandidates] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [joinedCandidates, setJoinedCandidates] = useState([]);
  const [onboardedCandidates, setOnboardedCandidates] = useState([]);
  const [activeJobs, setActiveJobs] = useState([]);
  const [deactiveJobs, setDeactiveJobs] = useState([]);

  const [activeField, setActiveField] = useState({ name: "", value: "" });
  const [activeContactField, setActiveContactField] = useState({
    name: "",
    value: "",
  });
  const [searchName, setSearchName] = useState("");
  const [searchemail, setSearchEmail] = useState("");
  const [searchdesignation, setSearchDesignation] = useState("");
  const [searchphone, setSearchPhone] = useState("");
  const [searchaccountname, setSearchAccountname] = useState("");
  const [selecteddate, setSelectedDate] = useState("");
  const [isFilterActiveName, setIsFilterActiveName] = useState(false);
  const [isFilterActiveEmail, setIsFilterActiveEmail] = useState(false);
  const [isFilterActiveDesignation, setIsFilterActiveDesignation] =
    useState(false);
  const [isFilterActivePhone, setIsFilterActivePhone] = useState(false);
  const [isFilterActiveClient, setIsFilterActiveClient] = useState(false);
  const [isFilterActiveDate, setIsFilterActiveDate] = useState(false);
  const [isFilterActiveCreateBy, setIsFilterActiveCreateBy] = useState(false);
  const [isFilterActiveModifyBy, setIsFilterActiveModifyBy] = useState(false);
  const [isFilterActiveUpdateDate, setIsFilterActiveUpdateDate] =
    useState(false);

  const [searchjobtitle, setSearchJobtitle] = useState("");
  const [searchaccountmanager, setSearchAccountmanager] = useState([]);
  const [searchteamleader, setSearchTeamleader] = useState([]);
  const [searchstatus, setSearchStatus] = useState("");
  const [searchrecruiter, setSearchRecruiter] = useState([]);
  const [searchClient, setSearchClient] = useState("");
  const [searchcity, setSearchCity] = useState([]);
  const [searchstate, setSearchState] = useState([]);
  const [searchskill, setSearchSkill] = useState("");
  const [selectedJobdate, setSelectedJobDate] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [searchSubmissionDate, setSearchSubmissionDate] = useState([]);
  const [searchSubmissionUpdateDate, setSearchSubmissionUpdateDate] = useState(
    []
  );

  const [defaultCities, setDefaultCities] = useState([]);
  const [defaultStates, setDefaultStates] = useState([]);

  const [startContactDate, setStartContactDate] = useState("");
  const [endContactDate, setEndContactDate] = useState("");
  const [updatedContactStartDate, setUpdatedContactStartDate] = useState("");
  const [updatedContactEndDate, setUpdatedContactEndDate] = useState("");

  const [searchCreatedBy, setSearchCreatedBy] = useState([]);
  const [searchModifiedBy, setSearchModifiedBy] = useState([]);

  const [isFilterActiveJclient, setIsFilterActiveJclient] = useState(false);
  const [isFilterActiveJobt, setIsFilterActiveJobt] = useState(false);
  const [isFilterActiveCity, setIsFilterActiveCity] = useState(false);
  const [isFilterActiveState, setIsFilterActiveState] = useState(false);
  const [isFilterActiveActMang, setIsFilterActiveActMang] = useState(false);
  const [isFilterActiveContactPerson, setIsFilterActiveContactPerson] =
    useState(false);
  const [isFilterActiveRecruiter, setIsFilterActiveRecruiter] = useState(false);
  const [isFilterActiveSkill, setIsFilterActiveSkill] = useState(false);
  const [isFilterActiveStatus, setIsFilterActiveStatus] = useState(false);
  const [isFilterActiveJdate, setIsFilterActiveJdate] = useState(false);
  const [isFilterActiveTlead, setIsFilterActiveTlead] = useState(false);
  const [isFilterJobActiveOwner, setIsFilterJobActiveOwner] = useState(false);

  const [submissionPreview, SetSubmissionPreview] = useState(false);
  const [accountPreview, SetAccountPreview] = useState(false);
  const [objectURL, setObjectURL] = useState("");

  const [ViewSendMail, SetSendMailShow] = useState(false);
  const [ViewSendMailJob, SetSendMailShowJob] = useState(false);
  const [ViewSendMailSub, SetSendMailShowSub] = useState(false);
  const [checked, SetIschecked] = useState([]);
  const [toemail, SetToemail] = useState("");
  const [ccemail, SetCcemail] = useState("");
  const [bccemail, SetBccemail] = useState("");
  const [subject, SetSubject] = useState("");
  const [mailMessage, SetMailMessage] = useState("");

  const [contactNoteValue, setContactNoteValue] = useState({});
  const [contactNote, setContactNote] = useState(false);
  const [jobScreeningId, setJobScreeningId] = useState([]);
  const [jobSearchActiveField, setJobSearchActiveField] = useState({
    name: "",
    value: "",
  });
  const [searchJobLoading, setSearchJobLoading] = useState(false);

  const [contact, setContact] = useState({
    accountId: "",
    accountName: "",
    clientemail: "",
    accountOwnerName: "",
    department: "",
    designation: "",
    email: "",
    phone: "",
    externalphone: "",
    street: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
  });
  const [contactId, setContactId] = useState("");
  const [addContact, setAddContact] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [deleteContact, setDeleteContact] = useState(false);

  const [candidateNote, setCandidateNote] = useState(false);
  const [ViewSubmheaderOrders, setViewSubmheaderOrders] = useState(false);
  const [submheaderOrders, setSubmHeaderOrders] = useState(
    JSON.parse(localStorage.getItem("submheaderOrders")) ||
      defaultSubmHeaderOrders.filter(
        (header) => submCandidateHeaderShow[header]
      )
  );
  const [displaySubmheaderOrders, setDisplaySubmheaderOrders] = useState(
    defaultSubmHeaderOrders
  );
  const [defaultDisplaySubmHeaderOrders, setDefaultDisplaySubmHeaderOrders] =
    useState(
      JSON.parse(localStorage.getItem("defaultDisplaySubmHeaderOrders")) ||
        submCandidateHeaderShow
    );
  const handleSubmHeaderOrdersshow = () => {
    setViewSubmheaderOrders(true);
  };
  const handleSubmHeaderOrdersClose = () => {
    setViewSubmheaderOrders(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(displaySubmheaderOrders);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDisplaySubmheaderOrders(items);
  };

  const handleApplyClick = () => {
    const temp = Object.keys(defaultDisplaySubmHeaderOrders).filter(
      (key) => defaultDisplaySubmHeaderOrders[key]
    );
    const main = displaySubmheaderOrders.filter((key) => temp.includes(key));
    setSubmHeaderOrders(main);
    toast.success("Columns change successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
    handleSubmHeaderOrdersClose();
  };
  const handleCheckboxChange = (key, isChecked) => {
    const temp = JSON.parse(JSON.stringify(defaultDisplaySubmHeaderOrders));
    temp[key] = isChecked;
    setDefaultDisplaySubmHeaderOrders(temp);
  };

  const handleCandidateNoteShow = () => {
    setCandidateNote(true);
  };
  const handleCandidateNoteClose = () => {
    setCandidateNote(false);
  };

  const getCandidatNotes = async (id) => {
    try {
      setNotesLoading(true);
      if (id) {
        const response = await axios.get(
          `${config?.apiUrl}/api/get_Candidate_Note/${id}`
        );
        const notedata = response.data.data;
        setGetNotes(notedata);
        setNotesLoading(false);
      } else {
        setNotesLoading(false);
      }
    } catch (error) {
      setNotesLoading(false);
      toast.error(
        error?.message ||
          "Something happened wrong, please try again after sometime.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };
  const filename = "Submissions";

  const exportSubmissions = (submissions, filename) => {
    // Check if any submission is selected
    if (checked.length === 0) {
      toast.error("Please select at least one user to export information", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    // Define file properties
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    // Filter submissions based on checked IDs
    const filteredSubmissions = submissions
      .filter((candidate) =>
        checked.some((check) => check.id === candidate._id)
      )
      .map((candidate) => {
        const filteredVal = {};

        displaySubmheaderOrders.forEach((field) => {
          const value = defaultDisplaySubmHeaderOrders[field];
          if (value) {
            switch (field) {
              case "email":
                filteredVal["Email"] = candidate.candidateId?.email;
                break;
              case "clientemail":
                filteredVal["Client Email"] = candidate.clientId?.email;
                break;
              case "firstName":
                filteredVal["First Name"] = candidate.candidateId?.firstName;
                break;
              case "lastName":
                filteredVal["Last Name"] = candidate.candidateId?.lastName;
                break;
              case "client":
                filteredVal["Client"] = candidate.clientId?.accountName;
                break;
              case "owner_name":
                filteredVal["Owner Name"] = candidate?.candidate_ownerName;
                break;
              case "city":
                filteredVal["City"] = candidate.candidateId?.city;
                break;
              case "state":
                filteredVal["State"] = candidate.candidateId?.state;
                break;
              case "phone":
                filteredVal["Phone"] = candidate.candidateId?.phone;
                break;
              case "DOB":
                filteredVal["Date of Birth"] =
                  candidate.candidateId?.dateofBirth;
                break;
              case "address":
                filteredVal["Address"] = candidate.candidateId?.address;
                break;
              case "aadharcardnumber":
                filteredVal["Aadhar Number"] =
                  candidate.candidateId?.AadharcardNumber;
                break;
              case "pancardnumber":
                filteredVal["PAN Number"] =
                  candidate.candidateId?.PancardNumber;
                break;
              case "designation":
                filteredVal["Designation"] = candidate.candidateId?.designation;
                break;
              case "jobtitle":
                filteredVal["Job Title"] = candidate.jobId?.jobtitle;
                break;
              case "workExperience":
                filteredVal["Comapny name"] =
                  candidate.candidateId &&
                  (candidate.candidateId.workExperience.length === 0
                    ? "N/A"
                    : candidate.candidateId.workExperience
                        .map((exp) => ({
                          ...exp,
                          startDate: exp.startDate
                            ? new Date(exp.startDate)
                            : null,
                          endDate: exp.endDate
                            ? exp.endDate.toLowerCase() === "present"
                              ? new Date()
                              : new Date(exp.endDate)
                            : exp.startDate,
                        }))
                        .sort((a, b) => (b.endDate || 0) - (a.endDate || 0))
                        .slice(0, 1)
                        .map((latestExperience, index) => {
                          return latestExperience?.companyname;
                        })[0]);
                break;
              case "educationDetails":
                filteredVal["Education"] =
                  candidate.candidateId &&
                  (candidate?.candidateId?.educationDetails.length === 0
                    ? "N/A"
                    : candidate.candidateId?.educationDetails
                        .map((edu) => ({
                          ...edu,
                          startDate: edu.startDate
                            ? new Date(
                                edu.startDate.replace(
                                  /(\d{2})\/(\d{2})\/(\d{4})/,
                                  "$2/$1/$3"
                                )
                              )
                            : null,
                          endDate: edu.endDate
                            ? new Date(
                                edu.endDate.replace(
                                  /(\d{2})\/(\d{2})\/(\d{4})/,
                                  "$2/$1/$3"
                                )
                              )
                            : null,
                        }))
                        .sort(
                          (a, b) =>
                            (b.endDate || new Date(0)) -
                            (a.endDate || new Date(0))
                        )
                        .slice(0, 1)
                        .map((latestDegree, index) => {
                          return latestDegree?.degree;
                        })[0]);
                break;
              case "experience":
                filteredVal["Experience"] = candidate.candidateId?.experiance;
                break;
              case "gender":
                filteredVal["Gender"] = candidate.candidateId?.gender;
                break;
              case "fullName":
                filteredVal["Full Name"] =
                  `${candidate.candidateId?.firstName} ${candidate.candidateId?.lastName}`;
                break;
              case "currentCandidateCTCType":
                filteredVal["Current CTC Type"] =
                  candidate.currentCandidateCTCType;
                break;
              case "currentCandidateCTC":
                filteredVal["Current CTC"] = candidate.currentCandidateCTC;
                break;
              case "expectedCandidateCTCType":
                filteredVal["Expected CTC Type"] =
                  candidate.expectedCandidateCTCType;
                break;
              case "expectedCandidateCTC":
                filteredVal["Expected CTC"] = candidate.expectedCandidateCTC;
                break;
              case "status":
                filteredVal["Status"] = candidate.status;
                break;
              case "submission_at":
                // Assuming candidate?.created_at contains the ISO 8601 formatted date string
                let isoDate = candidate?.created_at;
                if (isoDate) {
                  let date = new Date(isoDate);
                  let day = date.getUTCDate().toString().padStart(2, "0");
                  let month = (date.getUTCMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  let year = date.getUTCFullYear();
                  let formattedDate = `${day}-${month}-${year}`;
                  filteredVal["Submission date"] = formattedDate;
                }
                break;

              default:
                filteredVal[field] = candidate[field];
            }
          }
        });

        delete filteredVal.Questions;
        delete filteredVal.QnA;

        return filteredVal;
      });

    const ws = XLSX.utils.json_to_sheet(filteredSubmissions);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, filename + fileExtension);
    const activityOn = `Download excel from submission number of download is ${filteredSubmissions?.length}`;
    const activityDo = `Download excel`;
    const component = "acccount";
    userActivity(
      candidate_ownerName,
      userId,
      component,
      activityDo,
      activityOn,
      userRole
    );
    handleCloseModal();
  };

  const [candidates, setCandidates] = useState([]);
  const [searchjob, setSearchJob] = useState("");
  const [searchcandidate, setSearchcandidate] = useState("");
  const [searchJob, SetSearchJob] = useState(false);
  const [searchCandidateName, setSearchCandidateName] = useState("");
  const [searchCandidateDesignation, setSearchCandidateDesignation] =
    useState("");
  const [searchCandidateCity, setSearchCandidateCity] = useState("");
  const [candidateSearchActiveField, setCandidateSearchActiveField] = useState({
    name: "",
    value: "",
  });
  const [searchCandidateModelShow, SetSearchCandidate] = useState(false);
  const [submitSubmission, SetSubmitSubmission] = useState(false);
  const [jobtitle, Setjobtitle] = useState([]);
  const [candidatename, Setcandidatename] = useState([]);
  const [submissionpage, setSubmissionPage] = useState(0);
  const [rowsPerSubmissionPage, setRowsPerSubmissionPage] = useState(10);

  const [candidatesubmissionpage, setCandidateSubmissionPage] = useState(0);
  const [rowsPerCandidateSubmissionPage, setRowsPerCandidateSubmissionPage] =
    useState(10);

  const handleChangeSubmissionPage = (event, newSubmissionPage) => {
    setSubmissionPage(newSubmissionPage);
    if (searchjob.length !== 0 || jobSearchActiveField.value !== "") {
      handleFilterAllFieldStep(
        jobSearchActiveField.name !== ""
          ? jobSearchActiveField.value
          : searchjob,
        newSubmissionPage,
        rowsPerSubmissionPage,
        jobSearchActiveField.name
      );
    } else {
      handleFilterAllFieldStep("",newSubmissionPage, rowsPerSubmissionPage, account?._id);
    }
  };
  const handleChangeRowsPerSubmissionPage = (event) => {
    setRowsPerSubmissionPage(+event.target.value);
    setSubmissionPage(0);
    if (searchjob.length !== 0 || jobSearchActiveField.value !== "") {
      handleFilterAllFieldStep(
        jobSearchActiveField.name !== ""
          ? jobSearchActiveField.value
          : searchjob,
        0,
        +event.target.value,
        jobSearchActiveField.name
      );
    } else {
      handleFilterAllFieldStep("",0, +event.target.value, account?._id);
    }
  };

  const handleChangeCandidateSubmissionPage = (
    event,
    newCandidateSubmissionPage
  ) => {
    setCandidateSubmissionPage(newCandidateSubmissionPage);
    if (
      searchcandidate.length !== 0 ||
      (candidateSearchActiveField.name !== "" &&
        candidateSearchActiveField.value !== "")
    ) {
      filterCandidateData(
        candidateSearchActiveField.value,
        newCandidateSubmissionPage,
        rowsPerCandidateSubmissionPage,
        candidateSearchActiveField.name
      );
    } else {
      filterCandidateData(
        "",
        newCandidateSubmissionPage,
        rowsPerCandidateSubmissionPage
      );
    }
  };

  const handleChangeRowsPerCandidateSubmissionPage = (event) => {
    setRowsPerCandidateSubmissionPage(+event.target.value);
    setCandidateSubmissionPage(0);
    if (
      searchcandidate.length !== 0 ||
      (candidateSearchActiveField.name !== "" &&
        candidateSearchActiveField.value !== "")
    ) {
      filterCandidateData(
        candidateSearchActiveField.value,
        0,
        +event.target.value,
        candidateSearchActiveField.name
      );
    } else {
      filterCandidateData("",0, +event.target.value);
    }
  };

  const handleAddContactShow = () => {
    setAddContact(true);
  };
  const handleAddContactClose = () => {
    contactFormik.resetForm();
    setAddContact(false);
    setContactId("");
    setContact({
      accountId: "",
      accountName: "",
      clientemail: "",
      accountOwnerName: "",
      department: "",
      designation: "",
      email: "",
      phone: "",
      externalphone: "",
      street: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    });
    setSelectedCountry({
      name: "India",
      isoCode: "IN",
    });
    setSelectedState("");
    setSelectedCity("");
  };
  const handleEditContactShow = () => {
    setEditContact(true);
  };
  const handleEditContactClose = () => {
    setEditContact(false);
    setContactId("");
    setContact({
      accountId: "",
      accountName: "",
      clientemail: "",
      accountOwnerName: "",
      department: "",
      designation: "",
      email: "",
      phone: "",
      externalphone: "",
      street: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    });
    setSelectedCountry({
      name: "India",
      isoCode: "IN",
    });
    setSelectedState("");
    setSelectedCity("");
  };
  const handleDeleteContactShow = () => {
    setDeleteContact(true);
  };
  const handleDeleteContactClose = () => {
    setDeleteContact(false);
    setContact({
      accountId: "",
      accountName: "",
      clientemail: "",
      accountOwnerName: "",
      department: "",
      designation: "",
      email: "",
      phone: "",
      externalphone: "",
      street: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    });
    setSelectedCountry({
      name: "India",
      isoCode: "IN",
    });
    setSelectedState("");
    setSelectedCity("");
  };
  const handleSendMailShow = () => {
    SetSendMailShow(true);
  };
  const handleSendMailClose = () => {
    SetSendMailShow(false);
    SetToemail("");
    SetCcemail("");
    SetBccemail("");
    SetSubject("");
    SetMailMessage("");
  };
  const handleSendMailJobShow = () => {
    SetSendMailShowJob(true);
  };
  const handleSendMailJobClose = () => {
    SetSendMailShowJob(false);
  };
  const handleSendMailSubShow = () => {
    SetSendMailShowSub(true);
  };
  const handleSendMailSubClose = () => {
    SetSendMailShowSub(false);
  };
  const handleDeleteJobShow = () => {
    setDeleteJob(true);
  };
  const handleDeleteJobClose = () => {
    setDeleteJob(false);
    setJobpost({
      jobtitle: "",
      customer: "",
      country: "",
      targetdate: "",
      recruiter: "",
      teamleader: "",
      status: "",
      customertype: "",
      jobtype: "",
      city: "",
      state: "",
      remotestatus: "",
      accountmanager: "",
      jobdescription: "",
      experience: "",
      clientId: "",
      clientContactName: "",
      accountName: "",
      email: "",
      numberofPosition: "",
      skill: "",
      question: "",
      placementType: "",
      clientpayType: "",
      ctc: "",
      onetimeplacementbillType: "",
      onetimeplacementbillValue: "",
    });
    setSelectedCountry({
      name: "India",
      isoCode: "IN",
    });
    setSelectedState("");
    setSelectedCity("");
  };
  const handleDeleteSubmissionShow = () => {
    setDeleteSubmission(true);
  };
  const handleDeleteSubmissionClose = () => {
    setDeleteSubmission(false);
    setSubmission({
      jobId: "",
      clientId: "",
      candidateId: "",
      Questions: "",
      email: "",
      clientemail: "",
      status: "Client - Submitted",
      currentCandidateCTCType: "",
      currentCandidateCTC: "",
      expectedCandidateCTCType: "",
      expectedCandidateCTC: "",
      noticePeriod: "",
      relevantExperiance: "",
      availability: "",
      engagement: "",
      locationPreference: "",
      QnA: [],
    });
  };

  const handleEditAccountShow = () => {
    SetEditAccountShow(true);
  };
  const handleEditAccountClose = () => {
    SetEditAccountShow(false);
  };

  const handleAddBilling = () => {
    let newField = {
      street: "",
      pincode: "",
      nameofBillingAddress: "",
      GSTIN: "",
      country: null,
      countryCode: "",
      state: null,
      stateCode: "",
      city: null,
    };
    setBillingFields([...billingFields, newField]);
  };

  const handleFormChange = (index, event) => {
    let data = [...billingFields];
    data[index][event.target.name] = event.target.value;

    setBillingFields(data);
  };

  const handleFormBillingChange = (index, value, action) => {
    let data = [...billingFields];
    if (action.name === "country") {
      data[index].country = value.name;
      data[index].countryCode = value.isoCode;
      data[index].state = null;
      data[index].stateCode = "";
      data[index].city = null;
    } else if (action.name === "state") {
      data[index].state = value.name;
      data[index].stateCode = value.isoCode;
      data[index].city = null;
    } else if (action.name === "city") {
      data[index].city = value.name;
    }
    setBillingFields(data);
  };

  const handleAddShipping = () => {
    let newField = {
      street: "",
      pincode: "",
      nameofShippingAddress: "",
      GSTIN: "",
      country: null,
      countryCode: "",
      state: null,
      stateCode: "",
      city: null,
    };
    setShippingFields([...shippingFields, newField]);
  };

  const handleShippingForm = (index, event) => {
    let data = [...shippingFields];
    data[index][event.target.name] = event.target.value;
    setShippingFields(data);
  };

  const handleFormShippingChange = (index, value, action) => {
    let data = [...shippingFields];
    if (action.name === "country") {
      data[index].country = value.name;
      data[index].countryCode = value.isoCode;
      data[index].state = null;
      data[index].stateCode = "";
      data[index].city = null;
    } else if (action.name === "state") {
      data[index].state = value.name;
      data[index].stateCode = value.isoCode;
      data[index].city = null;
    } else if (action.name === "city") {
      data[index].city = value.name;
    }
    setShippingFields(data);
  };

  const handleAddInterview = () => {
    let newfield = {
      street: "",
      pincode: "",
      timezone: "",
      country: null,
      countryCode: "",
      state: null,
      stateCode: "",
      city: null,
    };
    setInterviewFields([...InterviewFields, newfield]);
  };

  const handleInterviewForm = (index, event) => {
    let data = [...InterviewFields];
    data[index][event.target.name] = event.target.value;
    setInterviewFields(data);
  };

  const handleFormInterviewChange = (index, value, action) => {
    let data = [...InterviewFields];
    if (action.name === "country") {
      data[index].country = value.name;
      data[index].countryCode = value.isoCode;
      data[index].state = null;
      data[index].stateCode = "";
      data[index].city = null;
    } else if (action.name === "state") {
      data[index].state = value.name;
      data[index].stateCode = value.isoCode;
      data[index].city = null;
    } else if (action.name === "city") {
      data[index].city = value.name;
    }
    setInterviewFields(data);
  };

  const [gstCertificate, setGstCertificate] = useState(null);
  const [msa, setMsa] = useState(null);
  const [panCard, setPanCard] = useState(null);
  const [signedMsa, setSignedMsa] = useState(null);
  const [editClientLoading, setEditClientLoading] = useState(false);

  // Edit client Form
  const editAccountFormik = useFormik({
    initialValues: {
      accountName: "",
      accountOwner: "",
      email: "",
      designation: "",
      website: "",
      phone: "",
      status: "",
      industry: "",
      description: "",
      contractEnddate: "",
      msa: null,
      signedMsa: null,
      gstCertificate: null,
      panCard: null,
      accountOwnerId: "",
    },
    validationSchema: editClient,
    onSubmit: async (datas) => {
      setEditClientLoading(true);
      const formData = new FormData();

      Object.keys(datas).forEach((key) => {
        formData.append(key, datas[key]);
      });

      formData.append("billingaddress", JSON.stringify(billingFields));
      formData.append("shippingaddress", JSON.stringify(shippingFields));
      formData.append("interviewaddress", JSON.stringify(InterviewFields));
      const url = `${config?.apiUrl}/api/update_Account/${accountId}`;

      try {
        const response = await axios.put(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        const accountData = response?.data?.data;
        const activityOn = `Update account ${accountData?.accountName} `;
        const activityDo = "update Account";
        const component = "account";
        const reData =  {
          name:`${accountData?.accountName}`,
          id: accountData._id,
          action: "editAccount",
          type: "account",
          note: null,
        }
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole,
          reData
        );
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        const ContactURL = `${config?.apiUrl}/api/update_Contact_name/${accountData?._id}`;
        const Credentials = {
          accountName: accountData?.accountName,
        };
        try {
          await axios.put(ContactURL, Credentials)
          handleFilterContactAllFieldStep("", 0, 10);
        } catch (err) {
          toast.error(err?.response?.data?.msg || "Please check details", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        handleFilterAllFieldSteps("",0, 10);
        setEditClientLoading(false);
        handleEditAccountClose();
        getAccount(id);
      } catch (err) {
        setEditClientLoading(false);
        console.log("err editAccountFormik", err);
        toast.error(err?.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  });

  const getEditContactData = (data) => {
    editAccountFormik.setValues({
      accountName: data.accountName,
      // accountOwner: data.accountOwner,
      accountOwner: `${data?.accountOwnerData?.firstName} ${data?.accountOwnerData?.lastName}`,
      email: data.email,
      designation: data.designation,
      website: data.website,
      phone: data.phone,
      status: data.status,
      industry: data.industry,
      description: data.description,
      contractEnddate: data.contractEnddate,
      msa: data?.companydetail?.msa,
      signedMsa: data?.companydetail?.signedMsa,
      accountOwnerId: data?.accountOwnerId ? data?.accountOwnerId : "",
    });
    setGstCertificate(data?.companydetail?.gstCertificate);
    setMsa(data?.companydetail?.msa);
    setPanCard(data?.companydetail?.panCard);
    setSignedMsa(data?.companydetail?.signedMsa);
    setBillingFields(data?.billingaddress);
    setShippingFields(data?.shippingaddress);
    setInterviewFields(data?.interviewaddress);
  };
  const companySaveFile = (e, fileType) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      switch (fileType) {
        case "gstCertificate":
          setGstCertificate(selectedFile);
          editAccountFormik.setFieldValue("gstCertificate", selectedFile);
          break;
        case "msa":
          setMsa(selectedFile);
          editAccountFormik.setFieldValue("msa", selectedFile);
          break;
        case "panCard":
          setPanCard(selectedFile);
          editAccountFormik.setFieldValue("panCard", selectedFile);
          break;
        case "signedMsa":
          setSignedMsa(selectedFile);
          editAccountFormik.setFieldValue("signedMsa", selectedFile);
          break;
        default:
          break;
      }
    }
  };

  const contactFormik = useFormik({
    initialValues: {
      accountOwnerName: "",
      department: "",
      designation: "",
      email: "",
      phone: "",
      externalphone: "",
      street: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
      countryCode: "",
      stateCode: "",
    },
    validationSchema: addContactSchema,
    onSubmit: async (item) => {
      try {
        let data = {
          accountId: account?._id,
          accountName: account?.accountName,
          accountOwnerName: item.accountOwnerName,
          department: item.department,
          designation: item.designation,
          email: item.email,
          phone: item.phone,
          externalphone: item.externalphone,
          street: item.street,
          country: item.country,
          state: item.state,
          city: item.city,
          pincode: item.pincode,
          countryCode: item?.countryCode || selectedState?.countryCode || "IN",
          stateCode: item?.stateCode || selectedState?.isoCode,
          created_by: userId || "",
          modified_by: userId || "",
        };
        const reduxAccountContactApiResponse =
          await addContactApi(data).unwrap();
        toast.success(reduxAccountContactApiResponse?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        const activityOn = `For  ${data?.accountName} create a new contact person ${data?.accountOwnerName}`;
        const activityDo = "Add new contact person";
        const component = "contact";
        const reData =  {
          name:`${data?.accountName}`,
          id: data._id,
          action: "addContact",
          type: "contact",
          note: null,
        }
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole,
          reData
        );
        setContact({
          accountId: "",
          accountName: "",
          clientemail: "",
          accountOwnerName: "",
          department: "",
          designation: "",
          email: "",
          phone: "",
          externalphone: "",
          street: "",
          country: "",
          state: "",
          city: "",
          pincode: "",
        });
        setSelectedCountry({
          name: "India",
          isoCode: "IN",
        });
        setSelectedState("");
        setSelectedCity("");
        handleAddContactClose();
        getContactdata(page, rowsPerPage, account._id);
      } catch (error) {
        toast.error(error?.data?.msg || "Error while adding contact", {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleAddContactClose();
      }
    },
  });
  const handleAddContactEmailSend = async (data) => {
    const { accountId } = data;
    const clientInfo = accounts.find((account) => account._id === accountId);
    const clientemail = clientInfo?.email;

    if (!data) {
      toast.error("Invalid credentials", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    const response = await axios
      .post(`${config?.apiUrl}/api/addContact_EmailSend`, {
        email: clientemail,
        data: data,
      })
      .then((result) => {
        console.log({ result });
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const handleContactInfoEmailSend = async (data) => {
    const { email, accountId } = data;
    const clientInfo = accounts.find((account) => account._id === accountId);

    if (!data) {
      toast.error("Invalid credentials", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    const response = await axios
      .post(`${config?.apiUrl}/api/ContactInfo_EmailSend`, {
        email: email,
        clientInfo: clientInfo,
      })
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFilterContactAllFieldStep = async (
    string = "",
    page = 0,
    rowsPerPage = 10,
    fieldName
  ) => {
    const url = `${config?.apiUrl}/api/get_all_search_Account_Contacts?page=${page + 1}&limit=${rowsPerPage}&text=${fieldName == "text" ? string : searchContactfields}&accountOwnerName=${fieldName == "accountOwnerName" ? string : searchName}&email=${fieldName == "email" ? string : searchemail}&designation=${fieldName == "designation" ? string : searchdesignation}&accountName=${account._id}&createStartDate=${fieldName == "created_at" ? string?.startDate || "" : startContactDate || ""}&createEndDate=${fieldName == "created_at" ? string?.endDate || "" : endContactDate}&updatedStartDate=${fieldName == "updated_at" ? string?.startDate || "" : updatedContactStartDate}&updatedEndDate=${fieldName == "updated_at" ? string?.endDate || "" : updatedContactEndDate}&created_by=${fieldName == "created_by" ? string : searchCreatedBy || ""}&modified_by=${fieldName == "modified_by" ? string : searchModifiedBy || ""}`;
    const queryObject = {
      phone: fieldName == "phone" ? string : searchphone,
    };
    try {
      setLoading(true);
      const filterdata = await axios.get(url, {
        params: queryObject,
        headers: {
          Authorization: `Bearer ${cookie.token}`,
          "Content-Type": "application/json",
        },
      });

      const accountContactData = filterdata?.data?.data;

      const totalContact = filterdata?.data?.totalContact;
      accountContactData.totalContact = totalContact;
      setTotalContatsPages(Math.ceil(totalContact/rowsPerPage))
      setDisplayContacts(accountContactData);
      setLoading(false);
      handlePopOverClose(false);
      return accountContactData;
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      handlePopOverClose(false);
    }
  };
  const [contactOldName, setContactOldName] = useState("");
  const editContactFormik = useFormik({
    initialValues: {
      accountId: "",
      accountName: "",

      accountOwnerName: "",
      department: "",
      designation: "",
      email: "",
      phone: "",
      externalphone: "",
      street: "",
      country: "",
      state: "",
      city: "",
      pincode: "",
    },
    validationSchema: addContactSchema,
    onSubmit: async (item) => {
      try {
        const Credentials = {
          accountId: item.accountId,
          accountName: item.accountName,
          accountOwnerName: item.accountOwnerName,
          department: item.department,
          designation: item.designation,
          email: item.email,
          phone: item.phone,
          externalphone: item.externalphone,
          street: item.street,
          country: item.country,
          state: item.state,
          city: item.city,
          pincode: item.pincode,
          countryCode: item?.countryCode || selectedState?.countryCode || "IN",
          stateCode: item?.stateCode || selectedState?.isoCode,
          modified_by: userId || "",
        };
        const updateContactApiResponse = await updateContactApi({
          contactId,
          data: Credentials,
        }).unwrap();
        toast.success(updateContactApiResponse?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        const data = updateContactApiResponse?.data;
        const activityOn = `Update  ${data?.accountOwnerName} contact person of ${data?.accountName}`;
        const activityDo = "Update contact person";
        const component = "contact";
        const reData =  {
          name:`${data?.accountName}`,
          id: data._id,
          action: "editContact",
          type: "contact",
          note: null,
        }
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole,
          reData
        );
        // if (data?.accountOwnerName !=  contactOldName) {
        //     const jobupdateurl = `${config?.apiUrl}/api/update_submitterName/${data?.accountId}`;
        //     const updateData = {
        //       oldClientConatctName: contactOldName,
        //       newClientConatctName: data?.accountOwnerName,
        //     };
        //     await axios.put(jobupdateurl, updateData).then((resp) => {
        //    });
        // }
        handleEditContactClose();
        await  handleFilterAllFieldStep("",0, 10, account?._id);
        await getContactdata(page, rowsPerPage, account?._id);
        // handleFilterContactAllFieldStep("", 0, 10);
      } catch (error) {
        console.log("=error", error);

        toast.error(error?.data?.msg || "Failed to update contact", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    },
  });

  const editContactdata = (val) => {
    debugger
    editContactFormik.setValues({
      accountId: val.accountId,
      accountName: val.accountName,

      accountOwnerName: `${val.accountOwnerData?.firstName} ${val.accountOwnerData?.lastName}`,
      department: val.department,
      designation: val.designation,
      email: val.email,
      phone: val.phone,
      externalphone: val.externalphone,
      street: val.street,
      country: val.country,
      state: val.state,
      city: val.city,
      pincode: val.pincode,
    });
    setContactOldName(val?.accountOwnerName);
    setSelectedState({
      name: val?.state,
      countryCode: val?.countryCode,
      isoCode: val?.stateCode,
    });
    setSelectedCity({
      name: val?.city,
      countryCode: val?.countryCode,
      isoCode: val?.stateCode,
    });
  };

  const handleDeleteContact = async (e) => {
    e.preventDefault();
    const url = `${config?.apiUrl}/api/revome_Contact/${contactId}`;
    await axios
      .delete(url)
      .then((response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        const contactData = response?.data?.data;
        const activityOn = `For  ${contactData?.accountName} delete contact person ${contactData?.accountOwnerName}`;
        const activityDo = "delete contact person";
        const component = "contact";
        const reData = {
          action: "deleteContact",
          type: "contact",
          note: null,
        };
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole,
          reData
        );
        handleDeleteContactClose();
        getContactdata(page, rowsPerPage, account._id);
        // handleFilterContactAllFieldStep("", 0, 10);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSubmissionPreviewShow = () => {
    SetSubmissionPreview(true);
  };
  const handleAccountPreviewShow = () => {
    SetAccountPreview(true);
  };
  const handleSubmissionPreviewClose = () => {
    SetSubmissionPreview(false);
    SetAccountPreview(false);
    setSelectedType("");
    setObjectURL("");
    setSubmission({
      jobId: "",
      clientId: "",
      candidateId: "",
      Questions: "",
      email: "",
      clientemail: "",
      status: "Client - Submitted",
      currentCandidateCTCType: "",
      currentCandidateCTC: "",
      expectedCandidateCTCType: "",
      expectedCandidateCTC: "",
      noticePeriod: "",
      relevantExperiance: "",
      availability: "",
      engagement: "",
      locationPreference: "",
      QnA: [],
    });
  };

  const handleInputs = (e) => {
    let name, value;
    name = e?.target?.name;
    value = e?.target?.value;
    if (name && value) {
      setSubmission({ ...submission, [name]: value });
    } else {
      setSubmission({});
    }
  };

  const saveSubmissionFile = (e) => {
    setSubmission({
      ...submission,
      fileName: e.target.files[0].name,
      filePath: e.target.files[0].path,
      file: e.target.files[0],
    });
  };

  const handlePostShow = () => {
    SetPostShow(true);
    getContactdata(0, 10, account._id, "jobModel");
  };
  const handlePostClose = () => {
    formik.resetForm();
    SetPostShow(false);

    setSelectedTeamLeader(null);
    setSelectedRecruiter(null);
    setSelectedAccountManager(null);
    setSelectedClientContactName(null);
    setJobpost({
      jobtitle: "",
      customer: "",
      country: "",
      targetdate: "",
      recruiter: "",
      teamleader: "",
      status: "",
      customertype: "",
      jobtype: "",
      city: "",
      state: "",
      remotestatus: "",
      accountmanager: "",
      jobdescription: "",
      experience: "",
      clientId: "",
      clientContactName: "",
      accountName: "",
      email: "",
      numberofPosition: "",
      skill: "",
      question: "",
      placementType: "",
      clientpayType: "",
      ctc: "",
      onetimeplacementbillType: "",
      onetimeplacementbillValue: "",
    });
    setSelectedCountry({
      name: "India",
      isoCode: "IN",
    });
    setSelectedState("");
    setSelectedCity("");
    getContactdata(0, 10, account._id);
  };

  const handleEditShow = () => {
    SetEditShow(true);
    getContactdata(0, 10, account._id, "jobModel");
  };
  const handleEditClose = () => {
    editFormik.resetForm();
    setGstCertificate("");
    getContactdata(0, 10, account._id);
    setSelectedTeamLeader(null);
    setSelectedRecruiter(null);
    setSelectedAccountManager(null);
    setSelectedClientContactName(null);
    setMsa("");
    setSignedMsa("");
    setPanCard("");
    SetEditShow(false);
    setJobId("");
    setSelectedCountry({
      name: "India",
      isoCode: "IN",
    });
    setSelectedState("");
    setSelectedCity("");
    setJobpost({
      jobtitle: "",
      customer: "",
      country: "",
      targetdate: "",
      recruiter: "",
      teamleader: "",
      status: "",
      customertype: "",
      jobtype: "",
      city: "",
      state: "",
      remotestatus: "",
      accountmanager: "",
      jobdescription: "",
      experience: "",
      clientId: "",
      clientContactName: "",
      accountName: "",
      email: "",
      numberofPosition: "",
      skill: "",
      question: "",
      placementType: "",
      clientpayType: "",
      ctc: "",
      onetimeplacementbillType: "",
      onetimeplacementbillValue: "",
    });
  };

  const handleEditStatusShow = () => {
    SetEditStatusShow(true);
  };
  const handleEditStatusClose = () => {
    SetEditStatusShow(false);
    setJobId("");
    setSelectedCountry({
      name: "India",
      isoCode: "IN",
    });
    setSelectedState("");
    setSelectedCity("");
    setJobpost({
      jobtitle: "",
      customer: "",
      country: "",
      targetdate: "",
      recruiter: "",
      teamleader: "",
      status: "",
      customertype: "",
      jobtype: "",
      city: "",
      state: "",
      remotestatus: "",
      accountmanager: "",
      jobdescription: "",
      experience: "",
      clientId: "",
      clientContactName: "",
      accountName: "",
      email: "",
      numberofPosition: "",
      skill: "",
      question: "",
      placementType: "",
      clientpayType: "",
      ctc: "",
      onetimeplacementbillType: "",
      onetimeplacementbillValue: "",
    });
  };

  const handleAppliedJobClientEmailSend = async (formData) => {
    const { clientemail, email, clientId, jobId } = formData;
    const jobDetails = displayClientJobs.find((job) => job._id === jobId);
    const jobTitle = jobDetails?.jobtitle;

    if (!formData) {
      toast.error("Invalid credentials", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    const response = await axios
      .post(`${config?.apiUrl}/api/Job_Applied_Client_EmailSend`, {
        email: clientemail,
        candidateEmail: email,
        clientId: clientId,
        jobTitle: jobTitle,
      })
      .then((result) => {
        toast.success(result?.data?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.msg ||
            "Something happened wrong, please try again after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const handleAppliedJobEmailSend = async (formData) => {
    const { email, jobId } = formData;

    const jobDetails = displayClientJobs.find((job) => job._id === jobId);

    if (!formData) {
      toast.error("Invalid credentials", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    const response = await axios
      .post(`${config?.apiUrl}/api/Job_Applied_EmailSend`, {
        email: email,
        jobDetails: jobDetails,
      })
      .then((result) => {
        toast.success(result?.data?.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.msg ||
            "Something happened wrong, please try again after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const handleCreateSubmission = () => {
    filterCandidateData("",candidatesubmissionpage, rowsPerCandidateSubmissionPage);
    SetSearchJob(true);
    handleFilterAllFieldStep("",0, 10, account?._id, true);
  };
  const handleCreateSubmissionClose = () => {
    SetSearchJob(false);
    handleFilterAllFieldStep("",0, 10, account?._id);
    clearJobFilter();
  };

  const handleSearchCandidateShow = () => {
    SetSearchCandidate(true);
  };
  const handleSearchCandidateClose = () => {
    SetSearchCandidate(false);
    clearCandidateSearchFilter();
  };

  const handleSubmitSubmissionShow = () => {
    SetSubmitSubmission(true);
  };
  const handleSubmitSubmissionClose = () => {
    SetSubmitSubmission(false);
    setSubmission({
      jobId: "",
      clientId: "",
      candidateId: "",
      Questions: "",
      email: "",
      clientemail: "",
      status: "Client - Submitted",
      currentCandidateCTCType: "",
      currentCandidateCTC: "",
      expectedCandidateCTCType: "",
      expectedCandidateCTC: "",
      noticePeriod: "",
      relevantExperiance: "",
      availability: "",
      engagement: "",
      locationPreference: "",
      QnA: [],
    });
    setId("");
  };

  const handleEditSubmissionShow = () => {
    SetSubmitSubmission(true);
    SetEditSubmission(true);
  };

  const handleEditSubmissionStatusShow = () => {
    SetEditSubmissionStatus(true);
  };
  const handleEditSubmissionStatusClose = () => {
    SetEditSubmissionStatus(false);
    setSubmission({
      jobId: "",
      clientId: "",
      candidateId: "",
      Questions: "",
      email: "",
      clientemail: "",
      status: "Client - Submitted",
      currentCandidateCTCType: "",
      currentCandidateCTC: "",
      expectedCandidateCTCType: "",
      expectedCandidateCTC: "",
      noticePeriod: "",
      relevantExperiance: "",
      availability: "",
      engagement: "",
      locationPreference: "",
      QnA: [],
    });
  };


  const filterCandidateData = async (
    string = "",
    page = 0,
    limit = 10,
    fieldName
  ) => {
    
      const url = `${config?.apiUrl}/api/get_all_search_candidate?page=${page + 1}&limit=${limit}&firstName=${searchCandidateName}&city=${searchCandidateCity}&candidate_ownerName=${fieldName == "candidate_ownerName" ? string : searchCandidateOwnerName?.value || ""}`;

      const queryObject = {
        text: searchcandidate,
        designation: searchCandidateDesignation,
        candidate_id: uniqueCandidateId,
      };
      try {
        setSearchCandidateLoading(true);
        const filterdata = await axios.get(url, {
          params: queryObject,
          headers: {
            Authorization: `Bearer ${cookie.token}`,
            "Content-Type": "application/json",
          },
        });

        let temp = filterdata.data.data;

        const totalCandidates = filterdata.data.totalCandidates;
        temp.totalCandidates = totalCandidates;
        setCandidates(temp);
        setSearchCandidateLoading(false);
      } catch (error) {
        toast.error(error, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSearchCandidateLoading(false);
      }
    
  };

  const AccontPreview = async (id, selection) => {
    try {
      const result = await axios.get(
        `${config?.apiUrl}/api/download_Account_Resume/${id}?selection=${selection}`,
        {
          responseType: "blob",
        }
      );
      setObjectURL(URL.createObjectURL(result.data));

      const activityOn = `view ${selection} of account ${account?.accountName} `;
      const activityDo = "view file";
      const component = "account";
      const reData =  {
        name:`${account?.accountName}`,
        id: account._id,
        action: "accountView",
        type: "account",
        note: null,
      }
      userActivity(
        candidate_ownerName,
        userId,
        component,
        activityDo,
        activityOn,
        userRole,
        reData
      );
      setLoading(false);
    } catch (error) {
      console.log("error AccontPreview", error);
      setObjectURL("no-resume");
      setLoading(false);
      toast.error("Error while Preview file. Try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const SubmissionPreview = async (submissionid) => {
    try {
      const result = await axios.get(
        `${config?.apiUrl}/api/download_Submission_Resume/${submissionid}`,
        {
          responseType: "blob",
        }
      );
      setObjectURL(URL.createObjectURL(result.data));
      setLoading(false);
    } catch (error) {
      setObjectURL("no-resume");
      toast.error("Error while Preview file. Try again later", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const [oldStatus, setOldStatus] = useState("");

  useEffect(() => {
    submissionid && setOldStatus(submission?.status);
  }, [submissionid]);

  const handleEditSubmissionStatus = async (e) => {
    if (e) {
      e.preventDefault();
    }
    const { status } = submission;
    const url = `${config?.apiUrl}/api/appliedjob_update/${submissionid}`;
    const Credentials = { status, candidate_ownerName, userId };

    await axios
      .put(url, Credentials)
      .then(async (response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });

        const resonoseData = response.data.data;
        const candidateId = await resonoseData?.candidateId;
        const updateData = {
          status: resonoseData?.status,
        };
        if (
          resonoseData?.status === "Joined" ||
          resonoseData?.status === "Submitted" ||
          resonoseData?.status === "Client - Submitted" ||
          resonoseData?.status === "Internal-Submitted"
        ) {
          updateData.candidateStatus =
            resonoseData?.status === "Client - Submitted"
              ? "QA Check"
              : resonoseData?.status === "Internal-Submitted"
                ? "Submitted"
                : resonoseData?.status;
        }
        const url = `${config?.apiUrl}/api/update_candidate/${candidateId}`;
        await axios.put(url, updateData).then((response) => {
          const mailSend = response.data.data?.sendMail;
          // if (mailSend) {
          //   handleUpdateStatusChangeMailSendToCandidate(
          //     submission,
          //     resonoseData,
          //     oldStatus
          //   );
          // }
        });

        setOldStatus("");
        handleEditSubmissionStatusClose();
        handleFilterAllFieldSteps(
          "",
          clientSubmissionPage,
          clientSubmissionPageRowsPerPage,
          submissionModuleData
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const job_ownerName = localStorage?.getItem("candidate_ownerName");

  const formik = useFormik({
    initialValues: {
      jobtitle: "",
      customer: "",
      country: "India",
      targetdate: "",
      recruiter: "",
      teamleader: "",
      status: "Active",
      customertype: "",
      jobtype: "Full Time",
      city: "",
      state: "",
      remotestatus: "Onsite",
      accountmanager: "",
      jobdescription: "",
      experience: "",
      clientId: "",
      clientContactName: "",
      accountName: "",
      email: "",
      numberofPosition: "",
      skill: "",
      question: "",
      placementType: "One Time Placement",
      clientpayType: "Annual Salary",
      ctc: "",
      onetimeplacementbillType: "",
      onetimeplacementbillValue: "",
      industry: "",
      ownerPhone: "",
      stateCode: "",
      countryCode: "",
    },
    validationSchema: addJobs(),
    onSubmit: async (data) => {
      setJobLoading(true);
      const jobData = {
        jobtitle: data.jobtitle,
        customer: data.customer,
        country: data.country,
        targetdate: data.targetdate,
        recruiter: data.recruiter,
        teamleader: data.teamleader,
        status: data.status,
        jobtype: data.jobtype,
        city: data.city,
        state: data.state,
        remotestatus: data.remotestatus,
        accountmanager: data.accountmanager,
        jobdescription: data.jobdescription,
        experience: data.experience,

        clientId: account?._id,
        clientContactName: data.clientContactName,
        numberofPosition: data.numberofPosition,
        skill: data.skill,
        question: data.question,
        placementType: data.placementType,
        clientpayType: data.clientpayType,
        ctc: data.ctc,

        onetimeplacementbillType: data.onetimeplacementbillType,
        onetimeplacementbillValue: data.onetimeplacementbillValue,
        job_ownerName: userId,
        job_updateOwnerName: userId,
        job_ownerName_Id: userId,
        job_updateOwnerName_Id: userId,
        industry: data.industry || "",
        ownerPhone: data.ownerPhone,
        countryCode: data?.countryCode || selectedState?.countryCode || "IN",
        stateCode: data?.stateCode || selectedState?.isoCode,
      };

      const response = await axios
        .post(`${config?.apiUrl}/api/add_jobpost`, jobData)
        .then((result) => {
          toast.success(result.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          const data = result?.data?.data;
          const activityOn = `Add new ${data?.jobtitle} job `;
          const activityDo = "Add Job";
          const component = "account";
          const reData =  {
            name:`${data?.jobtitle}`,
            id: data._id,
            action: "addJob",
            type: "job",
            note: null,
          }
          userActivity(
            candidate_ownerName,
            userId,
            component,
            activityDo,
            activityOn,
            userRole,
            reData,
          );

          const jbid = result.data.data._id;

          const jbtitle = result.data.data?.jobtitle;

          const clientid = result?.data?.data?.clientId;
          handleActivitySubmit(
            jbid,
            `Job " ${jbtitle} "has been created by ${candidate_ownerName}`,
            new Date().getTime(),
            clientid,
          );

          setSelectedState(null);
          setSelectedCity(null);
          setJobpost({
            jobtitle: "",
            customer: "",
            country: "",
            targetdate: "",
            recruiter: "",
            teamleader: "",
            status: "",
            customertype: "",
            jobtype: "",
            city: "",
            state: "",
            remotestatus: "",
            accountmanager: "",
            jobdescription: "",
            experience: "",
            clientId: "",
            clientContactName: "",
            accountName: "",
            email: "",
            numberofPosition: "",
            skill: "",
            question: "",
            placementType: "",
            clientpayType: "",
            ctc: "",
            onetimeplacementbillType: "",
            onetimeplacementbillValue: "",
          });
          setSelectedCountry({
            name: "India",
            isoCode: "IN",
          });
          setSelectedState("");
          setSelectedCity("");
          handlePostClose();
          handleFilterAllFieldStep("",page, rowsPerPage, account?._id);

          setSelectedTeamLeader(null);
          setSelectedRecruiter(null);
          setSelectedAccountManager(null);
          setSelectedClientContactName(null);
          setJobLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.msg || "Please check details", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setJobLoading(false);
        });
    },
  });

  const handleCandidateActivitySubmit = async (
    candidateId,
    _activityDes,
    _created_at,
    _submitterName
  ) => {
    const response = await axios
      .post(`${config?.apiUrl}/api/Set_Candidateactivities`, {
        candidateId: candidateId,
        jobActivity: _activityDes,
        created_at: _created_at,
        submittedBy: _submitterName,
      })
      .then((result) => {
        console.log("success activity");
        // toast.success(result.data.msg, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      })
      .catch((err) => {
        toast.error(err?.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const [updateOwnerName, setUpdateOwnerName] = useState("");

  const getJobs = (val) => {
    editFormik.setValues({
      jobtitle: val.jobtitle,
      customer: val.customer,
      country: val.country,
      targetdate: val.targetdate,
      recruiter: val.recruiter,
      teamleader: val.teamleader,
      status: val.status,
      jobtype: val.jobtype,
      city: val.city,
      state: val.state,
      remotestatus: val.remotestatus,
      accountmanager: val.accountmanager,
      jobdescription: val.jobdescription,
      experience: val.experience,
      clientId: val?.clientId?._id,
      clientContactName: val.clientContactName,
      numberofPosition: val.numberofPosition,
      skill: val.skill,
      question: val.question,
      placementType: val.placementType,
      clientpayType: val.clientpayType,
      ctc: val.ctc,
      onetimeplacementbillType: val.onetimeplacementbillType,
      onetimeplacementbillValue: val.onetimeplacementbillValue,
      job_ownerName: job_ownerName,
      industry: val.industry || "",
      ownerPhone: val?.ownerPhone
        ? val?.ownerPhone
        : val?.jobOwnerDetails?.phone,
    });
    setUpdateOwnerName(
      `${val?.job_owener_data?.firstName} ${val?.job_owener_data?.lastName}`
    );

    setSelectedTeamLeader(getOptionById(val.teamleader, reduxUserNames));
    setSelectedRecruiter(getOptionById(val.recruiter, reduxUserNames));
    setSelectedAccountManager(
      getOptionById(val.accountmanager, reduxUserNames)
    );

    setSelectedState({
      name: val?.state,
      countryCode: val?.countryCode,
      isoCode: val?.stateCode,
    });
    setSelectedCity({
      name: val?.city,
      countryCode: val?.countryCode,
      isoCode: val?.stateCode,
    });
    setSelectedClientContactName({
      value: val?.clientContactName_data?._id,
      label: val?.clientContactName_data?.accountOwnerName,
    });
  };

  const editFormik = useFormik({
    initialValues: {
      jobtitle: "",
      customer: "",
      country: "India",
      targetdate: "",
      recruiter: "",
      teamleader: "",
      status: "",
      jobtype: "",
      city: "",
      state: "",
      remotestatus: "",
      accountmanager: "",
      jobdescription: "",
      experience: "",
      clients: "",
      clientId: "",
      clientContactName: "",
      accountName: "",
      email: "",
      numberofPosition: "",
      skill: "",
      question: "",
      placementType: "",
      clientpayType: "",
      ctc: "",
      onetimeplacementbillType: "",
      onetimeplacementbillValue: "",
      industry: "",
      ownerPhone: "",
    },
    validationSchema: addJobs(),
    onSubmit: async (data) => {
      setJobLoading(true);
      const url = `${config?.apiUrl}/api/update_jobpost/${jobid}`;
      const Credentials = {
        jobtitle: data.jobtitle,
        customer: data.customer,
        country: data.country,
        targetdate: data.targetdate,
        recruiter: data.recruiter,
        teamleader: data.teamleader,
        status: data.status,
        jobtype: data.jobtype,
        city: data.city,
        state: data.state,
        remotestatus: data.remotestatus,
        accountmanager: data.accountmanager,
        jobdescription: data.jobdescription,
        experience: data.experience,

        clientId: account._id,
        clientContactName: data.clientContactName,

        numberofPosition: data.numberofPosition,
        skill: data.skill,
        question: data.question,
        placementType: data.placementType,
        clientpayType: data.clientpayType,
        ctc: data.ctc,
        onetimeplacementbillType: data.onetimeplacementbillType,
        onetimeplacementbillValue: data.onetimeplacementbillValue,
        job_updateOwnerName: userId,
        job_updateOwnerName_Id: userId,
        industry: data.industry || "",
        ownerPhone: data.ownerPhone,
        countryCode: data?.countryCode || selectedState?.countryCode || "IN",
        stateCode: data?.stateCode || selectedState?.isoCode,
      };

      await axios
        .put(url, Credentials)
        .then((response) => {
          toast.success(response.data.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
          const data = response?.data?.data;

          const activityOn = `Update ${data?.jobtitle} job `;
          const activityDo = "Update Job";
          const component = "account";
          const reData =  {
            name:`${data?.jobtitle}`,
            id: data._id,
            action: "editJob",
            type: "job",
            note: null,
          }
          userActivity(
            candidate_ownerName,
            userId,
            component,
            activityDo,
            activityOn,
            userRole,
            reData
          );
          const jbid = response.data.data._id;

          handleActivitySubmit(
            jbid,
            `Job ${data?.jobtitle} has been edited by "${candidate_ownerName}"`,
            new Date().getTime(),
            data.clientId
          );
          handleEditClose();
          // getclientJobsdata(page, rowsPerPage, account.accountName);
          handleFilterAllFieldStep("", 0, 10);
          setSelectedTeamLeader(null);
          setSelectedRecruiter(null);
          setSelectedAccountManager(null);
          setSelectedClientContactName(null);
          setJobLoading(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.msg || "Please check details", {
            position: toast.POSITION.TOP_RIGHT,
          });
          console.log("err::::::::::::", err);
          setJobLoading(false);
        });
    },
  });
  const job_updateOwnerName = localStorage?.getItem("candidate_ownerName");

  const handleActivitySubmit = async (
    _jbid,
    _activityDes,
    _created_at,
    _clientId
  ) => {
    const response = await axios
      .post(`${config?.apiUrl}/api/Set_Jobactivities`, {
        jobId: _jbid,
        jobActivity: _activityDes,
        created_at: _created_at,
        clientId: _clientId,
      })
      .then((result) => {
        // toast.success(result.data.msg, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.msg ||
            "Something happened wrong, please try again after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    try {
      const deleteJobApiResponse = await deleteJobApi(jobid).unwrap();
      toast.success(deleteJobApiResponse.msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
      const jobData = deleteJobApiResponse?.data;
      const activityOn = `Delete job ${jobData?.jobs?.jobtitle} `;
      const activityDo = "Delete job";
      const component = "account";
      userActivity(
        candidate_ownerName,
        userId,
        component,
        activityDo,
        activityOn,
        userRole
      );
      handleDeleteJobClose();
      handleFilterAllFieldStep(
        activeField.value,
        page,
        rowsPerPage,
        activeField.name
      );
    } catch (error) {
      toast.error(error?.data?.msg || "Failed to delete job", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDeleteSubmission = async (e) => {
    e.preventDefault();
    const url = `${config?.apiUrl}/api/remove_submission/${submissionid}`;
    await axios
      .delete(url)
      .then((response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        const submissionData = response?.data?.data;
        const activityOn = `Delete submission ${submissionData?.candidateId} `;
        const activityDo = "Delete submission";
        const component = "account";
        const reData = {
          action: "deleteSubmission",
          type: "submission",
          note: null,
        };
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole,
          reData
        );

        handleDeleteSubmissionClose();
        // getAppliedJobdata(
        //   clientSubmissionPage,
        //   clientSubmissionPageRowsPerPage,
        //   submissionModuleData
        // );
        handleFilterAllFieldStep("", page,rowsPerPage);
        setSubmissionId("");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  const handleResumeMatching = async (e) => {
    setLoadingResumeScreening(true);
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", submission?.file);
    formData.append("fileName", submission?.fileName);
    formData.append("filePath", submission?.filePath);
    formData.append("jobRequirement", JSON.stringify(jobpost));
    formData.append("requiredSkill", submission?.skill);
    await axios
      .post(`${config?.apiUrl}/api/resume_screening`, formData)
      .then((result) => {
        toast.success(result.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        const pasreConclusion = result.data.data?.conclusion;
        setLoadingResumeScreening(false);
        const requiredSkill = submission?.skill || submission?.candidateId?.skill;
        const rdata = JSON.parse(result.data.data?.jsonResponse);
        const pAge = rdata?.age;
        setParseAge(pAge);
        const percentMatch = result.data.data?.percentageMatch;
        setParsePercentage(percentMatch);
        let parsedSkills = Array.isArray(rdata.skills)
          ? rdata.skills
          : rdata.skills.split(",");

        const requiredSkillsArray = requiredSkill?.split(",");

        const matchedSkills = parsedSkills?.filter((parsedSkill) => {
          return requiredSkillsArray.some((reqSkill) => {
            // Check if the beginning of any word in parsedSkill matches the beginning of reqSkill
            const parsedWords = parsedSkill.toLowerCase().split(" ");
            const reqWords = reqSkill.toLowerCase().split(" ");

            return parsedWords.some((parsedWord) =>
              reqWords.some(
                (reqWord) =>
                  parsedWord.startsWith(reqWord) ||
                  reqWord.startsWith(parsedWord)
              )
            );
          });
        });

        const missingSkills = requiredSkillsArray?.filter(
          (reqSkill) =>
            !matchedSkills.some(
              (matchedSkill) =>
                matchedSkill.toLowerCase().startsWith(reqSkill.toLowerCase()) ||
                reqSkill.toLowerCase().startsWith(matchedSkill.toLowerCase())
            )
        );

        setMatchingSkills(matchedSkills);
        setMissingSkills(missingSkills);
        setParsedSkills(parsedSkills);
        setparseResumeConclusion(pasreConclusion);
        setLoadingResumeScreening(false);
        document.getElementsByClassName("matched_skill")[0].style.display =
          "block";
        document.getElementsByClassName("conclusion")[0].style.display =
          "block";
        if (matchedSkills.length === 0) {
          toast.error("You don't match the required skills.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err.response && err.response.status == 404) {
          // Display toaster for file not found in the database
          toast.error("Resume file not found in the database.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoadingResumeScreening(false);
        } else {
          // Display a general error toaster
          toast.error(
            "Please check your resume or try again later. If the issue persists, please add your resume.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          setLoadingResumeScreening(false);
        }
        setLoading(false);
        setLoadingResumeScreening(false);
      });
  };

  const handleContactChangePage = (event, newPage) => {
    setTablePage((prev) => {
      return {
        ...prev,
        contact: newPage,
      };
    });
    if (
      searchContactfields.length !== 0 ||
      activeContactField.name !== "" ||
      activeContactField.value
    ) {
      handleFilterContactAllFieldStep(
        activeContactField.value,
        newPage,
        tableRowsPerPage['contact']
        ,
        activeContactField.name
      );
    } else {
      getContactdata(newPage, tableRowsPerPage['contact'], account._id);
    }
  };

  const handleContactChangeRowsPerPage = (event) => {
    console.log('handleContactChangeRowsPerPage',+event.target.value);
    let rowperPage = +event.target.value;
    // setRowsPerPage(+event.target.value);
    setTableRowsPerPage((prev) => {
      return {
        ...prev,
        contact: rowperPage,
      };
    });
    // setPage(0);
    setTablePage((prev) => {
      return {
        ...prev,
        contact: DEFAULT_PAGE,
      };
    });
    if (
      searchContactfields.length !== 0 ||
      activeContactField.name !== "" ||
      activeContactField.value
    ) {
      handleFilterContactAllFieldStep(
        activeContactField.value,
        0,
        +event.target.value,
        activeContactField.name
      );
    } else {
      getContactdata(0, +event.target.value, account._id);
    }
  };

  const handleChangePage = (event, newPage, tableName) => {
    // setPage(newPage);
    if (tableName === "job") {
      setTablePage((prev) => {
        return {
          ...prev,
          job: newPage,
        };
      });
      // getAppliedJobdata(
      //   newPage,
      //   clientSubmissionPageRowsPerPage,
      //   submissionModuleData
      // );
    } else {
      setTablePage((prev) => {
        return {
          ...prev,
          contact: newPage,
        };
      });
      // getContactdata(newPage, tableRowsPerPage.tableName, account._id);
    }

    if (
      searchAllfields.length !== 0 ||
      activeField.name !== "" ||
      activeField.value
    ) {
      handleFilterAllFieldStep(
        activeField.value,
        newPage,
        tableRowsPerPage[tableName],
        activeField.name
      );
    } else {
      handleFilterAllFieldStep(
        activeField.value,
        newPage,
        tableRowsPerPage[tableName],
        activeField.name
      );
    }
  };

  const handleChangeRowsPerPage = (event, tableName) => {
    let rowperPage = +event.target.value;
    if (tableName === "job") {
      // getAppliedJobdata(0, +event.target.value, submissionModuleData);
      setTableRowsPerPage((prev) => {
        return {
          ...prev,
          job: rowperPage,
        };
      });
    } else {
      getContactdata(0, rowperPage, account._id);
      setTableRowsPerPage((prev) => {
        return {
          ...prev,
          contact: rowperPage,
        };
      });
    }
    // setTablePage((prev) => {
    //   return {
    //     ...prev,
    //     job: 0,
    //     contact: 0,
    //   };
    // });
    if (
      searchAllfields.length !== 0 ||
      activeField.name !== "" ||
      activeField.value
    ) {
      handleFilterAllFieldStep(
        activeField.value,
        0,
        rowperPage,
        activeField.name
      );
    } else {
      handleFilterAllFieldStep(
        activeField.value,
        0,
        rowperPage,
        activeField.name
      );
    }
  };

  const getAccount = async (userid) => {
    try {
      const response = await axios.get(
        `${config?.apiUrl}/accountview/${userid}`
      );
      const accountdata = response.data.data;
      setAccount(accountdata);
      setDisplayAccount(accountdata);
      setBillingAddress(accountdata.billingaddress);
      setShippingAddress(accountdata.shippingaddress);
      setInterviewAddress(accountdata.interviewaddress);
      if (accountdata.accountName) {
        getContactdata(page, rowsPerPage, accountdata._id);

        // getclientJobsdata(page, rowsPerPage, accountdata.accountName);
      }
    } catch (err) {
      toast.error(err?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  const findDate = (changeDate) => {
    const selectedDate = new Date(changeDate ? changeDate : selectedJobdate);
    const day = String(selectedDate.getDate()).padStart(2, "0");
    const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
    const year = selectedDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const handleFilterAllFieldStep = async (
    string = "",
    page = 0,
    rowsPerPage = 10,
    fieldName,
    isActive
  ) => {
    const baseUrl = `${config?.apiUrl}/api/get_all_search_jobpost?page=${page + 1}&limit=${rowsPerPage}&city=${fieldName == "city" ? string : searchcity}&state=${fieldName == "state" ? string : searchstate}&accountmanager=${fieldName === "accountmanager" ? string : searchaccountmanager || ""}&recruiter=${fieldName === "recruiter" ? string : searchrecruiter || ""}&teamleader=${fieldName === "teamleader" ? string : searchteamleader || ""}&status=${fieldName === "status" ? string : searchstatus}&clientContactName=${fieldName == "clientContactName" ? string : searchContactPerson}&clientName=${account._id}&job_ownerName_Id=${fieldName === "job_ownerName" ? string : searchJobOwner || ""}&startDate=${fieldName == "created_at" ? string?.startDate || "" : startDate}&endDate=${fieldName == "created_at" ? string?.endDate || "" : endDate}&industry=${fieldName == "industry" ? string : searchIndustry || ""}`;

    const url = `${baseUrl}&activeJob=${true}`;

    const queryObject = {
      text: fieldName == "text" ? string : searchAllfields,
      jobtitle: fieldName == "jobtitle" ? string : searchjobtitle,
      skill: fieldName == "skill" ? string : searchskill,
      job_id: fieldName == "job_id" ? string : searchJobId,
    };
    try {
      setJobLoading(true);
      const filterdata = await axios.get(url, {
        params: queryObject,
        headers: {
          Authorization: `Bearer ${cookie.token}`,
          "Content-Type": "application/json",
        },
      });

      const clientJobsData = filterdata.data.data;
      const totalJobPosts = filterdata?.data?.totalJobPosts;
      clientJobsData.totalJobPosts = totalJobPosts;
      setClientJobs(clientJobsData)
      setTotalPages(Math.ceil(totalJobPosts/rowsPerPage))
      selectedStartDate && selectedEndDate && setClientJobs(clientJobsData);
      setDisplayClientJobs(clientJobsData);
      setJobLoading(false);
      handlePopOverClose(false);
      return temp;
    } catch (error) {
      toast.error(error, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setJobLoading(false);
      handlePopOverClose(false);
    }
  };
  const filterJobData = async (filterValue, filterField) => {
    let page = 0;
    // setPage(0);
    setTablePage((prev) => {
      return {
        ...prev,
        job: DEFAULT_PAGE,
      };
    });
    new Promise((resolve, reject) => {
      setTimeout(async () => {
        switch (filterField) {
          case "clientName":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "clientName"
            );
            setIsFilterActiveJclient(filterValue?.length !== 0 ? true : false);
            break;

          case "jobTitle":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "jobtitle"
            );
            setIsFilterActiveJobt(filterValue?.length !== 0 ? true : false);
            break;

          case "city":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "city"
            );
            setIsFilterActiveCity(filterValue?.length !== 0 ? true : false);
            break;

          case "state":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "state"
            );
            setIsFilterActiveState(filterValue?.length !== 0 ? true : false);
            break;

          case "contactperson":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "clientContactName"
            );
            setIsFilterActiveContactPerson(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "accountmanager":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "accountmanager"
            );
            setIsFilterActiveActMang(filterValue?.length !== 0 ? true : false);
            break;

          case "recruiter":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "recruiter"
            );
            setIsFilterActiveRecruiter(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "teamleader":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "teamleader"
            );
            setIsFilterActiveTlead(filterValue?.length !== 0 ? true : false);
            break;

          case "skill":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "skill"
            );
            setIsFilterActiveSkill(filterValue?.length !== 0 ? true : false);
            break;

          case "status":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "status"
            );
            setIsFilterActiveStatus(filterValue?.length !== 0 ? true : false);
            break;

          case "owner_name":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "job_ownerName"
            );
            setIsFilterJobActiveOwner(filterValue?.length !== 0 ? true : false);
            break;

          case "date":
            await handleFilterAllFieldStep(
              {
                startDate: filterValue?.startDate,
                endDate: filterValue?.endDate,
              },
              page,
              rowsPerPage,
              "created_at"
            );
            setIsFilterActiveJdate(
              filterValue?.startDate && filterValue?.endDate ? true : false
            );
            break;

          case "industry":
            await handleFilterAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "industry"
            );
            setIsFilterActiveIndustry(filterValue?.length !== 0 ? true : false);
            break;

          default:
            break;
        }
        resolve();
      }, 0);
    });
  };

  const clearCandidateSearchFilter = () => {
    setSearchcandidate("");
    setSearchCandidateName("");
    setCandidateSearchActiveField({ name: "", value: "" });
    setSearchCandidateDesignation("");
    setSearchCandidateCity("");
    setSearchCandidateOwnerName(null);
    filterCandidateData("",candidatesubmissionpage, rowsPerCandidateSubmissionPage);
  };

  const clearJobFilter = () => {
    setTablePage((prev) => {
      return {
        ...prev,
        job: DEFAULT_PAGE,
      };
    });
    setTableRowsPerPage((prev) => {
      return {
        ...prev,
        job: DEFAULT_ROW_PER_PAGE,
      };
    });
    setSearchJobId("");
    setIsFilterActiveJobId(false)
    setSearchAllfields("");
    setSearchJobtitle("");
    setSearchAccountmanager([]);
    setSearchStatus("");
    setSearchRecruiter([]);
    setSearchTeamleader([]);
    setSearchClient("");
    setSearchCity([]);
    setSearchState([]);
    setSearchSkill("");
    setSearchContactPerson("");
    setSelectedJobDate("");
    setStartDate("");
    setEndDate("");
    setSearchJob("");
    setJobSearchActiveField({ name: "", value: "" });
    setActiveField({ name: "", value: "" });
    // setPage(0);
    setRowsPerPage(10);
    setSearchJobOwner([]);
    setSearchIndustry([]);
    setIsFilterActiveIndustry(false);
    setIsFilterActiveJclient(false);
    setIsFilterActiveJobt(false);
    setIsFilterActiveCity(false);
    setIsFilterActiveState(false);
    setIsFilterActiveActMang(false);
    setIsFilterActiveContactPerson(false);
    setIsFilterActiveRecruiter(false);
    setIsFilterActiveTlead(false);
    setIsFilterActiveSkill(false);
    setIsFilterActiveStatus(false);
    setIsFilterActiveJdate(false);
    setIsFilterJobActiveOwner(false);
    // setDisplayClientJobs(clientJobs);
    handleFilterAllFieldStep("",submissionpage, rowsPerSubmissionPage, account._id);
    history.push(`/details/accountview/${id}`, {
      replace: true,
    });
  };

  const getContactdata = async (
    page = 0,
    rowsPerPage = 10,
    clientName = "",
    modelShow
  ) => {
    setLoading(true);
    try {
      const url =
        modelShow !== undefined
          ? `${config?.apiUrl}/api/get_client_contact/${id}`
          : `${config?.apiUrl}/api/get_all_search_Account_Contacts?page=${page + 1}&limit=${rowsPerPage}&text=${searchAllfields}&accountName=${clientName}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${cookie.token}`,
          "Content-Type": "application/json",
        },
      });
      const contactsData = response.data.data;
      const totalContact = response.data.totalContact;
      contactsData.totalContact = totalContact;
      setContacts(contactsData);
      setDisplayContacts(contactsData);
      setTotalContatsPages(Math.ceil(totalContact/rowsPerPage))
      setLoading(false);
    } catch (err) {
      toast.error(err?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const filterContactData = async (filterValue, filterField) => {
    await new Promise((resolve) => {
      // setPage(0);
      setTablePage((prev) => {
        return {
          ...prev,
          contact: DEFAULT_PAGE,
        };
      });
      let page = 0;
      setTimeout(async () => {
        switch (filterField) {
          case "Name":
            await handleFilterContactAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "accountOwnerName"
            );
            setIsFilterActiveName(filterValue?.length !== 0 ? true : false);
            break;

          case "email":
            await handleFilterContactAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "email"
            );
            setIsFilterActiveEmail(filterValue?.length !== 0 ? true : false);
            break;

          case "phone":
            await handleFilterContactAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "phone"
            );
            setIsFilterActivePhone(filterValue?.length !== 0 ? true : false);
            break;

          case "designation":
            await handleFilterContactAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "designation"
            );
            setIsFilterActiveDesignation(
              filterValue?.length !== 0 ? true : false
            );
            break;
          case "created_by":
            await handleFilterContactAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "created_by"
            );
            setIsFilterActiveCreateBy(filterValue?.length !== 0 ? true : false);
            break;
          case "modified_by":
            await handleFilterContactAllFieldStep(
              filterValue,
              page,
              rowsPerPage,
              "modified_by"
            );
            setIsFilterActiveModifyBy(filterValue?.length !== 0 ? true : false);
            break;

          case "date":
            await handleFilterContactAllFieldStep(
              {
                startDate: filterValue?.startDate,
                endDate: filterValue?.endDate,
              },
              page,
              rowsPerPage,
              "created_at"
            );
            setIsFilterActiveDate(
              filterValue?.startDate && filterValue?.endDate ? true : false
            );
            break;

          case "updatedate":
            await handleFilterContactAllFieldStep(
              {
                startDate: filterValue?.startDate,
                endDate: filterValue?.endDate,
              },
              page,
              rowsPerPage,
              "updated_at"
            );
            setIsFilterActiveUpdateDate(
              filterValue?.startDate && filterValue?.endDate ? true : false
            );
            break;

          default:
            break;
        }
        resolve();
      }, 0);
    });
  };

  const clearContactFilter = () => {
    setTablePage((prev) => {
      return {
        ...prev,
        contact: DEFAULT_PAGE,
      };
    });
    setTableRowsPerPage((prev) => {
      return {
        ...prev,
        contact: DEFAULT_ROW_PER_PAGE,
      };
    });
    setSearchName("");
    setSearchEmail("");
    setSearchDesignation("");
    setSearchPhone("");
    setSearchAccountname("");
    setSelectedDate("");
    setStartContactDate("");
    setEndContactDate("");
    setSearchCreatedBy([]);
    setSearchModifiedBy([]);
    setUpdatedContactEndDate("");
    setUpdatedContactStartDate("");
    setActiveContactField({ name: "", value: "" });
    setSearchContactfields("");
    setIsFilterActiveName(false);
    setIsFilterActiveEmail(false);
    setIsFilterActiveDesignation(false);
    setIsFilterActivePhone(false);
    setIsFilterActiveClient(false);
    setIsFilterActiveDate(false);
    setIsFilterActiveCreateBy(false);
    setIsFilterActiveModifyBy(false);
    setIsFilterActiveUpdateDate(false);
    getContactdata(page, rowsPerPage, account._id);
  };

  const handleEditStatus = async (e) => {
    e.preventDefault();
    const { status } = jobpost;
    const url = `${config?.apiUrl}/api/update_jobpost/${jobid}`;
    const Credentials = { status, job_updateOwnerName };
    await axios
      .put(url, Credentials)
      .then((response) => {
        toast.success(response.data.msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleEditStatusClose();
        handleFilterAllFieldStep(
          activeField.value,
          page,
          rowsPerPage,
          activeField.name
        );
        const activityOn = `Status has been change to ${response.data?.data?.status} for jobtitle ${response.data?.data?.jobtitle} `;
        const activityDo = "Update status";
        const component = "account";
        const reData =  {
          name:`${response.data?.data?.jobtitle}`,
          id: account._id,
          action: "editJob",
          type: "job",
          note: null,
        }
        userActivity(
          candidate_ownerName,
          userId,
          component,
          activityDo,
          activityOn,
          userRole,
          reData
        );
      })
      .catch((err) => {
        toast.error(err?.response?.data?.msg || "Please check details", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  // const getAppliedJobdata = async (
  //   getpage = 0,
  //   rowperpage = 10,
  //   response = {}
  // ) => {
  //   try {
  //     setLoading(true);
  //     const response = await axios.get(
  //       // `${config?.apiUrl}/api/get_appliedjob/${id}?page=${getpage + 1}&limit=${rowperpage}&submissionOwnerName=${(response?.view == "ownrecords" || response?.view == "ownandteamrecords") && cookie?.role !== "Admin" ? userId : ""}&permissionValue=${response?.view == "ownandteamrecords" ? "ownandteamrecords" : ""}`,
  //       // `${config?.apiUrl}/api/get_appliedjob/${id}?page=${getpage + 1}&limit=${rowperpage}&submissionOwnerName=${(response?.view == "ownrecords" || response?.view == "ownandteamrecords") && cookie?.role !== "Admin" ? userId : ""}&permissionValue=""`,
  //       `${config?.apiUrl}/api/get_appliedjob/${id}?page=${getpage + 1}&limit=${rowperpage}`  ,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${cookie.token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     if (response.data.status !== "error") {
  //       const appliedJobData = response.data.data;
  //       const totalSubmissionCount = response.data.totalSubmissionCount;
  //       const teams = response?.data?.team;
  //       appliedJobData.totalSubmissionCount = totalSubmissionCount;
  //       setAppliedJob(appliedJobData);
  //       setDisplayAppliedJob(appliedJobData);
  //       setTeamData(teams);
  //       setLoading(false);

  //       const pipelnefiltered = appliedJobData.filter((val) => {
  //         return val.status === "Client - Submitted";
  //       });
  //       setPipelinedCandidates(pipelnefiltered);

  //       const Interviewfiltered = appliedJobData.filter((val) => {
  //         return val.status === "Interview Scheduled";
  //       });
  //       setInterviewedCandidates(Interviewfiltered);

  //       const Selectedfiltered = appliedJobData.filter((val) => {
  //         return val.status === "Selected";
  //       });
  //       setSelectedCandidates(Selectedfiltered);

  //       const Acceptedfiltered = appliedJobData.filter((val) => {
  //         return val.status === "Joined";
  //       });
  //       setJoinedCandidates(Acceptedfiltered);

  //       const Onboardedfiltered = appliedJobData.filter((val) => {
  //         return val.status === "Onboarded";
  //       });
  //       setOnboardedCandidates(Onboardedfiltered);
  //     } else {
  //       setAppliedJob([]);
  //       setDisplayAppliedJob([]);
  //       setLoading(false);
  //     }
  //   } catch (e) {
  //     toast.error(err?.response?.data?.error, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     });
  //     setLoading(false);
  //   }
  // };

  const [selectedType, setSelectedType] = useState(null);
  const [isTypeSelectionOpen, setIsTypeSelectionOpen] = useState(false);

  const handleTypeSelection = (type) => {
    setSelectedType(type);
    setIsTypeSelectionOpen(false);
  };

  const inputElm = document.querySelector("#skill");
  const tagify = new Tagify(inputElm, {
    originalInputValueFormat: (valuesArr) =>
      valuesArr.map((item) => item.value).join(","),
  });

  const inputElm2 = document.querySelector("#question");
  const tagify2 = new Tagify(inputElm2, {
    originalInputValueFormat: (valuesArr2) =>
      valuesArr2.map((item) => item.value).join(","),
  });

  const getaccountsdata = () => {
    axios
      .get(`${config?.apiUrl}/api/get_all_clients`)
      .then((response) => {
        const accountsData = response.data.data;
        setAccounts(accountsData);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getCandidateApplicationStatus = async () => {
    await axios
      .get(`${config.apiUrl}/api/get_All_candidate_application_status`)
      .then((response) => {
        const statusData = response.data.data;
        setApplicationStatus(statusData);
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.error ||
            "Something happened wrong, please try again after sometime.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };
  const [searchfname, setSearchFname] = useState("");
  const [searchlname, setSearchLname] = useState("");
  const [searchjobt, setSearchJobt] = useState("");
  const [searchstatusSubmission, setSearchStatusSubmission] = useState([]);
  const [searchcustomer, setSearchCustomer] = useState([]);
  const [searchOwnerName, setSearchOwnerName] = useState([]);
  const [searchcandidatectctype, setSearchCandidatectctype] = useState("");
  const [searchcandidatectc, setSearchCandidatectc] = useState("");
  const [searchState, setSearchstate] = useState([]);
  const [searchExpectedCandidateCTC, setSearchExpectedCandidateCTC] =
    useState("");
  const [searchExpectedCandidateCTCType, setSearchExpectedCandidateCTCType] =
    useState("");
  const [searchNoticePeriod, setSearchNoticePeriod] = useState("");
  const [searchPhone, setSearchphone] = useState("");
  const [searchAddress, setSearchAddress] = useState("");
  const [searchDOB, setSearchDOB] = useState("");
  const [searchAadharCardNumber, setSearchAadharCardNumber] = useState("");
  const [searchPanCardNumber, setSearchPanCardNumber] = useState("");
  const [searchDesignation, setSearchdesignation] = useState("");
  const [searchWorkExperience, setSearchWorkExperience] = useState("");
  const [searchEducationDetails, setSearchEducationDetails] = useState("");
  const [searchExperience, setSearchExperience] = useState(null);
  const [searchGender, setSearchGender] = useState("");
  const [searchInterviewScheduleDate, setSearchInterviewScheduleDate] =
    useState([]);
  const [searchInterviewScheduleTime, setSearchInterviewScheduleTime] =
    useState("");
  const [searchInterviewScheduleLocation, setSearchInterviewScheduleLocation] =
    useState("");
  const [searchInterviewScheduleLink, setSearchInterviewScheduleLink] =
    useState("");
  const [searchJoinDate, setSearchJoinDate] = useState([]);
  const [searchSubmissionBy, setSearchSubmissionBy] = useState([]);
  const [searchEmail, setSearchemail] = useState("");
  const [searchContactName, setSearchContactName] = useState("");
  const [searchActualCTC, setSearchActualCTC] = useState("");
  const [searchOfferCTC, setSearchOfferCTC] = useState("");
  const [searchExpectedJoinDate, setSearchExpectedJoinDate] = useState([]);
  const [searchUpdatedBy, setSearchUpdatedBy] = useState([]);
  const [searchFullName, setSearchFullName] = useState("");
  const [searchIndustry, setSearchIndustry] = useState([]);
  const [searchcitySubmission, setSearchCitySubmission] = useState([]);
  const [searchInterviewRescheduleDate, setSearchInterviewRescheduleDate] =
    useState([]);
  const [searchTestRescheduleDate, setSearchTestRescheduleDate] = useState([]);
  const [searchBackoutDate, setSearchBackoutDate] = useState([]);

  const [isFilterActiveBackoutDate, setIsFilterActiveBackoutDate] =
    useState(false);
  const [
    isFilterActiveTestRescheduleDate,
    setIsFilterActiveTestRescheduleDate,
  ] = useState(false);
  const [
    isFilterActiveInterviewRescheduleDate,
    setIsFilterActiveInterviewRescheduleDate,
  ] = useState(false);
  const [isFilterActiveStateSubmission, setIsFilterActiveStateSubmission] =
    useState(false);
  const [isFilterActiveCitySubmission, setIsFilterActiveCitySubmission] =
    useState(false);
  const [isFilterActiveStatusSubmission, setIsFilterActiveStatusSubmission] =
    useState(false);
  const [isFilterActiveIndustry, setIsFilterActiveIndustry] = useState(false);
  const [isFilterActiveFullName, setIsFilterActiveFullName] = useState(false);
  const [isFilterActiveUpdatedBy, setIsFilterActiveUpdatedBy] = useState(false);
  const [isFilterActiveExpectedJoinDate, setIsFilterActiveExpectedJoinDate] =
    useState(false);
  const [isFilterActiveUpdatedDate, setIsFilterActiveUpdatedDate] =
    useState(false);
  const [isFilterActiveOfferCTC, setIsFilterActiveOfferCTC] = useState(false);
  const [isFilterActiveActualCTC, setIsFilterActiveActualCTC] = useState(false);
  const [isFilterActiveSubmissionBy, setIsFilterActiveSubmissionBy] =
    useState(false);
  const [isFilterActiveJoinDate, setIsFilterActiveJoinDate] = useState(false);
  const [
    isFilterActiveInterviewScheduleLink,
    setIsFilterActiveInterviewScheduleLink,
  ] = useState(false);
  const [
    isFilterActiveInterviewScheduleLocation,
    setIsFilterActiveInterviewScheduleLocation,
  ] = useState(false);
  const [
    isFilterActiveInterviewScheduleTime,
    setIsFilterActiveInterviewScheduleTime,
  ] = useState(false);
  const [
    isFilterActiveInterviewScheduleDate,
    setIsFilterActiveInterviewScheduleDate,
  ] = useState(false);
  const [isFilterActiveGender, setIsFilterActiveGender] = useState(false);
  const [isFilterActiveExperience, setIsFilterActiveExperience] =
    useState(false);
  const [isFilterActiveEducationDetails, setIsFilterActiveEducationDetails] =
    useState(false);
  const [isFilterActiveWorkExperience, setIsFilterActiveWorkExperience] =
    useState(false);
  const [isFilterActivePanCardNumber, setIsFilterActivePanCardNumber] =
    useState(false);
  const [isFilterActiveAadharCardNumber, setIsFilterActiveAadharCardNumber] =
    useState(false);
  const [isFilterActiveDOB, setIsFilterActiveDOB] = useState(false);
  const [isFilterActiveAddress, setIsFilterActiveAddress] = useState(false);
  const [isFilterActiveNoticePeriod, setIsFilterActiveNoticePeriod] =
    useState(false);
  const [isFilterActivEexpectedCtctype, setIsFilterActiveExpectedCtctype] =
    useState(false);
  const [isFilterActiveExpectedCtc, setIsFilterActiveExpectedCtc] =
    useState(false);
  const [isFilterActiveFname, setIsFilterActiveFname] = useState(false);
  const [isFilterActiveLname, setIsFilterActiveLname] = useState(false);
  const [isFilterActiveOwner, setIsFilterActiveOwner] = useState(false);
  const [isFilterActiveCtctype, setIsFilterActiveCtctype] = useState(false);
  const [isFilterActiveCtc, setIsFilterActiveCtc] = useState(false);
  const [isFilterActiveContactName, setIsFilterActiveContactName] =
    useState(false);

  const statusField = "all";
  const [mySubmissionValue, setMySubmissionValue] = useState("");
  const [popoverVisibleSubmission, setPopoverVisibleSubmission] = useState({});
  const handlePopOverCloseSubmission = (key, visibility) => {
    const newPopoverVisible = key ? { [key]: visibility } : {};
    setPopoverVisibleSubmission(newPopoverVisible);
  };
  const handleFilterAllFieldSteps = async (
    string = "",
    page = 0,
    rowsPerPage = 10,
    fieldName
  ) => {

    const searchParamsObj = {
      page: page + 1,
      limit: rowsPerPage,
      candidateFirstName:
        fieldName == "candidateFirstName" ? string : searchfname,
      candidateLastName:
        fieldName == "candidateLastName" ? string : searchlname,
      candidateSource:
        fieldName == "candidateSource" ? string : searchSourceData,
      email: fieldName == "email" ? string : searchEmail,
      status: fieldName == "status" ? string : searchstatusSubmission,
      ownerName: fieldName == "ownerName" ? string : searchOwnerName || "",
      currentCandidateCTC:
        fieldName == "currentCandidateCTC" ? string : searchcandidatectc,
      expectedCandidateCTC:
        fieldName == "expectedCandidateCTC"
          ? string
          : searchExpectedCandidateCTC,
      currentCandidateCTCType:
        fieldName == "currentCandidateCTCType"
          ? string
          : searchcandidatectctype,
      expectedCandidateCTCType:
        fieldName == "expectedCandidateCTCType"
          ? string
          : searchExpectedCandidateCTCType,
      city: fieldName == "city" ? string : searchcitySubmission || "",
      state: fieldName == "state" ? string : searchstate || "",
      contactName: fieldName == "contactName" ? string : searchContactName,
      noticePeriod: fieldName == "noticePeriod" ? string : searchNoticePeriod &&`${searchNoticePeriod?.noticePeriod[0] || 0}-${searchNoticePeriod?.noticePeriod[1]}`, 
      address: fieldName == "address" ? string : searchAddress,
      DOB: fieldName == "DOB" ? string : searchDOB,
      pancardNumber:
        fieldName == "pancardNumber" ? string : searchPanCardNumber,
      aadharcardNumber:
        fieldName == "aadharcardNumber" ? string : searchAadharCardNumber,
      gender: fieldName == "gender" ? string : searchGender,
      workExperience:
        fieldName == "workExperience" ? string : searchWorkExperience,
      educationDetails:
        fieldName == "educationDetails" ? string : searchEducationDetails,
      submissionBy:
        fieldName == "submissionBy" ? string || "" : searchSubmissionBy || "",
      statusField: statusField !== undefined ? statusField : "",
      interviewScheduleDate:
        fieldName == "interviewScheduleDate"
          ? string?.startDate && string?.endDate
            ? [string?.startDate, string?.endDate]
            : ""
          : searchInterviewScheduleDate || "",
      interviewScheduleTime:
        fieldName == "interviewScheduleTime"
          ? string
          : searchInterviewScheduleTime,
      interviewScheduleLocation:
        fieldName == "interviewScheduleLocation"
          ? string
          : searchInterviewScheduleLocation,
      interviewScheduleLink:
        fieldName == "interviewScheduleLink"
          ? string
          : searchInterviewScheduleLink,
      joinDate:
        fieldName == "joinDate"
          ? string?.startDate && string?.endDate
            ? [string?.startDate, string?.endDate]
            : ""
          : searchJoinDate || "",
      actualCTC: fieldName == "actualCTC" ? string : searchActualCTC,
      expectedJoinDate:
        fieldName == "expectedJoinDate"
          ? string?.startDate && string?.endDate
            ? [string?.startDate, string?.endDate]
            : ""
          : searchExpectedJoinDate || "",
      offerCTC: fieldName == "offerCTC" ? string : searchOfferCTC,
      isClientView: true,
      updatedBy: fieldName == "updatedBy" ? string : searchUpdatedBy || "",
      candidateFullName:
        fieldName == "candidateFullName" ? string : searchFullName,
      industry: fieldName == "industry" ? string : searchIndustry || "",
      createdAt:
        fieldName == "created_at"
          ? string?.startDate && string?.endDate
            ? [string?.startDate, string?.endDate]
            : ""
          : searchSubmissionDate || "",
      updatedAt:
        fieldName == "updated_at"
          ? string?.startDate && string?.endDate
            ? [string?.startDate, string?.endDate]
            : ""
          : searchSubmissionUpdateDate || "",
      interviewRescheduleDate:
        fieldName == "interviewRescheduleDate"
          ? string?.startDate && string?.endDate
            ? [string?.startDate, string?.endDate]
            : ""
          : searchInterviewRescheduleDate || "",
      testRescheduleDate:
        fieldName == "testRescheduleDate"
          ? string?.startDate && string?.endDate
            ? [string?.startDate, string?.endDate]
            : ""
          : searchTestRescheduleDate || "",
      backoutDate:
        fieldName == "backoutDate"
          ? string?.startDate && string?.endDate
            ? [string?.startDate, string?.endDate]
            : ""
          : searchBackoutDate || "",
      clientName: id,
    };
    const queryString = generateQueryParams(searchParamsObj);
    const url = `${config?.apiUrl}/api/get_All_search_submission?${queryString}`;
    const isClientView = true;
    const clientId = id;
    const queryObject = {
      searchText: fieldName == "text" ? string : searchAllfields,
      jobtitle: fieldName == "jobtitle" ? string : searchjobt,
      designation: fieldName == "designation" ? string : searchDesignation,
      phone: fieldName == "phone" ? string : searchPhone,
      submissionId: fieldName =='submission_id' ? string:searchSubmissionId,
      experienceMin:
      fieldName == "experience"
        ? string?.experience && string?.experience[0] !== undefined &&
          string?.experience[0] !== null &&
          (!isNaN(string?.experience[0]) || string?.experience[0] === 0)
          ? string?.experience[0]
          : null
        : searchExperience?.experience[0] !== undefined &&
            searchExperience?.experience[0] !== null &&
            (!isNaN(searchExperience?.experience[0]) || searchExperience?.experience[0] === 0)
          ? searchExperience?.experience[0]
          : null,
    experienceMax:
      fieldName == "experience"
        ? string?.experience && string?.experience[1] !== undefined &&
          string?.experience[1] !== null &&
          (!isNaN(string?.experience[1]) || string?.experience[1] === 0)
          ? string?.experience[1]
          : null
        : searchExperience?.experience[1] !== undefined &&
            searchExperience?.experience[1] !== null &&
            (!isNaN(searchExperience?.experience[1]) || searchExperience?.experience[1] === 0)
          ? searchExperience?.experience[1]
          : null,
    };

    try {
      setLoading !== undefined && setLoading(true);
      const filterdata = await axios.get(url, {
        params: queryObject,
        headers: {
          Authorization: `Bearer ${cookie?.token}`,
          "Content-Type": "application/json",
        },
      });

      const allSubmissionData = filterdata.data.data;

      const totalSubmissionCount = filterdata.data.totalSubmissionCount;
      allSubmissionData.totalSubmissionCount = totalSubmissionCount;
      setAppliedJob(allSubmissionData);
      handlePopOverCloseSubmission(false);
      // setDisplaySubmissions(allSubmissionData);
      selectedEndDate &&
        selectedStartDate &&
        searchSubmissionBy &&
        setAppliedJob(allSubmissionData);
      setDisplayAppliedJob(allSubmissionData);
      setLoading !== undefined && setLoading(false);
      return allSubmissionData;
    } catch (error) {
      toast.error(error?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      handlePopOverCloseSubmission(false);
      setLoading !== undefined && setLoading(false);
    }
  };
  const filterData = async (filterValue, filterField, filterDate) => {
    
    new Promise((resolve) => {
      let clientSubmissionPage = 0;
      setClientSubmissionPage(0);
      setTimeout(async () => {
        switch (filterField) {
          case "industry":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "industry"
            );
            setIsFilterActiveIndustry(filterValue?.length !== 0 ? true : false);
            break;
          case "firstName":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "candidateFirstName"
            );
            setIsFilterActiveFname(filterValue?.length == 0 ? false : true);
            break;
          case "fullName":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "candidateFullName"
            );
            setIsFilterActiveFullName(filterValue?.length == 0 ? false : true);
            break;

          case "lastName":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "candidateLastName"
            );
            setIsFilterActiveLname(filterValue?.length == 0 ? false : true);
            break;

          case "email":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "email"
            );
            setIsFilterActiveEmail(filterValue?.length == 0 ? false : true);

            break;

          case "jobTitle":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "jobtitle"
            );
            setIsFilterActiveJobt(filterValue?.length == 0 ? false : true);
            break;

          case "status":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "status"
            );
            setIsFilterActiveStatusSubmission(
              filterValue?.length == 0 ? false : true
            );
            break;

          case "client":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "clientName"
            );
            setIsFilterActiveClient(filterValue?.length == 0 ? false : true);
            break;

          case "contactName":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "contactName"
            );
            setIsFilterActiveContactName(
              filterValue?.length == 0 ? false : true
            );
            break;

          case "owner_name":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "ownerName"
            );
            setIsFilterActiveOwner(filterValue?.length == 0 ? false : true);

            break;

          case "currentCandidateCTCType":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "currentCandidateCTCType"
            );
            setIsFilterActiveCtctype(filterValue?.length !== 0 ? true : false);
            break;

          case "currentCandidateCTC":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "currentCandidateCTC"
            );
            setIsFilterActiveCtc(filterValue?.length !== 0 ? true : false);
            break;

          case "expectedCandidateCTCType":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "expectedCandidateCTCType"
            );
            setIsFilterActiveExpectedCtctype(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "expectedCandidateCTC":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "expectedCandidateCTC"
            );
            setIsFilterActiveExpectedCtc(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "city":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "city"
            );
            setIsFilterActiveCitySubmission(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "phone":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "phone"
            );
            setIsFilterActivePhone(filterValue?.length !== 0 ? true : false);
            break;

          case "state":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "state"
            );
            setIsFilterActiveStateSubmission(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "noticePeriod":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "noticePeriod"
            );
            setIsFilterActiveNoticePeriod(
              filterValue && filterValue.length > 0 ? filterValue[0] : null
            );
            break;

          case "address":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "address"
            );
            setIsFilterActiveAddress(filterValue?.length !== 0 ? true : false);
            break;

          case "DOB":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "DOB"
            );
            setIsFilterActiveDOB(filterValue?.length !== 0 ? true : false);
            break;

          case "pancardNumber":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "pancardNumber"
            );
            setIsFilterActivePanCardNumber(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "aadharcardNumber":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "aadharcardNumber"
            );
            setIsFilterActiveAadharCardNumber(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "designation":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "designation"
            );
            setIsFilterActiveDesignation(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "experience":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "experience"
            );
            setIsFilterActiveExperience(filterValue &&  filterValue.experience  && typeof filterValue?.experience[1] =='number'  ? filterValue?.experience : null);
            break;

          case "gender":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "gender"
            );
            setIsFilterActiveGender(filterValue?.length !== 0 ? true : false);
            break;

          case "workExperience":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "workExperience"
            );
            setIsFilterActiveWorkExperience(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "educationDetails":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "educationDetails"
            );
            setIsFilterActiveEducationDetails(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "submissionBy":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "submissionBy"
            );
            setIsFilterActiveSubmissionBy(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "interviewScheduleDate":
            const { startDate, endDate } = filterValue;
            await handleFilterAllFieldSteps(
              { startDate, endDate },
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "interviewScheduleDate"
            );
            setIsFilterActiveInterviewScheduleDate(
              filterValue?.startDate && filterValue?.endDate ? true : false
            );
            break;

          case "interviewScheduleTime":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "interviewScheduleTime"
            );
            setIsFilterActiveInterviewScheduleTime(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "interviewScheduleLocation":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "interviewScheduleLocation"
            );
            setIsFilterActiveInterviewScheduleLocation(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "interviewScheduleLink":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "interviewScheduleLink"
            );
            setIsFilterActiveInterviewScheduleLink(
              filterValue?.length !== 0 ? true : false
            );
            break;

          case "joinDate":
            await handleFilterAllFieldSteps(
              {
                startDate: filterValue?.startDate,
                endDate: filterValue?.endDate,
              },
              page,
              rowsPerPage,
              "joinDate"
            );
            setIsFilterActiveJoinDate(
              filterValue?.startDate && filterValue?.endDate ? true : false
            );
            break;
          case "expectedJoinDate":
            await handleFilterAllFieldSteps(
              {
                startDate: filterValue?.startDate,
                endDate: filterValue?.endDate,
              },
              page,
              rowsPerPage,
              "expectedJoinDate"
            );
            setIsFilterActiveExpectedJoinDate(
              filterValue?.startDate && filterValue?.endDate ? true : false
            );
            break;
          case "actualCTC":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "actualCTC"
            );
            setIsFilterActiveActualCTC(
              filterValue?.length !== 0 ? true : false
            );
            break;
          case "offerCTC":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "offerCTC"
            );
            setIsFilterActiveOfferCTC(filterValue?.length !== 0 ? true : false);
            break;

          case "created_at":
            await handleFilterAllFieldSteps(
              {
                startDate: filterValue?.startDate,
                endDate: filterValue?.endDate,
              },
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "created_at"
            );
            setIsFilterActiveDate(
              filterValue?.startDate && filterValue?.endDate ? true : false
            );
            break;
          case "updated_at":
            await handleFilterAllFieldSteps(
              {
                startDate: filterValue?.startDate,
                endDate: filterValue?.endDate,
              },
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "updated_at"
            );
            setIsFilterActiveUpdatedDate(
              filterValue?.startDate && filterValue?.endDate ? true : false
            );
            break;
          case "updatedBy":
            await handleFilterAllFieldSteps(
              filterValue,
              clientSubmissionPage,
              clientSubmissionPageRowsPerPage,
              "updatedBy"
            );
            setIsFilterActiveUpdatedBy(
              filterValue?.length !== 0 ? true : false
            );
            break;
          case "interviewRescheduleDate":
            await handleFilterAllFieldSteps(
              {
                startDate: filterValue?.startDate,
                endDate: filterValue?.endDate,
              },
              page,
              rowsPerPage,
              "interviewRescheduleDate"
            );
            setIsFilterActiveInterviewRescheduleDate(
              filterValue?.startDate && filterValue?.endDate ? true : false
            );
            break;
          case "testRescheduleDate":
            await handleFilterAllFieldSteps(
              {
                startDate: filterValue?.startDate,
                endDate: filterValue?.endDate,
              },
              page,
              rowsPerPage,
              "testRescheduleDate"
            );
            setIsFilterActiveTestRescheduleDate(
              filterValue?.startDate && filterValue?.endDate ? true : false
            );
            break;
          case "backoutDate":
            await handleFilterAllFieldSteps(
              {
                startDate: filterValue?.startDate,
                endDate: filterValue?.endDate,
              },
              page,
              rowsPerPage,
              "backoutDate"
            );
            setIsFilterActiveBackoutDate(
              filterValue?.startDate && filterValue?.endDate ? true : false
            );
            break;
          case "source":
            await handleFilterAllFieldSteps(
              filterValue,
              page,
              rowsPerPage,
              "candidateSource"
            );
            setIsFilterActiveSourceSearch(
              filterValue?.length !== 0 ? true : false
            );
            break;
            case "submission_id":
            await handleFilterAllFieldSteps(
              filterValue,
              page,
              rowsPerPage,
              "submission_id"
            );
            setIsFilterActiveSubmissionId(
              filterValue?.length !== 0 ? true : false
            );
            break;
            case "job_id":
        await handleFilterAllFieldStep(
          filterValue,
          page,
          rowsPerPage,
          "job_id"
        );
        setIsFilterActiveJobId(filterValue?.length !== 0 ? true : false);
        break;
          default:
            break;
        }
        resolve();
      }, 0);
    });
  };

  const clearFilter = () => {
    setIsFilterActiveSubmissionId(false)
    setSearchSubmissionId("")
    setClientSubmissionPage(0);
    setClientSubmissionPageRowsPerPage((prev) => 10);
    setSearchAllfields("");
    setSearchFname("");
    setSearchLname("");
    setSearchJobt("");
    setSearchStatusSubmission([]);
    setIsFilterActiveSourceSearch(false);
    setSearchSourceData([]);
    setSearchCustomer([]);
    setSearchOwnerName([]);
    setSearchCandidatectctype("");
    setSearchCandidatectc("");
    setSearchCitySubmission([]);
    setSearchContactName("");
    setSearchstate([]);
    setSearchCandidatectctype("");
    setSearchCandidatectc("");
    setSearchNoticePeriod("");
    setSearchphone("");
    setSearchAddress("");
    setSearchExpectedCandidateCTCType("");
    setSearchExpectedCandidateCTC("");
    setSearchDOB("");
    setSearchAadharCardNumber("");
    setSearchPanCardNumber("");
    setSearchdesignation("");
    setSearchWorkExperience("");
    setSearchEducationDetails("");
    setSearchExperience(null);
    setSearchGender("");
    setSearchInterviewScheduleDate([]);
    setSearchSubmissionDate([]);
    setSearchSubmissionUpdateDate([]);
    setSearchInterviewScheduleLink("");
    setSearchInterviewScheduleLocation("");
    setSearchInterviewScheduleTime("");
    setSearchJoinDate([]);
    setSearchSubmissionBy([]);
    setSearchemail("");
    setActiveField({ name: "", value: "" });
    setSearchActualCTC("");
    setSearchOfferCTC("");
    setSearchExpectedJoinDate([]);
    setSearchUpdatedBy([]);
    setMySubmissionValue("");
    setSearchFullName("");
    setSearchIndustry([]);
    setSearchInterviewRescheduleDate([]);
    setSearchTestRescheduleDate([]);
    setSearchBackoutDate([]);
    setIsFilterActiveBackoutDate(false);
    setIsFilterActiveTestRescheduleDate(false);
    setIsFilterActiveInterviewRescheduleDate(false);
    setIsFilterActiveIndustry(false);
    setIsFilterActiveFullName(false);
    setIsFilterActiveUpdatedBy(false);
    setIsFilterActiveUpdatedDate(false);
    setIsFilterActiveExpectedJoinDate(false);
    setIsFilterActiveOfferCTC(false);
    setIsFilterActiveActualCTC(false);
    setIsFilterActiveEmail(false);
    setIsFilterActiveSubmissionBy(false);
    setIsFilterActiveJoinDate(false);
    setIsFilterActiveInterviewScheduleTime(false);
    setIsFilterActiveInterviewScheduleLocation(false);
    setIsFilterActiveInterviewScheduleLink(false);
    setIsFilterActiveInterviewScheduleDate(false);
    setIsFilterActiveGender(false);
    setIsFilterActiveExperience(false);
    setIsFilterActiveEducationDetails(false);
    setIsFilterActiveWorkExperience(false);
    setIsFilterActiveContactName(false);
    setIsFilterActiveDesignation(false);
    setIsFilterActivePanCardNumber(false);
    setIsFilterActiveAadharCardNumber(false);
    setIsFilterActiveDOB(false);
    setIsFilterActiveAddress(false);
    setIsFilterActivePhone(false);
    setIsFilterActiveNoticePeriod(false);
    setIsFilterActiveExpectedCtc(false);
    setIsFilterActiveExpectedCtctype(false);
    setIsFilterActiveFname(false);
    setIsFilterActiveLname(false);
    setIsFilterActiveJobt(false);
    setIsFilterActiveStatusSubmission(false);
    setIsFilterActiveClient(false);
    setIsFilterActiveOwner(false);
    setIsFilterActiveCtctype(false);
    setIsFilterActiveCtc(false);
    setIsFilterActiveCitySubmission(false);
    setIsFilterActiveStateSubmission(false);
    setIsFilterActiveDate(false);
    // getAllSubmissiondata(page, rowsPerPage, moduleData);
    handleFilterAllFieldSteps("",0, rowsPerPage, submissionModuleData);
    history.push(`/details/accountview/${id}`, {
      replace: true,
    });
  };

  const fetchModuleData = async () => {
    try {
      // setIsLoading(true);
      const response = await axios.get(
        `${config.apiUrl}/api/getpermissions/${cookie.role}`
      );
      const modules = response.data.modules;

      const submissionModule = modules.find(
        (item) => item.module === "submission"
      );
      submissionModule &&
        (await handleFilterAllFieldSteps(
          "",
          clientSubmissionPage,
          clientSubmissionPageRowsPerPage,
          submissionModule.permissions
        ));
      if (submissionModule) {
        const permissionsData = submissionModule.permissions;
        setSubmissionModuleData(permissionsData);
      }
    } catch (error) {
      error &&
      handleFilterAllFieldSteps(
        "",
          clientSubmissionPage,
          clientSubmissionPageRowsPerPage
        );
      console.log("Error while fetching module data:", error);
    }
  };
  useEffect(() => {
    cookie?.role && !selectedEndDate && !selectedStartDate && fetchModuleData();
  }, [cookie?.role]);

  const handleContactNoteShow = () => {
    setContactNote(true);
  };
  const handleContactNoteClose = () => {
    setContactNote(false);
  };
  const minDate = new Date().toISOString().split("T")[0];

  const formatedAutoDate = () => {
    const today = new Date();
    const next30Days = new Date(today);
    next30Days.setDate(next30Days.getDate() + 30);
    const formattedDate = next30Days.toISOString().substr(0, 10);
    formik.setFieldValue("targetdate", formattedDate);
  };
  useEffect(() => {
    ViewPost && formatedAutoDate();
  }, [ViewPost]);

  useEffect(() => {
    const activityOn = `View account ${account?.accountName} `;
    const activityDo = "View Account";
    const component = "account";
    const reData =  {
      name:`${account?.accountName}`,
      id: account._id,
      action: "accountView",
      type: "account",
      note: null,
    }
    account?.accountName &&
      userActivity(
        candidate_ownerName,
        userId,
        component,
        activityDo,
        activityOn,
        userRole
      );
  }, [account?.accountName]);

  const [industries, setIndustries] = useState([]);
  const getIndustryData = async () => {
    try {
      const response = await axios.get(
        `${config?.apiUrl}/api/get_all_industries`
      );
      setIndustries(response.data);
    } catch (error) {
      console.error("Error fetching industry data:", error);
    }
  };

  useEffect(() => {
    if (cookie?.token?.length) {
      // id &&
      //   getAppliedJobdata(
      //     clientSubmissionPage,
      //     clientSubmissionPageRowsPerPage,
      //     submissionModuleData
      //   );
      getIndustryData();
      id && getAccount(id);
      getaccountsdata();
      getCandidateApplicationStatus();
    } else {
      history.replace("/");
    }
  }, [id, cookie]);

  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const renderMenu = (menu, defaultCities) => {
    if (defaultCities?.length === 0) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          <SpinnerIcon spin /> loading...
        </p>
      );
    }
    return menu;
  };

  const handleCities = async () => {
    try {
      if (defaultCities.length == 0) {
        const allCities = await getAllCities();
        // Remove duplicate city names
        const uniqueCities =
          allCities &&
          Array.from(new Set(allCities?.map((city) => city?.name?.trim()))).map(
            (name) => allCities?.find((city) => city?.name?.trim() === name)
          );
        setDefaultCities(uniqueCities || []);
      }
    } catch (error) {
      toast.error(error?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleStates = async () => {
    try {
      if (defaultStates.length == 0) {
        const allStates = await getAllStates();
        // Remove duplicate state names
        const uniqueStates =
          allStates &&
          Array.from(
            new Set(allStates?.map((state) => state?.name?.trim()))
          ).map((name) =>
            allStates?.find((state) => state?.name?.trim() === name)
          );
        setDefaultStates(uniqueStates || []);
      }
    } catch (error) {
      toast.error(error?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    if (account.accountName) {
      handleFilterAllFieldStep("",page, rowsPerPage, account?._id);
    }
  }, [account.accountName]);

  const formatStartDate = moment(queryParams.get("sd"), "DD/MM/YYYY");
  const formatEndDate = moment(queryParams.get("ed"), "DD/MM/YYYY");
  useEffect(() => {
    if (formatStartDate.isValid() && formatEndDate.isValid()) {
      const formattedStartDate = formatStartDate.format("YYYY-MM-DD");
      const formattedEndDate = formatEndDate.format("YYYY-MM-DD");

      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);

      if (queryParams.get("i") == 4) {
        setSearchSubmissionUpdateDate([
          new Date(formattedStartDate),
          new Date(formattedEndDate),
        ]);
        setActiveField({
          name: "updated_at",
          value: {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          },
        });
      }

      if (queryParams.get("i") == 3) {
        setActiveField({
          name: "created_at",
          value: { startDate: formattedStartDate, endDate: formattedEndDate },
        });
      }

      // setActiveField({
      //   name: "updated_at",
      //   value: {
      //     startDate: formattedStartDate,
      //     endDate: formattedEndDate,
      //   },
      // });
      // setIsFilterActiveDate(
      //   formattedStartDate && formattedEndDate ? true : false
      // );
    }
    if (selectedUserNames) {
      const arrayOfOwner = selectedUserNames?.split(",");
      setSearchSubmissionBy(arrayOfOwner);
      setActiveField({
        name: "submissionBy",
        value: arrayOfOwner,
      });
    }
    if (selectedStatus) {
      const arrayOfStatus = selectedStatus?.split(",");
      setSearchStatusSubmission(arrayOfStatus);
      setActiveField({
        name: "status",
        value: arrayOfStatus,
      });
    }
  }, [queryParams.get("sd")]);

  const queryIndex = queryParams.get("i");
  const accountName = account.accountName || queryParams.get("clientName");

  useEffect(() => {
    if (startDate && endDate) {
      if (queryIndex == "3" && accountName) {
        filterJobData({ startDate, endDate }, "date");
      } else if (queryIndex == "4" && searchSubmissionUpdateDate?.length) {
        filterData({ startDate, endDate }, "updated_at");
      }
    }
    if (searchSubmissionBy.length !== 0 && selectedUserNames) {
      filterData(searchSubmissionBy, "submissionBy");
    }
    if (searchstatusSubmission.length !== 0 && selectedStatus) {
      filterData(searchstatusSubmission, "status");
    }
  }, [
    startDate,
    endDate,
    searchSubmissionBy,
    searchSubmissionUpdateDate,
    searchstatusSubmission,
  ]);

  // Client Dashboard

  const [sourceDataKeys, setSourceDataKeys] = useState([]);
  const [totalCounts, setTotalCounts] = useState(null);
  const [sourceDataValues, setSourceDataValues] = useState([]);
  const [countLoading, setCountLoading] = useState(false);
  const [userNames, setUserNames] = useState([]);
  const [allUserNames, setAllUserNames] = useState([]);
  const [contactPersonNames, setContactPersonNames] = useState([]);

  const currentDate = new Date();
  const defaultStartDate = startOfMonth(currentDate);
  const defaultEndDate = endOfMonth(currentDate);

  const [searchStartDate, setSearchStartDate] = useState(
    format(defaultStartDate, "dd/MM/yyyy")
  );
  const [searchEndDate, setSearchEndDate] = useState(
    format(defaultEndDate, "dd/MM/yyyy")
  );
  const getAllPortalUsers = async () => {
    try {
      const response = await axios.get(`${config?.apiUrl}/api/users/names`, {
        headers: {
          Authorization: `Bearer ${cookie.token}`,
        },
      });
      const recruiterData = response.data.users;
      setAllUserNames(
        recruiterData?.map((res) => ({
          value: `${res.firstName} ${res.lastName}`,
          label: `${res.firstName} ${res.lastName}`,
        }))
      );
    } catch (err) {
      toast.error(err?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const ownershipChatOption = {
    chart: {
      type: "bar",
      height: 100,
      zoom: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
        barHeight: "50%",
      },
    },
    colors: ["#33b2df", "#546E7A", "#d4526e"],
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Jobs", "Candidates", "Submissions"],
    },
  };

  const getClientDashboardCount = async ({
    startDate = searchStartDate,
    endDate = searchEndDate,
    ownerName = "",
    contactPersonName = "",
    clientId = "",
  }) => {
    setCountLoading(true);
    try {
      const response = await axios.get(
        `${config?.apiUrl}/api/client/count?startDate=${startDate ? startDate : searchStartDate}&endDate=${endDate ? endDate : searchEndDate}&ownerName=${ownerName ? ownerName : []}&contactPersonName=${contactPersonName}&clientID=${clientId}`
      );
      const countsData = response.data.data;
      // setSourceDataKeys(
      //   Object.keys(countsData?.sourceCountData).map((key) => key)
      // );
      // setTeamMemberDatas(response.data.memberRecords);
      // setSelectedTeamData(response.data.teamData);
      // setTopSubmissionUsersData(response.data?.topSubmissionUsers);
      setSourceDataKeys(
        Object.keys(countsData?.sourceCountData).map((key) => key)
      );
      setSourceDataValues(Object.values(countsData?.sourceCountData));
      setTotalCounts(countsData);
      setCountLoading(false);
    } catch (err) {
      console.log("err::::::::::", err);
      toast.error(err?.response?.data?.msg || "Please check details", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setCountLoading(false);
    }
  };
  const predefinedRanges = [
    {
      label: "Today",
      value: [startOfDay(new Date()), startOfDay(new Date())],
      placement: "left",
    },
    {
      label: "Yesterday",
      value: [
        startOfDay(addDays(new Date(), -1)),
        startOfDay(addDays(new Date(), -1)),
      ],
      placement: "left",
    },
    {
      label: "Last 7 Days",
      value: [startOfDay(addDays(new Date(), -7)), startOfDay(new Date())],
      placement: "left",
    },
    {
      label: "Last 30 Days",
      value: [startOfDay(addDays(new Date(), -30)), startOfDay(new Date())],
      placement: "left",
    },
    {
      label: "This Week",
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: "left",
    },
    {
      label: "Current Month",
      value: [startOfMonth(new Date()), endOfMonth(new Date())],
      placement: "left",
    },
    {
      label: "Previous Month",
      value: [
        startOfMonth(subMonths(new Date(), 1)),
        endOfMonth(subMonths(new Date(), 1)),
      ],
      placement: "left",
    },
  ];

  const handleDateChange = (value) => {
    const formattedDates =
      value && value?.map((date) => format(date, "dd/MM/yyyy"));
    if (formattedDates) {
      setSearchStartDate(formattedDates[0]);
      setSearchEndDate(formattedDates[1]);
    }
  };

  const handleResetFilter = () => {
    setSearchStartDate(format(defaultStartDate, "dd/MM/yyyy"));
    setSearchEndDate(format(defaultEndDate, "dd/MM/yyyy"));
    setUserNames([]);
    setContactPersonNames([]);
    getClientDashboardCount({
      startDate: format(defaultStartDate, "dd/MM/yyyy"),
      endDate: format(defaultEndDate, "dd/MM/yyyy"),
      clientId: id,
      ownerName: "",
      contactPersonName: "",
    });
  };

  const parseDate = (dateStr) => parse(dateStr, "dd/MM/yyyy", new Date());
  const chartState = {
    series: [
      {
        name: "Count",
        data: [
          totalCounts?.jobpostCount || 0,
          totalCounts?.candidateCount || 0,
          totalCounts?.submissionCount || 0,
        ],
      },
    ],
    options: ownershipChatOption,
  };

  const FunnelChartState = {
    series: [
      totalCounts?.submissionCount || 0,
      totalCounts?.rejectedCandidateCount || 0,
      totalCounts?.selectedCandidateCount || 0,
      totalCounts?.joinedCandidateCount || 0,
    ],
    options: FunnelChartOptions,
  };

  const ColumnChartsState = {
    series: [
      {
        name: "Count",
        data: sourceDataValues || [],
      },
    ],
    options: {
      ...ColumnChartsObject,
      xaxis: {
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
        categories: sourceDataKeys || [],
        tickPlacement: "on",
      },
    },
  };

  function createQueryString(obj) {
    return Object.entries(obj)
      .filter(([_, value]) => {
        if (Array.isArray(value) && value.length === 0) return false;
        return value != null && value !== "";
      })
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      )
      .join("&");
  }

  const [params, setParams] = useState({
    sd: searchStartDate,
    ed: searchEndDate,
    ownerNames: [],
  });

  const getUserNames = async () => {
    try {
      const response = await axios.get(`${config?.apiUrl}/api/users/names`, {
        headers: {
          Authorization: `Bearer ${cookie.token}`,
        },
      });
      const recruiterData = response.data.users;
      return recruiterData;
    } catch (err) {
      toast.error(err?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return [];
    }
  };

  const getParams = async () => {
    const userNames = await getUserNames();
    const ownerNames = userNames.map(
      (user) => `${user.firstName?.trim()} ${user.lastName?.trim()}`
    );

    return {
      sd: searchStartDate,
      ed: searchEndDate,
      ownerNames: ownerNames,
    };
  };

  useEffect(() => {
    const fetchParams = async () => {
      const newParams = await getParams();
      setParams(newParams);
      setCountLoading(false);
    };

    if (cookie.token) {
      fetchParams();
    }
  }, []);

  useEffect(() => {
    if (cookie.token) {
      getAllPortalUsers();
    }
    if (id) {
      getClientDashboardCount({
        startDate: format(defaultStartDate, "dd/MM/yyyy"),
        endDate: format(defaultEndDate, "dd/MM/yyyy"),
        clientId: id,
      });
    }
  }, [id, cookie.token]);
  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    if (iParam) {
      setActiveTab(Number(iParam));
      queryParams.delete("i");
    }
  }, [activeTab, iParam]);

  const handlePath = async (moduleName, staticStatus) => {
    if (moduleName) {
      const response = await axios.get(
        `${config?.apiUrl}/api/get/status?moduleName=${moduleName}`
      );
      const fetchedStatus = response.data.status;
      const userNamesParam = userNames
        ? `&userNames=${userNames.join(",")}`
        : "";
      const statusParam = fetchedStatus ? `&status=${fetchedStatus}` : "";
      const constructedPath = `/details/accountview/${id}?sd=${searchStartDate}&ed=${searchEndDate}&i=4${userNamesParam}${statusParam}&clientName=${account.accountName}`;
      history.push(constructedPath, {
        replace: true,
      });
    } else {
      const userNamesParam = userNames
        ? `&userNames=${userNames.join(",")}`
        : "";
      const statusParam = staticStatus ? `&status=${staticStatus}` : "";
      const constructedPath = `/details/accountview/${id}?sd=${searchStartDate}&ed=${searchEndDate}&i=4${userNamesParam}${statusParam}&clientName=${account.accountName}`;
      history.push(constructedPath, {
        replace: true,
      });
    }
  };

  const showAllIndustries = industries?.map((val) => ({
    label: val?.industry,
    value: val?.industry,
  }));

  function commonJobTextSeachFunc() {
    setTablePage((prev) => {
      return {
        ...prev,
        job: DEFAULT_PAGE,
      };
    });
    setTableRowsPerPage((prev) => {
      return {
        ...prev,
        job: DEFAULT_ROW_PER_PAGE,
      };
    });
    handleFilterAllFieldStep(searchAllfields, 0, rowsPerPage, "text");
  }

  return (
    <Box>
      <ToastContainer />
      <Box
        className={isSidebarOpen ? "content_details" : "content_details_nav"}
      >
        <div
          style={{
            width: "auto",
            margin: "5rem 0rem 1.5rem 0rem",
          }}
        >
          <Flex
            position="relative"
            direction="column"
            align={{ sm: "center" }}
            maxW="auto"
            height="auto"
            mx="auto"
            bg={useColorModeValue("white", "gray.700")}
            shadow={{ sm: "base" }}
            rounded={{ sm: "lg" }}
            padding="7px 20px 7px 20px"
          >
            <Avatar
              mt="-10"
              borderWidth="6px"
              borderColor={useColorModeValue("white", "gray.700")}
              size="xl"
              name={displayAccount.accountName}
              className="bg_color"
              color="white"
            />
            <div className="box_details">
              <Box
                textAlign={{ sm: "center" }}
                pt="2"
                style={{ paddingRight: "37px", marginBottom: "1rem" }}
              >
                <Heading
                  size="lg"
                  fontWeight="extrabold"
                  letterSpacing="tight"
                  className="title_color"
                >
                  {!displayAccount?.accountName
                    ? "N/A"
                    : displayAccount?.accountName}
                </Heading>
                <div
                  style={{
                    backgroundColor: "rgb(244 247 253)",
                    borderRadius: "5px",
                    marginTop: "7px",
                    width: "auto",
                    padding: "0px 10px 0px 10px",
                  }}
                >
                  <Text color={useColorModeValue("gray.600", "gray.400")}>
                    <i
                      className="fa fa-circle"
                      style={{
                        color:
                          displayAccount?.status == "Active" ? "green" : "red",
                        fontSize: "12px",
                      }}
                    ></i>{" "}
                    {!displayAccount?.status ? "N/A" : displayAccount?.status}
                  </Text>
                </div>
              </Box>
              <hr className="horizontal_line" />
              <div>
                <Text color={useColorModeValue("blue.600")}>
                  <a href={displayAccount?.website} target="_blank">
                    {displayAccount?.website}
                  </a>
                </Text>
                <Text color={useColorModeValue("gray.600", "gray.400")}>
                  Created On :{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {!displayAccount?.created_at
                      ? "N/A"
                      : displayAccount?.created_at}
                  </span>
                </Text>
              </div>
              <hr className="horizontal_line" />
              <div
                style={{
                  backgroundColor: "rgb(244 247 253)",
                  borderRadius: "5px",
                  width: "auto",
                  height: "auto",
                  padding: "10px",
                  display: "flex",
                }}
              >
                <Avatar
                  name={displayAccount.accountOwner}
                  className="bg_color"
                  color="white"
                />
                <Text
                  color={useColorModeValue("gray.600", "gray.400")}
                  style={{ marginLeft: "10px" }}
                >
                  Account Owner <br />
                  <span style={{ fontWeight: "bold" }}>
                    {!displayAccount?.accountOwnerData
                      ? "N/A"
                      : `${displayAccount?.accountOwnerData?.firstName} ${displayAccount?.accountOwnerData?.lastName}`}
                  </span>
                </Text>
              </div>
            </div>
          </Flex>
        </div>
        <Tabs
          style={{
            width: "100%",
          }}
          variant="enclosed"
          onChange={(index) => {
            setActiveTab(index);
            if (queryParams.has("i")) {
              history.push(`/details/accountview/${id}`, {
                replace: true,
              });
              clearFilter();
              clearJobFilter();
            }
          }}
          index={activeTab}
        >
          <TabList style={{ flexWrap: "wrap" }}>
            <Tab _selected={{ color: "white", bg: "var(--main-bg-color)" }}>
              Overview
            </Tab>
            <Tab _selected={{ color: "white", bg: "var(--main-bg-color)" }}>
              Info
            </Tab>
            <Tab _selected={{ color: "white", bg: "var(--main-bg-color)" }}>
              Contacts ({contacts?.totalContact || 0})
            </Tab>
            <Tab _selected={{ color: "white", bg: "var(--main-bg-color)" }}>
              Jobs ({clientJobs?.totalJobPosts || 0})
            </Tab>
            <Tab _selected={{ color: "white", bg: "var(--main-bg-color)" }}>
              Submission ({appliedJob?.totalSubmissionCount || 0})
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Box>
                {/* Client Dashboard */}
                <Stack gap={10} flexDir={"row"} alignItems={"center"}>
                  <DateRangePicker
                    format="dd/MM/yyyy"
                    showOneCalendar
                    ranges={predefinedRanges}
                    onChange={handleDateChange}
                    value={[
                      parseDate(searchStartDate),
                      parseDate(searchEndDate),
                    ]}
                  />
                  {(cookie?.role === "Admin" ||
                    ["Account Manager", "Team Lead"].includes(
                      cookie?.role
                    )) && (
                    <CheckPicker
                      data={allUserNames}
                      style={{ width: 224, marginTop: "5px" }}
                      value={userNames}
                      onChange={(e) => {
                        setUserNames(e);
                      }}
                      placeholder="Select Users"
                    />
                  )}
              

                  <Button
                    sx={{
                      color: "white",
                      outline: "var(--main-bg-color)",
                      background: "var(--main-bg-color)",
                    }}
                    _hover={{
                      backgroundColor: "transparent",
                      outlineColor: "var(--main-bg-color)",
                      color: "var(--main-bg-color)",
                      border: "1px solid var(--main-bg-color)",
                    }}
                    onClick={() => {
                      searchStartDate, searchEndDate, userNames;
                      getClientDashboardCount({
                        startDate: searchStartDate,
                        endDate: searchEndDate,
                        ownerName: userNames?.join(",") || "",
                        contactPersonName: contactPersonNames?.join(",") || "",
                        clientId: id,
                      });
                    }}
                  >
                    Apply
                  </Button>
                  <Button
                    sx={{
                      color: "white",
                      outline: "var(--main-bg-color)",
                      background: "var(--main-bg-color)",
                    }}
                    _hover={{
                      backgroundColor: "transparent",
                      outlineColor: "var(--main-bg-color)",
                      color: "var(--main-bg-color)",
                      border: "1px solid var(--main-bg-color)",
                    }}
                    onClick={handleResetFilter}
                  >
                    Reset
                  </Button>
                </Stack>
                <SimpleGrid
                  columns={{ sm: 1, md: 2, xl: 4 }}
                  spacing="24px"
                  my="20px"
                >
                  <CountCard
                    label={"Total Jobs"}
                    path={`/details/accountview/${id}?sd=${searchStartDate}&ed=${searchEndDate}&i=3&${userNames?.length ? `&userNames=${userNames.join(",")}` : ""} ${selectedStatus?.length ? `&userNames=${userNames.join(",")}` : ""}&clientName=${account.accountName}`}
                    loading={countLoading}
                    numberCount={totalCounts?.jobpostCount}
                    icon={
                      <WorkOutlineIcon
                        fontSize="small"
                        style={{ color: "white" }}
                      />
                    }
                    text={"New Job Posted"}
                  />
                  <CountCard
                    label={"Total Submissions"}
                    path={`/details/accountview/${id}?sd=${searchStartDate}&ed=${searchEndDate}&i=4${userNames ? `&userNames=${userNames.join(",")}` : ""}&clientName=${account.accountName}`}
                    loading={countLoading}
                    numberCount={totalCounts?.submissionCount}
                    icon={<DescriptionIcon style={{ color: "white" }} />}
                    text={"Resume Received"}
                  />

                  <Box onClick={() => handlePath("Joined")}>
                    <CountCard
                      label={"Total Joined Candidates"}
                      loading={countLoading}
                      numberCount={totalCounts?.joinedCandidateCount}
                      icon={<PersonAddAlt1Icon style={{ color: "white" }} />}
                      text={"Joined Candidates"}
                    />
                  </Box>
                  <Box onClick={() => handlePath("", "Test - Pending")}>
                    <CountCard
                      label={"Total Test Pending Candidates"}
                      loading={countLoading}
                      numberCount={totalCounts?.testPendingCount}
                      icon={<PersonAddAlt1Icon style={{ color: "white" }} />}
                      text={"Test Pending Candidates"}
                    />
                  </Box>
                </SimpleGrid>
                <SimpleGrid
                  columns={{ sm: 1, md: 2, xl: 5 }}
                  spacing="14px"
                  mb="20px"
                >
                  <Box onClick={() => handlePath("", "Test Cleared")}>
                    <CountCard
                      label={"Total Test Cleared Candidates"}
                      loading={countLoading}
                      numberCount={totalCounts?.testClearedCount}
                      icon={<PersonAddAlt1Icon style={{ color: "white" }} />}
                      text={"Test Cleared Candidates"}
                    />
                  </Box>
                  <Box onClick={() => handlePath("Interview Schedule")}>
                    <CountCard
                      label={"Total Interview Schedule"}
                      loading={countLoading}
                      numberCount={totalCounts?.interviewScheduleCount}
                      icon={<EventAvailableIcon style={{ color: "white" }} />}
                      text={"Interview Schedule"}
                    />
                  </Box>
                  <Box onClick={() => handlePath("Interview Attained")}>
                    <CountCard
                      label={"Total Interview Attained"}
                      loading={countLoading}
                      numberCount={totalCounts?.interviewAttainedCount}
                      icon={<WorkHistoryIcon style={{ color: "white" }} />}
                      text={"Interview Attained"}
                    />
                  </Box>
                  <Box onClick={() => handlePath("Selected")}>
                    <CountCard
                      label={"Total Selected Candidate"}
                      loading={countLoading}
                      numberCount={totalCounts?.selectedCandidateCount}
                      icon={<HowToRegIcon style={{ color: "white" }} />}
                      text={"Selected Candidate"}
                    />
                  </Box>
                  <Box onClick={() => handlePath("Rejected")}>
                    <CountCard
                      label={"Total Rejected Candidate"}
                      loading={countLoading}
                      numberCount={totalCounts?.rejectedCandidateCount}
                      icon={
                        <Icon
                          as={RiUserForbidFill}
                          boxSize={5}
                          style={{ color: "white" }}
                        />
                      }
                      text={"Rejected Candidate"}
                    />
                  </Box>
                </SimpleGrid>
                <Card style={{ padding: "1rem" }}>
                  <Text fontSize="lg" fontWeight="bold" className="title_color">
                    Submission Source
                  </Text>
                  {countLoading ? (
                    <Box
                      sx={{
                        height: "450px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="var(--main-bg-color)"
                        size="xl"
                      />
                    </Box>
                  ) : (
                    <ReactApexChart
                      options={ColumnChartsState.options}
                      series={ColumnChartsState.series}
                      type="bar"
                      height={450}
                    />
                  )}
                </Card>
              </Box>
            </TabPanel>
            {/* Info */}
            <TabPanel>
              <Card style={{ width: "100%", marginBottom: "2rem" }}>
                <Card.Header style={{ textAlign: "left", fontWeight: "bold" }}>
                  Account Information
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item style={{ textAlign: "left" }}>
                    <Container style={{ minWidth: "100%" }}>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Account Name
                          </Text>
                          <span>
                            {!displayAccount?.accountName
                              ? "N/A"
                              : displayAccount?.accountName}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Account Owner
                          </Text>
                          <span>
                            {!displayAccount?.accountOwnerData
                              ? "N/A"
                              : `${displayAccount?.accountOwnerData?.firstName} ${displayAccount?.accountOwnerData?.lastName}`}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Account Status
                          </Text>
                          <span>
                            {!displayAccount?.status
                              ? "N/A"
                              : displayAccount?.status}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Owner Designation
                          </Text>
                          <span>
                            {!displayAccount?.designation
                              ? "N/A"
                              : displayAccount?.designation}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Website
                          </Text>
                          <span>
                            {!displayAccount?.website
                              ? "N/A"
                              : displayAccount?.website}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Phone Number
                          </Text>
                          <span>
                            {!displayAccount?.phone
                              ? "N/A"
                              : displayAccount?.phone}
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Industry
                          </Text>
                          <span>
                            {!displayAccount?.industry
                              ? "N/A"
                              : displayAccount?.industry}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Description
                          </Text>
                          <span>
                            {!displayAccount?.description
                              ? "N/A"
                              : displayAccount?.description}
                          </span>
                        </Col>
                        <Col
                          lg={4}
                          md={6}
                          sm={4}
                          style={{ marginBottom: "1rem" }}
                        >
                          <Text
                            color={useColorModeValue("gray.600", "gray.400")}
                            style={{ fontWeight: "bold" }}
                          >
                            Contract End Date
                          </Text>
                          <span>
                            {!displayAccount?.contractEnddate
                              ? "N/A"
                              : `${displayAccount?.contractEnddate.split("-")[2]}/${displayAccount?.contractEnddate.split("-")[1]}/${displayAccount?.contractEnddate.split("-")[0]}`}
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
              {billingAddress.map((billing) => (
                <Card
                  style={{ width: "100%", marginBottom: "2rem" }}
                  key={billing.nameofBillingAddress}
                >
                  <Card.Header
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    Billing Address
                  </Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item style={{ textAlign: "left" }}>
                      <Container style={{ minWidth: "100%" }}>
                        <Row>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Name of Billing Address
                            </Text>
                            <span>
                              {!billing?.nameofBillingAddress
                                ? "N/A"
                                : billing?.nameofBillingAddress}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Street
                            </Text>
                            <span>
                              {!billing?.street ? "N/A" : billing?.street}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Country
                            </Text>
                            <span>
                              {!billing?.country ? "N/A" : billing?.country}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              State
                            </Text>
                            <span>
                              {!billing?.state ? "N/A" : billing?.state}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              City
                            </Text>
                            <span>
                              {!billing?.city ? "N/A" : billing?.city}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Pincode
                            </Text>
                            <span>
                              {!billing?.pincode ? "N/A" : billing?.pincode}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={4} md={6} sm={4}>
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              GST IN
                            </Text>
                            <span>
                              {!billing?.GSTIN ? "N/A" : billing?.GSTIN}
                            </span>
                          </Col>
                        </Row>
                      </Container>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              ))}
              {shippingAddress.map((shipping) => (
                <Card
                  style={{ width: "100%", marginBottom: "2rem" }}
                  key={shipping.nameofShippingAddress}
                >
                  <Card.Header
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    Shipping Address
                  </Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item style={{ textAlign: "left" }}>
                      <Container style={{ minWidth: "100%" }}>
                        <Row>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Name of Shipping Address
                            </Text>
                            <span>
                              {!shipping?.nameofShippingAddress
                                ? "N/A"
                                : shipping?.nameofShippingAddress}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Street
                            </Text>
                            <span>
                              {!shipping?.street ? "N/A" : shipping?.street}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Country
                            </Text>
                            <span>
                              {!shipping?.country ? "N/A" : shipping?.country}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              State
                            </Text>
                            <span>
                              {!shipping?.state ? "N/A" : shipping?.state}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              City
                            </Text>
                            <span>
                              {!shipping?.city ? "N/A" : shipping?.city}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Pincode
                            </Text>
                            <span>
                              {!shipping?.pincode ? "N/A" : shipping?.pincode}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Gst IN
                            </Text>
                            <span>
                              {!shipping?.GSTIN ? "N/A" : shipping?.GSTIN}
                            </span>
                          </Col>
                        </Row>
                        <br />
                      </Container>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              ))}
              {interviewAddress.map((interview) => (
                <Card
                  style={{ width: "100%", marginBottom: "2rem" }}
                  key={interview.country}
                >
                  <Card.Header
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    Interview Address
                  </Card.Header>
                  <ListGroup variant="flush">
                    <ListGroup.Item style={{ textAlign: "left" }}>
                      <Container style={{ minWidth: "100%" }}>
                        <Row>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Country
                            </Text>
                            <span>
                              {!interview?.country ? "N/A" : interview?.country}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              State
                            </Text>
                            <span>
                              {!interview?.state ? "N/A" : interview?.state}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              City
                            </Text>
                            <span>
                              {!interview?.city ? "N/A" : interview?.city}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Street
                            </Text>
                            <span>
                              {!interview?.street ? "N/A" : interview?.street}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Pincode
                            </Text>
                            <span>
                              {!interview?.pincode ? "N/A" : interview?.pincode}
                            </span>
                          </Col>
                          <Col
                            lg={4}
                            md={6}
                            sm={4}
                            style={{ marginBottom: "1rem" }}
                          >
                            <Text
                              color={useColorModeValue("gray.600", "gray.400")}
                              style={{ fontWeight: "bold" }}
                            >
                              Time Zone
                            </Text>
                            <span>
                              {!interview?.timezone
                                ? "N/A"
                                : interview?.timezone}
                            </span>
                          </Col>
                        </Row>
                      </Container>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              ))}
              <Box className="btns_box">
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "1rem",
                  }}
                >
                  {!selectedType && (
                    <button
                      className="candi_btns"
                      id="resumeSelectButton"
                      onClick={() => {
                        setIsTypeSelectionOpen(!isTypeSelectionOpen);
                      }}
                    >
                      Select Company Details
                    </button>
                  )}

                  {/* Resume Type Selection Dropdown */}
                  {isTypeSelectionOpen && (
                    <div>
                      <select
                        onChange={(e) => handleTypeSelection(e.target.value)}
                        style={{
                          padding: "10px",
                          fontSize: "14px",
                          width: "200px",
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                          cursor: "pointer",
                          background: "#023f61",
                          color: "#fff",
                          fontWeight: "900",
                        }}
                      >
                        <option
                          value=""
                          style={{
                            fontStyle: "italic",
                            color: "#fff",
                            background: "gray",
                            fontWeight: "900",
                          }}
                        >
                          Select Company Document
                        </option>
                        {displayAccount?.companydetail &&
                          Object.keys(displayAccount.companydetail).map(
                            (key) => (
                              <option
                                key={key}
                                value={key}
                                style={{
                                  textTransform: "uppercase",
                                  padding: "8px",
                                  color: "#fff",
                                  background: "gray",
                                  fontWeight: "900",
                                }}
                              >
                                {key}
                              </option>
                            )
                          )}
                      </select>
                    </div>
                  )}
                  {selectedType && (
                    <button
                      className="candi_btns"
                      id="resumePerviewButton"
                      onClick={() => {
                        AccontPreview(displayAccount?._id, selectedType);
                        handleAccountPreviewShow();
                      }}
                    >
                      Preview {selectedType}
                    </button>
                  )}
                  <button
                    className="candi_btns"
                    onClick={() => {
                      handleEditAccountShow();
                      setAccountId(displayAccount?._id);
                      getEditContactData(displayAccount);
                    }}
                  >
                    Edit
                  </button>
                </Box>
              </Box>
              {/* Modal for Edit Account */}
              <div className="model-box-view">
                <Modal
                  show={editAccountShow}
                  onHide={handleEditAccountClose}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="usertitle">
                      Edit Account
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                        padding: "0.3rem",
                        paddingBottom: "15px",
                      }}
                      className="usertitle"
                    >
                      Account Information
                    </h1>
                    <form
                      id="addaccount"
                      onSubmit={editAccountFormik.handleSubmit}
                    >
                      <Container>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Account Name
                            </FormLabel>
                            <Input
                              className="input-field"
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Account Name"
                              size="lg"
                              name="accountName"
                              value={editAccountFormik.values.accountName}
                              onChange={editAccountFormik.handleChange}
                              onBlur={editAccountFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editAccountFormik.touched.accountName &&
                                  editAccountFormik.errors.accountName
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editAccountFormik.touched.accountName &&
                            editAccountFormik.errors.accountName ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editAccountFormik.errors.accountName}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Website
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="website"
                              size="lg"
                              name="website"
                              value={editAccountFormik.values.website}
                              onChange={editAccountFormik.handleChange}
                              onBlur={editAccountFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editAccountFormik.touched.website &&
                                  editAccountFormik.errors.website
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editAccountFormik.touched.website &&
                            editAccountFormik.errors.website ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editAccountFormik.errors.website}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Phone
                            </FormLabel>
                            <div className="accountphone">
                              <PhoneInput
                                containerStyle={{
                                  border:
                                    editAccountFormik.touched.phone &&
                                    editAccountFormik.errors.phone
                                      ? "1px solid red"
                                      : "",
                                  borderRadius:
                                    editAccountFormik.touched.phone &&
                                    editAccountFormik.errors.phone
                                      ? "4px"
                                      : "",
                                }}
                                dropdownStyle={{
                                  border:
                                    editAccountFormik.touched.phone &&
                                    editAccountFormik.errors.phone
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                }}
                                country={"in"}
                                onChange={(phone) => {
                                  const phoneNumberWithPlus = `+${phone}`;

                                  editAccountFormik.setFieldValue(
                                    "phone",
                                    phoneNumberWithPlus
                                  );
                                }}
                                value={editAccountFormik.values.phone}
                                onBlur={editAccountFormik.handleBlur}
                              />
                              {editAccountFormik.touched.phone &&
                              editAccountFormik.errors.phone ? (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editAccountFormik.errors.phone}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Account Owner
                            </FormLabel>
                            {/* <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Account Owner"
                              size="lg"
                              name="accountOwner"
                              value={editAccountFormik.values.accountOwner}
                              onChange={editAccountFormik.handleChange}
                              onBlur={editAccountFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editAccountFormik.touched.accountOwner &&
                                  editAccountFormik.errors.accountOwner
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            /> */}
                            <ReactSelect
                              className="w-100"
                              classNamePrefix="Select Owner Name"
                              placeholder="Search Owner Name"
                              isSearchable={true}
                              name="accountOwner"
                              options={reduxUserNames.map((user) => ({
                                // value: `${user.firstName?.trim()} ${user.lastName?.trim()}`,
                                label: `${user.firstName?.trim()} ${user.lastName?.trim()}`,
                                value: user?._id,
                              }))}
                              value={
                                editAccountFormik.values.accountOwner
                                  ? {
                                      label:
                                        editAccountFormik.values.accountOwner,
                                      value:
                                        editAccountFormik.values.accountOwnerId,
                                    }
                                  : null
                              }
                              onChange={(selectedOption) => {
                                editAccountFormik.setFieldValue(
                                  "accountOwner",
                                  selectedOption ? selectedOption.label : ""
                                );
                                editAccountFormik?.setFieldValue(
                                  "accountOwnerId",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              onBlur={editAccountFormik.handleBlur}
                              styles={{
                                control: (provided, state) => ({
                                  ...provided,
                                  height: "37px",
                                  border:
                                    editAccountFormik.touched.accountOwner &&
                                    editAccountFormik.errors.accountOwner
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                }),
                              }}
                            />
                            {editAccountFormik.touched.accountOwner &&
                            editAccountFormik.errors.accountOwner ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editAccountFormik.errors.accountOwner}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Email
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Email"
                              size="lg"
                              name="email"
                              value={editAccountFormik.values.email}
                              onChange={editAccountFormik.handleChange}
                              onBlur={editAccountFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editAccountFormik.touched.email &&
                                  editAccountFormik.errors.email
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editAccountFormik.touched.email &&
                            editAccountFormik.errors.email ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editAccountFormik.errors.email}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Owner Designation
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Owner Designation"
                              size="lg"
                              name="designation"
                              value={editAccountFormik.values.designation}
                              onChange={editAccountFormik.handleChange}
                              onBlur={editAccountFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editAccountFormik.touched.designation &&
                                  editAccountFormik.errors.designation
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editAccountFormik.touched.designation &&
                            editAccountFormik.errors.designation ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editAccountFormik.errors.designation}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Account Status
                            </FormLabel>
                            <select
                              className="form-control"
                              name="status"
                              value={editAccountFormik.values.status}
                              onChange={editAccountFormik.handleChange}
                              onBlur={editAccountFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                cursor: "pointer",
                              }}
                            >
                              <option disabled={true} value="">
                                Account Status
                              </option>
                              <option>Active</option>
                              <option>Inactive</option>
                            </select>
                          </Col>
                          <Col lg={6} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Industry
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Industry"
                              mb="24px"
                              size="lg"
                              name="industry"
                              value={editAccountFormik.values.industry}
                              onChange={editAccountFormik.handleChange}
                              onBlur={editAccountFormik.handleBlur}
                              style={{ width: "100%", height: "37px" }}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Description
                            </FormLabel>
                            <Textarea
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Description"
                              mb="24px"
                              size="lg"
                              name="description"
                              value={editAccountFormik.values.description}
                              onChange={editAccountFormik.handleChange}
                              onBlur={editAccountFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border: "1px solid #ccc6c6",
                              }}
                            />
                          </Col>
                          <Col lg={6} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Contract End Date
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="date"
                              placeholder="Contract End Date"
                              mb="24px"
                              size="lg"
                              name="contractEnddate"
                              value={editAccountFormik.values.contractEnddate}
                              onChange={editAccountFormik.handleChange}
                              onBlur={editAccountFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                cursor: "pointer",
                              }}
                            />
                          </Col>
                        </Row>
                      </Container>
                      <FormControl>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <h1
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.3rem",
                              padding: "0.3rem",
                              paddingBottom: "15px",
                            }}
                            className="usertitle"
                          >
                            Company Details
                          </h1>
                        </div>
                        <div
                          style={{
                            border: "1px solid #d3d1d1",
                            width: "100%",
                            height: "auto",
                            borderRadius: "5px",
                            padding: "10px",
                          }}
                        >
                          <Flex gap={5}>
                            <div
                              style={{ width: "100%", marginBottom: "1rem" }}
                            >
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                  color: "#000000",
                                }}
                              >
                                GST Certificate
                              </label>
                              <div class="file-dummy">
                                <div class="default">
                                  <span class="drag">Drag and Drop Resume</span>
                                  <br />
                                  or you can <span class="browse">
                                    Browse
                                  </span>{" "}
                                  and find a file here
                                </div>
                                <input
                                  type="file"
                                  id="candidate-file-input"
                                  name="gstCertificate"
                                  onChange={(e) => {
                                    companySaveFile(e, "gstCertificate");
                                  }}
                                  accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                                />
                              </div>
                              {gstCertificate ? (
                                <div
                                  style={{
                                    width: "auto",
                                    height: "2rem",
                                    border: "1px solid grey",
                                    marginTop: "1rem",
                                    padding: "4px",
                                    paddingLeft: "16px",
                                  }}
                                  className="resumename"
                                >
                                  {(() => {
                                    if (
                                      typeof gstCertificate === "string" &&
                                      gstCertificate.trim() !== "" &&
                                      gstCertificate.trim() !== "Select File"
                                    ) {
                                      const nameParts =
                                        gstCertificate.split("/");
                                      const lastName =
                                        nameParts[nameParts.length - 1];
                                      return lastName;
                                    } else if (gstCertificate instanceof File) {
                                      const lastName =
                                        gstCertificate.name.split(".")[0];
                                      return lastName;
                                    } else {
                                      return "Select File";
                                    }
                                  })()}
                                </div>
                              ) : null}
                            </div>
                            <div
                              style={{ width: "100%", marginBottom: "1rem" }}
                            >
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                  color: "#000000",
                                }}
                              >
                                MSA (Master Services Agreemenent)
                              </label>
                              <div class="file-dummy">
                                <div class="default">
                                  <span class="drag">Drag and Drop Resume</span>
                                  <br />
                                  or you can <span class="browse">
                                    Browse
                                  </span>{" "}
                                  and find a file here
                                </div>
                                <input
                                  type="file"
                                  id="candidate-file-input"
                                  name="msa"
                                  onBlur={editAccountFormik.handleBlur}
                                  onChange={(e) => {
                                    companySaveFile(e, "msa");
                                  }}
                                  accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                                />
                              </div>
                              {msa ? (
                                <div
                                  style={{
                                    width: "auto",
                                    height: "2rem",
                                    border: "1px solid grey",
                                    marginTop: "1rem",
                                    padding: "4px",
                                    paddingLeft: "16px",
                                  }}
                                  className="resumename"
                                >
                                  {(() => {
                                    if (
                                      typeof msa === "string" &&
                                      msa.trim() !== "" &&
                                      msa.trim() !== "Select File"
                                    ) {
                                      const nameParts = msa.split("/");
                                      const lastName =
                                        nameParts[nameParts.length - 1];

                                      return lastName;
                                    } else if (msa instanceof File) {
                                      const lastName = msa.name.split(".")[0];

                                      return lastName;
                                    } else {
                                      return "Select File";
                                    }
                                  })()}
                                </div>
                              ) : null}
                            </div>
                          </Flex>
                          <Flex gap={5}>
                            <div
                              style={{ width: "100%", marginBottom: "1rem" }}
                            >
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                  color: "#000000",
                                }}
                              >
                                PAN Card
                              </label>
                              <div class="file-dummy">
                                <div class="default">
                                  <span class="drag">Drag and Drop Resume</span>
                                  <br />
                                  or you can <span class="browse">
                                    Browse
                                  </span>{" "}
                                  and find a file here
                                </div>
                                <input
                                  type="file"
                                  id="candidate-file-input"
                                  name="panCard"
                                  onChange={(e) => {
                                    companySaveFile(e, "panCard");
                                  }}
                                  accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                                />
                              </div>
                              {panCard ? (
                                <div
                                  style={{
                                    width: "auto",
                                    height: "2rem",
                                    border: "1px solid grey",
                                    marginTop: "1rem",
                                    padding: "4px",
                                    paddingLeft: "16px",
                                  }}
                                  className="resumename"
                                >
                                  {(() => {
                                    if (
                                      typeof panCard === "string" &&
                                      panCard.trim() !== "" &&
                                      panCard.trim() !== "Select File"
                                    ) {
                                      const nameParts = panCard.split("/");
                                      const lastName =
                                        nameParts[nameParts.length - 1];

                                      return lastName;
                                    } else if (panCard instanceof File) {
                                      const lastName =
                                        panCard.name.split(".")[0];

                                      return lastName;
                                    } else {
                                      return "Select File";
                                    }
                                  })()}
                                </div>
                              ) : null}
                            </div>
                            <div
                              style={{ width: "100%", marginBottom: "1rem" }}
                            >
                              <label
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                  color: "#000000",
                                }}
                              >
                                Signed MSA
                              </label>
                              <div class="file-dummy">
                                <div class="default">
                                  <span class="drag">Drag and Drop Resume</span>
                                  <br />
                                  or you can <span class="browse">
                                    Browse
                                  </span>{" "}
                                  and find a file here
                                </div>
                                <input
                                  type="file"
                                  id="candidate-file-input"
                                  name="signedMsa"
                                  onChange={(e) => {
                                    companySaveFile(e, "signedMsa");
                                  }}
                                  accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                                />
                              </div>
                              {signedMsa ? (
                                <div
                                  style={{
                                    width: "auto",
                                    height: "2rem",
                                    border: "1px solid grey",
                                    marginTop: "1rem",
                                    padding: "4px",
                                    paddingLeft: "16px",
                                  }}
                                  className="resumename"
                                >
                                  {(() => {
                                    if (
                                      typeof signedMsa === "string" &&
                                      signedMsa.trim() !== "" &&
                                      signedMsa.trim() !== "Select File"
                                    ) {
                                      const nameParts = signedMsa.split("/");
                                      const lastName =
                                        nameParts[nameParts.length - 1];

                                      return lastName;
                                    } else if (signedMsa instanceof File) {
                                      const lastName =
                                        signedMsa.name.split(".")[0];

                                      return lastName;
                                    } else {
                                      return "Select File";
                                    }
                                  })()}
                                </div>
                              ) : null}
                            </div>
                          </Flex>
                        </div>

                        {/* For Billing Address */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "1rem",
                            marginTop: "1.5rem",
                          }}
                        >
                          <h1
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.3rem",
                              padding: "0.3rem",
                              paddingBottom: "15px",
                            }}
                            className="usertitle"
                          >
                            Billing Address
                          </h1>
                          <span
                            style={{
                              display: "flex",
                              width: "76px",
                              height: "34px",
                              fontFamily: "Roboto",
                              color: "#627D8C",
                              fontSize: "20px",
                              border: "1.2px solid #87A6B7",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "3px",
                              cursor: "pointer",
                            }}
                            onClick={handleAddBilling}
                          >
                            Add
                          </span>
                        </div>
                        {billingFields.map((billing, index) => (
                          <div key={index} style={{ position: "relative" }}>
                            <div className="close_icon">
                              <CloseButton
                                onClick={() => {
                                  let filterData = billingFields.filter(
                                    (res, In) => In !== index
                                  );
                                  setBillingFields(filterData);
                                }}
                              />
                            </div>
                            <div
                              style={{
                                border: "1px solid #d3d1d1",
                                width: "100%",
                                height: "auto",
                                borderRadius: "5px",
                                padding: "10px",
                              }}
                            >
                              <Container>
                                <form>
                                  <Row>
                                    <Col
                                      lg={4}
                                      md={4}
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      <label
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          fontFamily: "Roboto",
                                          color: "#000000",
                                        }}
                                      >
                                        Country
                                      </label>
                                      <ReactSelect
                                        name="country"
                                        options={Country.getAllCountries()}
                                        placeholder="Select Country"
                                        getOptionLabel={(options) =>
                                          options["name"]
                                        }
                                        getOptionValue={(options) =>
                                          options["isoCode"]
                                        }
                                        value={
                                          billing.country
                                            ? {
                                                name: billing.country,
                                                isoCode: billing.countryCode,
                                              }
                                            : null
                                        }
                                        onChange={(value, action) => {
                                          handleFormBillingChange(
                                            index,
                                            value,
                                            action
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col
                                      lg={4}
                                      md={4}
                                      style={{ marginBottom: "1rem" }}
                                    >
                                      <label
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          fontFamily: "Roboto",
                                          color: "#000000",
                                        }}
                                      >
                                        State
                                      </label>
                                      <ReactSelect
                                        name="state"
                                        options={State.getStatesOfCountry(
                                          billing.countryCode
                                        )}
                                        placeholder="Select State"
                                        getOptionLabel={(options) =>
                                          options["name"]
                                        }
                                        getOptionValue={(options) =>
                                          options["isoCode"]
                                        }
                                        value={
                                          billing.state
                                            ? {
                                                name: billing.state,
                                                isoCode: billing.stateCode,
                                              }
                                            : null
                                        }
                                        onChange={(value, action) => {
                                          handleFormBillingChange(
                                            index,
                                            value,
                                            action
                                          );
                                        }}
                                      />
                                    </Col>
                                    <Col lg={4} md={4}>
                                      <label
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          fontFamily: "Roboto",
                                          color: "#000000",
                                        }}
                                      >
                                        City
                                      </label>
                                      <ReactSelect
                                        name="city"
                                        options={City.getCitiesOfState(
                                          billing.countryCode,
                                          billing.stateCode
                                        )}
                                        placeholder="Select City"
                                        getOptionLabel={(options) =>
                                          options["name"]
                                        }
                                        getOptionValue={(options) =>
                                          options["name"]
                                        }
                                        value={
                                          billing.city
                                            ? { name: billing.city }
                                            : null
                                        }
                                        onChange={(value, action) => {
                                          handleFormBillingChange(
                                            index,
                                            value,
                                            action
                                          );
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <br />
                                  <Row>
                                    <Col lg={4} md={4}>
                                      <label
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          fontFamily: "Roboto",
                                          color: "#000000",
                                        }}
                                      >
                                        Street
                                      </label>
                                      <Input
                                        variant="auth"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="Street"
                                        mb="24px"
                                        size="lg"
                                        name="street"
                                        value={billing.street}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                        style={{ height: "37px" }}
                                      />
                                    </Col>
                                    <Col lg={4} md={4}>
                                      <label
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          fontFamily: "Roboto",
                                          color: "#000000",
                                        }}
                                      >
                                        Pincode
                                      </label>
                                      <Input
                                        variant="auth"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="Pincode"
                                        mb="24px"
                                        size="lg"
                                        name="pincode"
                                        value={billing.pincode}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                        style={{ height: "37px" }}
                                      />
                                    </Col>
                                    <Col lg={4} md={4}>
                                      <label
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          fontFamily: "Roboto",
                                          color: "#000000",
                                        }}
                                      >
                                        Name of the Billing Address
                                      </label>
                                      <Input
                                        variant="auth"
                                        fontSize="sm"
                                        type="text"
                                        placeholder="Name of the Billing Address"
                                        mb="24px"
                                        size="lg"
                                        name="nameofBillingAddress"
                                        value={billing.nameofBillingAddress}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                        style={{ height: "37px" }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg={12} md={6}>
                                      <label
                                        style={{
                                          fontSize: "13px",
                                          fontWeight: "400",
                                          fontFamily: "Roboto",
                                          color: "#000000",
                                        }}
                                      >
                                        GSTIN
                                      </label>
                                      <Input
                                        variant="auth"
                                        fontSize="sm"
                                        type="text"
                                        mb="24px"
                                        size="lg"
                                        name="GSTIN"
                                        value={billing.GSTIN}
                                        onChange={(event) =>
                                          handleFormChange(index, event)
                                        }
                                        style={{ height: "37px" }}
                                      />
                                    </Col>
                                  </Row>
                                </form>
                              </Container>
                            </div>
                            <br />
                          </div>
                        ))}

                        {/* For shipping Address */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "1rem",
                          }}
                        >
                          <h1
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.3rem",
                              padding: "0.3rem",
                              paddingBottom: "15px",
                            }}
                            className="usertitle"
                          >
                            Shipping Address
                          </h1>
                          <span
                            style={{
                              display: "flex",
                              width: "76px",
                              height: "34px",
                              fontFamily: "Roboto",
                              color: "#627D8C",
                              fontSize: "20px",
                              border: "1.2px solid #87A6B7",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "3px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleAddShipping();
                            }}
                          >
                            Add
                          </span>
                        </div>
                        {shippingFields.map((shipping, index) => {
                          return (
                            <div key={index} style={{ position: "relative" }}>
                              <div className="close_icon">
                                <CloseButton
                                  onClick={() => {
                                    let filterData = shippingFields.filter(
                                      (res, In) => In !== index
                                    );
                                    setShippingFields(filterData);
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  border: "1px solid #d3d1d1",
                                  width: "100%",
                                  height: "auto",
                                  borderRadius: "5px",
                                  padding: "10px",
                                }}
                              >
                                <Container>
                                  <form>
                                    <Row>
                                      <Col
                                        lg={4}
                                        md={4}
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          Country
                                        </label>
                                        <ReactSelect
                                          name="country"
                                          options={Country.getAllCountries()}
                                          placeholder="Select Country"
                                          getOptionLabel={(options) =>
                                            options["name"]
                                          }
                                          getOptionValue={(options) =>
                                            options["isoCode"]
                                          }
                                          value={
                                            shipping.country
                                              ? {
                                                  name: shipping.country,
                                                  isoCode: shipping.countryCode,
                                                }
                                              : null
                                          }
                                          onChange={(value, action) => {
                                            handleFormShippingChange(
                                              index,
                                              value,
                                              action
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col
                                        lg={4}
                                        md={4}
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          State
                                        </label>
                                        <ReactSelect
                                          name="state"
                                          options={State.getStatesOfCountry(
                                            shipping.countryCode
                                          )}
                                          placeholder="Select State"
                                          getOptionLabel={(options) =>
                                            options["name"]
                                          }
                                          getOptionValue={(options) =>
                                            options["isoCode"]
                                          }
                                          value={
                                            shipping.state
                                              ? {
                                                  name: shipping.state,
                                                  isoCode: shipping.stateCode,
                                                }
                                              : null
                                          }
                                          onChange={(value, action) => {
                                            handleFormShippingChange(
                                              index,
                                              value,
                                              action
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col lg={4} md={4}>
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          City
                                        </label>
                                        <ReactSelect
                                          name="city"
                                          options={City.getCitiesOfState(
                                            shipping.countryCode,
                                            shipping.stateCode
                                          )}
                                          placeholder="Select City"
                                          getOptionLabel={(options) =>
                                            options["name"]
                                          }
                                          getOptionValue={(options) =>
                                            options["name"]
                                          }
                                          value={
                                            shipping.city
                                              ? { name: shipping.city }
                                              : null
                                          }
                                          onChange={(value, action) => {
                                            handleFormShippingChange(
                                              index,
                                              value,
                                              action
                                            );
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg={4} md={4}>
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          Street
                                        </label>
                                        <Input
                                          variant="auth"
                                          fontSize="sm"
                                          type="text"
                                          placeholder="Street"
                                          mb="24px"
                                          size="lg"
                                          name="street"
                                          value={shipping.street}
                                          onChange={(event) =>
                                            handleShippingForm(index, event)
                                          }
                                          style={{ height: "37px" }}
                                        />
                                      </Col>
                                      <Col lg={4} md={4}>
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          Pincode
                                        </label>
                                        <Input
                                          variant="auth"
                                          fontSize="sm"
                                          type="text"
                                          placeholder="Pincode"
                                          mb="24px"
                                          size="lg"
                                          name="pincode"
                                          value={shipping.pincode}
                                          onChange={(event) =>
                                            handleShippingForm(index, event)
                                          }
                                          style={{ height: "37px" }}
                                        />
                                      </Col>
                                      <Col lg={4} md={4}>
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          Name of the Shipping Address
                                        </label>
                                        <Input
                                          variant="auth"
                                          fontSize="sm"
                                          type="text"
                                          placeholder="Name of the Shipping Address"
                                          mb="24px"
                                          size="lg"
                                          name="nameofShippingAddress"
                                          value={shipping.nameofShippingAddress}
                                          onChange={(event) =>
                                            handleShippingForm(index, event)
                                          }
                                          style={{ height: "37px" }}
                                        />
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg={12} md={6}>
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          GSTIN
                                        </label>
                                        <Input
                                          variant="auth"
                                          fontSize="sm"
                                          type="text"
                                          mb="24px"
                                          size="lg"
                                          name="GSTIN"
                                          value={shipping.GSTIN}
                                          onChange={(event) =>
                                            handleShippingForm(index, event)
                                          }
                                          style={{ height: "37px" }}
                                        />
                                      </Col>
                                    </Row>
                                  </form>
                                </Container>
                              </div>
                              <br />
                            </div>
                          );
                        })}

                        {/* For Interview Address */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "1rem",
                          }}
                        >
                          <h1
                            style={{
                              fontWeight: "bold",
                              fontSize: "1.3rem",
                              padding: "0.3rem",
                              paddingBottom: "15px",
                            }}
                            className="usertitle"
                          >
                            Interview Address
                          </h1>
                          <span
                            style={{
                              display: "flex",
                              width: "76px",
                              height: "34px",
                              fontFamily: "Roboto",
                              color: "#627D8C",
                              fontSize: "20px",
                              border: "1.2px solid #87A6B7",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "3px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleAddInterview();
                            }}
                          >
                            Add
                          </span>
                        </div>
                        {InterviewFields.map((interview, index) => {
                          return (
                            <div key={index} style={{ position: "relative" }}>
                              <div className="close_icon">
                                <CloseButton
                                  onClick={() => {
                                    let filterData = InterviewFields.filter(
                                      (res, In) => In !== index
                                    );
                                    setInterviewFields(filterData);
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  border: "1px solid #d3d1d1",
                                  width: "100%",
                                  height: "auto",
                                  borderRadius: "5px",
                                  padding: "10px",
                                  marginBottom: "24px",
                                }}
                              >
                                <Container>
                                  <form>
                                    <Row>
                                      <Col
                                        lg={4}
                                        md={4}
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          Country
                                        </label>

                                        <ReactSelect
                                          name="country"
                                          options={Country.getAllCountries()}
                                          placeholder="Select Country"
                                          getOptionLabel={(options) =>
                                            options["name"]
                                          }
                                          getOptionValue={(options) =>
                                            options["isoCode"]
                                          }
                                          value={
                                            interview.country
                                              ? {
                                                  name: interview.country,
                                                  isoCode:
                                                    interview.countryCode,
                                                }
                                              : null
                                          }
                                          onChange={(value, action) => {
                                            handleFormInterviewChange(
                                              index,
                                              value,
                                              action
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col
                                        lg={4}
                                        md={4}
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          State
                                        </label>

                                        <ReactSelect
                                          name="state"
                                          options={State.getStatesOfCountry(
                                            interview.countryCode
                                          )}
                                          placeholder="Select State"
                                          getOptionLabel={(options) =>
                                            options["name"]
                                          }
                                          getOptionValue={(options) =>
                                            options["isoCode"]
                                          }
                                          value={
                                            interview.state
                                              ? {
                                                  name: interview.state,
                                                  isoCode: interview.stateCode,
                                                }
                                              : null
                                          }
                                          onChange={(value, action) => {
                                            handleFormInterviewChange(
                                              index,
                                              value,
                                              action
                                            );
                                          }}
                                        />
                                      </Col>
                                      <Col lg={4} md={4}>
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          City
                                        </label>

                                        <ReactSelect
                                          name="city"
                                          options={City.getCitiesOfState(
                                            interview.countryCode,
                                            interview.stateCode
                                          )}
                                          placeholder="Select City"
                                          getOptionLabel={(options) =>
                                            options["name"]
                                          }
                                          getOptionValue={(options) =>
                                            options["name"]
                                          }
                                          value={
                                            interview.city
                                              ? { name: interview.city }
                                              : null
                                          }
                                          onChange={(value, action) => {
                                            handleFormInterviewChange(
                                              index,
                                              value,
                                              action
                                            );
                                          }}
                                        />
                                      </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                      <Col lg={4} md={4}>
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          Street
                                        </label>
                                        <Input
                                          variant="auth"
                                          fontSize="sm"
                                          type="text"
                                          placeholder="Street"
                                          mb="24px"
                                          size="lg"
                                          name="street"
                                          value={interview.street}
                                          onChange={(event) =>
                                            handleInterviewForm(index, event)
                                          }
                                          style={{ height: "37px" }}
                                        />
                                      </Col>
                                      <Col lg={4} md={4}>
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          Pincode
                                        </label>
                                        <Input
                                          variant="auth"
                                          fontSize="sm"
                                          type="text"
                                          placeholder="Pincode"
                                          mb="24px"
                                          size="lg"
                                          name="pincode"
                                          value={interview.pincode}
                                          onChange={(event) =>
                                            handleInterviewForm(index, event)
                                          }
                                          style={{ height: "37px" }}
                                        />
                                      </Col>
                                      <Col
                                        lg={4}
                                        md={4}
                                        style={{ marginBottom: "1rem" }}
                                      >
                                        <label
                                          style={{
                                            fontSize: "13px",
                                            fontWeight: "400",
                                            fontFamily: "Roboto",
                                            color: "#000000",
                                          }}
                                        >
                                          Time Zone
                                        </label>
                                        <select
                                          className="form-control"
                                          name="timezone"
                                          value={interview.timezone}
                                          onChange={(event) =>
                                            handleInterviewForm(index, event)
                                          }
                                          style={{ height: "37px" }}
                                        >
                                          <option disabled={true} value="">
                                            Select
                                          </option>
                                          <option>
                                            (UTC-06:00) Central Time (US &
                                            Canada)
                                          </option>
                                          <option>
                                            (UTC-07:00) Mountain Time (US &
                                            Canada)
                                          </option>
                                          <option>
                                            (UTC-08:00) Pacific Time (US & Los
                                            Angeles)
                                          </option>
                                          <option>
                                            (UTC-05:00) Eastern Time (US &
                                            Canada)
                                          </option>
                                          <option>
                                            (UTC-08:00) Pacific Time (US &
                                            Phoenix)
                                          </option>
                                        </select>
                                      </Col>
                                    </Row>
                                  </form>
                                </Container>
                              </div>
                            </div>
                          );
                        })}
                      </FormControl>
                      <Modal.Footer
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="cancel"
                          type="button"
                          onClick={() => handleEditAccountClose()}
                        >
                          Cancle
                        </Button>
                        <Button
                          className="btn_bg_color"
                          isLoading={editClientLoading}
                          type="submit"
                        >
                          Update
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
            </TabPanel>
            {/* Contact */}
            <TabPanel>
              <div className="table_box">
                <Row>
                  <Col lg={8}>
                    <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleFilterContactAllFieldStep(
                            searchContactfields,
                            0,
                            10,
                            "text"
                          );
                        }}
                        style={{ width: "100%" }}
                      >
                        <InputGroup>
                          <InputLeftElement
                            children={
                              <img
                                src={Searchicon}
                                width="18px"
                                height="18px"
                                style={{ cursor: "pointer" }}
                              />
                            }
                            onClick={() => {
                              setTablePage((prev) => {
                                return {
                                  ...prev,
                                  contact: DEFAULT_PAGE,
                                };
                              });
                              setTableRowsPerPage((prev) => {
                                return {
                                  ...prev,
                                  contact: DEFAULT_ROW_PER_PAGE,
                                };
                              });
                              handleFilterContactAllFieldStep(
                                searchContactfields,
                                0,
                                10,
                                "text"
                              );
                            }}
                          />
                          <Input
                            type="text"
                            id="searchAllfields"
                            placeholder="Search Name, Clients, Designation..."
                            value={searchContactfields}
                            onChange={(e) => {
                              setSearchContactfields(e.target.value);
                            }}
                            style={{
                              height: "37px",
                              padding: "9px",
                              paddingLeft: "2.6rem",
                              color: "#7B9AAA",
                              fontFamily: "Roboto",
                              fontWeight: "500",
                              lineHeight: "19px",
                              fontSize: "15px",
                            }}
                          />
                          {searchContactfields.length !== 0 && (
                            <InputRightElement
                              children={
                                <CloseButton
                                  size="md"
                                  sx={{
                                    background: "#fff",
                                    color: "rgb(123, 154, 170)",
                                  }}
                                  _hover={{ background: "" }}
                                  _focus={{ boxShadow: "none" }}
                                />
                              }
                              onClick={() => {
                                setSearchContactfields("");
                                // setPage(0);
                                // setRowsPerPage(10);
                                // handleFilterContactAllFieldStep(
                                //   "",
                                //   0,
                                //   10,
                                //   "text"
                                // );
                              }}
                            />
                          )}
                        </InputGroup>
                      </form>
                      {/* {(activeContactField.name || searchContactfields) && ( */}
                      <Button
                        className="bg_color"
                        onClick={() => clearContactFilter()}
                        style={{
                          color: "white",
                          border: "1px solid #d7d7d7",
                          fontWeight: "500",
                          letterSpacing: "1px",
                          fontSize: "14px",
                        }}
                      >
                        Reset
                      </Button>
                      {/* )} */}
                    </Box>
                  </Col>
                  <Col lg={4}>
                    <Box className="button_box">
                      <Button
                        style={{
                          color: "white",
                          border: "1px solid #d7d7d7",
                          fontWeight: "500",
                          letterSpacing: "1px",
                          fontSize: "14px",
                        }}
                        className="bg_color"
                        onClick={() => {
                          handleAddContactShow();
                          setContact({ ...contact, accountId: account?._id });
                        }}
                      >
                        Add Contact
                      </Button>
                    </Box>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                }}
              >
                <Paper style={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer style={{ maxHeight: 680 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow sx={{ textAlignLast: "center" }}>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "50px",
                            }}
                          >
                            <Box className="table_menu">
                              <Checkbox
                                size="md"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    SetIschecked((oldarr) => [
                                      ...oldarr,
                                      ...displaycontacts?.map((value) => ({
                                        id: value?._id,
                                        email: value?.email,
                                      })),
                                    ]);
                                  } else {
                                    SetIschecked([]);
                                  }
                                }}
                              />
                              <Menu>
                                <MenuButton>
                                  <ExpandMoreIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                </MenuButton>
                                <MenuList
                                  style={{ position: "absolute", top: "12px" }}
                                >
                                  <MenuItem
                                    icon={<MailOutlineIcon />}
                                    onClick={() => {
                                      handleSendMailShow();
                                    }}
                                  >
                                    Batch Send Mail
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Box>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Name
                            <OverlayTrigger
                              {...popOverConfig("contactName")}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterContactData}
                                      inputState={searchName}
                                      fieldName={"Name"}
                                      setInputState={setSearchName}
                                      setActiveField={setActiveContactField}
                                      activeFieldName={"Name"}
                                      isFilterActiveState={isFilterActiveName}
                                      inputPlaceholder={
                                        "Search Account Name..."
                                      }
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveName ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Designation
                            <OverlayTrigger
                              {...popOverConfig("designation")}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterContactData}
                                      inputState={searchdesignation}
                                      fieldName={"designation"}
                                      setInputState={setSearchDesignation}
                                      setActiveField={setActiveContactField}
                                      activeFieldName={"designation"}
                                      isFilterActiveState={
                                        isFilterActiveDesignation
                                      }
                                      inputPlaceholder={"Search Designation..."}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveDesignation ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Email
                            <OverlayTrigger
                              {...popOverConfig("email")}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterContactData}
                                      inputState={searchemail}
                                      fieldName={"email"}
                                      setInputState={setSearchEmail}
                                      setActiveField={setActiveContactField}
                                      activeFieldName={"email"}
                                      isFilterActiveState={isFilterActiveEmail}
                                      inputPlaceholder={"Search Email..."}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveEmail ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Mobile
                            <OverlayTrigger
                              {...popOverConfig("phone")}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterContactData}
                                      inputState={searchphone}
                                      fieldName={"phone"}
                                      setInputState={setSearchPhone}
                                      setActiveField={setActiveContactField}
                                      activeFieldName={"phone"}
                                      isFilterActiveState={isFilterActivePhone}
                                      inputPlaceholder={"Search Phone..."}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActivePhone ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Client
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Created By
                            <OverlayTrigger
                              {...popOverConfig("createdBy")}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body>
                                    <Box sx={{ display: "flex", gap: 5 }}>
                                      <CheckPicker
                                        data={reduxUserNames.map((user) => ({
                                          label: `${user.firstName?.trim()} ${user.lastName?.trim()}`,
                                          value: user?._id,
                                        }))}
                                        style={{ width: 224 }}
                                        menuClassName="reactMultiSelectMenu"
                                        value={searchCreatedBy}
                                        onChange={(selectedOption) => {
                                          setSearchCreatedBy(selectedOption);
                                          setActiveContactField({
                                            name: "created_by",
                                            value: selectedOption,
                                          });
                                        }}
                                        onClean={() => {
                                          if (isFilterActiveCreateBy) {
                                            filterContactData("", "created_by");
                                            setIsFilterActiveCreateBy(false);
                                          }
                                        }}
                                        placeholder="Select Users"
                                      />
                                      <IconButton
                                        color={"#fff"}
                                        background={"var(--main-bg-color)"}
                                        aria-label="Search date range"
                                        _hover={{
                                          background: "var(--main-bg-color)",
                                        }}
                                        size={"sm"}
                                        icon={<FiSearch />}
                                        disabled={
                                          searchCreatedBy?.length !== 0
                                            ? false
                                            : true
                                        }
                                        onClick={() => {
                                          filterContactData(
                                            searchCreatedBy,
                                            "created_by"
                                          );
                                        }}
                                      />
                                    </Box>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveCreateBy ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Modified By
                            <OverlayTrigger
                              {...popOverConfig("modifiedBy")}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <Box sx={{ display: "flex", gap: 5 }}>
                                      <CheckPicker
                                        data={reduxUserNames.map((user) => ({
                                          label: `${user.firstName?.trim()} ${user.lastName?.trim()}`,
                                          value: user?._id,
                                        }))}
                                        style={{ width: 224 }}
                                        menuClassName="reactMultiSelectMenu"
                                        value={searchModifiedBy}
                                        onChange={(selectedOption) => {
                                          setSearchModifiedBy(selectedOption);
                                          setActiveContactField({
                                            name: "modified_by",
                                            value: selectedOption,
                                          });
                                        }}
                                        onClean={() => {
                                          if (isFilterActiveModifyBy) {
                                            filterContactData(
                                              "",
                                              "modified_by"
                                            );
                                            setIsFilterActiveModifyBy(false);
                                          }
                                        }}
                                        placeholder="Select Users"
                                      />
                                      <IconButton
                                        color={"#fff"}
                                        background={"var(--main-bg-color)"}
                                        aria-label="Search date range"
                                        _hover={{
                                          background: "var(--main-bg-color)",
                                        }}
                                        size={"sm"}
                                        icon={<FiSearch />}
                                        disabled={
                                          searchModifiedBy?.length !== 0
                                            ? false
                                            : true
                                        }
                                        onClick={() => {
                                          filterContactData(
                                            searchModifiedBy,
                                            "modified_by"
                                          );
                                        }}
                                      />
                                    </Box>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveModifyBy ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Created On
                            <OverlayTrigger
                              {...popOverConfig("date")}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "460px" }}
                                >
                                  <Popover.Body onClick={(e) => e.stopPropagation()}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        gap: 5,
                                        marginBottom: 2,
                                        alignItems: "center",
                                      }}
                                    >
                                      <InputGroup>
                                        <Input
                                          type="date"
                                          id="startDate"
                                          placeholder="From Date..."
                                          value={startContactDate}
                                          onChange={(e) => {
                                            setStartContactDate(e.target.value);
                                            const filterDate = {
                                              startDate: e.target.value,
                                              endDate: endContactDate,
                                            };
                                            setActiveContactField({
                                              name: "created_at",
                                              value: filterDate,
                                            });
                                          }}
                                          style={{
                                            height: "37px",
                                            color: "#7B9AAA",
                                            fontFamily: "Roboto",
                                            fontWeight: "500",
                                            lineHeight: "19px",
                                            fontSize: "15px",
                                          }}
                                        />
                                      </InputGroup>
                                      <span
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-evenly",
                                        }}
                                      >
                                        {" "}
                                        -{" "}
                                      </span>
                                      <InputGroup>
                                        <Input
                                          type="date"
                                          id="endDate"
                                          placeholder="To Date..."
                                          value={endContactDate}
                                          min={startContactDate}
                                          onChange={(e) => {
                                            setEndContactDate(e.target.value);
                                            const filterDate = {
                                              startDate: startContactDate,
                                              endDate: e.target.value,
                                            };
                                            setActiveContactField({
                                              name: "created_at",
                                              value: filterDate,
                                            });
                                          }}
                                          style={{
                                            height: "37px",
                                            color: "#7B9AAA",
                                            fontFamily: "Roboto",
                                            fontWeight: "500",
                                            lineHeight: "19px",
                                            fontSize: "15px",
                                          }}
                                        />
                                      </InputGroup>
                                      {startContactDate && endContactDate && (
                                        <IconButton
                                          color={"#fff"}
                                          background={"var(--main-bg-color)"}
                                          aria-label="Search date range"
                                          _hover={{
                                            background: "var(--main-bg-color)",
                                          }}
                                          size={"sm"}
                                          icon={<FiSearch />}
                                          onClick={() => {
                                            const filterDate = {
                                              startDate: startContactDate,
                                              endDate: endContactDate,
                                            };
                                            filterContactData(
                                              filterDate,
                                              "date"
                                            );
                                          }}
                                        />
                                      )}
                                      {isFilterActiveDate && (
                                        <CloseButton
                                          size="sm"
                                          onClick={() => {
                                            setStartContactDate("");
                                            setEndContactDate("");
                                            setActiveContactField({
                                              name: "created_at",
                                              value: {
                                                startDate: "",
                                                endDate: "",
                                              },
                                            });
                                            handleFilterContactAllFieldStep(
                                              {
                                                startDate: "",
                                                endDate: "",
                                              },
                                              page,
                                              rowsPerPage,
                                              "created_at"
                                            );
                                            setIsFilterActiveDate(false);
                                            handlePopOverClose(false);
                                          }}
                                          _focus={{ boxShadow: "none" }}
                                        />
                                      )}
                                    </Box>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveDate ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Updated On
                            <OverlayTrigger
                              {...popOverConfig("updatedOn")}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "460px" }}
                                >
                                  <Popover.Body onClick={(e) => e.stopPropagation()}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        gap: 5,
                                        marginBottom: 2,
                                        alignItems: "center",
                                      }}
                                    >
                                      <InputGroup>
                                        <Input
                                          type="date"
                                          id="startDate"
                                          placeholder="From Date..."
                                          value={updatedContactStartDate}
                                          onChange={(e) => {
                                            setUpdatedContactStartDate(
                                              e.target.value
                                            );
                                            const filterDate = {
                                              startDate: e.target.value,
                                              endDate: updatedContactEndDate,
                                            };
                                            setActiveContactField({
                                              name: "updated_at",
                                              value: filterDate,
                                            });
                                          }}
                                          style={{
                                            height: "37px",
                                            color: "#7B9AAA",
                                            fontFamily: "Roboto",
                                            fontWeight: "500",
                                            lineHeight: "19px",
                                            fontSize: "15px",
                                          }}
                                        />
                                      </InputGroup>
                                      <span
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          justifyContent: "space-evenly",
                                        }}
                                      >
                                        {" "}
                                        -{" "}
                                      </span>
                                      <InputGroup>
                                        <Input
                                          type="date"
                                          id="endDate"
                                          placeholder="To Date..."
                                          value={updatedContactEndDate}
                                          min={updatedContactStartDate}
                                          onChange={(e) => {
                                            setUpdatedContactEndDate(
                                              e.target.value
                                            );
                                            const filterDate = {
                                              startDate:
                                                updatedContactStartDate,
                                              endDate: e.target.value,
                                            };
                                            setActiveContactField({
                                              name: "updated_at",
                                              value: filterDate,
                                            });
                                          }}
                                          style={{
                                            height: "37px",
                                            color: "#7B9AAA",
                                            fontFamily: "Roboto",
                                            fontWeight: "500",
                                            lineHeight: "19px",
                                            fontSize: "15px",
                                          }}
                                        />
                                      </InputGroup>
                                      {updatedContactStartDate &&
                                        updatedContactEndDate && (
                                          <IconButton
                                            color={"#fff"}
                                            background={"var(--main-bg-color)"}
                                            aria-label="Search date range"
                                            _hover={{
                                              background:
                                                "var(--main-bg-color)",
                                            }}
                                            size={"sm"}
                                            icon={<FiSearch />}
                                            onClick={() => {
                                              const filterDate = {
                                                startDate:
                                                  updatedContactStartDate,
                                                endDate: updatedContactEndDate,
                                              };
                                              filterContactData(
                                                filterDate,
                                                "updatedate"
                                              );
                                            }}
                                          />
                                        )}
                                      {isFilterActiveUpdateDate && (
                                        <CloseButton
                                          size="sm"
                                          onClick={() => {
                                            setUpdatedContactEndDate("");
                                            setUpdatedContactStartDate("");
                                            setActiveContactField({
                                              name: "updated_at",
                                              value: {
                                                startDate: "",
                                                endDate: "",
                                              },
                                            });
                                            handleFilterContactAllFieldStep(
                                              {
                                                startDate: "",
                                                endDate: "",
                                              },
                                              page,
                                              rowsPerPage,
                                              "updated_at"
                                            );
                                            setIsFilterActiveUpdateDate(false);
                                            handlePopOverClose(false);
                                          }}
                                          _focus={{ boxShadow: "none" }}
                                        />
                                      )}
                                    </Box>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveUpdateDate ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <>
                            <TableRow>
                              <TableCell colSpan={9}>
                                <Loadar />
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <>
                            {Array.isArray(displaycontacts) &&
                            displaycontacts.length == 0 ? (
                              <>
                                <TableRow>
                                  <TableCell colSpan={9}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={NotFound}
                                        alt="NotFound"
                                        style={{ width: "16rem" }}
                                      />
                                      <p>
                                        No Records Found! Please try again....
                                      </p>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : (
                              <>
                                {displaycontacts &&
                                  displaycontacts.map((value, index) => (
                                    <TableRow key={value._id}>
                                      <TableCell className="nowrap_content">
                                        <Box className="table_menu">
                                          <Checkbox
                                            size="md"
                                            value={value?._id}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                SetIschecked((oldarr) => [
                                                  ...oldarr,
                                                  {
                                                    id: e.target?.value,
                                                    email: value?.email,
                                                  },
                                                ]);
                                              } else {
                                                SetIschecked((olarr) => {
                                                  return olarr.filter(
                                                    (value) =>
                                                      e.target?.value !==
                                                      value?.id
                                                  );
                                                });
                                              }
                                            }}
                                            isChecked={checked
                                              ?.map((val) => val?.id)
                                              .includes(value?._id)}
                                          />
                                          <Menu>
                                            <MenuButton>
                                              <MoreVertIcon
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "20px",
                                                }}
                                              />
                                            </MenuButton>
                                            <MenuList
                                              style={{
                                                position: "absolute",
                                                top: "2px",
                                                zIndex: "100",
                                              }}
                                            >
                                              <MenuItem
                                                icon={
                                                  <ModeEditIcon
                                                    style={{ fontSize: "20px" }}
                                                  />
                                                }
                                                onClick={() => {
                                                  handleEditContactShow();
                                                  setContact(value);
                                                  setContactId(value?._id);
                                                  editContactdata(value);
                                                }}
                                              >
                                                Edit
                                              </MenuItem>
                                              <MenuItem
                                                icon={
                                                  <DeleteIcon
                                                    style={{ fontSize: "20px" }}
                                                  />
                                                }
                                                onClick={() => {
                                                  handleDeleteContactShow(
                                                    setContact(value),
                                                    setContactId(value?._id)
                                                  );
                                                }}
                                              >
                                                Delete
                                              </MenuItem>
                                            </MenuList>
                                          </Menu>
                                          <div style={{ position: "relative" }}>
                                            {value?.noteCount !== 0 && (
                                              <div
                                                style={{
                                                  color: "white",
                                                  height: "auto",
                                                  width: "auto",
                                                  textAlign: "center",
                                                  position: "absolute",
                                                  marginLeft: "11px",
                                                  marginTop: "-10px",
                                                  borderRadius: "50px",
                                                  fontSize: "10px",
                                                  padding: "1px 5px",
                                                }}
                                                className="bg_color"
                                              >
                                                <span>{value?.noteCount}</span>
                                              </div>
                                            )}
                                            <Tooltip
                                              hasArrow
                                              label="Notes"
                                              bg="gray.300"
                                              color="black"
                                            >
                                              <button
                                                className="note_btn"
                                                onClick={() => {
                                                  handleContactNoteShow();
                                                  setContactNoteValue({
                                                    ...contactNoteValue,
                                                    contactId: value?._id,
                                                    name : value?.accountOwnerName
                                                  });
                                                  getContactNotes(
                                                    value?._id,
                                                    setGetNotes,
                                                    setNotesLoading
                                                  );
                                                }}
                                              >
                                                N
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </Box>
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                        className="nowrap_content"
                                      >
                                        <Link
                                          to={`/details/contactview/${value._id}`}
                                          target="_blank"
                                        >
                                          <span>
                                            {!value?.accountOwnerName
                                              ? "N/A"
                                              : value?.accountOwnerName}
                                          </span>
                                        </Link>
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.designation
                                          ? "N/A"
                                          : value?.designation}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.email ? "N/A" : value?.email}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.phone ? "N/A" : value?.phone}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.accountName
                                          ? "N/A"
                                          : value?.accountName}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.created_by_data
                                          ? "N/A"
                                          : `${value?.created_by_data?.firstName} ${value?.created_by_data?.lastName}`}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.modified_by_data
                                          ? "N/A"
                                          : `${value?.modified_by_data?.firstName} ${value?.modified_by_data?.lastName}`}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.created_at
                                          ? "N/A"
                                          : new Date(
                                              value?.created_at
                                            ).toLocaleDateString("en-GB", {
                                              day: "2-digit",
                                              month: "2-digit",
                                              year: "numeric",
                                            })}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.updated_at
                                          ? "N/A"
                                          : new Date(
                                              value?.updated_at
                                            ).toLocaleDateString("en-GB", {
                                              day: "2-digit",
                                              month: "2-digit",
                                              year: "numeric",
                                            })}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
  

            <CommonPagination
              rowsPerPage={tableRowsPerPage.contact}
              page={tablePage.contact}
              totalPages={Math.ceil(displaycontacts?.totalContact/tableRowsPerPage.contact)||0}
              handleChangePage={handleContactChangePage}
              handleChangeRowsPerPage={handleContactChangeRowsPerPage}
              count={displaycontacts?.totalContact}
            />
                </Paper>
              </div>
              {/* Modal for Add Contact */}
              <div className="model-box-view">
                <Modal
                  show={addContact}
                  onHide={handleAddContactClose}
                  backdrop="static"
                  keyboard={false}
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="usertitle">Add Contact</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <h1
                      style={{
                        fontWeight: "bold",
                        fontSize: "1.3rem",
                        padding: "0.3rem",
                        paddingBottom: "15px",
                      }}
                      className="usertitle"
                    >
                      Contact Information
                    </h1>
                    <form id="addaccount" onSubmit={contactFormik.handleSubmit}>
                      <Container>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Name
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Name"
                              size="lg"
                              name="accountOwnerName"
                              value={contactFormik.values.accountOwnerName}
                              onChange={contactFormik.handleChange}
                              onBlur={contactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  contactFormik.touched.accountOwnerName &&
                                  contactFormik.errors.accountOwnerName
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {contactFormik.touched.accountOwnerName &&
                            contactFormik.errors.accountOwnerName ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {contactFormik.errors.accountOwnerName}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Email
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Email"
                              size="lg"
                              name="email"
                              value={contactFormik.values.email}
                              onChange={contactFormik.handleChange}
                              onBlur={contactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  contactFormik.touched.email &&
                                  contactFormik.errors.email
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {contactFormik.touched.email &&
                            contactFormik.errors.email ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {contactFormik.errors.email}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Designation
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Designation"
                              size="lg"
                              name="designation"
                              value={contactFormik.values.designation}
                              onChange={contactFormik.handleChange}
                              onBlur={contactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  contactFormik.touched.designation &&
                                  contactFormik.errors.designation
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {contactFormik.touched.designation &&
                            contactFormik.errors.designation ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {contactFormik.errors.designation}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Department
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Department"
                              size="lg"
                              name="department"
                              value={contactFormik.values.department}
                              onChange={contactFormik.handleChange}
                              onBlur={contactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  contactFormik.touched.department &&
                                  contactFormik.errors.department
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {contactFormik.touched.department &&
                            contactFormik.errors.department ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {contactFormik.errors.department}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Phone
                            </FormLabel>
                            <div className="contactphone">
                              <PhoneInput
                                country={"in"}
                                containerStyle={{
                                  border:
                                    contactFormik.touched.phone &&
                                    contactFormik.errors.phone
                                      ? "1px solid red"
                                      : "",
                                  borderRadius:
                                    contactFormik.touched.phone &&
                                    contactFormik.errors.phone
                                      ? "4px"
                                      : "",
                                }}
                                dropdownStyle={{
                                  border:
                                    contactFormik.touched.phone &&
                                    contactFormik.errors.phone
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                }}
                                onChange={(phone) => {
                                  setContact({ ...contact, phone: phone });
                                  const phoneNumberWithPlus = `+${phone}`;

                                  contactFormik.setFieldValue(
                                    "phone",
                                    phoneNumberWithPlus
                                  );
                                }}
                                value={contactFormik.values.phone}
                                onBlur={contactFormik.handleBlur}
                              />
                              {contactFormik.touched.phone &&
                              contactFormik.errors.phone ? (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {contactFormik.errors.phone}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              External Phone
                            </FormLabel>
                            <div className="contactphone">
                              <PhoneInput
                                country={"in"}
                                onChange={(externalphone) => {
                                  setContact({
                                    ...contact,
                                    externalphone: externalphone,
                                  });
                                  const phoneNumberWithPlus = `+${externalphone}`;

                                  contactFormik.setFieldValue(
                                    "externalphone",
                                    phoneNumberWithPlus
                                  );
                                }}
                                value={contactFormik.values.externalphone}
                                onBlur={contactFormik.handleBlur}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Country
                            </FormLabel>
                            <Select
                              options={Country.getAllCountries()}
                              placeholder="Select Country"
                              name="country"
                              getOptionLabel={(options) => {
                                return options["name"];
                              }}
                              getOptionValue={(options) => {
                                return options["name"];
                              }}
                              classNamePrefix={
                                contactFormik.touched.country &&
                                contactFormik.errors.country &&
                                "democlass"
                              }
                              styles={{
                                border:
                                  contactFormik.touched.country &&
                                  contactFormik.errors.country
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                              value={
                                contactFormik.values.country && selectedCountry
                              }
                              onBlur={contactFormik.handleBlur}
                              onChange={(item) => {
                                setSelectedCountry(item);
                                setContact({ ...contact, country: item.name });
                                contactFormik.setFieldValue(
                                  "country",
                                  item.name
                                );
                              }}
                            />
                            {contactFormik.touched.country &&
                            contactFormik.errors.country ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {contactFormik.errors.country}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              State
                            </FormLabel>
                            <Select
                              options={State?.getStatesOfCountry(
                                selectedCountry?.isoCode
                              )}
                              placeholder="Select State"
                              getOptionLabel={(options) => {
                                return options["name"];
                              }}
                              getOptionValue={(options) => {
                                return options["name"];
                              }}
                              classNamePrefix={
                                contactFormik.touched.state &&
                                contactFormik.errors.state &&
                                "democlass"
                              }
                              styles={{
                                border:
                                  contactFormik.touched.state &&
                                  contactFormik.errors.state
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                              name="state"
                              value={
                                contactFormik.values.state && selectedState
                              }
                              onChange={(item) => {
                                setSelectedState(item);
                                setContact({ ...contact, state: item.name });
                                contactFormik.setFieldValue("state", item.name);
                              }}
                              onBlur={contactFormik.handleBlur}
                            />
                            {contactFormik.touched.state &&
                            contactFormik.errors.state ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {contactFormik.errors.state}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              City
                            </FormLabel>
                            <Select
                              options={City.getCitiesOfState(
                                selectedState?.countryCode,
                                selectedState?.isoCode
                              )}
                              placeholder="Select City"
                              getOptionLabel={(options) => {
                                return options["name"];
                              }}
                              getOptionValue={(options) => {
                                return options["name"];
                              }}
                              classNamePrefix={
                                contactFormik.touched.city &&
                                contactFormik.errors.city &&
                                "democlass"
                              }
                              styles={{
                                border:
                                  contactFormik.touched.city &&
                                  contactFormik.errors.city
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                              name="city"
                              value={contactFormik.values.city && selectedCity}
                              onChange={(item) => {
                                setSelectedCity(item);
                                setContact({ ...contact, city: item.name });
                                contactFormik.setFieldValue("city", item.name);
                              }}
                              onBlur={contactFormik.handleBlur}
                            />
                            {contactFormik.touched.city &&
                            contactFormik.errors.city ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {contactFormik.errors.city}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Street
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Street"
                              size="lg"
                              name="street"
                              value={contactFormik.values.street}
                              onChange={contactFormik.handleChange}
                              onBlur={contactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  contactFormik.touched.street &&
                                  contactFormik.errors.street
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {contactFormik.touched.street &&
                            contactFormik.errors.street ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {contactFormik.errors.street}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Pincode
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Pincode"
                              size="lg"
                              name="pincode"
                              value={contactFormik.values.pincode}
                              onChange={contactFormik.handleChange}
                              onBlur={contactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  contactFormik.touched.pincode &&
                                  contactFormik.errors.pincode
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {contactFormik.touched.pincode &&
                            contactFormik.errors.pincode ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {contactFormik.errors.pincode}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6}></Col>
                        </Row>
                      </Container>
                      <Modal.Footer
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="cancel"
                          type="button"
                          onClick={handleAddContactClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn_bg_color"
                          isLoading={addContactApiResult.isLoading}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
              {/* Modal for Edit Contact */}
              <div className="model-box-view">
                <Modal
                  show={editContact}
                  onHide={handleEditContactClose}
                  backdrop="static"
                  keyboard={false}
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="usertitle">
                      Edit Contact
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form
                      id="addaccount"
                      onSubmit={editContactFormik.handleSubmit}
                    >
                      <Container>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Account Name
                            </FormLabel>

                            <select
                            disabled={true}
                              className="form-control"
                              name="accountName"
                              value={editContactFormik.values.accountName}
                              onChange={(e) => {
                                editContactFormik.setFieldValue(
                                  "accountName",
                                  e.target.options[e.target.selectedIndex].text
                                );
                                editContactFormik.setFieldValue(
                                  "accountId",
                                  e.target.options[e.target.selectedIndex].id
                                );
                                editContactFormik.setFieldValue(
                                  "clientemail",
                                  e.target.options[e.target.selectedIndex].title
                                );
                              }}
                              style={{ width: "100%", height: "37px" }}
                            >
                              <option  value="">
                                Accounts
                              </option>
                              {accounts.map((account) => (
                                <option
                                  id={account._id}
                                  value={account.accountName}
                                  title={account.email}
                                >
                                  {account.accountName}
                                </option>
                              ))}
                            </select>
                          </Col>
                        </Row>
                      </Container>
                      <h1
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                          padding: "0.3rem",
                          paddingBottom: "15px",
                        }}
                        className="usertitle"
                      >
                        Contact Information
                      </h1>

                      <Container>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Name
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Name"
                              size="lg"
                              name="accountOwnerName"
                              value={editContactFormik.values.accountOwnerName}
                              onChange={editContactFormik.handleChange}
                              onBlur={editContactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editContactFormik.touched.accountOwnerName &&
                                  editContactFormik.errors.accountOwnerName
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editContactFormik.touched.accountOwnerName &&
                            editContactFormik.errors.accountOwnerName ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editContactFormik.errors.accountOwnerName}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Email
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Email"
                              size="lg"
                              name="email"
                              value={editContactFormik.values.email}
                              onChange={editContactFormik.handleChange}
                              onBlur={editContactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editContactFormik.touched.email &&
                                  editContactFormik.errors.email
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editContactFormik.touched.email &&
                            editContactFormik.errors.email ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editContactFormik.errors.email}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Designation
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Designation"
                              size="lg"
                              name="designation"
                              value={editContactFormik.values.designation}
                              onChange={editContactFormik.handleChange}
                              onBlur={editContactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editContactFormik.touched.designation &&
                                  editContactFormik.errors.designation
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editContactFormik.touched.designation &&
                            editContactFormik.errors.designation ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editContactFormik.errors.designation}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Department
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Department"
                              size="lg"
                              name="department"
                              value={editContactFormik.values.department}
                              onChange={editContactFormik.handleChange}
                              onBlur={editContactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editContactFormik.touched.department &&
                                  editContactFormik.errors.department
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editContactFormik.touched.department &&
                            editContactFormik.errors.department ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editContactFormik.errors.department}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Phone
                            </FormLabel>
                            <div className="contactphone">
                              <PhoneInput
                                country={"in"}
                                containerStyle={{
                                  border:
                                    formik.touched.phone && formik.errors.phone
                                      ? "1px solid red"
                                      : "",
                                  borderRadius:
                                    formik.touched.phone && formik.errors.phone
                                      ? "4px"
                                      : "",
                                }}
                                dropdownStyle={{
                                  border:
                                    formik.touched.phone && formik.errors.phone
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                }}
                                onChange={(phone) => {
                                  setContact({ ...contact, phone: phone });
                                  const phoneNumberWithPlus = `+${phone}`;

                                  editContactFormik.setFieldValue(
                                    "phone",
                                    phoneNumberWithPlus
                                  );
                                }}
                                value={editContactFormik.values.phone}
                                onBlur={editContactFormik.handleBlur}
                              />
                              {editContactFormik.touched.phone &&
                              editContactFormik.errors.phone ? (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editContactFormik.errors.phone}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              External Phone
                            </FormLabel>
                            <div className="contactphone">
                              <PhoneInput
                                country={"in"}
                                onChange={(externalphone) => {
                                  setContact({
                                    ...contact,
                                    externalphone: externalphone,
                                  });
                                  const phoneNumberWithPlus = `+${externalphone}`;

                                  editContactFormik.setFieldValue(
                                    "externalphone",
                                    phoneNumberWithPlus
                                  );
                                }}
                                value={editContactFormik.values.externalphone}
                                onBlur={editContactFormik.handleBlur}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Country
                            </FormLabel>
                            <Select
                              options={Country.getAllCountries()}
                              placeholder="Select Country"
                              name="country"
                              classNamePrefix={
                                editContactFormik.touched.country &&
                                editContactFormik.errors.country &&
                                "democlass"
                              }
                              styles={{
                                border:
                                  editContactFormik.touched.country &&
                                  editContactFormik.errors.country
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                              getOptionLabel={(options) => {
                                return options["name"];
                              }}
                              getOptionValue={(options) => {
                                return options["name"];
                              }}
                              value={
                                editContactFormik.values.country &&
                                selectedCountry
                              }
                              onBlur={editContactFormik.handleBlur}
                              onChange={(item) => {
                                setSelectedCountry(item);
                                setContact({ ...contact, country: item.name });
                                editContactFormik.setFieldValue(
                                  "country",
                                  item.name
                                );
                              }}
                            />
                            {editContactFormik.touched.country &&
                            editContactFormik.errors.country ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editContactFormik.errors.country}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              State
                            </FormLabel>
                            <Select
                              options={State?.getStatesOfCountry(
                                selectedCountry?.isoCode
                              )}
                              placeholder="Select State"
                              getOptionLabel={(options) => {
                                return options["name"];
                              }}
                              getOptionValue={(options) => {
                                return options["name"];
                              }}
                              classNamePrefix={
                                editContactFormik.touched.state &&
                                editContactFormik.errors.state &&
                                "democlass"
                              }
                              styles={{
                                border:
                                  editContactFormik.touched.state &&
                                  editContactFormik.errors.state
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                              name="state"
                              value={
                                editContactFormik.values.state && selectedState
                              }
                              onChange={(item) => {
                                if (selectedState?.isoCode !== item.isoCode) {
                                  setSelectedState(item);
                                  setSelectedCity(null);
                                  setContact({ ...contact, state: item.name });
                                  editContactFormik.setFieldValue(
                                    "state",
                                    item.name
                                  );
                                  editContactFormik.setFieldValue("city", "");
                                }
                              }}
                              onBlur={editContactFormik.handleBlur}
                            />
                            {editContactFormik.touched.state &&
                            editContactFormik.errors.state ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editContactFormik.errors.state}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              City
                            </FormLabel>
                            <Select
                              options={City.getCitiesOfState(
                                selectedCountry?.isoCode,
                                selectedState?.isoCode
                              )}
                              placeholder="Select City"
                              getOptionLabel={(options) => {
                                return options["name"];
                              }}
                              getOptionValue={(options) => {
                                return options["name"];
                              }}
                              classNamePrefix={
                                editContactFormik.touched.city &&
                                editContactFormik.errors.city &&
                                "democlass"
                              }
                              styles={{
                                border:
                                  editContactFormik.touched.city &&
                                  editContactFormik.errors.city
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                              name="city"
                              value={
                                editContactFormik.values.city && selectedCity
                              }
                              onChange={(item) => {
                                setSelectedCity(item);
                                setContact({ ...contact, city: item.name });
                                editContactFormik.setFieldValue(
                                  "city",
                                  item.name
                                );
                              }}
                              onBlur={editContactFormik.handleBlur}
                            />
                            {editContactFormik.touched.city &&
                            editContactFormik.errors.city ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editContactFormik.errors.city}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Street
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Street"
                              size="lg"
                              name="street"
                              value={editContactFormik.values.street}
                              onChange={editContactFormik.handleChange}
                              onBlur={editContactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editContactFormik.touched.street &&
                                  editContactFormik.errors.street
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editContactFormik.touched.street &&
                            editContactFormik.errors.street ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editContactFormik.errors.street}
                              </div>
                            ) : null}
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6} style={{ marginBottom: "24px" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Pincode
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Pincode"
                              size="lg"
                              name="pincode"
                              value={editContactFormik.values.pincode}
                              onChange={editContactFormik.handleChange}
                              onBlur={editContactFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editContactFormik.touched.pincode &&
                                  editContactFormik.errors.pincode
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editContactFormik.touched.pincode &&
                            editContactFormik.errors.pincode ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editContactFormik.errors.pincode}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={6} md={6}></Col>
                        </Row>
                      </Container>
                      <Modal.Footer
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          className="cancel"
                          type="button"
                          onClick={handleEditContactClose}
                        >
                          Cancel
                        </Button>
                        <Button
                          className="btn_bg_color"
                          isLoading={updateContactApiResult.isLoading}
                          type="submit"
                        >
                          Update
                        </Button>
                      </Modal.Footer>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
              {/* Modal for Delete Contact */}
              <div className="model-box-view" id="deleteaccount">
                <Modal
                  show={deleteContact}
                  onHide={handleDeleteContactClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="usertitle">
                      Delete Contact
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Are you sure to delete?</p>
                  </Modal.Body>
                  <Modal.Footer
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      className="cancel"
                      onClick={handleDeleteContactClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn_bg_color"
                      onClick={handleDeleteContact}
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              {/* Modal for Batch Send Email for contacts*/}
              <ComposeMailForm
                ViewSendMail={ViewSendMail}
                handleSendMailClose={handleSendMailClose}
                SetToemail={SetToemail}
                SetCcemail={SetCcemail}
                SetBccemail={SetBccemail}
                SetSubject={SetSubject}
                SetMailMessage={SetMailMessage}
                checked={checked}
                endPoint={"/api/SelectContact_EmailSend"}
              />

              {/* Modal for Batch Send Email for Jobs*/}
              <ComposeMailForm
                ViewSendMail={ViewSendMailJob}
                handleSendMailClose={handleSendMailJobClose}
                SetToemail={SetToemail}
                SetCcemail={SetCcemail}
                SetBccemail={SetBccemail}
                SetSubject={SetSubject}
                SetMailMessage={SetMailMessage}
                checked={checked}
                endPoint={"/api/SelectJob_EmailSend"}
              />

              {/* Modal for Batch Send Email for Submissions*/}
              <ComposeMailForm
                ViewSendMail={ViewSendMailSub}
                handleSendMailClose={handleSendMailSubClose}
                SetToemail={SetToemail}
                SetCcemail={SetCcemail}
                SetBccemail={SetBccemail}
                SetSubject={SetSubject}
                SetMailMessage={SetMailMessage}
                checked={checked}
                endPoint={"/api/SelectCandidate_EmailSend"}
              />
            </TabPanel>
            {/* Jobs */}
            <TabPanel>
              <div className="table_box">
                <Row>
                  <Col lg={8}>
                    <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
                      <InputGroup>
                        <InputLeftElement
                          onClick={commonJobTextSeachFunc}
                          children={
                            <img
                              src={Searchicon}
                              width="18px"
                              height="18px"
                              style={{ cursor: "pointer" }}
                            />
                          }
                        />
                        <Input
                          type="text"
                          id="searchAllfields"
                          placeholder="Search Job Title, Skill, City..."
                          value={searchAllfields}
                          onChange={(e) => {
                            setSearchAllfields(e.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              commonJobTextSeachFunc();
                            }
                          }}
                          style={{
                            height: "37px",
                            padding: "9px",
                            paddingLeft: "2.6rem",
                            color: "#7B9AAA",
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            lineHeight: "19px",
                            fontSize: "15px",
                          }}
                        />
                        {searchAllfields.length !== 0 && (
                          <InputRightElement
                            children={
                              <CloseButton
                                size="md"
                                sx={{
                                  background: "#fff",
                                  color: "rgb(123, 154, 170)",
                                }}
                                _hover={{ background: "" }}
                                _focus={{ boxShadow: "none" }}
                              />
                            }
                            onClick={() => {
                              setSearchAllfields("");
                              // setPage(0);
                              // setRowsPerPage(10);
                              // handleFilterAllFieldStep(
                              //   "",
                              //   page,
                              //   rowsPerPage,
                              //   "text"
                              // );
                            }}
                          />
                        )}
                      </InputGroup>
                      {/* {(activeField.value !== "" || searchAllfields) && ( */}
                      <Button
                        className="bg_color"
                        onClick={() => clearJobFilter()}
                        style={{
                          color: "white",
                          border: "1px solid #d7d7d7",
                          fontWeight: "500",
                          letterSpacing: "1px",
                          fontSize: "14px",
                        }}
                      >
                        Reset
                      </Button>
                      {/* )} */}
                    </Box>
                  </Col>
                  <Col lg={4}>
                    <Box className="button_box">
                      <Button
                        style={{
                          color: "white",
                          border: "1px solid #d7d7d7",
                          fontWeight: "500",
                          letterSpacing: "1px",
                          fontSize: "14px",
                        }}
                        className="bg_color"
                        onClick={() => {
                          handlePostShow();
                          formik.setFieldValue(
                            "ownerPhone",
                            localStorage.getItem("mobileNo")
                          );
                        }}
                      >
                        Add Job
                      </Button>
                    </Box>
                  </Col>
                </Row>
              </div>
              <div
                style={{
                  boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
                }}
              >
                <Paper style={{ width: "100%", overflow: "hidden" }}>
                  <TableContainer style={{ maxHeight: 680 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow sx={{ textAlignLast: "center" }}>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "50px",
                            }}
                          >
                            <Box className="table_menu">
                              <Checkbox
                                size="md"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    SetIschecked((oldarr) => [
                                      ...oldarr,
                                      ...displayClientJobs?.map((value) => ({
                                        id: value?._id,
                                        email: value?.clientId?.email,
                                      })),
                                    ]);
                                  } else {
                                    SetIschecked([]);
                                  }
                                }}
                              />
                              <Menu>
                                <MenuButton>
                                  <ExpandMoreIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                </MenuButton>
                                <MenuList
                                  style={{ position: "absolute", top: "12px" }}
                                >
                                  <MenuItem
                                    icon={<MailOutlineIcon />}
                                    onClick={() => {
                                      handleSendMailShow();
                                    }}
                                  >
                                    Batch Send Mail
                                  </MenuItem>
                                </MenuList>
                              </Menu>
                            </Box>
                          </TableCell>
                          <TableCell
                      className="tabelheadtitle"
                      style={{
                        color: "#718096",
                        fontWeight: "bold",
                        minWidth: "130px",
                      }}
                    >
                      Job Id
                      <OverlayTrigger
                              {...popOverConfig('job_id')}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchJobId}
                                      fieldName={"job_id"}
                                      setInputState={setSearchJobId}
                                      setActiveField={setActiveField}
                                      activeFieldName={"job_id"}
                                      isFilterActiveState={isFilterActiveJobId}
                                      inputPlaceholder={"Search Job Id"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveJobId ? (
                                <FilterAltIcon
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                    </TableCell>
                          <TableCell
                            className="tabelheadtitle"
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Job Title
                            <OverlayTrigger
                              {...popOverConfig("jobTitle")}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterJobData}
                                      inputState={searchjobtitle}
                                      fieldName={"jobTitle"}
                                      setInputState={setSearchJobtitle}
                                      setActiveField={setActiveField}
                                      activeFieldName={"jobTitle"}
                                      isFilterActiveState={isFilterActiveJobt}
                                      inputPlaceholder={"Search Job Title"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveJobt ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Client
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            City
                            <OverlayTrigger
                              {...popOverConfig("city")}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body>
                                    <Box sx={{ display: "flex", gap: 5 }}>
                                      <CheckPicker
                                        data={defaultCities?.map((city) => ({
                                          label: city?.name?.trim(),
                                          value: city?.name?.trim(),
                                        }))}
                                        style={{ width: 224 }}
                                        menuClassName="reactMultiSelectMenu"
                                        value={searchcity}
                                        renderMenu={(menu) =>
                                          renderMenu(menu, defaultCities)
                                        }
                                        onOpen={handleCities}
                                        onClean={() => {
                                          if (isFilterActiveCity) {
                                            filterJobData("", "city");
                                            setIsFilterActiveCity(false);
                                            setSearchCity([]);
                                            setActiveField({
                                              name: "city",
                                              value: "",
                                            });
                                          }
                                        }}
                                        onChange={(selectedOption) => {
                                          setSearchCity(selectedOption);
                                          setActiveField({
                                            name: "city",
                                            value: selectedOption,
                                          });
                                        }}
                                        placeholder="Select Cities"
                                      />
                                      <IconButton
                                        color={"#fff"}
                                        background={"var(--main-bg-color)"}
                                        aria-label="Search date range"
                                        _hover={{
                                          background: "var(--main-bg-color)",
                                        }}
                                        size={"sm"}
                                        isDisabled={searchcity?.length == 0}
                                        icon={<FiSearch />}
                                        onClick={() => {
                                          filterJobData(searchcity, "city");
                                        }}
                                      />
                                    </Box>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveCity ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "160px",
                            }}
                          >
                            State
                            <OverlayTrigger
                              {...popOverConfig("state")}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body>
                                    <Box sx={{ display: "flex", gap: 5 }}>
                                      <CheckPicker
                                        data={defaultStates?.map((state) => ({
                                          label: state?.name?.trim(),
                                          value: state?.name?.trim(),
                                        }))}
                                        style={{ width: 224 }}
                                        menuClassName="reactMultiSelectMenu"
                                        value={searchstate}
                                        renderMenu={(menu) =>
                                          renderMenu(menu, defaultStates)
                                        }
                                        onOpen={handleStates}
                                        onClean={() => {
                                          if (isFilterActiveState) {
                                            filterJobData("", "state");
                                            setIsFilterActiveState(false);
                                            setSearchState([]);
                                            setActiveField({
                                              name: "state",
                                              value: "",
                                            });
                                          }
                                        }}
                                        onChange={(selectedOption) => {
                                          setSearchState(selectedOption);
                                          setActiveField({
                                            name: "state",
                                            value: selectedOption,
                                          });
                                        }}
                                        placeholder="Select States"
                                      />
                                      <IconButton
                                        color={"#fff"}
                                        background={"var(--main-bg-color)"}
                                        aria-label="Search date range"
                                        _hover={{
                                          background: "var(--main-bg-color)",
                                        }}
                                        isDisabled={searchstate?.length == 0}
                                        size={"sm"}
                                        icon={<FiSearch />}
                                        onClick={() => {
                                          filterJobData(searchstate, "state");
                                        }}
                                      />
                                    </Box>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveState ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Status
                            <OverlayTrigger
                              {...popOverConfig("status")}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      style={{
                                        width: "auto",
                                      }}
                                    >
                                      <select
                                        className="form-control"
                                        id="searchstatus"
                                        value={searchstatus}
                                        onChange={(e) => {
                                          setSearchStatus(e.target.value);
                                          setActiveField({
                                            name: "status",
                                            value: e.target.value,
                                          });
                                          filterJobData(
                                            e.target.value,
                                            "status"
                                          );
                                        }}
                                        style={{
                                          height: "37px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <option disabled={true} value="">
                                          Status
                                        </option>
                                        <option value="Active">Active</option>
                                        <option value="Deactive">
                                          Deactive
                                        </option>
                                        <option value="On Hold">On Hold</option>
                                        <option value="Cancelled">
                                          Cancelled
                                        </option>
                                        <option value="Target Date Expired">
                                          Target Date Expired
                                        </option>
                                      </select>
                                      {isFilterActiveStatus && (
                                        <CloseButton
                                          size="sm"
                                          onClick={() => {
                                            filterJobData("", "status");
                                            setSearchStatus("");
                                            setActiveField({
                                              name: "status",
                                              value: "",
                                            });
                                          }}
                                          _focus={{ boxShadow: "none" }}
                                        />
                                      )}
                                    </Box>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveStatus ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "170px",
                            }}
                          >
                            Contact Person
                            <OverlayTrigger
                              {...popOverConfig("contactperson")}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterJobData}
                                      inputState={searchContactPerson}
                                      fieldName={"contactperson"}
                                      setInputState={setSearchContactPerson}
                                      setActiveField={setActiveField}
                                      activeFieldName={"clientContactName"}
                                      isFilterActiveState={
                                        isFilterActiveContactPerson
                                      }
                                      inputPlaceholder={"Search Contact Person"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveContactPerson ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Owner
                            <OverlayTrigger
                              {...popOverConfig("owner_name")}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body>
                                    <Box sx={{ display: "flex", gap: 5 }}>
                                      <CheckPicker
                                        data={reduxUserNames.map((user) => ({
                                          label: `${user.firstName?.trim()} ${user.lastName?.trim()}`,
                                          value: user?._id,
                                        }))}
                                        style={{ width: 224 }}
                                        menuClassName="reactMultiSelectMenu"
                                        value={searchJobOwner}
                                        onClean={() => {
                                          if (isFilterJobActiveOwner) {
                                            filterJobData("", "owner_name");
                                            setIsFilterJobActiveOwner(false);
                                          }
                                        }}
                                        onChange={(selectedOption) => {
                                          setSearchJobOwner(selectedOption);
                                          setActiveField({
                                            name: "job_ownerName",
                                            value: selectedOption,
                                          });
                                        }}
                                        placeholder="Select Users"
                                      />
                                      <IconButton
                                        color={"#fff"}
                                        background={"var(--main-bg-color)"}
                                        aria-label="Search date range"
                                        _hover={{
                                          background: "var(--main-bg-color)",
                                        }}
                                        size={"sm"}
                                        icon={<FiSearch />}
                                        isDisabled={searchJobOwner?.length == 0}
                                        onClick={() => {
                                          filterJobData(
                                            searchJobOwner,
                                            "owner_name"
                                          );
                                        }}
                                      />
                                    </Box>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterJobActiveOwner ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "150px",
                            }}
                          >
                            Account Manager
                            <OverlayTrigger
                              {...popOverConfig("accountmanager")}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body>
                                    <Box sx={{ display: "flex", gap: 5 }}>
                                      <CheckPicker
                                        data={reduxUserNames.map((user) => ({
                                          label: `${user.firstName?.trim()} ${user.lastName?.trim()}`,
                                          value: user?._id,
                                        }))}
                                        style={{ width: 224 }}
                                        menuClassName="reactMultiSelectMenu"
                                        value={searchaccountmanager}
                                        onClean={() => {
                                          if (isFilterActiveActMang) {
                                            filterJobData("", "accountmanager");
                                            setIsFilterActiveActMang(false);
                                          }
                                        }}
                                        onChange={(selectedOption) => {
                                          setSearchAccountmanager(
                                            selectedOption
                                          );
                                          setActiveField({
                                            name: "accountmanager",
                                            value: selectedOption,
                                          });
                                        }}
                                        placeholder="Select Users"
                                      />
                                      <IconButton
                                        color={"#fff"}
                                        background={"var(--main-bg-color)"}
                                        aria-label="Search date range"
                                        _hover={{
                                          background: "var(--main-bg-color)",
                                        }}
                                        size={"sm"}
                                        icon={<FiSearch />}
                                        isDisabled={
                                          searchaccountmanager?.length == 0
                                        }
                                        onClick={() => {
                                          filterJobData(
                                            searchaccountmanager,
                                            "accountmanager"
                                          );
                                        }}
                                      />
                                    </Box>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveActMang ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Recruiter
                            <OverlayTrigger
                              {...popOverConfig("recruiter")}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body>
                                    <Box sx={{ display: "flex", gap: 5 }}>
                                      <CheckPicker
                                        data={reduxUserNames.map((user) => ({
                                          label: `${user.firstName?.trim()} ${user.lastName?.trim()}`,
                                          value: user?._id,
                                        }))}
                                        style={{ width: 224 }}
                                        menuClassName="reactMultiSelectMenu"
                                        value={searchrecruiter}
                                        onClean={() => {
                                          if (isFilterActiveRecruiter) {
                                            filterJobData("", "recruiter");
                                            setIsFilterActiveRecruiter(false);
                                          }
                                        }}
                                        onChange={(selectedOption) => {
                                          setSearchRecruiter(selectedOption);
                                          setActiveField({
                                            name: "recruiter",
                                            value: selectedOption,
                                          });
                                        }}
                                        placeholder="Select Users"
                                      />
                                      <IconButton
                                        color={"#fff"}
                                        background={"var(--main-bg-color)"}
                                        aria-label="Search date range"
                                        _hover={{
                                          background: "var(--main-bg-color)",
                                        }}
                                        size={"sm"}
                                        icon={<FiSearch />}
                                        isDisabled={
                                          searchrecruiter?.length == 0
                                        }
                                        onClick={() => {
                                          filterJobData(
                                            searchrecruiter,
                                            "recruiter"
                                          );
                                        }}
                                      />
                                    </Box>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveRecruiter ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "160px",
                            }}
                          >
                            Teamleader
                            <OverlayTrigger
                              {...popOverConfig("teamleader")}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body>
                                    <Box sx={{ display: "flex", gap: 5 }}>
                                      <CheckPicker
                                        data={reduxUserNames.map((user) => ({
                                          label: `${user.firstName?.trim()} ${user.lastName?.trim()}`,
                                          value: user?._id,
                                        }))}
                                        style={{ width: 224 }}
                                        menuClassName="reactMultiSelectMenu"
                                        value={searchteamleader}
                                        onClean={() => {
                                          if (isFilterActiveTlead) {
                                            filterJobData("", "teamleader");
                                            setIsFilterActiveTlead(false);
                                          }
                                        }}
                                        onChange={(selectedOption) => {
                                          setSearchTeamleader(selectedOption);
                                          setActiveField({
                                            name: "teamleader",
                                            value: selectedOption,
                                          });
                                        }}
                                        placeholder="Select Users"
                                      />
                                      <IconButton
                                        color={"#fff"}
                                        background={"var(--main-bg-color)"}
                                        aria-label="Search date range"
                                        _hover={{
                                          background: "var(--main-bg-color)",
                                        }}
                                        size={"sm"}
                                        icon={<FiSearch />}
                                        isDisabled={
                                          searchteamleader?.length == 0
                                        }
                                        onClick={() => {
                                          filterJobData(
                                            searchteamleader,
                                            "teamleader"
                                          );
                                        }}
                                      />
                                    </Box>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveTlead ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Skill
                            <OverlayTrigger
                              {...popOverConfig("skill")}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterJobData}
                                      inputState={searchskill}
                                      fieldName={"skill"}
                                      setInputState={setSearchSkill}
                                      setActiveField={setActiveField}
                                      activeFieldName={"skill"}
                                      isFilterActiveState={isFilterActiveSkill}
                                      inputPlaceholder={"Search Skill"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveSkill ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Published Date
                            <OverlayTrigger
                              {...popOverConfig("created_at")}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "460px" }}
                                >
                                  <Popover.Body onClick={(e) => e.stopPropagation()}>
                                    <div
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          gap: 5,
                                          marginBottom: 2,
                                          alignItems: "center",
                                        }}
                                      >
                                        <InputGroup>
                                          <Input
                                            type="date"
                                            id="startDate"
                                            placeholder="From Date..."
                                            value={startDate}
                                            onChange={(e) => {
                                              setStartDate(e.target.value);
                                              const filterDate = {
                                                startDate: e.target.value,
                                                endDate: endDate,
                                              };
                                              setActiveField({
                                                name: "created_at",
                                                value: filterDate,
                                              });
                                            }}
                                            style={{
                                              height: "37px",
                                              color: "#7B9AAA",
                                              fontFamily: "Roboto",
                                              fontWeight: "500",
                                              lineHeight: "19px",
                                              fontSize: "15px",
                                            }}
                                          />
                                        </InputGroup>
                                        <span
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-evenly",
                                          }}
                                        >
                                          {" "}
                                          -{" "}
                                        </span>
                                        <InputGroup>
                                          <Input
                                            type="date"
                                            id="endJoinDate"
                                            placeholder="To Date..."
                                            value={endDate}
                                            min={startDate}
                                            onChange={(e) => {
                                              setEndDate(e.target.value);
                                              const filterDate = {
                                                startDate: startDate,
                                                endDate: e.target.value,
                                              };
                                              setActiveField({
                                                name: "created_at",
                                                value: filterDate,
                                              });
                                            }}
                                            style={{
                                              height: "37px",
                                              color: "#7B9AAA",
                                              fontFamily: "Roboto",
                                              fontWeight: "500",
                                              lineHeight: "19px",
                                              fontSize: "15px",
                                            }}
                                          />
                                        </InputGroup>
                                        {startDate && endDate && (
                                          <IconButton
                                            color={"#fff"}
                                            background={"var(--main-bg-color)"}
                                            aria-label="Search date range"
                                            _hover={{
                                              background:
                                                "var(--main-bg-color)",
                                            }}
                                            size={"sm"}
                                            icon={<FiSearch />}
                                            onClick={() => {
                                              const filterDate = {
                                                startDate: startDate,
                                                endDate: endDate,
                                              };
                                              filterJobData(filterDate, "date");
                                            }}
                                          />
                                        )}
                                        {isFilterActiveJdate && (
                                          <CloseButton
                                            size="sm"
                                            onClick={() => {
                                              setEndDate("");
                                              setStartDate("");
                                              setActiveField({
                                                name: "created_at",
                                                value: {
                                                  startDate: "",
                                                  endDate: "",
                                                },
                                              });
                                              handleFilterAllFieldStep(
                                                {
                                                  startDate: "",
                                                  endDate: "",
                                                },
                                                page,
                                                rowsPerPage,
                                                "created_at"
                                              );
                                              setIsFilterActiveJdate(false);
                                              handlePopOverClose(false);
                                              if (
                                                queryParams.has("sd") ||
                                                queryParams.has("ed")
                                              ) {
                                                queryParams.delete("sd");
                                                queryParams.delete("ed");

                                                // Construct the new URL
                                                const newSearch =
                                                  queryParams.toString();
                                                const newPathname = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;

                                                // Update the URL without these parameters
                                                history.push(newPathname, {
                                                  replace: true,
                                                });
                                              }
                                            }}
                                            _focus={{ boxShadow: "none" }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveJdate ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "180px",
                              width: "fit-content",
                            }}
                          >
                            Industry
                            <OverlayTrigger
                              {...popOverConfig("industry")}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body>
                                    <CheckFilter
                                      filterData={filterData}
                                      setActiveField={setActiveField}
                                      handlePopOverClose={handlePopOverClose}
                                      field={"industry"}
                                      placeholder={"Select Industry"}
                                      value={searchIndustry}
                                      setValue={setSearchIndustry}
                                      data={showAllIndustries}
                                      isFilter={isFilterActiveIndustry}
                                      setIsFilter={setIsFilterActiveIndustry}
                                    />
                                    {/* <Box sx={{ display: "flex", gap: 5 }}>
                                      <CommonCheckPicker
                                        data={showAllIndustries}
                                        value={searchIndustry}
                                        placeholder="Select Industry"
                                        onChange={(selectedOption) => {
                                          setSearchIndustry(selectedOption);
                                          setActiveField({
                                            name: "industry",
                                            value: selectedOption,
                                          });
                                        }}
                                        onClean={() => {
                                          if (isFilterActiveIndustry) {
                                            filterJobData(
                                              selectedOption,
                                              "industry"
                                            );
                                            // setIsFilterActiveIndustry(false);
                                          }
                                        }}
                                        width={224}
                                      />
                                      <IconButton
                                        color={"#fff"}
                                        background={"var(--main-bg-color)"}
                                        aria-label="Search industry"
                                        _hover={{
                                          background: "var(--main-bg-color)",
                                        }}
                                        size={"sm"}
                                        icon={<FiSearch />}
                                        disabled={
                                          searchIndustry?.length !== 0
                                            ? false
                                            : true
                                        }
                                        onClick={() => {
                                          filterJobData(
                                            searchIndustry,
                                            "industry"
                                          );
                                        }}
                                      />
                                    </Box> */}
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveIndustry ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {jobLoading ? (
                          <>
                            <TableRow>
                              <TableCell colSpan={10}>
                                <Loadar />
                              </TableCell>
                            </TableRow>
                          </>
                        ) : (
                          <>
                            {displayClientJobs.length == 0 ? (
                              <>
                                <TableRow>
                                  <TableCell colSpan={10}>
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={NotFound}
                                        alt="NotFound"
                                        style={{ width: "16rem" }}
                                      />
                                      <p>
                                        No Records Found! Please try again....
                                      </p>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </>
                            ) : (
                              <>
                                {displayClientJobs &&
                                  displayClientJobs.map((value, index) => (
                                    <TableRow key={value._id}>
                                      <TableCell className="nowrap_content">
                                        <Box className="table_menu">
                                          <Checkbox
                                            size="md"
                                            value={value?._id}
                                            onChange={(e) => {
                                              if (e.target.checked) {
                                                SetIschecked((oldarr) => [
                                                  ...oldarr,
                                                  {
                                                    id: e.target?.value,
                                                    email:
                                                      value?.clientId?.email,
                                                  },
                                                ]);
                                              } else {
                                                SetIschecked((olarr) => {
                                                  return olarr.filter(
                                                    (value) =>
                                                      e.target?.value !==
                                                      value?.id
                                                  );
                                                });
                                              }
                                            }}
                                            isChecked={checked
                                              ?.map((val) => val?.id)
                                              .includes(value?._id)}
                                          />
                                          <Menu>
                                            <MenuButton>
                                              <MoreVertIcon
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: "20px",
                                                }}
                                              />
                                            </MenuButton>
                                            <MenuList
                                              style={{
                                                position: "absolute",
                                                top: "2px",
                                                zIndex: "100",
                                              }}
                                            >
                                              <MenuItem
                                                icon={
                                                  <ModeEditIcon
                                                    style={{ fontSize: "20px" }}
                                                  />
                                                }
                                                onClick={() => {
                                                  handleEditShow();
                                                  setJobpost(value);
                                                  setJobId(value._id);
                                                  getJobs(value);
                                                }}
                                              >
                                                Edit
                                              </MenuItem>
                                              <MenuItem
                                                icon={
                                                  <DeleteIcon
                                                    style={{ fontSize: "20px" }}
                                                  />
                                                }
                                                onClick={() => {
                                                  handleDeleteJobShow(
                                                    setJobpost(value),
                                                    setJobId(value?._id)
                                                  );
                                                }}
                                              >
                                                Delete
                                              </MenuItem>
                                            </MenuList>
                                          </Menu>
                                          <div style={{ position: "relative" }}>
                                            {value?.noteCount !== 0 && (
                                              <div
                                                style={{
                                                  color: "white",
                                                  height: "auto",
                                                  width: "auto",
                                                  textAlign: "center",
                                                  position: "absolute",
                                                  marginLeft: "11px",
                                                  marginTop: "-10px",
                                                  borderRadius: "50px",
                                                  fontSize: "10px",
                                                  padding: "1px 5px",
                                                }}
                                                className="bg_color"
                                              >
                                                <span>{value?.noteCount}</span>
                                              </div>
                                            )}
                                            <Tooltip
                                              hasArrow
                                              label="Notes"
                                              bg="gray.300"
                                              color="black"
                                            >
                                              <button
                                                className="note_btn"
                                                onClick={() => {
                                                  handleJobNoteShow();
                                                  setNote({
                                                    ...note,
                                                    jobId: value?._id,
                                                  });
                                                  getJobNotes(
                                                    value?._id,
                                                    setGetNotes,
                                                    setNotesLoading
                                                  );
                                                }}
                                              >
                                                N
                                              </button>
                                            </Tooltip>
                                          </div>
                                          <div style={{ position: "relative" }}>
                                            {value?.submissionCount !== 0 && (
                                              <div
                                                style={{
                                                  color: "white",
                                                  height: "auto",
                                                  width: "auto",
                                                  textAlign: "center",
                                                  position: "absolute",
                                                  marginLeft: "11px",
                                                  marginTop: "-10px",
                                                  borderRadius: "50px",
                                                  fontSize: "10px",
                                                  padding: "1px 5px",
                                                }}
                                                className="bg_color"
                                              >
                                                <span>
                                                  {value?.submissionCount}
                                                </span>
                                              </div>
                                            )}
                                            <Tooltip
                                              hasArrow
                                              label="Submission"
                                              bg="gray.300"
                                              color="black"
                                            >
                                              <button
                                                className="note_btn"
                                                style={{ color: "green" }}
                                                onClick={() =>
                                                  window.open(
                                                    `/details/jobpostview/${value._id}?i=2`,
                                                    "_blank"
                                                  )
                                                }
                                              >
                                                S
                                              </button>
                                            </Tooltip>
                                          </div>
                                        </Box>
                                      </TableCell>
                                      <TableCell
                                  style={{
                                    fontWeight: "bold",
                                  }}
                                  className="nowrap_content"
                                >
                                  <Link
                                    to={
                                      `/details/jobpostview/${value._id}`
                                      // moduleData.view == "none" ||
                                      // (moduleData?.view == "ownrecords" &&
                                      //   value?.job_ownerName?._id !==
                                      //     userId)
                                      //   ? "/admin/jobpost"
                                      //   : `/details/jobpostview/${value._id}`
                                    }
                                    target="_blank"
                                  >    
                                   <span>
                                          {!value?.uniqueJobId
                                            ? "N/A"
                                            : `TKJ-${value?.uniqueJobId}`}
                                        </span>
                                  </Link>
                                </TableCell>
                                      <TableCell
                                        style={{
                                          fontWeight: "bold",
                                        }}
                                        className="nowrap_content"
                                      >
                                        <Link
                                          to={`/details/jobpostview/${value._id}`}
                                          target="_blank"
                                        >
                                          <span>
                                            {!value?.jobtitle
                                              ? "N/A"
                                              : value?.jobtitle}
                                          </span>
                                        </Link>
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.clientId?.accountName
                                          ? "N/A"
                                          : value?.clientId?.accountName}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.city ? "N/A" : value?.city}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.state ? "N/A" : value?.state}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.status ? "N/A" : value?.status}{" "}
                                        <Tooltip
                                          hasArrow
                                          label="Change Status"
                                          bg="gray.300"
                                          color="black"
                                        >
                                          <EditIcon
                                            style={{
                                              fontSize: "15px",
                                              cursor: "pointer",
                                            }}
                                            onClick={() => {
                                              handleEditStatusShow(
                                                setJobpost(value),
                                                setJobId(value._id)
                                              );
                                            }}
                                          />
                                        </Tooltip>
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.clientContactName_data
                                          ?.accountOwnerName
                                          ? "N/A"
                                          : value?.clientContactName_data
                                              ?.accountOwnerName}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.job_owener_data
                                          ? "N/A"
                                          : `${value?.job_owener_data?.firstName} ${value?.job_owener_data?.lastName}`}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.account_manager_data?._id
                                          ? "N/A"
                                          : `${value?.account_manager_data?.firstName} ${value?.account_manager_data?.lastName}`}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.recruiter_data?._id
                                          ? "N/A"
                                          : `${value?.recruiter_data?.firstName} ${value?.recruiter_data?.lastName}`}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.teamleader_data?._id
                                          ? "N/A"
                                          : `${value?.teamleader_data?.firstName} ${value?.teamleader_data?.lastName}`}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.skill ? (
                                          "N/A"
                                        ) : (
                                          <span
                                            title={value.skill}
                                            style={{ cursor: "pointer" }}
                                          >
                                            {value.skill.length > 20
                                              ? `${value.skill.slice(0, 20)}...`
                                              : value.skill}
                                          </span>
                                        )}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.created_at_date
                                          ? "N/A"
                                          : new Date(
                                              value?.created_at_date
                                            ).toLocaleDateString()}
                                      </TableCell>
                                      <TableCell className="nowrap_content">
                                        {!value?.industry
                                          ? "N/A"
                                          : value?.industry}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

            <CommonPagination 
              rowsPerPage={tableRowsPerPage.job}
              page={tablePage.job}
              totalPages={Math.ceil(displayClientJobs?.totalJobPosts/tableRowsPerPage.job)||1}
              handleChangePage={(event, page) =>{
                console.log(page,event)
                handleChangePage(event, page, "job")

              }
                }
              handleChangeRowsPerPage={(event) =>
                handleChangeRowsPerPage(event, "job")
              }
              count={displayClientJobs?.totalJobPosts || 0}
            />
                </Paper>
              </div>
              {/* Modal for Add Job post */}
              <Modal
                show={ViewPost}
                onHide={handlePostClose}
                closeOnOverlayClick={false}
                size="xl"
                backdrop="static"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title
                    id="contained-modal-title-vcenter"
                    className="usertitle"
                  >
                    Quick Add - Job
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <form onSubmit={formik.handleSubmit}>
                    <Container>
                      <Row>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Job Title <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Input
                            variant="auth"
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Job Title"
                            size="lg"
                            name="jobtitle"
                            value={formik.values.jobtitle}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{
                              width: "100%",
                              height: "37px",
                              border:
                                formik.touched.jobtitle &&
                                formik.errors.jobtitle
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          />
                          {formik.touched.jobtitle && formik.errors.jobtitle ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.jobtitle}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Job Type <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <select
                            className="form-control"
                            name="jobtype"
                            value={formik.values.jobtype}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{
                              width: "100%",
                              height: "37px",
                              border:
                                formik.touched.jobtype && formik.errors.jobtype
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          >
                            <option disabled={true} value="">
                              Job Type
                            </option>
                            <option value="Full Time">Full Time</option>
                            <option value="Part Time">Part Time</option>
                            <option value="Freelance">Freelance</option>
                          </select>
                          {formik.touched.jobtype && formik.errors.jobtype ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.jobtype}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Client Contact Name{" "}
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          {contacts?.length === 0 ? (
                            <>
                              <Select
                                name="clientContactName"
                                value={jobpost.clientContactName}
                                onChange={handleInputs}
                                options={[]}
                                styles={{
                                  width: "100%",
                                  height: "37px",
                                  border:
                                    formik.touched.clientContactName &&
                                    formik.errors.clientContactName
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <Select
                                name="clientContactName"
                                value={selectedClientContactName}
                                onChange={(selectedOption) => {
                                  setSelectedClientContactName(selectedOption);

                                  formik.setFieldValue(
                                    "clientContactName",
                                    selectedOption?.value
                                  );
                                }}
                                onBlur={formik.handleBlur}
                                options={contacts.map((val) => ({
                                  value: val._id,
                                  label: val.accountOwnerName,
                                }))}
                                styles={{
                                  width: "100%",
                                  height: "37px",
                                  border:
                                    formik.touched.clientContactName &&
                                    formik.errors.clientContactName
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                }}
                              />
                            </>
                          )}
                          {formik?.touched?.clientContactName &&
                          formik?.errors?.clientContactName ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik?.errors?.clientContactName}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Country <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <div style={{ width: "100%", height: "37px" }}>
                            <Select
                              options={Country.getAllCountries()}
                              placeholder="Select Country"
                              getOptionLabel={(options) => {
                                return options["name"];
                              }}
                              getOptionValue={(options) => {
                                return options["name"];
                              }}
                              value={selectedCountry}
                              onChange={(item) => {
                                setSelectedCountry(item);
                                setJobpost({ ...jobpost, country: item.name });
                                formik.setFieldValue("country", item.name);
                              }}
                              classNamePrefix={
                                formik.touched.country &&
                                formik.errors.country &&
                                "democlass"
                              }
                              styles={{
                                border:
                                  formik.touched.country &&
                                  formik.errors.country
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {formik.touched.country && formik.errors.country ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {formik.errors.country}
                              </div>
                            ) : null}
                          </div>
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            State <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Select
                            options={State?.getStatesOfCountry(
                              selectedCountry?.isoCode
                            )}
                            placeholder="Select State"
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["name"];
                            }}
                            value={selectedState}
                            onChange={(item) => {
                              setSelectedState(item);
                              setJobpost({ ...jobpost, state: item.name });
                              formik.setFieldValue("state", item.name);
                            }}
                            classNamePrefix={
                              formik.touched.state &&
                              formik.errors.state &&
                              "democlass"
                            }
                            styles={{
                              border:
                                formik.touched.state && formik.errors.state
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          />
                          {formik.touched.state && formik.errors.state ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.state}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            City <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Select
                            options={City.getCitiesOfState(
                              selectedState?.countryCode,
                              selectedState?.isoCode
                            )}
                            placeholder="Select City"
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["name"];
                            }}
                            value={selectedCity}
                            onChange={(item) => {
                              setSelectedCity(item);
                              setJobpost({ ...jobpost, city: item.name });
                              formik.setFieldValue("city", item.name);
                            }}
                            classNamePrefix={
                              formik.touched.city &&
                              formik.errors.city &&
                              "democlass"
                            }
                            styles={{
                              border:
                                formik.touched.city && formik.errors.city
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          />
                          {formik.touched.city && formik.errors.city ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.city}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Target Date <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Input
                            variant="auth"
                            fontSize="sm"
                            ms="4px"
                            type="date"
                            placeholder="Target Date"
                            size="lg"
                            min={minDate}
                            name="targetdate"
                            value={formik.values.targetdate}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{
                              width: "100%",
                              height: "37px",
                              cursor: "pointer",
                              border:
                                formik.touched.targetdate &&
                                formik.errors.targetdate
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          />
                          {formik.touched.targetdate &&
                          formik.errors.targetdate ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.targetdate}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Status <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <select
                            className="form-control"
                            name="status"
                            value={formik.values.status}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{
                              width: "100%",
                              height: "37px",
                              border:
                                formik.touched.status && formik.errors.status
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          >
                            <option disabled={true} value="">
                              Status
                            </option>
                            <option value="Active">Active</option>
                            <option value="Deactive">Deactive</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Target Date Expired">
                              Target Date Expired
                            </option>
                          </select>
                          {formik.touched.status && formik.errors.status ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.status}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Experience (in years) <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Input
                            className="form-control"
                            name="experience"
                            type="number"
                            placeholder="Experience"
                            value={formik.values.experience}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{
                              width: "100%",
                              height: "37px",
                              border:
                                formik.touched.experience &&
                                formik.errors.experience
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          ></Input>
                          {formik.touched.experience &&
                          formik.errors.experience ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.experience}
                            </div>
                          ) : null}
                        </Col>

                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Recruiters
                          </FormLabel>
                          <Select
                            value={selectedRecruiter}
                            name="recruiter"
                            onChange={(selectedOption) => {
                              setSelectedRecruiter(selectedOption);
                              formik.setFieldValue(
                                "recruiter",
                                `${selectedOption.value}`
                              );
                            }}
                            options={reduxUserNames.map((user) => ({
                              label: `${user.firstName} ${user.lastName}`,
                              value: user?._id,
                            }))}
                            onBlur={formik.handleBlur}
                            styles={{
                              width: "100%",
                              height: "37px",
                              border:
                                formik.touched.recruiter &&
                                formik.errors.recruiter
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          />
                          {formik.touched.recruiter &&
                          formik.errors.recruiter ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.recruiter}
                            </div>
                          ) : null}
                        </Col>

                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Team Leader
                          </FormLabel>
                          <Select
                            name="teamleader"
                            value={selectedTeamLeader}
                            onChange={(selectedOption) => {
                              setSelectedTeamLeader(selectedOption);
                              formik.setFieldValue(
                                "teamleader",
                                selectedOption ? selectedOption.value : ""
                              );
                            }}
                            options={reduxUserNames.map((user) => ({
                              value: user?._id,
                              label: `${user.firstName} ${user.lastName}`,
                            }))}
                            onBlur={formik.handleBlur}
                            styles={{
                              width: "100%",
                              height: "37px",
                              border:
                                formik.touched.teamleader &&
                                formik.errors.teamleader
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          />
                          {formik.touched.teamleader &&
                          formik.errors.teamleader ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.teamleader}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Account Manager
                          </FormLabel>
                          <Select
                            name="accountmanager"
                            value={selectedAccountManager}
                            onChange={(selectedOption) => {
                              setSelectedAccountManager(selectedOption);
                              formik.setFieldValue(
                                "accountmanager",
                                `${selectedOption.value}`
                              );
                            }}
                            options={reduxUserNames.map((user) => ({
                              value: user?._id,
                              label: `${user.firstName} ${user.lastName}`,
                            }))}
                            onBlur={formik.handleBlur}
                            styles={{
                              width: "100%",
                              height: "37px",
                              border:
                                formik.touched.teamleader &&
                                formik.errors.teamleader
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          />
                          {formik.touched.teamleader &&
                          formik.errors.teamleader ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.teamleader}
                            </div>
                          ) : null}
                        </Col>

                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            No. Of Position{" "}
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <Input
                            variant="auth"
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Number of Position"
                            size="lg"
                            name="numberofPosition"
                            value={formik.values.numberofPosition}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            style={{
                              width: "100%",
                              height: "37px",
                              cursor: "pointer",
                              border:
                                formik.touched.numberofPosition &&
                                formik.errors.numberofPosition
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          />
                          {formik.touched.numberofPosition &&
                          formik.errors.numberofPosition ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.numberofPosition}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Remote Status{" "}
                            <span style={{ color: "red" }}>*</span>
                          </FormLabel>
                          <input
                            type="radio"
                            name="remotestatus"
                            style={{ width: "25px" }}
                            value="Onsite"
                            checked={formik.values.remotestatus === "Onsite"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            defaultChecked
                          />{" "}
                          Onsite
                          <input
                            type="radio"
                            name="remotestatus"
                            style={{ width: "25px" }}
                            value="Remote"
                            checked={formik.values.remotestatus === "Remote"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />{" "}
                          Remote
                          <input
                            type="radio"
                            name="remotestatus"
                            style={{ width: "25px" }}
                            value="Hybrid"
                            checked={formik.values.remotestatus === "Hybrid"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />{" "}
                          Hybrid &nbsp;
                          {formik.touched.remotestatus &&
                          formik.errors.remotestatus ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {formik.errors.remotestatus}
                            </div>
                          ) : null}
                        </Col>
                        <Col lg={4} md={6}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Industry
                          </FormLabel>
                          <select
                            className="form-control"
                            name="industry"
                            value={formik.values.industry}
                            onChange={formik.handleChange}
                            style={{
                              width: "100%",
                              height: "37px",
                              border:
                                formik.touched.industry &&
                                formik.errors.industry
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          >
                            <option disabled={true} value="">
                              Select Industry
                            </option>
                            {industries?.map((val) => (
                              <>
                                <option value={val?.industry}>
                                  {val?.industry}
                                </option>
                              </>
                            ))}
                          </select>
                          {formik.touched.industry &&
                            formik.errors.industry && (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {formik.errors.industry}
                              </div>
                            )}
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Job Owner
                          </FormLabel>
                          <input
                            variant="auth"
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Enter Your Skill"
                            mb="24px"
                            size="lg"
                            disabled
                            value={getCurrentLoggedInUserName?.label || "N/A"}
                            style={{
                              width: "100%",
                              height: "37px",
                              padding: "9px",
                            }}
                          />
                        </Col>
                        <Col lg={4} md={6} style={{ marginBottom: "2rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Owner whatsapp number
                          </FormLabel>
                          <div className="candidatephone">
                            <PhoneInput
                              country={"in"}
                              containerStyle={{
                                border:
                                  formik.touched.ownerPhone &&
                                  formik.errors.ownerPhone
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                                borderRadius: "4px",
                              }}
                              value={formik.values.ownerPhone}
                              onChange={(phone) => {
                                const cleanedPhone = phone.replace(/\D/g, "");
                                const formattedPhone = `+${cleanedPhone.slice(
                                  0,
                                  2
                                )} ${cleanedPhone.slice(2)}`;

                                const phoneNumberWithPlus = `+${phone}`;
                                formik.setFieldValue(
                                  "ownerPhone",
                                  phoneNumberWithPlus
                                );
                              }}
                            />
                            {formik.touched.ownerPhone &&
                              formik.errors.ownerPhone && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {formik.errors.ownerPhone}
                                </div>
                              )}
                          </div>
                        </Col>
                      </Row>
                      <Box sx={{ marginBottom: "1rem" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Skill Required <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <input
                          variant="auth"
                          fontSize="sm"
                          type="text"
                          placeholder="Enter Your Skill"
                          size="lg"
                          id="skill"
                          name="skill"
                          value={formik.values.skill}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            width: "100%",
                            height: "37px",
                            padding: "9px",
                            border:
                              formik.touched.skill && formik.errors.skill
                                ? "1px solid red"
                                : "1px solid #ccc",
                          }}
                        />
                        {formik.touched.skill && formik.errors.skill ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {formik.errors.skill}
                          </div>
                        ) : null}
                      </Box>
                      <Box sx={{ marginBottom: "1rem" }}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Questions
                        </FormLabel>
                        <input
                          variant="auth"
                          fontSize="sm"
                          ms="4px"
                          type="text"
                          placeholder="Enter Your Questions"
                          mb="24px"
                          size="lg"
                          id="question"
                          name="question"
                          value={formik.values.question}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          style={{
                            width: "100%",
                            height: "37px",
                          }}
                        />
                      </Box>
                      <Flex justifyItems={"center"}>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Job description{" "}
                          <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Button
                          onClick={(e) => {
                            handleGenerateJD(e, formik, setJdLoading);
                          }}
                          borderRadius={"4px"}
                          isLoading={jdLoading}
                          className="bg_color"
                          size="sm"
                          mb={1}
                          _hover={"none"}
                          color={"#FFF"}
                        >
                          Generate JD
                        </Button>
                      </Flex>
                      <JobDescriptionEditor formik={formik} />
                      {formik.touched.jobdescription &&
                      formik.errors.jobdescription ? (
                        <div style={{ color: "red", fontSize: "14px" }}>
                          {formik.errors.jobdescription}
                        </div>
                      ) : null}
                    </Container>

                    {/* For Salary Details */}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "24px",
                      }}
                    >
                      <h1
                        style={{
                          fontWeight: "bold",
                          fontSize: "1.3rem",
                          padding: "0.3rem",
                          paddingBottom: "15px",
                        }}
                        className="usertitle"
                      >
                        Salary Details
                      </h1>
                    </div>
                    <div
                      style={{
                        border: "1px solid #d3d1d1",
                        width: "100%",
                        height: "auto",
                        borderRadius: "5px",
                        padding: "10px",
                      }}
                    >
                      <Container>
                        <Row>
                          <Col lg={4} md={4} style={{ marginBottom: "1rem" }}>
                            <label
                              style={{
                                fontSize: "13px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                color: "#000000",
                              }}
                            >
                              Placement Type
                            </label>
                            <select
                              className="form-control"
                              name="placementType"
                              value={formik.values.placementType}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              style={{
                                height: "37px",
                                cursor: "pointer",
                                border:
                                  formik.touched.placementType &&
                                  formik.errors.placementType
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            >
                              <option disabled={true} value="">
                                Select
                              </option>
                              <option>One Time Placement</option>
                              <option>Contractual</option>
                              <option>C2H</option>
                              <option>Temporary</option>
                            </select>
                            {formik.touched.placementType &&
                            formik.errors.placementType ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {formik.errors.placementType}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={4} style={{ marginBottom: "1rem" }}>
                            <label
                              style={{
                                fontSize: "13px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                color: "#000000",
                              }}
                            >
                              Client Pay Type
                            </label>
                            <select
                              className="form-control"
                              name="clientpayType"
                              value={formik.values.clientpayType}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              style={{
                                height: "37px",
                                cursor: "pointer",
                                border:
                                  formik.touched.clientpayType &&
                                  formik.errors.clientpayType
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            >
                              <option disabled={true} value="">
                                Select
                              </option>
                              <option>Monthly</option>
                              <option>Annual Salary</option>
                            </select>
                            {formik.touched.clientpayType &&
                            formik.errors.clientpayType ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {formik.errors.clientpayType}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={4}>
                            <label
                              style={{
                                fontSize: "13px",
                                fontWeight: "400",
                                fontFamily: "Roboto",
                                color: "#000000",
                              }}
                            >
                              CTC
                            </label>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              type="text"
                              placeholder="CTC"
                              size="lg"
                              name="ctc"
                              value={formik.values.ctc}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              style={{
                                height: "37px",
                                border:
                                  formik.touched.ctc && formik.errors.ctc
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {formik.touched.ctc && formik.errors.ctc ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {formik.errors.ctc}
                              </div>
                            ) : null}
                          </Col>
                        </Row>

                        <Modal.Footer
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            className="cancel"
                            type="button"
                            onClick={handlePostClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="bg_color"
                            color={"#fff"}
                            type="submit"
                            isLoading={jobLoading}
                          >
                            Save
                          </Button>
                        </Modal.Footer>
                      </Container>
                    </div>
                  </form>
                </Modal.Body>
              </Modal>

              {/* Modal for Edit Job post */}
              <div className="model-box-view">
                <Modal
                  show={ViewEdit}
                  onHide={handleEditClose}
                  size="xl"
                  backdrop="static"
                  closeOnOverlayClick={false}
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="usertitle">Edit Job</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <form onSubmit={editFormik.handleSubmit}>
                      <Container>
                        <Row>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Job Title
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Job Title"
                              size="lg"
                              name="jobtitle"
                              value={editFormik.values.jobtitle}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editFormik.touched.jobtitle &&
                                  editFormik.errors.jobtitle
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editFormik.touched.jobtitle &&
                            editFormik.errors.jobtitle ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.jobtitle}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Job Type
                            </FormLabel>
                            <select
                              className="form-control"
                              name="jobtype"
                              value={editFormik.values.jobtype}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editFormik.touched.jobtype &&
                                  editFormik.errors.jobtype
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            >
                              <option disabled={true} value="">
                                Job Type
                              </option>
                              <option value="Full Time">Full Time</option>
                              <option value="Part Time">Part Time</option>
                              <option value="Freelance">Freelance</option>
                            </select>
                            {editFormik.touched.jobtype &&
                            editFormik.errors.jobtype ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.jobtype}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Client Contact Name{" "}
                              <span style={{ color: "red" }}>*</span>
                            </FormLabel>
                            {contacts?.length === 0 ? (
                              <>
                                <Select
                                  name="clientContactName"
                                  value={jobpost.clientContactName}
                                  onChange={handleInputs}
                                  options={[]}
                                  styles={{
                                    width: "100%",
                                    height: "37px",
                                    border:
                                      editFormik.touched.clientContactName &&
                                      editFormik.errors.clientContactName
                                        ? "1px solid red"
                                        : "1px solid #ccc",
                                  }}
                                />
                              </>
                            ) : (
                              <>
                                <Select
                                  name="clientContactName"
                                  value={selectedClientContactName}
                                  onChange={(selectedOption) => {
                                    setSelectedClientContactName(
                                      selectedOption
                                    );
                                    editFormik.setFieldValue(
                                      "clientContactName",
                                      selectedOption?.value
                                    );
                                  }}
                                  onBlur={editFormik.handleBlur}
                                  options={contacts.map((val) => ({
                                    value: val._id,
                                    label: val.accountOwnerName,
                                  }))}
                                  styles={{
                                    width: "100%",
                                    height: "37px",
                                    border:
                                      editFormik.touched.clientContactName &&
                                      editFormik.errors.clientContactName
                                        ? "1px solid red"
                                        : "1px solid #ccc",
                                  }}
                                />
                              </>
                            )}
                            {editFormik?.touched?.clientContactName &&
                            editFormik?.errors?.clientContactName ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik?.errors?.clientContactName}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Country
                            </FormLabel>
                            <div style={{ width: "100%", height: "37px" }}>
                              <Select
                                options={Country.getAllCountries()}
                                placeholder="Select Country"
                                getOptionLabel={(options) => {
                                  return options["name"];
                                }}
                                getOptionValue={(options) => {
                                  return options["name"];
                                }}
                                value={selectedCountry}
                                onChange={(item) => {
                                  setSelectedCountry(item);
                                  setJobpost({
                                    ...jobpost,
                                    country: item.name,
                                  });
                                  editFormik.setFieldValue(
                                    "country",
                                    item.name
                                  );
                                }}
                                classNamePrefix={
                                  editFormik.touched.country &&
                                  editFormik.errors.country &&
                                  "democlass"
                                }
                                styles={{
                                  border:
                                    editFormik.touched.country &&
                                    editFormik.errors.country
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                }}
                              />
                              {editFormik.touched.country &&
                              editFormik.errors.country ? (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editFormik.errors.country}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              State
                            </FormLabel>
                            <Select
                              options={State?.getStatesOfCountry(
                                selectedCountry?.isoCode
                              )}
                              placeholder="Select State"
                              getOptionLabel={(options) => {
                                return options["name"];
                              }}
                              getOptionValue={(options) => {
                                return options["name"];
                              }}
                              value={selectedState}
                              onChange={(item) => {
                                if (selectedState?.isoCode !== item.isoCode) {
                                  setSelectedState(item);
                                  setSelectedCity(null);
                                  setJobpost({ ...jobpost, state: item.name });
                                  editFormik.setFieldValue("state", item.name);
                                  editFormik.setFieldValue("city", "");
                                }
                              }}
                              classNamePrefix={
                                editFormik.touched.state &&
                                editFormik.errors.state &&
                                "democlass"
                              }
                              styles={{
                                border:
                                  editFormik.touched.state &&
                                  editFormik.errors.state
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editFormik.touched.state &&
                            editFormik.errors.state ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.state}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              City
                            </FormLabel>
                            <Select
                              options={City.getCitiesOfState(
                                selectedState?.countryCode,
                                selectedState?.isoCode
                              )}
                              placeholder="Select City"
                              getOptionLabel={(options) => {
                                return options["name"];
                              }}
                              getOptionValue={(options) => {
                                return options["name"];
                              }}
                              value={selectedCity}
                              onChange={(item) => {
                                setSelectedCity(item);
                                setJobpost({ ...jobpost, city: item.name });
                                editFormik.setFieldValue("city", item.name);
                              }}
                              classNamePrefix={
                                editFormik.touched.city &&
                                editFormik.errors.city &&
                                "democlass"
                              }
                              styles={{
                                border:
                                  editFormik.touched.city &&
                                  editFormik.errors.city
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editFormik.touched.city &&
                            editFormik.errors.city ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.city}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Target Date
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="date"
                              placeholder="Target Date"
                              size="lg"
                              name="targetdate"
                              value={editFormik.values.targetdate}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                cursor: "pointer",
                                border:
                                  editFormik.touched.targetdate &&
                                  editFormik.errors.targetdate
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editFormik.touched.targetdate &&
                            editFormik.errors.targetdate ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.targetdate}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Status
                            </FormLabel>
                            <select
                              className="form-control"
                              name="status"
                              value={editFormik.values.status}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editFormik.touched.status &&
                                  editFormik.errors.status
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            >
                              <option disabled={true} value="">
                                Status
                              </option>
                              <option value="Active">Active</option>
                              <option value="Deactive">Deactive</option>
                              <option value="On Hold">On Hold</option>
                              <option value="Cancelled">Cancelled</option>
                              <option value="Target Date Expired">
                                Target Date Expired
                              </option>
                            </select>
                            {editFormik.touched.status &&
                            editFormik.errors.status ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.status}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                            Experience (in years)
                            </FormLabel>
                            <Input
                              className="form-control"
                              name="experience"
                              type="number"
                              placeholder="Experience"
                              value={editFormik.values.experience}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editFormik.touched.experience &&
                                  editFormik.errors.experience
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            ></Input>
                            {editFormik.touched.experience &&
                            editFormik.errors.experience ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.experience}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Recruiters
                            </FormLabel>
                            <Select
                              value={selectedRecruiter}
                              name="recruiter"
                              onChange={(selectedOption) => {
                                setSelectedRecruiter(selectedOption);
                                editFormik.setFieldValue(
                                  "recruiter",
                                  `${selectedOption.value}`
                                );
                              }}
                              options={reduxUserNames.map((user) => ({
                                value: user?._id,
                                label: `${user.firstName} ${user.lastName}`,
                              }))}
                              onBlur={editFormik.handleBlur}
                              styles={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editFormik.touched.recruiter &&
                                  editFormik.errors.recruiter
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editFormik.touched.recruiter &&
                            editFormik.errors.recruiter ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.recruiter}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Team Leader
                            </FormLabel>
                            <Select
                              name="teamleader"
                              value={selectedTeamLeader}
                              onChange={(selectedOption) => {
                                setSelectedTeamLeader(selectedOption);
                                editFormik.setFieldValue(
                                  "teamleader",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }}
                              options={reduxUserNames.map((user) => ({
                                value: user?._id,
                                label: `${user.firstName} ${user.lastName}`,
                              }))}
                              onBlur={editFormik.handleBlur}
                              styles={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editFormik.touched.teamleader &&
                                  editFormik.errors.teamleader
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editFormik.touched.teamleader &&
                            editFormik.errors.teamleader ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.teamleader}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Account Manager
                            </FormLabel>
                            <Select
                              name="accountmanager"
                              value={selectedAccountManager}
                              onChange={(selectedOption) => {
                                setSelectedAccountManager(selectedOption);
                                editFormik.setFieldValue(
                                  "accountmanager",
                                  `${selectedOption.value}`
                                );
                              }}
                              options={reduxUserNames.map((user) => ({
                                value: user?._id,
                                label: `${user.firstName} ${user.lastName}`,
                              }))}
                              onBlur={editFormik.handleBlur}
                              styles={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editFormik.touched.teamleader &&
                                  editFormik.errors.teamleader
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editFormik.touched.teamleader &&
                            editFormik.errors.teamleader ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.teamleader}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              No. Of Position
                            </FormLabel>
                            <Input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Number of Position"
                              size="lg"
                              name="numberofPosition"
                              value={editFormik.values.numberofPosition}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              style={{
                                width: "100%",
                                height: "37px",
                                cursor: "pointer",
                                border:
                                  editFormik.touched.numberofPosition &&
                                  editFormik.errors.numberofPosition
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            />
                            {editFormik.touched.numberofPosition &&
                            editFormik.errors.numberofPosition ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.numberofPosition}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Remote Status
                            </FormLabel>
                            <input
                              type="radio"
                              name="remotestatus"
                              style={{ width: "25px" }}
                              value="Onsite"
                              checked={
                                editFormik.values.remotestatus === "Onsite"
                              }
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              defaultChecked
                            />{" "}
                            Onsite
                            <input
                              type="radio"
                              name="remotestatus"
                              style={{ width: "25px" }}
                              value="Remote"
                              checked={
                                editFormik.values.remotestatus === "Remote"
                              }
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                            />{" "}
                            Remote
                            <input
                              type="radio"
                              name="remotestatus"
                              style={{ width: "25px" }}
                              value="Hybrid"
                              checked={
                                editFormik.values.remotestatus === "Hybrid"
                              }
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                            />{" "}
                            Hybrid &nbsp;
                            {editFormik.touched.remotestatus &&
                            editFormik.errors.remotestatus ? (
                              <div style={{ color: "red", fontSize: "14px" }}>
                                {editFormik.errors.remotestatus}
                              </div>
                            ) : null}
                          </Col>
                          <Col lg={4} md={6}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Industry
                            </FormLabel>
                            <select
                              className="form-control"
                              name="industry"
                              value={editFormik.values.industry}
                              onChange={editFormik.handleChange}
                              style={{
                                width: "100%",
                                height: "37px",
                                border:
                                  editFormik.touched.industry &&
                                  editFormik.errors.industry
                                    ? "1px solid red"
                                    : "1px solid #ccc",
                              }}
                            >
                              <option disabled={true} value="">
                                Select Industry
                              </option>
                              {industries?.map((val) => (
                                <>
                                  <option value={val?.industry}>
                                    {val?.industry}
                                  </option>
                                </>
                              ))}
                            </select>
                            {editFormik.touched.industry &&
                              editFormik.errors.industry && (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editFormik.errors.industry}
                                </div>
                              )}
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "1rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Job Owner
                            </FormLabel>
                            <input
                              variant="auth"
                              fontSize="sm"
                              ms="4px"
                              type="text"
                              placeholder="Enter Your Skill"
                              mb="24px"
                              size="lg"
                              disabled
                              value={updateOwnerName ? updateOwnerName : "N/A"}
                              style={{
                                width: "100%",
                                height: "37px",
                                padding: "9px",
                              }}
                            />
                          </Col>
                          <Col lg={4} md={6} style={{ marginBottom: "2rem" }}>
                            <FormLabel
                              ms="4px"
                              fontSize="sm"
                              fontWeight="400"
                              fontFamily="Roboto"
                              color="#000000"
                            >
                              Owner whatsapp number
                            </FormLabel>
                            <div className="candidatephone">
                              <PhoneInput
                                country={"in"}
                                containerStyle={{
                                  border:
                                    editFormik.touched.ownerPhone &&
                                    editFormik.errors.ownerPhone
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                  borderRadius: "4px",
                                }}
                                value={editFormik.values.ownerPhone}
                                onChange={(phone) => {
                                  const cleanedPhone = phone.replace(/\D/g, "");
                                  const formattedPhone = `+${cleanedPhone.slice(
                                    0,
                                    2
                                  )} ${cleanedPhone.slice(2)}`;

                                  const phoneNumberWithPlus = `+${phone}`;
                                  editFormik.setFieldValue(
                                    "ownerPhone",
                                    phoneNumberWithPlus
                                  );
                                }}
                              />
                              {editFormik.touched.ownerPhone &&
                                editFormik.errors.ownerPhone && (
                                  <div
                                    style={{ color: "red", fontSize: "14px" }}
                                  >
                                    {editFormik.errors.ownerPhone}
                                  </div>
                                )}
                            </div>
                          </Col>
                        </Row>
                        <Box sx={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Skill Required
                          </FormLabel>
                          <input
                            variant="auth"
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Enter Your Skill"
                            mb="24px"
                            size="lg"
                            name="skill"
                            id="skill"
                            value={editFormik.values.skill}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            style={{
                              width: "100%",
                              height: "37px",
                              padding: "9px",
                              border:
                                editFormik.touched.skill &&
                                editFormik.errors.skill
                                  ? "1px solid red"
                                  : "1px solid #ccc",
                            }}
                          />
                          {editFormik.touched.skill &&
                          editFormik.errors.skill ? (
                            <div style={{ color: "red", fontSize: "14px" }}>
                              {editFormik.errors.skill}
                            </div>
                          ) : null}
                        </Box>
                        <Box sx={{ marginBottom: "1rem" }}>
                          <FormLabel
                            ms="4px"
                            fontSize="sm"
                            fontWeight="400"
                            fontFamily="Roboto"
                            color="#000000"
                          >
                            Questions
                          </FormLabel>
                          <input
                            variant="auth"
                            fontSize="sm"
                            ms="4px"
                            type="text"
                            placeholder="Enter Your Questions"
                            mb="24px"
                            size="lg"
                            id="question"
                            name="question"
                            value={editFormik.values.question}
                            onChange={editFormik.handleChange}
                            onBlur={editFormik.handleBlur}
                            style={{
                              width: "100%",
                              height: "37px",
                            }}
                          />
                        </Box>

                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="400"
                          fontFamily="Roboto"
                          color="#000000"
                        >
                          Job description
                        </FormLabel>
                        <JobDescriptionEditor formik={editFormik} />
                        {editFormik.touched.jobdescription &&
                        editFormik.errors.jobdescription ? (
                          <div style={{ color: "red", fontSize: "14px" }}>
                            {editFormik.errors.jobdescription}
                          </div>
                        ) : null}
                      </Container>

                      {/* For Salary Details */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          marginTop: "24px",
                        }}
                      >
                        <h1
                          style={{
                            fontWeight: "bold",
                            fontSize: "1.3rem",
                            padding: "0.3rem",
                            paddingBottom: "15px",
                          }}
                          className="usertitle"
                        >
                          Salary Details
                        </h1>
                      </div>
                      <div
                        style={{
                          border: "1px solid #d3d1d1",
                          width: "100%",
                          height: "auto",
                          borderRadius: "5px",
                          padding: "10px",
                        }}
                      >
                        <Container>
                          <Row>
                            <Col lg={4} md={4} style={{ marginBottom: "1rem" }}>
                              <label
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                  color: "#000000",
                                }}
                              >
                                Placement Type
                              </label>
                              <select
                                className="form-control"
                                name="placementType"
                                value={editFormik.values.placementType}
                                onChange={editFormik.handleChange}
                                onBlur={editFormik.handleBlur}
                                style={{
                                  height: "37px",
                                  cursor: "pointer",
                                  border:
                                    editFormik.touched.placementType &&
                                    editFormik.errors.placementType
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                }}
                              >
                                <option disabled={true} value="">
                                  Select
                                </option>
                                <option>One Time Placement</option>
                                <option>Contractual</option>
                                <option>C2H</option>
                                <option>Temporary</option>
                              </select>
                              {editFormik.touched.placementType &&
                              editFormik.errors.placementType ? (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editFormik.errors.placementType}
                                </div>
                              ) : null}
                            </Col>
                            <Col lg={4} md={4} style={{ marginBottom: "1rem" }}>
                              <label
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                  color: "#000000",
                                }}
                              >
                                Client Pay Type
                              </label>
                              <select
                                className="form-control"
                                name="clientpayType"
                                value={editFormik.values.clientpayType}
                                onChange={editFormik.handleChange}
                                onBlur={editFormik.handleBlur}
                                style={{
                                  height: "37px",
                                  cursor: "pointer",
                                  border:
                                    editFormik.touched.clientpayType &&
                                    editFormik.errors.clientpayType
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                }}
                              >
                                <option disabled={true} value="">
                                  Select
                                </option>
                                <option>Monthly</option>
                                <option>Annual Salary</option>
                              </select>
                              {editFormik.touched.clientpayType &&
                              editFormik.errors.clientpayType ? (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editFormik.errors.clientpayType}
                                </div>
                              ) : null}
                            </Col>
                            <Col lg={4} md={4}>
                              <label
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "400",
                                  fontFamily: "Roboto",
                                  color: "#000000",
                                }}
                              >
                                CTC
                              </label>
                              <Input
                                variant="auth"
                                fontSize="sm"
                                type="text"
                                placeholder="CTC"
                                size="lg"
                                name="ctc"
                                value={editFormik.values.ctc}
                                onChange={editFormik.handleChange}
                                onBlur={editFormik.handleBlur}
                                style={{
                                  height: "37px",
                                  border:
                                    editFormik.touched.ctc &&
                                    editFormik.errors.ctc
                                      ? "1px solid red"
                                      : "1px solid #ccc",
                                }}
                              />
                              {editFormik.touched.ctc &&
                              editFormik.errors.ctc ? (
                                <div style={{ color: "red", fontSize: "14px" }}>
                                  {editFormik.errors.ctc}
                                </div>
                              ) : null}
                            </Col>
                          </Row>

                          <Modal.Footer
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              className="cancel"
                              type="button"
                              onClick={handleEditClose}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="bg_color"
                              color={"#fff"}
                              type="submit"
                              isLoading={jobLoading}
                            >
                              Update
                            </Button>
                          </Modal.Footer>
                        </Container>
                      </div>
                    </form>
                  </Modal.Body>
                </Modal>
              </div>
              {/* Modal for Delete Job */}
              <div className="model-box-view" id="deleteaccount">
                <Modal
                  show={deleteJob}
                  onHide={handleDeleteJobClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="usertitle">Delete Job</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Are you sure to delete?</p>
                  </Modal.Body>
                  <Modal.Footer
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button className="cancel" onClick={handleDeleteJobClose}>
                      Cancel
                    </Button>
                    <Button
                      className="btn_bg_color"
                      isLoading={deleteJobApiResult.isLoading}
                      onClick={handleDelete}
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              {/* Modal for Edit Job Status*/}
              <div className="model-box-view">
                <Modal
                  show={ViewEditStatus}
                  onHide={handleEditStatusClose}
                  backdrop="static"
                  closeOnOverlayClick={false}
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="usertitle">
                      Edit Job Status
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <label>Select Status</label>
                    <select
                      className="form-control"
                      name="status"
                      defaultValue={jobpost.status}
                      onChange={handleInputs}
                    >
                      <option disabled={true} value="">
                        Status
                      </option>
                      <option>Active</option>
                      <option>Deactive</option>
                      <option>On Hold</option>
                      <option>Cancelled</option>
                      <option>Target Date Expired</option>
                    </select>
                  </Modal.Body>
                  <Modal.Footer
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button className="cancel" onClick={handleEditStatusClose}>
                      Cancel
                    </Button>
                    <Button className="btn_bg_color" onClick={handleEditStatus}>
                      Update
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </TabPanel>
            {/* Submission */}
            <TabPanel>
              <CommonSubmissionFile
                isFilterActiveSubmissionId={isFilterActiveSubmissionId}
                searchSubmissionId={searchSubmissionId}
                setSearchSubmissionId={setSearchSubmissionId}
                setIsFilterActiveState={setIsFilterActiveState}
                setIsFilterActiveCity={setIsFilterActiveCity}
                setIsFilterActiveNoticePeriod={setIsFilterActiveNoticePeriod}
                setIsFilterActiveClient={setIsFilterActiveClient}
                setIsFilterActiveOwner={setIsFilterActiveOwner}
                setIsFilterActiveStatus={setIsFilterActiveStatus}
                setIsFilterActiveUpdatedBy={setIsFilterActiveUpdatedBy}
                setIsFilterActiveSourceSearch={setIsFilterActiveSourceSearch}
                setIsFilterActiveIndustry={setIsFilterActiveIndustry}
                setIsFilterActiveExperience={setIsFilterActiveExperience}
                isFilterActiveSourceSearch={isFilterActiveSourceSearch}
                displaysubmissions={displayAppliedJob}
                Searchicon={Searchicon}
                searchAllfields={textSearchSubmission}
                setSearchAllfields={setTextSearchSubmission}
                moduleData={submissionModuleData}
                handleCreateSubmission={handleCreateSubmission}
                setId={setId}
                handleSubmHeaderOrdersshow={handleSubmHeaderOrdersshow}
                SetIschecked={SetIschecked}
                handleSendMailShow={handleSendMailShow}
                handleOpenModal={handleOpenModal}
                checked={checked}
                handleEditShow={handleEditSubmissionShow}
                setSubmission={setSubmission}
                handleDeleteSubmissionShow={handleDeleteSubmissionShow}
                handleCandidateNoteShow={handleCandidateNoteShow}
                getCandidatNotes={getCandidatNotes}
                SubmissionPreview={SubmissionPreview}
                handleSubmissionPreviewShow={handleSubmissionPreviewShow}
                handleEditSubmissionStatusShow={handleEditSubmissionStatusShow}
                // setChangeStatus={setChangeStatus}
                setOldJobId={setOldJobId}
                page={clientSubmissionPage}
                rowsPerPage={clientSubmissionPageRowsPerPage}
                applicationStatus={applicationStatus}
                loading={loading}
                setLoading={setLoading}
                // changeStatus={changeStatus}
                setNote={setNote}
                note={note}
                submheaderOrders={submheaderOrders}
                submissions={appliedJob}
                setPage={setClientSubmissionPage}
                setRowsPerPage={setClientSubmissionPageRowsPerPage}
                componentName={"all"}
                setSubmissionId={setSubmissionId}
                clientId={account?.accountName}
                isClientView={true}
                Setcandidatename={Setcandidatename}
                teamData={teamData}
                setActiveField={setActiveField}
                activeField={activeField}
                clearFilter={clearFilter}
                searchfname={searchfname}
                setSearchFname={setSearchFname}
                searchlname={searchlname}
                setSearchLname={setSearchLname}
                searchjobt={searchjobt}
                setSearchJobt={setSearchJobt}
                searchstatus={searchstatusSubmission}
                setSearchStatus={setSearchStatusSubmission}
                searchcustomer={searchcustomer}
                setSearchCustomer={setSearchCustomer}
                searchOwnerName={searchOwnerName}
                setSearchOwnerName={setSearchOwnerName}
                searchcandidatectctype={searchcandidatectctype}
                setSearchCandidatectctype={setSearchCandidatectctype}
                searchcandidatectc={searchcandidatectc}
                setSearchCandidatectc={setSearchCandidatectc}
                searchcity={searchcitySubmission}
                setSearchCity={setSearchCitySubmission}
                searchstate={searchState}
                setSearchstate={setSearchstate}
                searchSubmissionDate={searchSubmissionDate}
                setSearchSubmissionDate={setSearchSubmissionDate}
                searchSubmissionUpdateDate={searchSubmissionUpdateDate}
                setSearchSubmissionUpdateDate={setSearchSubmissionUpdateDate}
                searchExpectedCandidateCTC={searchExpectedCandidateCTC}
                setSearchExpectedCandidateCTC={setSearchExpectedCandidateCTC}
                searchExpectedCandidateCTCType={searchExpectedCandidateCTCType}
                setSearchExpectedCandidateCTCType={
                  setSearchExpectedCandidateCTCType
                }
                searchNoticePeriod={searchNoticePeriod}
                setSearchNoticePeriod={setSearchNoticePeriod}
                searchPhone={searchPhone}
                setSearchPhone={setSearchphone}
                searchAddress={searchAddress}
                setSearchAddress={setSearchAddress}
                searchDOB={searchDOB}
                setSearchDOB={setSearchDOB}
                searchAadharCardNumber={searchAadharCardNumber}
                setSearchAadharCardNumber={setSearchAadharCardNumber}
                searchPanCardNumber={searchPanCardNumber}
                setSearchPanCardNumber={setSearchPanCardNumber}
                searchDesignation={searchDesignation}
                setSearchDesignation={setSearchdesignation}
                searchWorkExperience={searchWorkExperience}
                setSearchWorkExperience={setSearchWorkExperience}
                searchEducationDetails={searchEducationDetails}
                setSearchEducationDetails={setSearchEducationDetails}
                searchExperience={searchExperience}
                setSearchExperience={setSearchExperience}
                searchGender={searchGender}
                setSearchGender={setSearchGender}
                searchInterviewScheduleDate={searchInterviewScheduleDate}
                setSearchInterviewScheduleDate={setSearchInterviewScheduleDate}
                searchInterviewScheduleTime={searchInterviewScheduleTime}
                setSearchInterviewScheduleTime={setSearchInterviewScheduleTime}
                searchInterviewScheduleLocation={
                  searchInterviewScheduleLocation
                }
                setSearchInterviewScheduleLocation={
                  setSearchInterviewScheduleLocation
                }
                searchInterviewScheduleLink={searchInterviewScheduleLink}
                setSearchInterviewScheduleLink={setSearchInterviewScheduleLink}
                searchJoinDate={searchJoinDate}
                setSearchJoinDate={setSearchJoinDate}
                searchSubmissionBy={searchSubmissionBy}
                setSearchSubmissionBy={setSearchSubmissionBy}
                searchEmail={searchEmail}
                setSearchEmail={setSearchemail}
                searchContactName={searchContactName}
                setSearchContactName={setSearchContactName}
                searchActualCTC={searchActualCTC}
                setSearchActualCTC={setSearchActualCTC}
                searchOfferCTC={searchOfferCTC}
                setSearchOfferCTC={setSearchOfferCTC}
                searchExpectedJoinDate={searchExpectedJoinDate}
                setSearchExpectedJoinDate={setSearchExpectedJoinDate}
                searchUpdatedBy={searchUpdatedBy}
                setSearchUpdatedBy={setSearchUpdatedBy}
                searchFullName={searchFullName}
                setSearchFullName={setSearchFullName}
                isFilterActiveFname={isFilterActiveFname}
                isFilterActiveFullName={isFilterActiveFullName}
                isFilterActiveUpdatedBy={isFilterActiveUpdatedBy}
                isFilterActiveExpectedJoinDate={isFilterActiveExpectedJoinDate}
                isFilterActiveUpdatedDate={isFilterActiveUpdatedDate}
                isFilterActiveOfferCTC={isFilterActiveOfferCTC}
                isFilterActiveActualCTC={isFilterActiveActualCTC}
                isFilterActiveEmail={isFilterActiveEmail}
                isFilterActiveSubmissionBy={isFilterActiveSubmissionBy}
                isFilterActiveJoinDate={isFilterActiveJoinDate}
                isFilterActiveInterviewScheduleLink={
                  isFilterActiveInterviewScheduleLink
                }
                isFilterActiveInterviewScheduleLocation={
                  isFilterActiveInterviewScheduleLocation
                }
                isFilterActiveInterviewScheduleTime={
                  isFilterActiveInterviewScheduleTime
                }
                isFilterActiveInterviewScheduleDate={
                  isFilterActiveInterviewScheduleDate
                }
                isFilterActiveGender={isFilterActiveGender}
                isFilterActiveExperience={isFilterActiveExperience}
                isFilterActiveEducationDetails={isFilterActiveEducationDetails}
                isFilterActiveWorkExperience={isFilterActiveWorkExperience}
                isFilterActiveDesignation={isFilterActiveDesignation}
                isFilterActivePanCardNumber={isFilterActivePanCardNumber}
                isFilterActiveAadharCardNumber={isFilterActiveAadharCardNumber}
                isFilterActiveDOB={isFilterActiveDOB}
                isFilterActiveAddress={isFilterActiveAddress}
                isFilterActivePhone={isFilterActivePhone}
                isFilterActiveNoticePeriod={isFilterActiveNoticePeriod}
                isFilterActivEexpectedCtctype={isFilterActivEexpectedCtctype}
                isFilterActiveExpectedCtc={isFilterActiveExpectedCtc}
                isFilterActiveLname={isFilterActiveLname}
                isFilterActiveJobt={isFilterActiveJobt}
                isFilterActiveStatus={isFilterActiveStatusSubmission}
                isFilterActiveClient={isFilterActiveClient}
                isFilterActiveOwner={isFilterActiveOwner}
                isFilterActiveCtctype={isFilterActiveCtctype}
                isFilterActiveCtc={isFilterActiveCtc}
                isFilterActiveCity={isFilterActiveCitySubmission}
                isFilterActiveState={isFilterActiveStateSubmission}
                isFilterActiveDate={isFilterActiveDate}
                isFilterActiveContactName={isFilterActiveContactName}
                setIsFilterActiveSubmissionBy={setIsFilterActiveSubmissionBy}
                filterData={filterData}
                setMySubmissionValue={setMySubmissionValue}
                mySubmissionValue={mySubmissionValue}
                handleFilterAllFieldStep={handleFilterAllFieldSteps}
                popoverVisible={popoverVisibleSubmission}
                setPopoverVisible={setPopoverVisibleSubmission}
                handlePopOverClose={handlePopOverCloseSubmission}
                industries={industries}
                setSearchIndustry={setSearchIndustry}
                searchIndustry={searchIndustry}
                isFilterActiveIndustry={isFilterActiveIndustry}
                handleActivitySubmit={handleCandidateActivitySubmit}
                searchInterviewRescheduleDate={searchInterviewRescheduleDate}
                setSearchInterviewRescheduleDate={
                  setSearchInterviewRescheduleDate
                }
                isFilterActiveInterviewRescheduleDate={
                  isFilterActiveInterviewRescheduleDate
                }
                searchTestRescheduleDate={searchTestRescheduleDate}
                setSearchTestRescheduleDate={setSearchTestRescheduleDate}
                isFilterActiveTestRescheduleDate={
                  isFilterActiveTestRescheduleDate
                }
                searchBackoutDate={searchBackoutDate}
                setSearchBackoutDate={setSearchBackoutDate}
                isFilterActiveBackoutDate={isFilterActiveBackoutDate}
                setSearchSourceData={setSearchSourceData}
                searchSourceData={searchSourceData}
              />

              <CustomizeColumns
                ViewSubmheaderOrders={ViewSubmheaderOrders}
                handleSubmHeaderOrdersClose={handleSubmHeaderOrdersClose}
                onDragEnd={onDragEnd}
                displaySubmheaderOrders={displaySubmheaderOrders}
                defaultDisplaySubmHeaderOrders={defaultDisplaySubmHeaderOrders}
                handleCheckboxChange={handleCheckboxChange}
                handleApplyClick={handleApplyClick}
              />

              {/* Modal for Candidate Note creation */}
              <NoteSubmissionModel
                candidateNote={candidateNote}
                handleCandidateNoteClose={handleCandidateNoteClose}
                searchNote={searchNote}
                getnotes={getnotes}
                setSearchNote={setSearchNote}
                setNote={setNote}
                note={note}
                setGetNotes={setGetNotes}
                // getJobpostdata={getAppliedJobdata}
                getJobpostdata={handleFilterAllFieldStep}
                type={id}
                page={page}
                rowsPerPage={rowsPerPage}
                setNotesLoading={setNotesLoading}
                notesLoading={notesLoading}
              />

              <CustomizeColumns
                ViewSubmheaderOrders={isOpen}
                handleSubmHeaderOrdersClose={handleCloseModal}
                onDragEnd={onDragEnd}
                displaySubmheaderOrders={displaySubmheaderOrders}
                defaultDisplaySubmHeaderOrders={defaultDisplaySubmHeaderOrders}
                handleCheckboxChange={handleCheckboxChange}
                handleApplyClick={exportSubmissions}
                candidates={displayAppliedJob}
                filename={filename}
                Changeoption={handleApplyClick}
              />

              {/* Modal for Search Job */}

              <SearchJobModel
               searchJobModel={searchJob}
               searchJobId={searchJobId}
               setSearchJobId={setSearchJobId}
                
                handleCreateSubmissionClose={handleCreateSubmissionClose}
                searchjob={searchAllfields}
                setSearchJob={setSearchAllfields}
                filterJobpostData={handleFilterAllFieldStep}
                // loading={searchJobLoading}
                loading={jobLoading}
                jobposts={displayClientJobs}
                setSubmission={setSubmission}
                setJobScreeningId={setJobScreeningId}
                Setjobtitle={Setjobtitle}
                handleSubmitSubmissionShow={handleSearchCandidateShow}
                rowsPerSubmissionPage={rowsPerSubmissionPage}
                submissionpage={submissionpage}
                handleChangeSubmissionPage={handleChangeSubmissionPage}
                handleChangeRowsPerSubmissionPage={
                  handleChangeRowsPerSubmissionPage
                }
                submission={submission}
                jobtitle={jobtitle}
                clearFilter={clearJobFilter}
                jobSearchActiveField={jobSearchActiveField}
                setJobSearchActiveField={setJobSearchActiveField}
                searchJobTitle={searchjobtitle}
                setSearchJobTitle={setSearchJobtitle}
                searchJobContactName={searchContactPerson}
                setSearchJobContactName={setSearchContactPerson}
                searchJobCity={searchcity}
                setSearchJobCity={setSearchCity}
                setSearchJobOwner={setSearchJobOwner}
                searchJobOwner={searchJobOwner}
              />

              {/* Modal for Search Candidate */}
              <SearchCandidateModel
              serachCandidateId={uniqueCandidateId}
              setSerachCandidateId={setUniqueCandidateId}
                searchCandidateModelShow={searchCandidateModelShow}
                handleSearchCandidateClose={handleSearchCandidateClose}
                searchcandidate={searchcandidate}
                setSearchcandidate={setSearchcandidate}
                filterCandidateData={filterCandidateData}
                loading={searchCandidateLoading}
                candidates={candidates}
                setSubmission={setSubmission}
                submission={submission}
                Setcandidatename={Setcandidatename}
                candidatename={candidatename}
                handleSubmitSubmissionShow={handleSubmitSubmissionShow}
                rowsPerCandidateSubmissionPage={rowsPerCandidateSubmissionPage}
                candidatesubmissionpage={candidatesubmissionpage}
                handleChangeCandidateSubmissionPage={
                  handleChangeCandidateSubmissionPage
                }
                handleChangeRowsPerCandidateSubmissionPage={
                  handleChangeRowsPerCandidateSubmissionPage
                }
                setSearchCandidateName={setSearchCandidateName}
                searchCandidateName={searchCandidateName}
                setSearchCandidateDesignation={setSearchCandidateDesignation}
                searchCandidateDesignation={searchCandidateDesignation}
                setSearchCandidateCity={setSearchCandidateCity}
                searchCandidateCity={searchCandidateCity}
                setCandidateSearchActiveField={setCandidateSearchActiveField}
                candidateSearchActiveField={candidateSearchActiveField}
                clearCandidateSearchFilter={clearCandidateSearchFilter}
                setSearchCandidateOwnerName={setSearchCandidateOwnerName}
                searchCandidateOwnerName={searchCandidateOwnerName}
              />

              {/* Modal for Submit Submission */}

              {/* Modal for Edit Submission */}

              <Submissionform
               handleInputs={handleInputs}  
                show={true}
                applicationStatus={applicationStatus||''}
                candidateStatus={submission?.status}
                saveFile={saveSubmissionFile}
                handleSubmitSubmissionClose={handleSubmitSubmissionClose}
                submitSubmission={submitSubmission}
                submission={submission}
                handleActivitySubmit={handleActivitySubmit}
                setSubmission={setSubmission}
                handleCreateSubmissionClose={handleCreateSubmissionClose}
                handleSearchCandidateClose={handleSearchCandidateClose}
                candidatename={candidatename}
                jobtitle={jobtitle}
                id={Id}
                setId={setId}
                handleAppliedJobClientEmailSend={
                  handleAppliedJobClientEmailSend
                }
                handleCandidateActivitySubmit={handleCandidateActivitySubmit}
                handleAppliedJobEmailSend={handleAppliedJobEmailSend}
                handleEditShow={handleEditShow}
                editSubmission={editSubmission}
                handleEditClose={handleEditClose}
                component="account"
                // getAllSubmissiondata={getAppliedJobdata}
                getAllSubmissiondata={handleFilterAllFieldSteps}
                handleResumeMatching={handleResumeMatching}
                matchingSkills={matchingSkills}
                ParsedSkills={ParsedSkills}
                missingSkills={missingSkills}
                parseResumeConclusion={parseResumeConclusion}
                loadingResumeScreening={loadingResumeScreening}
                parsePercentage={parsePercentage}
                parseAge={parseAge}
                router={router}
                // handleUpdateStatus={handleUpdateStatus}
                clearFilter={clearJobFilter}
                // getCandidatedata={getCandidatedata}
                getCandidatedata={handleFilterAllFieldStep}
              />

              {/* Modal for Delete Submission */}
              <div className="model-box-view" id="deleteaccount">
                <Modal
                  show={deleteSubmission}
                  onHide={handleDeleteSubmissionClose}
                  backdrop="static"
                  keyboard={false}
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="usertitle">
                      Delete Submission
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <p>Are you sure to delete?</p>
                  </Modal.Body>
                  <Modal.Footer
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      className="cancel"
                      onClick={handleDeleteSubmissionClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="btn_bg_color"
                      onClick={handleDeleteSubmission}
                    >
                      Delete
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
              {/* Modal for Edit Submission Status*/}

              <EditSubmissionStatusModel
                editSubmissionStatus={editSubmissionStatus}
                handleEditSubmissionStatusClose={
                  handleEditSubmissionStatusClose
                }
                submission={submission}
                handleInputs={handleInputs}
                applicationStatus={applicationStatus}
                handleUpdateStatus={handleEditSubmissionStatus}
                handleEditShow={handleEditSubmissionShow}
                SetEditSubmissionStatus={SetEditSubmissionStatus}
              />

              {/* Modal for Submission Preview */}
              <div className="model-box-view">
                <Modal
                  show={submissionPreview}
                  onHide={handleSubmissionPreviewClose}
                  backdrop="static"
                  keyboard={false}
                  size="xl"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="usertitle">
                      Resume Preview
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Container>
                      <Row>
                        <Col lg={8} md={6} sm={6}>
                          {!objectURL ? (
                            <>
                              <div className="resume_ld">
                                <Loader />
                              </div>
                            </>
                          ) : objectURL === "no-resume" ? (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={NotFound}
                                alt="NotFound"
                                style={{ width: "16rem" }}
                              />
                              <p>No Resume Found! Please try again....</p>
                            </div>
                          ) : (
                            <div
                              style={{
                                borderRight: "1px solid rgb(222, 226, 230)",
                                paddingRight: "18px",
                              }}
                            >
                              <iframe
                                src={objectURL}
                                width="100%"
                                height="800px"
                              />
                            </div>
                          )}
                        </Col>
                        <Col lg={4} md={6} sm={6}>
                          <div className="resume_pr">
                            <div>
                              <Heading
                                size="md"
                                fontWeight="bold"
                                letterSpacing="tight"
                              >
                                {!submission?.candidateId?.firstName
                                  ? "N/A"
                                  : submission?.candidateId?.firstName}{" "}
                                {!submission?.candidateId?.lastName
                                  ? "N/A"
                                  : submission?.candidateId?.lastName}
                              </Heading>
                              <Text
                                color={useColorModeValue(
                                  "gray.600",
                                  "gray.400"
                                )}
                              >
                                <CallOutlinedIcon
                                  style={{ fontSize: "medium" }}
                                />{" "}
                                {!submission?.candidateId?.phone
                                  ? "N/A"
                                  : submission?.candidateId?.phone}
                              </Text>
                              <Text
                                color={useColorModeValue(
                                  "gray.600",
                                  "gray.400"
                                )}
                              >
                                <EmailOutlinedIcon
                                  style={{ fontSize: "medium" }}
                                />{" "}
                                {!submission?.candidateId?.email
                                  ? "N/A"
                                  : submission?.candidateId?.email}
                              </Text>
                            </div>
                            <div>
                              <Tooltip
                                hasArrow
                                label="Download Resume"
                                bg="gray.300"
                                color="black"
                              >
                                <a href={objectURL} download>
                                  <button class="btn bg-white border rounded mb-2 text-break">
                                    {!submission?.fileName
                                      ? "N/A"
                                      : submission?.fileName}{" "}
                                    <VerticalAlignBottomIcon
                                      style={{ color: "grey" }}
                                    />
                                  </button>
                                </a>
                              </Tooltip>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Modal.Body>
                  <Modal.Footer
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      className="cancel"
                      onClick={handleSubmissionPreviewClose}
                    >
                      Cancel
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              {/* Model for Account Preview */}
              <AccountPreviewModel
                accountPreview={accountPreview}
                handleSubmissionPreviewClose={handleSubmissionPreviewClose}
                objectURL={objectURL}
                displayAccount={displayAccount}
                selectedType={selectedType}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <NoteModel
        jobNote={contactNote}
        handleJobNoteClose={handleContactNoteClose}
        searchNote={searchNote}
        getnotes={getnotes}
        setSearchNote={setSearchNote}
        setNote={setContactNoteValue}
        note={contactNoteValue}
        setGetNotes={setGetNotes}
        getJobpostdata={handleFilterContactAllFieldStep}
        contactUrl={"/api/set_Contact_Note"}
        type={id}
        submissionData={account}
        setNotesLoading={setNotesLoading}
        notesLoading={notesLoading}
        page={page}
        rowPerPage={rowsPerPage}
      />
    </Box>
  );
}

export default Accountview;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config";
// import { Cookies } from "react-cookie";

// const cookies = new Cookies();

// export const getTokenFromCookies = async () => {
//   return cookies.get('token') || localStorage.getItem('token');

// };

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.apiUrl,
  }),

  endpoints: (builder) => ({
    loginApi: builder.mutation({
      query: (body) => ({
        url: `/api/login`,
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${body.token}`,
        },
        method: "POST",
        body,
      }),
    }),
    twoFactorApi: builder.mutation({
      query: (body) => ({
        url: `/two-fector`,
        method: "POST",
        body,
      }),
    }),
    forgotPasswordAPi: builder.mutation({
      query: (body) => ({
        url: `/api/forgotpass`,
        method: "POST",
        body,
      }),
    }),
    changePasswordAPi: builder.mutation({
        query: (body) => ({
          url: `/api/verify_otp_changepassword`,
          method: "POST",
          body,
        }),
      }),
  }),
});

export const {
  useLoginApiMutation,
  useTwoFactorApiMutation,
  useForgotPasswordAPiMutation,
  useChangePasswordAPiMutation
} = authApi;

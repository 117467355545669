import React, { useState } from "react";
import NotFound from "assets/svg/not-found.svg";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NoteModel from "../Commoncomponent/Note/NoteModel";
import DescriptionIcon from "@mui/icons-material/Description";
import ResumePreview  from "../Commoncomponent/ResumePreview/ResumePreview";
import {
  Box,
  Button,
  Flex,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,

} from "@chakra-ui/react";
// import { Input, Box, Text, Button, Flex } from "@chakra-ui/react";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Table,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import CommonPagination from "../CommonPagination/CommonPagination";
import Loader from "../../views/Dashboard/Loader";
import { toast } from "react-toastify";
import axios from "axios";
import config from "config";
import { useSearch } from "rsuite/esm/internals/Picker";
const MatchedCandidatesTable = ({
  loading,
  matchedData,
  totalCount,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  setSearchParams,
}) => {
  const [filters, setFilters] = useState({
    name: "",
    minScore: "",
    maxScore: "",
  });
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const [note, setNote] = useState({});
    const [candidateNote, setCandidateNote] =useState(false)
    const [getNotes,setGetNotes]=useState([])
    const [searchNote,setSearchNote] =useState("")
    const [notesLoading,setNotesLoading] =useState(false)
    const [loading1,setLoading]= useState(false)
    const [file,setFile] = useState(null)
    const [resumeFilePath,setResumeFilePath]=useState("")
    const [objectURL,setObjectURL]=useState("")
    const [submissionPreview,SetSubmissionPreview]=useState()
    const [submCandidate,setSubmCandidate]=useState(null)
    const [id,setId]=useState("")
  const resetFilter = () => {
    setFilters({ name: "", minScore: "", maxScore: "" });
    setSortConfig({ key: null, direction: "asc" });
    const searchParams = {
      name: "",
      minScore: 0,
      maxScore: 100,
      sortField: null,
      sortDirection: "asc",
    };
    setSearchParams(searchParams);
  };
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
    setSearchParams((prevParams) => ({
      ...prevParams,
      sortField: key,
      sortDirection: direction,
    }));
  };
  const handleCandidateNoteShow = () => {
    setCandidateNote(true);
  };
  const handleCandidateNoteClose = () => {
    setCandidateNote(false);
    setNotesLoading(false);
    setSearchNote("");
  };
  const handleSubmissionPreviewShow = () => {
    SetSubmissionPreview(true);
  };
   const getCandidate = (id) => {
      axios
        .get(`${config?.apiUrl}/candidateview/${id}`)
        .then((response) => {
          const candidatedata = response.data.data;
          setSubmCandidate(candidatedata);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };
  const handleSearch = () => {
    const searchParams = {
      name: filters.name,
      minScore: filters.minScore === "" ? 0 : Number(filters.minScore),
      maxScore: filters.maxScore === "" ? 100 : Number(filters.maxScore),
      sortField: sortConfig.key,
      sortDirection: sortConfig.direction,
    };
    setSearchParams(searchParams);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };
  const getCandidatNotes = async (id) => {
    try {
      setNotesLoading(true);
      if (id) {
        const response = await axios.get(
          `${config?.apiUrl}/api/get_Candidate_Note/${id}`
        );
        const notedata = response.data.data;
        setGetNotes(notedata);
        setNotesLoading(false);
      } else {
        setNotesLoading(false);
      }
    } catch (error) {
      setNotesLoading(false);
      toast.error(
        error?.message ||
          "Something happened wrong, please try again after sometime.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };
  const SubmissionPreview = async (id) => {
      try {
        const result = await axios.get(
          `${config?.apiUrl}/api/download_Candidate_Resume/${id}`,
          {
            responseType: "blob",
          }
        );
        setObjectURL(URL.createObjectURL(result.data));
        setResumeFilePath(URL.createObjectURL(result.data));
        setFile(result?.data);
        setLoading(false);
      } catch (error) {
        setObjectURL("no-resume");
        setResumeFilePath(null);
        toast.error("Error while Preview file. Try again later", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    };
  return (
    <Box overflowX="auto" p={4} bg="#f4f7fa" borderRadius="10px" boxShadow="lg">
      <Flex mb={4} gap="10px" flexDirection={{ base: "column", md: "row" }}>
        <Input
          placeholder="Search by name and id"
          value={filters.name}
          onChange={(e) => setFilters({ ...filters, name: e.target.value })}
          bg="white"
          borderRadius="8px"
          flex={1}
          onKeyDown={handleKeyDown}
        />
        {/* <Button 
          onClick={handleSearch} 
          colorScheme="blue" 
          minWidth={{ base: "100%", md: "120px" }}
        >
          Search
        </Button> */}
      </Flex>

      <Flex mb={4} gap="10px" flexDirection={{ base: "column", md: "row" }}>
        <Input
          placeholder="Min Match Score"
          type="number"
          value={filters.minScore}
          onChange={(e) => setFilters({ ...filters, minScore: e.target.value })}
          bg="white"
          borderRadius="8px"
          onKeyDown={handleKeyDown}
        />
        <Input
          placeholder="Max Match Score"
          type="number"
          value={filters.maxScore}
          onChange={(e) => setFilters({ ...filters, maxScore: e.target.value })}
          bg="white"
          borderRadius="8px"
          onKeyDown={handleKeyDown}
        />
        <Button
          onClick={handleSearch}
          style={{
            color: "white",
            border: "1px solid #d7d7d7",
            fontWeight: "500",
            letterSpacing: "1px",
            fontSize: "14px",
          }}
          className="bg_color"
          minWidth={{ base: "100%", md: "120px" }}
        >
          Apply Filters
        </Button>
        <Button
          onClick={resetFilter}
          style={{
            color: "white",
            border: "1px solid #d7d7d7",
            fontWeight: "500",
            letterSpacing: "1px",
            fontSize: "14px",
          }}
          className="bg_color"
          minWidth={{ base: "100%", md: "120px" }}
        >
          Reset
        </Button>
      </Flex>
      <TableContainer style={{ maxHeight: 650 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead id="tbl_exporttable_to_xls">
            <TableRow sx={{ textAlignLast: "center" }}>
              <TableCell
                style={{
                  color: "#718096",
                  fontWeight: "bold",
                  minWidth: "135px",
                  width: "fit-content",
                }}
                width={"120px"}
                color="white"
              ></TableCell>
              <TableCell
                style={{
                  color: "#718096",
                  fontWeight: "bold",
                  minWidth: "135px",
                  width: "fit-content",
                }}
                width={"160px"}
                color="white"
              >
                Candidate ID
              </TableCell>
              <TableCell
                style={{
                  color: "#718096",
                  fontWeight: "bold",
                  minWidth: "135px",
                  width: "fit-content",
                }}
                color="white"
                onClick={() => handleSort("name")}
              >
                Candidate Name{" "}
                {sortConfig.key === "name"
                  ? sortConfig.direction === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </TableCell>
              <TableCell
                style={{
                  color: "#718096",
                  fontWeight: "bold",
                  minWidth: "135px",
                  width: "fit-content",
                }}
                width={"160px"}
                color="white"
                onClick={() => handleSort("match_score")}
              >
                Match Score{" "}
                {sortConfig.key === "match_score"
                  ? sortConfig.direction === "asc"
                    ? "▲"
                    : "▼"
                  : ""}
              </TableCell>
              <TableCell
                style={{
                  color: "#718096",
                  fontWeight: "bold",
                  minWidth: "135px",
                  width: "fit-content",
                }}
                color="white"
              >
                Matched Skills
              </TableCell>
              <TableCell
                style={{
                  color: "#718096",
                  fontWeight: "bold",
                  minWidth: "135px",
                  width: "fit-content",
                }}
                color="white"
              >
                Missing Skills
              </TableCell>
              <TableCell
                style={{
                  color: "#718096",
                  fontWeight: "bold",
                  minWidth: "135px",
                  width: "fit-content",
                }}
                color="white"
              >
                Key Matching Factors
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <>
                <TableRow>
                  <TableCell colSpan={11}>
                    <Loader />
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
                {matchedData.length == 0 ? (
                  <TableCell colSpan={11}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        src={NotFound}
                        alt="NotFound"
                        style={{ width: "16rem" }}
                      />
                      <p>No Records Found! Please try again....</p>
                    </div>
                  </TableCell>
                ) : (
                  <>
                    {matchedData.map((candidate) => (
                      <TableRow
                        key={candidate._id}
                        _hover={{ background: "#e6f7ff" }}
                      >
                        <TableCell
                          style={{
                            color: "#718096",
                            fontWeight: "bold",
                          }}
                        >
                          <Box className="table_menu">
                            <div style={{ position: "relative" }}>
                              {candidate.noteCount  !== 0 && (
                                <div
                                  style={{
                                    color: "white",
                                    height: "auto",
                                    width: "auto",
                                    textAlign: "center",
                                    position: "absolute",
                                    marginLeft: "11px",
                                    marginTop: "-10px",
                                    borderRadius: "50px",
                                    fontSize: "10px",
                                    padding: "1px 5px",
                                  }}
                                  className="bg_color"
                                >
                                  <span>{candidate.noteCount }</span>
                                </div>
                              )}
                              <Tooltip
                                hasArrow
                                label="Notes"
                                bg="gray.300"
                                color="black"
                              >
                                <button
                                  className="note_btn"
                                  style={{ color: "#000" }}
                                  onClick={() => {
                                    handleCandidateNoteShow();
                                    setNote({
                                      ...note,
                                      candidateId: candidate.candidate_id,
                                      name : candidate.firstName +" " + candidate.lastName 
                                    });
                                    getCandidatNotes(candidate.candidate_id);
                                  }}
                                >
                                  N
                                </button>
                              </Tooltip>
                            </div>
                            <div style={{ position: "relative" }}>
                              {candidate?.submissionCount !== 0 && (
                                <div
                                  style={{
                                    color: "white",
                                    height: "auto",
                                    width: "auto",
                                    textAlign: "center",
                                    position: "absolute",
                                    marginLeft: "11px",
                                    marginTop: "-10px",
                                    borderRadius: "50px",
                                    fontSize: "10px",
                                    padding: "1px 5px",
                                  }}
                                  className="bg_color"
                                >
                                  <span>{candidate?.submissionCount }</span>
                                </div>
                              )}
                              <Tooltip
                                hasArrow
                                label="Submission"
                                bg="gray.300"
                                color="black"
                              >
                                <button
                                  className="note_btn"
                                  style={{ color: "green" }}
                                  onClick={() =>
                                    window.open(
                                      `/details/candidateview/${candidate?.candidate_id}?i=2`,
                                      "_blank"
                                    )
                                  }
                                >
                                  S
                                </button>
                              </Tooltip>
                            </div>
                            <Tooltip
                              hasArrow
                              label="Resume Preview"
                              bg="gray.300"
                              color="black"
                            >
                              <DescriptionIcon
                                className="list_view"
                                onClick={() => {
                                  SubmissionPreview(
                                    candidate.candidate_id,
                                    candidate.filePath
                                  );
                                  const activityOn = `View resume of candidate ${candidate?.firstName} ${candidate?.lastName}`;
                                  const activityDo = `View resume`;
                                  const component = "candidate";
                                  // userActivity(
                                  //   candidate_ownerName,
                                  //   userId,
                                  //   component,
                                  //   activityDo,
                                  //   activityOn,
                                  //   userRole
                                  // );
                                  // (
                                  //   value?._id,
                                  //   `View resume of ${value?.firstName} ${value?.lastName}`,
                                  //   new Date().getTime(),
                                  //   candidate_ownerName,
                                  //   userId
                                  // );
                                  handleSubmissionPreviewShow(
                                    // setSubmission(value),
                                    setId(candidate.candidate_id)
                                  );
                                  getCandidate(candidate?.candidate_id);
                                }}
                              />
                            </Tooltip>
                          </Box>
                        </TableCell>
                        <TableCell className="nowrap_content" style={{
                                  fontWeight: "bold",
                                }}>
                          <Link
                            target="_blank"
                            to={`/details/candidateview/${candidate?.candidate_id}`}
                          >
                            TKC-{candidate?.uniqueCandidateId}
                          </Link>
                        </TableCell>
                        <TableCell  className="nowrap_content" style={{
                                  fontWeight: "bold",
                                }}>
                          <Link
                            target="_blank"
                            to={`/details/candidateview/${candidate?.candidate_id}`}
                          >
                            {candidate?.firstName} {candidate?.lastName}
                          </Link>
                        </TableCell>
                        <TableCell fontWeight="bold" >
                          {candidate.match_score}
                        </TableCell>
                        <TableCell>
  <Tooltip
    hasArrow
    label={
      <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
        {candidate.matched_skills.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
    }
    bg="gray.300"
    color="black"
  >
    <span>
      {candidate.matched_skills
        .map(skill => skill.length > 50 ? `${skill.substring(0, 50)}...` : skill)
        .join(", ")}
    </span>
  </Tooltip>
</TableCell>

<TableCell>
  <Tooltip
    hasArrow
    label={
      <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
        {candidate.missing_skills.map((skill, index) => (
          <li key={index}>{skill}</li>
        ))}
      </ul>
    }
    bg="gray.300"
    color="black"
  >
    <span>
      {candidate.missing_skills
        .map(skill => skill.length > 50 ? `${skill.substring(0, 50)}...` : skill)
        .join(", ")}
    </span>
  </Tooltip>
</TableCell>

<TableCell>
  <Tooltip
    hasArrow
    label={
      <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
        {candidate.key_matching_factors.map((factor, index) => (
          <li key={index}>{factor}</li>
        ))}
      </ul>
    }
    bg="gray.300"
    color="black"
  >
    <span>
      {candidate.key_matching_factors
        .map(factor => factor.length > 50 ? `${factor.substring(0, 50)}...` : factor)
        .join(", ")}
    </span>
  </Tooltip>
</TableCell>
                      </TableRow>
                    ))}
                  </>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <ResumePreview
        objectURL={objectURL}
        setObjectURL={setObjectURL}
        file={file}
        setFile={setFile}
        submissionPreview={submissionPreview}
        setSubmissionPreview={SetSubmissionPreview}
        resumeFilePath={resumeFilePath}
        setResumeFilePath={setResumeFilePath}
        submission={[]}
        setSubmission={()=>{console.log('__')}}
        submCandidate={submCandidate}
        setSubmCandidate={setSubmCandidate}
      />
      <CommonPagination
        rowsPerPage={rowsPerPage}
        page={page}
        totalPages={Math.ceil(totalCount / rowsPerPage)}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        count={totalCount}
      />
       <NoteModel
              jobNote={candidateNote}
              handleJobNoteClose={handleCandidateNoteClose}
              searchNote={searchNote}
              getnotes={getNotes}
              setSearchNote={setSearchNote}
              setNote={setNote}
              note={note}
              setGetNotes={setGetNotes}
              // getJobpostdata={handleFilterAllFieldStep}
              candidateUrl={"/api/set_Candidate_Note"}
              setNotesLoading={setNotesLoading}
              notesLoading={notesLoading}
              // page={page}
              // rowPerPage={rowsPerPage}
              getCandidatNotes={getCandidatNotes}
            />
           
    </Box>

  );
};

export default MatchedCandidatesTable;

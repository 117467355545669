import {
  Box,
  Checkbox,
  CloseButton,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import DeleteIcon from "@mui/icons-material/Delete";
import DescriptionIcon from "@mui/icons-material/Description";
import EditIcon from "@mui/icons-material/Edit";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterListIcon from "@mui/icons-material/FilterList";
import IosShareIcon from "@mui/icons-material/IosShare";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { userActivity } from "Utils";
import NotFound from "assets/svg/not-found.svg";
import axios from "axios";
import CandidatePreview from "components/Commoncomponent/CandidatePreview/CandidatePreview";
import NoteSearch from "components/Commoncomponent/NoteSearch";
import config from "config";
import { format } from "date-fns";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Row from "react-bootstrap/Row";
import { useCookies } from "react-cookie";
import { FiSearch } from "react-icons/fi";
import { useParams } from "react-router";
import { Link, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { CheckPicker, DateRangePicker } from "rsuite";
import { getAllCities, getAllStates } from "../../Utils";
import Loadar from "../../views/Dashboard/Loader";
import "../../views/Dashboard/org.css";
import FilterRadioButton from "../FilterRadioButton/FilterRadioButton";
import InputTextSearchBox from "../InputTextSearchBox/InputTextSearchBox";
import CommonCheckPicker from "../CommonCheckPicker/CommonCheckPicker";
import { useSelector } from "react-redux";
import CheckFilter from "../CheckFilter/CheckFilter";
import CommonExperienceRange from "../CommonExprienceRange/CommonExperienceRange";
import CommonPagination from "../CommonPagination/CommonPagination";
import { CiSearch } from "react-icons/ci";

const CommonSubmissionFile = ({
  handleInputs,
  setDisplaySubmissions,
  displaysubmissions,
  Searchicon,
  searchAllfields,
  setSearchAllfields,
  moduleData,
  setIsFilterActiveStatus,
  setIsFilterActiveNoticePeriod,
  setIsFilterActiveClient,
  setIsFilterActiveOwner, 
  setIsFilterActiveState,
  setIsFilterActiveCity,
  setIsFilterActiveUpdatedBy,
  setIsFilterActiveSourceSearch,
  setIsFilterActiveIndustry,
  handleCreateSubmission,
  setIsFilterActiveExperience,
  setId,
  handleSubmHeaderOrdersshow,
  isFilterActiveSourceSearch,
  SetIschecked,
  handleSendMailShow,
  handleOpenModal,
  isFilterActiveSubmissionId,
  searchSubmissionId,
  setSearchSubmissionId,
  checked,
  handleEditShow,
  setSubmission,
  handleDeleteSubmissionShow,
  handleCandidateNoteShow,
  getCandidatNotes,
  SubmissionPreview,
  handleSubmissionPreviewShow,
  handleEditSubmissionStatusShow,
  setChangeStatus,
  setOldJobId,

  page,
  rowsPerPage,
  setRowsPerPage,

  applicationStatus,
  loading,
  setLoading,
  changeStatus,
  setNote,
  note,
  submissions,
  setPage,
  submheaderOrders,
  componentName,
  setSubmissionId,
  statusField,
  status,
  clientId,
  isClientView,
  Setcandidatename,
  contactId,
  jobId,
  candidateFname,
  candidateLname,
  teamData,
  setTotalPages,
  setActiveField,
  activeField,
  clearFilter,
  searchfname,
  setSearchFname,
  searchlname,
  setSearchLname,
  searchjobt,
  setSearchJobt,
  searchstatus,
  setSearchStatus,
  searchcustomer,
  setSearchCustomer,
  searchOwnerName,
  setSearchOwnerName,
  searchcandidatectctype,
  setSearchCandidatectctype,
  searchcandidatectc,
  setSearchCandidatectc,
  searchcity,
  setSearchCity,
  searchstate,
  setSearchstate,
  searchSubmissionDate,
  setSearchSubmissionDate,
  searchSubmissionUpdateDate,
  setSearchSubmissionUpdateDate,
  searchExpectedCandidateCTC,
  setSearchExpectedCandidateCTC,
  searchExpectedCandidateCTCType,
  setSearchExpectedCandidateCTCType,
  searchNoticePeriod,
  setSearchNoticePeriod,
  searchPhone,
  setSearchPhone,
  searchAddress,
  setSearchAddress,
  searchDOB,
  setSearchDOB,
  searchAadharCardNumber,
  setSearchAadharCardNumber,
  searchPanCardNumber,
  setSearchPanCardNumber,
  searchDesignation,
  setSearchDesignation,
  searchWorkExperience,
  setSearchWorkExperience,
  searchEducationDetails,
  setSearchEducationDetails,
  searchExperience,
  setSearchExperience,
  searchGender,
  setSearchGender,
  searchInterviewScheduleDate,
  setSearchInterviewScheduleDate,
  searchInterviewScheduleTime,
  setSearchInterviewScheduleTime,
  searchInterviewScheduleLocation,
  setSearchInterviewScheduleLocation,
  searchInterviewScheduleLink,
  setSearchInterviewScheduleLink,
  searchJoinDate,
  setSearchJoinDate,
  searchSubmissionBy,
  setSearchSubmissionBy,
  searchEmail,
  setSearchEmail,
  searchContactName,
  setSearchContactName,
  searchActualCTC,
  setSearchActualCTC,
  searchOfferCTC,
  setSearchOfferCTC,
  searchExpectedJoinDate,
  setSearchExpectedJoinDate,
  searchUpdatedBy,
  setSearchUpdatedBy,
  searchFullName,
  setSearchFullName,
  isFilterActiveFname,
  isFilterActiveFullName,
  isFilterActiveUpdatedBy,
  isFilterActiveExpectedJoinDate,
  isFilterActiveUpdatedDate,
  isFilterActiveOfferCTC,
  isFilterActiveActualCTC,
  isFilterActiveEmail,
  isFilterActiveJoinDate,
  isFilterActiveInterviewScheduleLink,
  isFilterActiveInterviewScheduleLocation,
  isFilterActiveInterviewScheduleTime,
  isFilterActiveInterviewScheduleDate,
  isFilterActiveGender,
  isFilterActiveExperience,
  isFilterActiveEducationDetails,
  isFilterActiveWorkExperience,
  isFilterActiveDesignation,
  isFilterActivePanCardNumber,
  isFilterActiveAadharCardNumber,
  isFilterActiveDOB,
  isFilterActiveAddress,
  isFilterActivePhone,
  isFilterActiveNoticePeriod,
  isFilterActivEexpectedCtctype,
  isFilterActiveExpectedCtc,
  isFilterActiveLname,
  isFilterActiveJobt,
  isFilterActiveStatus,
  isFilterActiveClient,
  isFilterActiveOwner,
  isFilterActiveCtctype,
  isFilterActiveCtc,
  isFilterActiveCity,
  isFilterActiveState,
  isFilterActiveDate,
  isFilterActiveContactName,
  isFilterActiveSubmissionBy,
  filterData,
  setMySubmissionValue,
  mySubmissionValue,
  handleFilterAllFieldStep,
  setIsFilterActiveSubmissionBy,
  popoverVisible,
  setPopoverVisible,
  handlePopOverClose,

  setSearchIndustry,
  searchIndustry,
  isFilterActiveIndustry,
  industries,
  handleActivitySubmit,

  searchInterviewRescheduleDate,
  setSearchInterviewRescheduleDate,
  isFilterActiveInterviewRescheduleDate,

  searchTestRescheduleDate,
  setSearchTestRescheduleDate,
  isFilterActiveTestRescheduleDate,

  searchBackoutDate,
  setSearchBackoutDate,
  isFilterActiveBackoutDate,
  filterRadioTitle,
  setSearchSourceData,
  searchSourceData,
  
}) => {
  const [searchQuery,setSearchQuery]=useState("")
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [perviewCandidateId, setPerviewCandidateId] = useState("");
  const [defaultCities, setDefaultCities] = useState([]);
  const [defaultStates, setDefaultStates] = useState([]);
  const { userNames } = useSelector((state) => state.usersSlice);
  const handleCandidatePreviewClose = () => {
    setIsOpenPreview(false);
  };
  const [cookie] = useCookies(["role", "id", "token"]);
  const candidate_ownerName = localStorage?.getItem("candidate_ownerName");
  const userId = cookie.id;
  const userRole = cookie?.role;

  const [clients, setClients] = useState([]);

  const popOverConfig = useCallback(
    (key) => {
      return {
        trigger: "click",
        placement: "auto",
        rootClose: true,
        show: !!popoverVisible?.[key],
        onToggle: (isShow) => handlePopOverClose(key, isShow),
      };
    },
    [popoverVisible]
  );

  let { id } = useParams();

  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (
      searchAllfields.length !== 0 ||
      activeField.name !== "" ||
      activeField.value
    ) {
      handleFilterAllFieldStep(
        activeField.value,
        newPage,
        rowsPerPage,
        activeField.name,
        moduleData,
      );
    } else {
      // getAllSubmissiondata(newPage, rowsPerPage);
      handleFilterAllFieldStep(
        activeField.value,
        newPage,
        rowsPerPage,
        activeField.name,
        moduleData,
      );
      // getAllSubmissiondata(newPage, rowsPerPage, moduleData);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    if (
      searchAllfields.length !== 0 ||
      activeField.name !== "" ||
      activeField.value
    ) {
      handleFilterAllFieldStep(
        activeField.value,
        0,
        +event.target.value,
        activeField.name,
        moduleData
      );
    } else {
      handleFilterAllFieldStep(
        activeField.value,
        0,
        +event.target.value,
        activeField.name,
        moduleData,
      );
      // getAllSubmissiondata(0, +event.target.value, moduleData);
    }
  };

  const findDate = (changeDate) => {
    const selectedDate = new Date(changeDate ? changeDate : selecteddate);
    const day = String(selectedDate.getDate()).padStart(2, "0");
    const month = String(selectedDate.getMonth() + 1).padStart(2, "0");
    const year = selectedDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };
  const isInvalidExperience = (value) => {
    if (value === null) return true;
    return !(typeof value === "number" || (typeof value === "string" && value.trim() !== "" && !isNaN(value)));
  };
  const downloadFieldNames = async () => {
    const response = await fetch(
      `${config?.apiUrl}/api/get_all_SubmissionFields`
    );
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "SubmissionTemplate.xlsx";
    a.click();
    const activityOn = `Download template of submission`;
    const activityDo = `Download template`;
    const component = "submission";
    userActivity(
      candidate_ownerName,
      userId,
      component,
      activityDo,
      activityOn,
      userRole
    );
  };

  const getclientsdata = () => {
    axios
      .get(`${config?.apiUrl}/api/client/names`)
      .then((response) => {
        const clientsData = response?.data?.clients || [];
        setClients(clientsData);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleTopFilterChange = (event) => {
    setMySubmissionValue(event.target.value);
    setActiveField({
      name: "submissionBy",
      value: userId?.trim() || "",
    });
    setSearchSubmissionBy([userId?.trim()]);
    setIsFilterActiveSubmissionBy(true);
    handleFilterAllFieldStep(userId?.trim(), 0, rowsPerPage, "submissionBy",moduleData);
    setPage(0);
  };

  const renderMenu = (menu, defaultCities) => {
    if (defaultCities.length === 0) {
      return (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
          <SpinnerIcon spin /> loading...
        </p>
      );
    }
    return menu;
  };

  const handleCities = async () => {
    try {
      if (defaultCities.length == 0) {
        const allCities = await getAllCities();
        // Remove duplicate city names
        const uniqueCities =
          allCities &&
          Array.from(new Set(allCities?.map((city) => city?.name?.trim()))).map(
            (name) => allCities?.find((city) => city?.name?.trim() === name)
          );
        setDefaultCities(uniqueCities || []);
      }
    } catch (error) {
      toast.error(error?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleStates = async () => {
    try {
      if (defaultStates.length == 0) {
        const allStates = await getAllStates();
        // Remove duplicate state names
        const uniqueStates =
          allStates &&
          Array.from(
            new Set(allStates?.map((state) => state?.name?.trim()))
          ).map((name) =>
            allStates?.find((state) => state?.name?.trim() === name)
          );
        setDefaultStates(uniqueStates || []);
      }
    } catch (error) {
      toast.error(error?.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const [sourceData, setSourceData] = useState([]);

  const getCandidateSources = async () => {
    try {
      const response = await axios.get(`${config?.apiUrl}/api/get_source`);
      setSourceData(
        response.data?.map((data) => {
          return { value: data.source, label: data.source };
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getclientsdata();
    getCandidateSources();
  }, []);

  const showAllApplicationStatus =
    componentName === "all"
      ? applicationStatus.map((user) => ({
          label: user.CandidateApplicationStatus.trim(),
          value: user.CandidateApplicationStatus.trim(),
        }))
      : statusField !== undefined
        ? applicationStatus.map((val) => ({
            label: val?.CandidateApplicationStatus,
            value: val?.CandidateApplicationStatus,
          }))
        : [];
  const showAllClientsList = clients.map((user) => ({
    label: user.accountName,
    value: user?._id,
  }));

  const userData = [
    ...(userNames
      ? userNames.map((user) => ({
          label: `${user.firstName?.trim()} ${user.lastName?.trim()}`,
          value: user?._id,
        }))
      : []),
  ];

  const showAllIndustries = industries?.map((val) => ({
    label: val?.industry,
    value: val?.industry,
  }));

  const { userTeam: userTeamData } = useSelector((state) => state.usersSlice);
  return (
    <>
      <FilterRadioButton
        handleTopFilterChange={handleTopFilterChange}
        myJobValue={mySubmissionValue}
        setMyJobsValue={setMySubmissionValue}
        radioValue={"mysubmissions"}
        radioLabel={filterRadioTitle || "My Submission"}
        isDisableOnly={moduleData?.view === "ownrecords"}
      />
      <div>
        <div className="table_box">
          <Row>
            <NoteSearch
              getCandidatedata={handleFilterAllFieldStep}
              setDisplayCandidates={setDisplaySubmissions}
              displaycandidates={displaysubmissions}
              Searchicon={Searchicon}
            />
            <Col lg={4}>
              <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
                <form
                  onSubmit={(e) => {
                    setPage(0);
                    setSearchAllfields(searchQuery);
                    e.preventDefault();
                    handleFilterAllFieldStep(
                      searchQuery,
                      0,
                      rowsPerPage,
                      "text",
                      moduleData
                    );
                  }}
                  style={{ width: "100%" }}
                >
                  <InputGroup>
                    <InputLeftElement
                      children={
                        <IconButton
                          variant={"outline"}
                          border={"none"}
                          _hover={{ background: "none" }}
                          isDisabled={searchQuery == ""}
                          onClick={() => {
                            setPage(0);
                            setSearchAllfields(searchQuery);
                            handleFilterAllFieldStep(
                              searchQuery,
                              0,
                              rowsPerPage,
                              "text",
                              moduleData
                            );
                          }}
                          fontSize={"x-large"}
                        >
                          <CiSearch />
                        </IconButton>
                      }
                    />
                    <Input
                      type="text"
                      id="searchAllfields"
                      placeholder="Search by Name, JobTitle, Client"
                      value={searchQuery}
                      onChange={(e) => {
                        setSearchQuery(e.target.value);
                      }}
                      style={{
                        height: "37px",
                        padding: "9px",
                        paddingLeft: "2.6rem",
                        color: "#7B9AAA",
                        fontFamily: "Roboto",
                        fontWeight: "500",
                        lineHeight: "19px",
                        fontSize: "15px",
                      }}
                    />
                    {searchAllfields?.length !== 0 && (
                      <InputRightElement
                        children={
                          <CloseButton
                            size="md"
                            sx={{
                              background: "#fff",
                              color: "rgb(123, 154, 170)",
                            }}
                            _hover={{ background: "" }}
                            _focus={{ boxShadow: "none" }}
                          />
                        }
                        onClick={() => {
                          setSearchAllfields("");
                          setSearchQuery("");
                          // setPage(0);
                          // setRowsPerPage(10);
                          // getAllSubmissiondata(0, 10, moduleData);
                          // handleFilterAllFieldStep(
                          //   "",
                          //   page,
                          //   rowsPerPage,
                          //   "text"
                          // );
                        }}
                      />
                    )}
                  </InputGroup>
                </form>
                {/* {(activeField?.name || searchAllfields) && ( */}
                <Button
                  className="bg_color"
                  onClick={() => {
                    clearFilter();
                    setSearchQuery("");
                  }}
                  style={{
                    color: "white",
                    border: "1px solid #d7d7d7",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    fontSize: "14px",
                  }}
                >
                  Reset
                </Button>
                {/* )} */}
              </Box>
            </Col>
            <Col lg={4}>
              <Box className="button_box">
                <Button
                  disabled={moduleData && moduleData.add == false}
                  style={{
                    color: "white",
                    border: "1px solid #d7d7d7",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    fontSize: "14px",
                  }}
                  className="bg_color"
                  onClick={() => {
                    handleCreateSubmission();
                    setId("");
                  }}
                >
                  Add Submission
                </Button>
                <Button
                  style={{
                    color: "white",
                    border: "1px solid #d7d7d7",
                    fontWeight: "500",
                    letterSpacing: "1px",
                    fontSize: "14px",
                  }}
                  className="bg_color"
                  onClick={() => {
                    handleSubmHeaderOrdersshow();
                  }}
                >
                  Columns
                </Button>
              </Box>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ width: "100%", boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" }}>
        <Paper style={{ width: "100%", overflow: "hidden" }}>
          <TableContainer style={{ maxHeight: 680 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow sx={{ textAlignLast: "center" }}>
                  <TableCell
                    style={{
                      color: "#718096",
                      fontWeight: "bold",
                      minWidth: "135px",
                      width: "fit-content",
                    }}
                  >
                    <Box className="table_menu">
                      <Checkbox
                        size="md"
                        onChange={(e) => {
                          if (e.target.checked) {
                            SetIschecked((oldarr) => [
                              ...oldarr,
                              ...displaysubmissions?.map((value) => ({
                                id: value?._id,
                                email: value?.candidateId?.email,
                              })),
                            ]);
                          } else {
                            SetIschecked([]);
                          }
                        }}
                      />
                      <Menu>
                        <MenuButton>
                          <ExpandMoreIcon style={{ cursor: "pointer" }} />
                        </MenuButton>
                        <MenuList style={{ position: "absolute", top: "12px" }}>
                          <MenuItem
                            icon={<MailOutlineIcon />}
                            onClick={() => {
                              handleSendMailShow();
                            }}
                          >
                            Batch Send Mail
                          </MenuItem>
                          <MenuItem
                            isDisabled={
                              cookie?.role !== "Admin" &&
                              moduleData?.exportExcel == false
                            }
                            icon={
                              <IosShareIcon
                                style={{
                                  color: "#718096",
                                  height: "22px",
                                }}
                              />
                            }
                            onClick={() => {
                              handleOpenModal();
                            }}
                          >
                            Export Submissions
                          </MenuItem>
                          <MenuItem
                            icon={
                              <SaveAltIcon
                                style={{
                                  color: "#718096",
                                  height: "22px",
                                }}
                              />
                            }
                            onClick={downloadFieldNames}
                          >
                            Download Template
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </Box>
                  </TableCell>

                  <TableCell
                    style={{
                      color: "#718096",
                      fontWeight: "bold",
                      minWidth: "150px",
                    }}
                  >
                    Submission Id
                    <OverlayTrigger
                      {...popOverConfig("submission_id")}
                      overlay={
                        <Popover id="popover-basic">
                          <Popover.Body>
                            <InputTextSearchBox
                              filterData={filterData}
                              inputState={searchSubmissionId}
                              fieldName={"submission_id"}
                              setInputState={setSearchSubmissionId}
                              setActiveField={setActiveField}
                              activeFieldName={"submission_id"}
                              isFilterActiveState={isFilterActiveSubmissionId}
                              inputPlaceholder={"Search Submission Id"}
                            />
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      {isFilterActiveSubmissionId ? (
                        <FilterAltIcon style={{ cursor: "pointer" }} />
                      ) : (
                        <FilterListIcon
                          style={{ cursor: "pointer", fontSize: "20px" }}
                        />
                      )}
                    </OverlayTrigger>
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#718096",
                      fontWeight: "bold",
                      minWidth: "130px",
                    }}
                  >
                    First Name
                    {candidateFname == undefined && (
                      <OverlayTrigger
                        {...popOverConfig("firstName")}
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Body>
                              <InputTextSearchBox
                                filterData={filterData}
                                inputState={searchfname}
                                fieldName={"firstName"}
                                setInputState={setSearchFname}
                                setActiveField={setActiveField}
                                activeFieldName={"candidateFirstName"}
                                isFilterActiveState={isFilterActiveFname}
                                inputPlaceholder={"Search First Name"}
                              />
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        {isFilterActiveFname ? (
                          <FilterAltIcon style={{ cursor: "pointer" }} />
                        ) : (
                          <FilterListIcon
                            style={{ cursor: "pointer", fontSize: "20px" }}
                          />
                        )}
                      </OverlayTrigger>
                    )}
                  </TableCell>
                  {submheaderOrders &&
                    submheaderOrders?.map((key) => {
                      if (key == "lastName") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Last Name
                            {candidateFname == undefined && (
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body>
                                      <InputTextSearchBox
                                        filterData={filterData}
                                        inputState={searchlname}
                                        fieldName={"lastName"}
                                        setInputState={setSearchLname}
                                        setActiveField={setActiveField}
                                        activeFieldName={"candidateLastName"}
                                        isFilterActiveState={
                                          isFilterActiveLname
                                        }
                                        inputPlaceholder={"Search Last Name"}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveLname ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            )}
                          </TableCell>
                        );
                      } else if (key == "jobtitle") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Job Title
                            {jobId == undefined && (
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body>
                                      <InputTextSearchBox
                                        filterData={filterData}
                                        inputState={searchjobt}
                                        fieldName={"jobTitle"}
                                        setInputState={setSearchJobt}
                                        setActiveField={setActiveField}
                                        activeFieldName={"jobtitle"}
                                        isFilterActiveState={isFilterActiveJobt}
                                        inputPlaceholder={"Search Job Title..."}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveJobt ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            )}
                          </TableCell>
                        );
                      } else if (key == "fullName") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Full Name
                            {candidateFname == undefined && (
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body>
                                      <InputTextSearchBox
                                        filterData={filterData}
                                        inputState={searchFullName}
                                        fieldName={"fullName"}
                                        setInputState={setSearchFullName}
                                        setActiveField={setActiveField}
                                        activeFieldName={"candidateFullName"}
                                        isFilterActiveState={
                                          isFilterActiveFullName
                                        }
                                        inputPlaceholder={"Search Full Name"}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveFullName ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            )}
                          </TableCell>
                        );
                      } else if (key == "status") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Status
                            {status == undefined && (
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover
                                    id="popover-basic"
                                    style={{ maxWidth: "100%" }}
                                  >
                                    <Popover.Body onClick={(e) => e.stopPropagation()}>
                                      <CheckFilter
                                        filterData={filterData}
                                        setActiveField={setActiveField}
                                        handlePopOverClose={handlePopOverClose}
                                        field={"status"}
                                        placeholder={"Select Status"}
                                        value={searchstatus}
                                        setValue={setSearchStatus}
                                        data={showAllApplicationStatus}
                                        isFilter={isFilterActiveStatus}
                                        setIsFilter={setIsFilterActiveStatus}
                                      />
                                    
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveStatus ? (
                                  <FilterAltIcon
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            )}
                          </TableCell>
                        );
                      } else if (key == "client") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Client
                            {clientId == undefined && (
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover
                                    id="popover-basic"
                                    style={{ maxWidth: "100%" }}
                                  >
                                    <Popover.Body onClick={(e) => e.stopPropagation()}>
                                      <CheckFilter
                                        filterData={filterData}
                                        setActiveField={setActiveField}
                                        handlePopOverClose={handlePopOverClose}
                                        field={"client"}
                                        placeholder={"Select Client"}
                                        value={searchcustomer}
                                        setValue={setSearchCustomer}
                                        data={showAllClientsList}
                                        isFilter={isFilterActiveClient}
                                        setIsFilter={setIsFilterActiveClient}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveClient ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            )}
                          </TableCell>
                        );
                      } else if (key == "clientcontactname") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Contact Name
                            {contactId == undefined && (
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body>
                                      <div style={{ width: "160px" }}>
                                        <InputGroup>
                                          <InputLeftElement
                                            children={
                                              <img
                                                src={Searchicon}
                                                width="18px"
                                                height="18px"
                                                style={{ cursor: "pointer" }}
                                              />
                                            }
                                            onClick={() => {
                                              filterData(
                                                searchContactName,
                                                "contactName"
                                              );
                                            }}
                                          />
                                          <Input
                                            type="text"
                                            id="searchcustomer"
                                            placeholder="Search Contact Name..."
                                            value={searchContactName}
                                            onChange={(e) => {
                                              setSearchContactName(
                                                e.target.value
                                              );
                                              setActiveField({
                                                name: "contactName",
                                                value: e.target.value,
                                              });
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === "Enter") {
                                                filterData(
                                                  searchContactName,
                                                  "contactName"
                                                );
                                              }
                                            }}
                                            style={{
                                              height: "37px",
                                              padding: "9px",
                                              paddingLeft: "2.6rem",
                                              color: "#7B9AAA",
                                              fontFamily: "Roboto",
                                              fontWeight: "500",
                                              lineHeight: "19px",
                                              fontSize: "15px",
                                            }}
                                          />
                                        </InputGroup>
                                      </div>
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveContactName ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            )}
                          </TableCell>
                        );
                      } else if (key == "owner_name") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "130px",
                            }}
                          >
                            Owner
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body onClick={(e) => e.stopPropagation()}>
                                    <CheckFilter
                                      filterData={filterData}
                                      setActiveField={setActiveField}
                                      handlePopOverClose={handlePopOverClose}
                                      field={"owner_name"}
                                      placeholder={"Select Owners"}
                                      value={searchOwnerName}
                                      setValue={setSearchOwnerName}
                                      data={userData}
                                      isFilter={isFilterActiveOwner}
                                      setIsFilter={setIsFilterActiveOwner}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveOwner ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "currentCandidateCTCType") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "190px",
                            }}
                          >
                            Candidate CTC Type
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <div style={{ width: "auto" }}>
                                      <select
                                        className="form-control"
                                        id="searchcandidatectctype"
                                        value={searchcandidatectctype}
                                        onChange={(e) => {
                                          setSearchCandidatectctype(
                                            e.target.value
                                          );
                                          setActiveField({
                                            name: "currentCandidateCTCType",
                                            value: e.target.value,
                                          });
                                          filterData(
                                            e.target.value,
                                            "currentCandidateCTCType"
                                          );
                                        }}
                                        style={{
                                          height: "37px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <option disabled={true} value="">
                                          Status
                                        </option>
                                        <option>Monthly</option>
                                        <option>Annual Salary</option>
                                        <option>Other</option>
                                      </select>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveCtctype ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "currentCandidateCTC") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "160px",
                            }}
                          >
                            Candidate CTC
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchcandidatectc}
                                      fieldName={"currentCandidateCTC"}
                                      setInputState={setSearchCandidatectc}
                                      setActiveField={setActiveField}
                                      activeFieldName={"currentCandidateCTC"}
                                      isFilterActiveState={isFilterActiveCtc}
                                      inputPlaceholder={"Search Current CTC"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveCtc ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "city") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "90px",
                            }}
                          >
                            City
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body onClick={(e) => e.stopPropagation()}>
                                    <CheckFilter
                                      filterData={filterData}
                                      setActiveField={setActiveField}
                                      handlePopOverClose={handlePopOverClose}
                                      field={"city"}
                                      placeholder={"Select Cities"}
                                      value={searchcity}
                                      setValue={setSearchCity}
                                      data={defaultCities?.map((city) => ({
                                        label: city?.name?.trim(),
                                        value: city?.name?.trim(),
                                      }))}
                                      isFilter={isFilterActiveCity}
                                      setIsFilter={setIsFilterActiveCity}
                                      fetchData={handleCities}
                                    />
                                  
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveCity ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "state") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "100px",
                            }}
                          >
                            State
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body onClick={(e) => e.stopPropagation()}>
                                    <CheckFilter
                                      filterData={filterData}
                                      setActiveField={setActiveField}
                                      handlePopOverClose={handlePopOverClose}
                                      field={"state"}
                                      placeholder={"Select State"}
                                      value={searchstate}
                                      setValue={setSearchstate}
                                      data={defaultStates?.map((state) => ({
                                        label: state?.name?.trim(),
                                        value: state?.name?.trim(),
                                      }))}
                                      isFilter={isFilterActiveState}
                                      setIsFilter={setIsFilterActiveState}
                                      fetchData={handleStates}
                                    />
                                   
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveState ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "submission_at") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "180px",
                            }}
                          >
                            Submission date
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body onClick={(e) => e.stopPropagation()}>
                                    <DateRangePicker
                                      placeholder="Select Date Range"
                                      format="dd.MM.yyyy"
                                      character=" – "
                                      menuClassName="reactDatePickerRange"
                                      value={searchSubmissionDate}
                                      placement="auto"
                                      onClean={() => {
                                        if (isFilterActiveDate) {
                                          setActiveField({
                                            name: "created_at",
                                            value: {
                                              startDate: "",
                                              endDate: "",
                                            },
                                          });
                                          handlePopOverClose(false);
                                          filterData(
                                            { startDate: "", endDate: "" },
                                            "created_at"
                                          );
                                          setSearchSubmissionDate([null, null]);
                                        }
                                      }}
                                      onChange={(value) => {
                                        const formattedDates =
                                          value &&
                                          value.map((date) =>
                                            format(date, "yyyy-MM-dd")
                                          );

                                        if (formattedDates) {
                                          const startDate = formattedDates[0];
                                          const endDate = formattedDates[1];
                                          setActiveField({
                                            name: "created_at",
                                            value: { startDate, endDate },
                                          });
                                          filterData(
                                            { startDate, endDate },
                                            "created_at"
                                          );
                                        }
                                        setSearchSubmissionDate(value);
                                      }}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveDate ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "expectedCandidateCTCType") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "250px",
                            }}
                          >
                            Candidate Expected CTC Type
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <div style={{ width: "auto" }}>
                                      <select
                                        className="form-control"
                                        id="searchcandidatectctype"
                                        value={searchExpectedCandidateCTCType}
                                        onChange={(e) => {
                                          setSearchExpectedCandidateCTCType(
                                            e.target.value
                                          );
                                          setActiveField({
                                            name: "expectedCandidateCTCType",
                                            value: e.target.value,
                                          });
                                          filterData(
                                            e.target.value,
                                            "expectedCandidateCTCType"
                                          );
                                        }}
                                        style={{
                                          height: "37px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <option disabled={true} value="">
                                          Status
                                        </option>
                                        <option>Monthly</option>
                                        <option>Annual Salary</option>
                                        <option>Other</option>
                                      </select>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActivEexpectedCtctype ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "expectedCandidateCTC") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Candidate Expected CTC
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchExpectedCandidateCTC}
                                      fieldName={"expectedCandidateCTC"}
                                      setInputState={
                                        setSearchExpectedCandidateCTC
                                      }
                                      setActiveField={setActiveField}
                                      activeFieldName={"expectedCandidateCTC"}
                                      isFilterActiveState={
                                        isFilterActiveExpectedCtc
                                      }
                                      inputPlaceholder={"Search Expected CTC"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveExpectedCtc ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "noticePeriod") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Notice period (in days)
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <CommonExperienceRange
                                      filterData={filterData}
                                      inputState={searchNoticePeriod}
                                      fieldName={"noticePeriod"}
                                      setInputState={setSearchNoticePeriod}
                                      setActiveField={setActiveField}
                                      activeFieldName={"noticePeriod"}
                                      isFilterActiveState={
                                        isFilterActiveNoticePeriod
                                      }
                                      inputPlaceholder={"Search notice period"}
                                      maxValue={90}
                                      setIsFilterActiveState={
                                        setIsFilterActiveNoticePeriod
                                      }
                                      singleVaue={false}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveNoticePeriod ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "phone") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Phone Number
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchPhone}
                                      fieldName={"phone"}
                                      setInputState={setSearchPhone}
                                      setActiveField={setActiveField}
                                      activeFieldName={"phone"}
                                      isFilterActiveState={isFilterActivePhone}
                                      inputPlaceholder={"Search Mobile No."}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActivePhone ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "address") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Address
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchAddress}
                                      fieldName={"address"}
                                      setInputState={setSearchAddress}
                                      setActiveField={setActiveField}
                                      activeFieldName={"address"}
                                      isFilterActiveState={
                                        isFilterActiveAddress
                                      }
                                      inputPlaceholder={"Search Address"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveAddress ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "DOB") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Date of birth
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchDOB}
                                      fieldName={"DOB"}
                                      setInputState={setSearchDOB}
                                      setActiveField={setActiveField}
                                      activeFieldName={"DOB"}
                                      isFilterActiveState={isFilterActiveDOB}
                                      inputPlaceholder={"Search DOB"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveDOB ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "pancardnumber") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Pan card number
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchPanCardNumber}
                                      fieldName={"pancardNumber"}
                                      setInputState={setSearchPanCardNumber}
                                      setActiveField={setActiveField}
                                      activeFieldName={"pancardNumber"}
                                      isFilterActiveState={
                                        isFilterActivePanCardNumber
                                      }
                                      inputPlaceholder={"Search PanCard Number"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActivePanCardNumber ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "aadharcardnumber") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Aadhar card number
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchAadharCardNumber}
                                      fieldName={"aadharcardNumber"}
                                      setInputState={setSearchAadharCardNumber}
                                      setActiveField={setActiveField}
                                      activeFieldName={"aadharcardNumber"}
                                      isFilterActiveState={
                                        isFilterActiveAadharCardNumber
                                      }
                                      inputPlaceholder={
                                        "Search Aadhar Card Number"
                                      }
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveAadharCardNumber ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "designation") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Designation
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchDesignation}
                                      fieldName={"designation"}
                                      setInputState={setSearchDesignation}
                                      setActiveField={setActiveField}
                                      activeFieldName={"designation"}
                                      isFilterActiveState={
                                        isFilterActiveDesignation
                                      }
                                      inputPlaceholder={"Search Designation"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveDesignation ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "experience") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Experience
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <CommonExperienceRange
                                      filterData={filterData}
                                      inputState={searchExperience}
                                      fieldName={"experience"}
                                      setInputState={setSearchExperience}
                                      setActiveField={setActiveField}
                                      activeFieldName={"experience"}
                                      isFilterActiveState={
                                        isFilterActiveExperience
                                      }
                                      setIsFilterActiveState={
                                        setIsFilterActiveExperience
                                      }
                                      inputPlaceholder={"Search Experience"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveExperience ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "gender") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Gender
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <div style={{ width: "auto" }}>
                                      <select
                                        className="form-control"
                                        id="searchGender"
                                        value={searchGender}
                                        onChange={(e) => {
                                          setSearchGender(e.target.value);
                                          filterData(e.target.value, "gender");
                                          setActiveField({
                                            name: "gender",
                                            value: e.target.value,
                                          });
                                        }}
                                        style={{
                                          height: "37px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <option disabled={true} value="">
                                          Gender
                                        </option>
                                        <option value={"Male"}>Male</option>
                                        <option value={"Female"}>Female</option>
                                        <option value={"Other"}>Other</option>
                                      </select>
                                    </div>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveGender ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "workExperience") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Company Name
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchWorkExperience}
                                      fieldName={"workExperience"}
                                      setInputState={setSearchWorkExperience}
                                      setActiveField={setActiveField}
                                      activeFieldName={"workExperience"}
                                      isFilterActiveState={
                                        isFilterActiveWorkExperience
                                      }
                                      inputPlaceholder={"Search Company Name"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveWorkExperience ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "educationDetails") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Education
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchEducationDetails}
                                      fieldName={"educationDetails"}
                                      setInputState={setSearchEducationDetails}
                                      setActiveField={setActiveField}
                                      activeFieldName={"educationDetails"}
                                      isFilterActiveState={
                                        isFilterActiveEducationDetails
                                      }
                                      inputPlaceholder={"Search Education"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveEducationDetails ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "interviewScheduleDate") {
                        return (
                          componentName &&
                          (componentName == "interview" ||
                            componentName == "submission" ||
                            componentName == "all") && (
                            <TableCell
                              style={{
                                color: "#718096",
                                fontWeight: "bold",
                                minWidth: "230px",
                              }}
                            >
                              Interview Schedule Date
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body onClick={(e) => e.stopPropagation()}>
                                      <DateRangePicker
                                        placeholder="Select Date Range"
                                        format="dd.MM.yyyy"
                                        character=" – "
                                        menuClassName="reactDatePickerRange"
                                        value={searchInterviewScheduleDate}
                                        placement="auto"
                                        onClean={() => {
                                          if (
                                            isFilterActiveInterviewScheduleDate
                                          ) {
                                            setActiveField({
                                              name: "interviewScheduleDate",
                                              value: {
                                                startDate: "",
                                                endDate: "",
                                              },
                                            });
                                            handlePopOverClose(false);
                                            filterData(
                                              { startDate: "", endDate: "" },
                                              "interviewScheduleDate"
                                            );
                                            setSearchInterviewScheduleDate([]);
                                          }
                                        }}
                                        onChange={(value) => {
                                          const formattedDates =
                                            value &&
                                            value.map((date) =>
                                              format(date, "yyyy-MM-dd")
                                            );

                                          if (formattedDates) {
                                            const startDate = formattedDates[0];
                                            const endDate = formattedDates[1];
                                            setActiveField({
                                              name: "interviewScheduleDate",
                                              value: { startDate, endDate },
                                            });
                                            filterData(
                                              { startDate, endDate },
                                              "interviewScheduleDate"
                                            );
                                          }
                                          setSearchInterviewScheduleDate(value);
                                        }}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveInterviewScheduleDate ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            </TableCell>
                          )
                        );
                      } else if (key == "interviewScheduleTime") {
                        return (
                          componentName &&
                          (componentName == "interview" ||
                            componentName == "submission" ||
                            componentName == "all") && (
                            <TableCell
                              style={{
                                color: "#718096",
                                fontWeight: "bold",
                                minWidth: "230px",
                              }}
                            >
                              Interview Schedule Time
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                        style={{ width: "auto" }}
                                      >
                                        <InputGroup>
                                          <InputLeftElement
                                            children={
                                              <img
                                                src={Searchicon}
                                                width="18px"
                                                height="18px"
                                                style={{ cursor: "pointer" }}
                                              />
                                            }
                                            onClick={() => {
                                              filterData(
                                                searchInterviewScheduleTime,
                                                "interviewScheduleTime"
                                              );
                                            }}
                                          />
                                          <Input
                                            type="time"
                                            id="searchInterviewScheduleTime"
                                            placeholder="search Interview Schedule Time"
                                            value={searchInterviewScheduleTime}
                                            onChange={(e) => {
                                              setSearchInterviewScheduleTime(
                                                e.target.value
                                              );
                                              setActiveField({
                                                name: "interviewScheduleTime",
                                                value: e.target.value,
                                              });
                                            }}
                                            onKeyDown={(e) => {
                                              if (e.key === "Enter") {
                                                filterData(
                                                  searchInterviewScheduleTime,
                                                  "interviewScheduleTime"
                                                );
                                              }
                                            }}
                                            style={{
                                              height: "37px",
                                              padding: "9px",
                                              paddingLeft: "2.6rem",
                                              color: "#7B9AAA",
                                              fontFamily: "Roboto",
                                              fontWeight: "500",
                                              lineHeight: "19px",
                                              fontSize: "15px",
                                            }}
                                          />
                                        </InputGroup>
                                        {isFilterActiveInterviewScheduleTime && (
                                          <CloseButton
                                            size="sm"
                                            onClick={() => {
                                              filterData(
                                                "",
                                                "interviewScheduleTime"
                                              );
                                              setSearchInterviewScheduleTime(
                                                ""
                                              );
                                              setActiveField({
                                                name: "interviewScheduleTime",
                                                value: "",
                                              });
                                            }}
                                            _focus={{ boxShadow: "none" }}
                                          />
                                        )}
                                      </Box>
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveInterviewScheduleTime ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            </TableCell>
                          )
                        );
                      } else if (key == "interviewScheduleLocation") {
                        return (
                          componentName &&
                          (componentName == "interview" ||
                            componentName == "submission" ||
                            componentName == "all") && (
                            <TableCell
                              style={{
                                color: "#718096",
                                fontWeight: "bold",
                                minWidth: "240px",
                              }}
                            >
                              Interview Schedule Location
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body>
                                      <InputTextSearchBox
                                        filterData={filterData}
                                        inputState={
                                          searchInterviewScheduleLocation
                                        }
                                        fieldName={"interviewScheduleLocation"}
                                        setInputState={
                                          setSearchInterviewScheduleLocation
                                        }
                                        setActiveField={setActiveField}
                                        activeFieldName={
                                          "interviewScheduleLocation"
                                        }
                                        isFilterActiveState={
                                          isFilterActiveInterviewScheduleLocation
                                        }
                                        inputPlaceholder={
                                          "Search Interview Location"
                                        }
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveInterviewScheduleLocation ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            </TableCell>
                          )
                        );
                      } else if (key == "interviewScheduleLink") {
                        return (
                          componentName &&
                          (componentName == "interview" ||
                            componentName == "submission" ||
                            componentName == "all") && (
                            <TableCell
                              style={{
                                color: "#718096",
                                fontWeight: "bold",
                                minWidth: "230px",
                              }}
                            >
                              Interview Schedule Link
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body>
                                      <InputTextSearchBox
                                        filterData={filterData}
                                        inputState={searchInterviewScheduleLink}
                                        fieldName={"interviewScheduleLink"}
                                        setInputState={
                                          setSearchInterviewScheduleLink
                                        }
                                        setActiveField={setActiveField}
                                        activeFieldName={
                                          "interviewScheduleLink"
                                        }
                                        isFilterActiveState={
                                          isFilterActiveInterviewScheduleLink
                                        }
                                        inputPlaceholder={
                                          "Search Interview Link"
                                        }
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveInterviewScheduleLink ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            </TableCell>
                          )
                        );
                      } else if (key == "joinDate") {
                        return (
                          componentName &&
                          (componentName == "join" ||
                            componentName == "all") && (
                            <TableCell
                              style={{
                                color: "#718096",
                                fontWeight: "bold",
                                minWidth: "230px",
                              }}
                            >
                              Joining Date
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover
                                    id="popover-basic"
                                    style={{ maxWidth: "460px" }}
                                  >
                                    <Popover.Body onClick={(e) => e.stopPropagation()}>
                                      <DateRangePicker
                                        placeholder="Select Date Range"
                                        format="dd.MM.yyyy"
                                        character=" – "
                                        menuClassName="reactDatePickerRange"
                                        value={searchJoinDate}
                                        placement="auto"
                                        onClean={() => {
                                          if (isFilterActiveJoinDate) {
                                            setActiveField({
                                              name: "joinDate",
                                              value: {
                                                startDate: "",
                                                endDate: "",
                                              },
                                            });
                                            handlePopOverClose(false);
                                            filterData(
                                              { startDate: "", endDate: "" },
                                              "joinDate"
                                            );
                                            setSearchJoinDate([]);
                                            if (
                                              queryParams.has("sd") ||
                                              queryParams.has("ed")
                                            ) {
                                              queryParams.delete("sd");
                                              queryParams.delete("ed");

                                              // Construct the new URL
                                              const newSearch =
                                                queryParams.toString();
                                              const newPathname = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;

                                              // Update the URL without these parameters
                                              history.push(newPathname, {
                                                replace: true,
                                              });
                                            }
                                          }
                                        }}
                                        onChange={(value) => {
                                          const formattedDates =
                                            value &&
                                            value.map((date) =>
                                              format(date, "yyyy-MM-dd")
                                            );

                                          if (formattedDates) {
                                            const startDate = formattedDates[0];
                                            const endDate = formattedDates[1];
                                            setActiveField({
                                              name: "joinDate",
                                              value: { startDate, endDate },
                                            });
                                            filterData(
                                              { startDate, endDate },
                                              "joinDate"
                                            );
                                          }
                                          setSearchJoinDate(value);
                                        }}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveJoinDate ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            </TableCell>
                          )
                        );
                      } else if (key == "actualCTC") {
                        return (
                          componentName &&
                          (componentName == "join" ||
                            componentName == "all") && (
                            <TableCell
                              style={{
                                color: "#718096",
                                fontWeight: "bold",
                                minWidth: "230px",
                              }}
                            >
                              Actual CTC
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body>
                                      <InputTextSearchBox
                                        filterData={filterData}
                                        inputState={searchActualCTC}
                                        fieldName={"actualCTC"}
                                        setInputState={setSearchActualCTC}
                                        setActiveField={setActiveField}
                                        activeFieldName={"actualCTC"}
                                        isFilterActiveState={
                                          isFilterActiveActualCTC
                                        }
                                        inputPlaceholder={"Search Actual CTC"}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveActualCTC ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            </TableCell>
                          )
                        );
                      } else if (key == "submissionBy") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Submission By
                            {moduleData?.view !== "ownrecords" && (
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover
                                    id="popover-basic"
                                    style={{ maxWidth: "100%" }}
                                  >
                                    <Popover.Body onClick={(e) => e.stopPropagation()}>
                                      <CheckFilter
                                        filterData={filterData}
                                        setActiveField={setActiveField}
                                        handlePopOverClose={handlePopOverClose}
                                        field={"submissionBy"}
                                        placeholder={"Select Users"}
                                        value={searchSubmissionBy}
                                        setValue={setSearchSubmissionBy}
                                        data={userData}
                                        isFilter={isFilterActiveSubmissionBy}
                                        setIsFilter={
                                          setIsFilterActiveSubmissionBy
                                        }
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveSubmissionBy ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            )}
                          </TableCell>
                        );
                      } else if (key == "email") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Email
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body>
                                    <InputTextSearchBox
                                      filterData={filterData}
                                      inputState={searchEmail}
                                      fieldName={"email"}
                                      setInputState={setSearchEmail}
                                      setActiveField={setActiveField}
                                      activeFieldName={"email"}
                                      isFilterActiveState={isFilterActiveEmail}
                                      inputPlaceholder={"Search Email"}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveEmail ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "expectedJoinDate") {
                        return (
                          componentName &&
                          (componentName == "Selected" ||
                            componentName == "all") && (
                            <TableCell
                              style={{
                                color: "#718096",
                                fontWeight: "bold",
                                minWidth: "230px",
                              }}
                            >
                              Expected Joining Date
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body onClick={(e) => e.stopPropagation()}>
                                      <DateRangePicker
                                        placeholder="Select Date Range"
                                        format="dd.MM.yyyy"
                                        character=" – "
                                        menuClassName="reactDatePickerRange"
                                        value={searchExpectedJoinDate}
                                        placement="auto"
                                        onClean={() => {
                                          if (isFilterActiveExpectedJoinDate) {
                                            setActiveField({
                                              name: "expectedJoinDate",
                                              value: {
                                                startDate: "",
                                                endDate: "",
                                              },
                                            });
                                            handlePopOverClose(false);
                                            filterData(
                                              { startDate: "", endDate: "" },
                                              "expectedJoinDate"
                                            );
                                            setSearchExpectedJoinDate([]);
                                          }
                                        }}
                                        onChange={(value) => {
                                          const formattedDates =
                                            value &&
                                            value.map((date) =>
                                              format(date, "yyyy-MM-dd")
                                            );

                                          if (formattedDates) {
                                            const startDate = formattedDates[0];
                                            const endDate = formattedDates[1];
                                            setActiveField({
                                              name: "expectedJoinDate",
                                              value: { startDate, endDate },
                                            });
                                            filterData(
                                              { startDate, endDate },
                                              "expectedJoinDate"
                                            );
                                          }
                                          setSearchExpectedJoinDate(value);
                                        }}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveExpectedJoinDate ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            </TableCell>
                          )
                        );
                      } else if (key == "offerCTC") {
                        return (
                          componentName &&
                          (componentName == "Selected" ||
                            componentName == "all") && (
                            <TableCell
                              style={{
                                color: "#718096",
                                fontWeight: "bold",
                                minWidth: "230px",
                              }}
                            >
                              Offer CTC
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body>
                                      <InputTextSearchBox
                                        filterData={filterData}
                                        inputState={searchOfferCTC}
                                        fieldName={"offerCTC"}
                                        setInputState={setSearchOfferCTC}
                                        setActiveField={setActiveField}
                                        activeFieldName={"offerCTC"}
                                        isFilterActiveState={
                                          isFilterActiveOfferCTC
                                        }
                                        inputPlaceholder={"Search Offer CTC"}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveOfferCTC ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            </TableCell>
                          )
                        );
                      } else if (key == "updated_at") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "180px",
                            }}
                          >
                            Updated date
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Body onClick={(e) => e.stopPropagation()}>
                                    <DateRangePicker
                                      placeholder="Select Date Range"
                                      format="dd.MM.yyyy"
                                      character=" – "
                                      menuClassName="reactDatePickerRange"
                                      value={searchSubmissionUpdateDate}
                                      placement="auto"
                                      onClean={() => {
                                        if (isFilterActiveUpdatedDate) {
                                          setActiveField({
                                            name: "updated_at",
                                            value: {
                                              startDate: "",
                                              endDate: "",
                                            },
                                          });
                                          handlePopOverClose(false);
                                          filterData(
                                            { startDate: "", endDate: "" },
                                            "updated_at"
                                          );
                                          setSearchSubmissionUpdateDate([]);
                                          if (
                                            queryParams.has("sd") ||
                                            queryParams.has("ed")
                                          ) {
                                            queryParams.delete("sd");
                                            queryParams.delete("ed");

                                            // Construct the new URL
                                            const newSearch =
                                              queryParams.toString();
                                            const newPathname = `${location.pathname}${newSearch ? `?${newSearch}` : ""}`;

                                            // Update the URL without these parameters
                                            history.push(newPathname, {
                                              replace: true,
                                            });
                                          }
                                        }
                                      }}
                                      onChange={(value) => {
                                        const formattedDates =
                                          value &&
                                          value.map((date) =>
                                            format(date, "yyyy-MM-dd")
                                          );

                                        if (formattedDates) {
                                          const startDate = formattedDates[0];
                                          const endDate = formattedDates[1];
                                          setActiveField({
                                            name: "updated_at",
                                            value: { startDate, endDate },
                                          });
                                          filterData(
                                            { startDate, endDate },
                                            "updated_at"
                                          );
                                        }
                                        setSearchSubmissionUpdateDate(value);
                                      }}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveUpdatedDate ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "updatedBy") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Updated By
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body onClick={(e) => e.stopPropagation()}>
                                    <CheckFilter
                                      filterData={filterData}
                                      setActiveField={setActiveField}
                                      handlePopOverClose={handlePopOverClose}
                                      field={"updatedBy"}
                                      placeholder={"Select Users"}
                                      value={searchUpdatedBy}
                                      setValue={setSearchUpdatedBy}
                                      data={userData}
                                      isFilter={isFilterActiveUpdatedBy}
                                      setIsFilter={setIsFilterActiveUpdatedBy}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveUpdatedBy ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "industry") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "180px",
                              width: "fit-content",
                            }}
                          >
                            Industry
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body onClick={(e) => e.stopPropagation()}>
                                    <CheckFilter
                                      filterData={filterData}
                                      setActiveField={setActiveField}
                                      handlePopOverClose={handlePopOverClose}
                                      field={"industry"}
                                      placeholder={"Select Industry"}
                                      value={searchIndustry}
                                      setValue={setSearchIndustry}
                                      data={showAllIndustries}
                                      isFilter={isFilterActiveIndustry}
                                      setIsFilter={setIsFilterActiveIndustry}
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveIndustry ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      } else if (key == "interviewRescheduleDate") {
                        return (
                          componentName &&
                          (componentName == "interview" ||
                            componentName == "submission" ||
                            componentName == "all") && (
                            <TableCell
                              style={{
                                color: "#718096",
                                fontWeight: "bold",
                                minWidth: "230px",
                              }}
                            >
                              Interview Reschedule Date
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body onClick={(e) => e.stopPropagation()}>
                                      <DateRangePicker
                                        placeholder="Select Date Range"
                                        format="dd.MM.yyyy"
                                        character=" – "
                                        menuClassName="reactDatePickerRange"
                                        value={searchInterviewRescheduleDate}
                                        placement="auto"
                                        onClean={() => {
                                          if (
                                            isFilterActiveInterviewRescheduleDate
                                          ) {
                                            setActiveField({
                                              name: "interviewRescheduleDate",
                                              value: {
                                                startDate: "",
                                                endDate: "",
                                              },
                                            });
                                            handlePopOverClose(false);
                                            filterData(
                                              { startDate: "", endDate: "" },
                                              "interviewRescheduleDate"
                                            );
                                            setSearchInterviewRescheduleDate(
                                              []
                                            );
                                          }
                                        }}
                                        onChange={(value) => {
                                          const formattedDates =
                                            value &&
                                            value.map((date) =>
                                              format(date, "yyyy-MM-dd")
                                            );

                                          if (formattedDates) {
                                            const startDate = formattedDates[0];
                                            const endDate = formattedDates[1];
                                            setActiveField({
                                              name: "interviewRescheduleDate",
                                              value: { startDate, endDate },
                                            });
                                            filterData(
                                              { startDate, endDate },
                                              "interviewRescheduleDate"
                                            );
                                          }
                                          setSearchInterviewRescheduleDate(
                                            value
                                          );
                                        }}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveInterviewRescheduleDate ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            </TableCell>
                          )
                        );
                      } else if (key == "testRescheduleDate") {
                        return (
                          componentName &&
                          (componentName == "interview" ||
                            componentName == "submission" ||
                            componentName == "all") && (
                            <TableCell
                              style={{
                                color: "#718096",
                                fontWeight: "bold",
                                minWidth: "230px",
                              }}
                            >
                              Test Reschedule Date
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body onClick={(e) => e.stopPropagation()}>
                                      <DateRangePicker
                                        placeholder="Select Date Range"
                                        format="dd.MM.yyyy"
                                        character=" – "
                                        menuClassName="reactDatePickerRange"
                                        value={searchTestRescheduleDate}
                                        placement="auto"
                                        onClean={() => {
                                          if (
                                            isFilterActiveTestRescheduleDate
                                          ) {
                                            setActiveField({
                                              name: "testRescheduleDate",
                                              value: {
                                                startDate: "",
                                                endDate: "",
                                              },
                                            });
                                            handlePopOverClose(false);
                                            filterData(
                                              { startDate: "", endDate: "" },
                                              "testRescheduleDate"
                                            );
                                            setSearchTestRescheduleDate([]);
                                          }
                                        }}
                                        onChange={(value) => {
                                          const formattedDates =
                                            value &&
                                            value.map((date) =>
                                              format(date, "yyyy-MM-dd")
                                            );

                                          if (formattedDates) {
                                            const startDate = formattedDates[0];
                                            const endDate = formattedDates[1];
                                            setActiveField({
                                              name: "testRescheduleDate",
                                              value: { startDate, endDate },
                                            });
                                            filterData(
                                              { startDate, endDate },
                                              "testRescheduleDate"
                                            );
                                          }
                                          setSearchTestRescheduleDate(value);
                                        }}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveTestRescheduleDate ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            </TableCell>
                          )
                        );
                      } else if (key == "backoutDate") {
                        return (
                          componentName &&
                          (componentName == "backout" ||
                            componentName == "all") && (
                            <TableCell
                              style={{
                                color: "#718096",
                                fontWeight: "bold",
                                minWidth: "230px",
                              }}
                            >
                              Backout Date
                              <OverlayTrigger
                                {...popOverConfig(key)}
                                overlay={
                                  <Popover id="popover-basic">
                                    <Popover.Body onClick={(e) => e.stopPropagation()}>
                                      <DateRangePicker
                                        placeholder="Select Date Range"
                                        format="dd.MM.yyyy"
                                        character=" – "
                                        menuClassName="reactDatePickerRange"
                                        value={searchBackoutDate}
                                        placement="auto"
                                        onClean={() => {
                                          if (isFilterActiveBackoutDate) {
                                            setActiveField({
                                              name: "backoutDate",
                                              value: {
                                                startDate: "",
                                                endDate: "",
                                              },
                                            });
                                            handlePopOverClose(false);
                                            filterData(
                                              { startDate: "", endDate: "" },
                                              "backoutDate"
                                            );
                                            setSearchBackoutDate([]);
                                          }
                                        }}
                                        onChange={(value) => {
                                          const formattedDates =
                                            value &&
                                            value.map((date) =>
                                              format(date, "yyyy-MM-dd")
                                            );
                                          if (formattedDates) {
                                            const startDate = formattedDates[0];
                                            const endDate = formattedDates[1];

                                            setActiveField({
                                              name: "backoutDate",
                                              value: { startDate, endDate },
                                            });
                                            filterData(
                                              { startDate, endDate },
                                              "backoutDate"
                                            );
                                          }
                                          setSearchBackoutDate(value);
                                        }}
                                      />
                                    </Popover.Body>
                                  </Popover>
                                }
                              >
                                {isFilterActiveBackoutDate ? (
                                  <FilterAltIcon
                                    style={{ cursor: "pointer" }}
                                  />
                                ) : (
                                  <FilterListIcon
                                    style={{
                                      cursor: "pointer",
                                      fontSize: "20px",
                                    }}
                                  />
                                )}
                              </OverlayTrigger>
                            </TableCell>
                          )
                        );
                      } else if (key == "source") {
                        return (
                          <TableCell
                            style={{
                              color: "#718096",
                              fontWeight: "bold",
                              minWidth: "230px",
                            }}
                          >
                            Source
                            <OverlayTrigger
                              {...popOverConfig(key)}
                              overlay={
                                <Popover
                                  id="popover-basic"
                                  style={{ maxWidth: "100%" }}
                                >
                                  <Popover.Body onClick={(e) => e.stopPropagation()}>
                                    <CheckFilter
                                      filterData={filterData}
                                      setActiveField={setActiveField}
                                      handlePopOverClose={handlePopOverClose}
                                      field={"source"}
                                      placeholder={"Select Sources"}
                                      value={searchSourceData}
                                      setValue={setSearchSourceData}
                                      data={sourceData}
                                      isFilter={isFilterActiveSourceSearch}
                                      setIsFilter={
                                        setIsFilterActiveSourceSearch
                                      }
                                    />
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              {isFilterActiveSourceSearch ? (
                                <FilterAltIcon style={{ cursor: "pointer" }} />
                              ) : (
                                <FilterListIcon
                                  style={{
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                />
                              )}
                            </OverlayTrigger>
                          </TableCell>
                        );
                      }
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <>
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Loadar />
                      </TableCell>
                    </TableRow>
                  </>
                ) : (
                  <>
                    {!Array.isArray(displaysubmissions) ||
                    (Array.isArray(displaysubmissions) &&
                      displaysubmissions?.length == 0) ? (
                      <>
                        <TableRow>
                          <TableCell colSpan={11}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <img
                                src={NotFound}
                                alt="NotFound"
                                style={{ width: "16rem" }}
                              />
                              <p>No Records Found! Please try again....</p>
                            </div>
                          </TableCell>
                        </TableRow>
                      </>
                    ) : (
                      <>
                        {Array.isArray(displaysubmissions) &&
                          displaysubmissions?.length !== 0 &&
                          displaysubmissions?.map((value, index) => (
                            <TableRow key={value._id}>
                              <TableCell
                                style={{
                                  color: "#718096",
                                  fontWeight: "bold",
                                }}
                              >
                                <Box className="table_menu">
                                  <Checkbox
                                    size="md"
                                    value={value?._id}
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        SetIschecked((oldarr) => [
                                          ...oldarr,
                                          {
                                            id: e.target?.value,
                                            email: value?.candidateId?.email,
                                          },
                                        ]);
                                      } else {
                                        SetIschecked((olarr) => {
                                          return olarr.filter(
                                            (value) =>
                                              e.target?.value !== value?.id
                                          );
                                        });
                                      }
                                    }}
                                    isChecked={checked
                                      ?.map((val) => val?.id)
                                      .includes(value?._id)}
                                  />
                                  <Menu>
                                    <MenuButton>
                                      <MoreVertIcon
                                        style={{
                                          cursor: "pointer",
                                          fontSize: "20px",
                                        }}
                                      />
                                    </MenuButton>
                                    <MenuList
                                      style={{
                                        position: "absolute",
                                        top: "12px",
                                        zIndex: "100",
                                      }}
                                    >
                                      <MenuItem
                                        //  isDisabled={
                                        //   (moduleData &&
                                        //     moduleData.edit === "none") || // If edit is "none", disable
                                        //   (moduleData?.edit === "ownrecords" &&
                                        //     String(value?.submissionById) !==
                                        //       String(userId)) || // If edit is "ownrecords" and user is not the owner, disable
                                        //   (moduleData?.edit ===
                                        //     "ownandteamrecords" && // If edit is "ownandteamrecords"
                                        //     !(
                                        //       (
                                        //         String(
                                        //           value?.submissionById
                                        //         ) === String(userId) || // Check if user is the owner
                                        //         (Array.isArray(userTeamData) &&
                                        //           userTeamData.includes(
                                        //             String(
                                        //               value?.submissionById
                                        //             )
                                        //           ))
                                        //       ) // Check if submission is from a team member
                                        //     ))
                                        // }
                                        isDisabled={
                                          (moduleData &&
                                            moduleData.edit === "none") ||
                                          (moduleData?.edit === "ownrecords" &&
                                            String(value?.submissionById) !==
                                              String(userId)) ||
                                          (moduleData?.edit ===
                                            "ownandteamrecords" &&
                                            !(
                                              (
                                                String(
                                                  value?.submissionById
                                                ) === String(userId) || // Check if user is the owner
                                                (Array.isArray(userTeamData) &&
                                                  userTeamData.includes(
                                                    String(
                                                      value?.submissionById
                                                    )
                                                  ))
                                              ) // Check if submission is from a team member
                                            ))
                                        }
                                        icon={
                                          <ModeEditIcon
                                            style={{ fontSize: "20px" }}
                                          />
                                        }
                                        onClick={() => {
                                          Setcandidatename &&
                                            Setcandidatename({
                                              candiname:
                                                value?.candidateId?.firstName +
                                                " " +
                                                value?.candidateId?.lastName,
                                            });
                                          handleEditShow();

                                          setSubmission(value);
                                          setId(value?._id);
                                        }}
                                      >
                                        Edit
                                      </MenuItem>
                                      <MenuItem
                                        isDisabled={
                                          (moduleData &&
                                            moduleData.delete === "none") ||
                                          (moduleData?.delete ===
                                            "ownrecords" &&
                                            String(value?.submissionById) !==
                                              String(userId)) ||
                                          (moduleData?.delete ===
                                            "ownandteamrecords" &&
                                            !(
                                              (
                                                String(
                                                  value?.submissionById
                                                ) === String(userId) || // Check if user is the owner
                                                (Array.isArray(userTeamData) &&
                                                  userTeamData.includes(
                                                    String(
                                                      value?.submissionById
                                                    )
                                                  ))
                                              ) // Check if submission is from a team member
                                            ))
                                        }
                                        icon={
                                          <DeleteIcon
                                            style={{ fontSize: "20px" }}
                                          />
                                        }
                                        onClick={() => {
                                          handleDeleteSubmissionShow();
                                          setSubmission(value);
                                          setSubmissionId &&
                                            setSubmissionId(value?._id);
                                          setId(value._id);
                                        }}
                                      >
                                        Delete
                                      </MenuItem>
                                      <MenuItem
                                        icon={
                                          <VisibilityIcon
                                            style={{ fontSize: "20px" }}
                                          />
                                        }
                                        onClick={() => {
                                          setIsOpenPreview(true);
                                          const activityOn = `Preview candidate ${value?.candidateId?.firstName} ${value?.candidateId?.lastName}`;
                                          const activityDo = "Preview";
                                          const component = "submission";
                                          const reData =  {
                                            name:`${value?.candidateId?.firstName} ${value?.candidateId?.lastName}`,
                                            id:value?.candidateId?._id,
                                            action: "candidateView",
                                            type: "candidate",
                                            note:null,
                                          }
                                          userActivity(
                                            candidate_ownerName,
                                            userId,
                                            component,
                                            activityDo,
                                            activityOn,
                                            userRole,
                                            reData,
                                          );
                                          setPerviewCandidateId(
                                            value?.candidateId?._id
                                          );
                                        }}
                                      >
                                        Preview Candidate
                                      </MenuItem>
                                    </MenuList>
                                  </Menu>
                                  <div style={{ position: "relative" }}>
                                    {value?.noteCount !== 0 && (
                                      <div
                                        style={{
                                          color: "white",
                                          height: "auto",
                                          width: "auto",
                                          textAlign: "center",
                                          position: "absolute",
                                          marginLeft: "11px",
                                          marginTop: "-10px",
                                          borderRadius: "50px",
                                          fontSize: "10px",
                                          padding: "1px 5px",
                                        }}
                                        className="bg_color"
                                      >
                                        <span>{value?.noteCount}</span>
                                      </div>
                                    )}
                                    <Tooltip
                                      hasArrow
                                      label="Notes"
                                      bg="gray.300"
                                      color="black"
                                    >
                                      <button
                                        className="note_btn"
                                        onClick={() => {
                                          handleCandidateNoteShow &&
                                            handleCandidateNoteShow();
                                          setNote({
                                            ...note,
                                            candidateId:
                                              value?.candidateId?._id,
                                            clientId: value?.clientId?._id,
                                          });
                                          getCandidatNotes &&
                                            getCandidatNotes(
                                              value?.candidateId?._id
                                            );
                                        }}
                                      >
                                        N
                                      </button>
                                    </Tooltip>
                                  </div>
                                  <Tooltip
                                    hasArrow
                                    label="Resume Preview"
                                    bg="gray.300"
                                    color="black"
                                  >
                                    <DescriptionIcon
                                      className="list_view"
                                      onClick={() => {
                                        SubmissionPreview(
                                          value._id,
                                          value.filePath
                                        );
                                        const activityOn = `View resume of candidate ${value?.candidateId?.firstName} ${value?.candidateId?.lastName}`;
                                        const activityDo = `View resume`;
                                        const component = "candidate";
                                        const reData =  {
                                          name:`${value?.candidateId?.firstName} ${value?.candidateId?.lastName}`,
                                          id:value?.candidateId?._id,
                                          action: "resumeView",
                                          type: "candidate",
                                          note: null,
                                        }
                                        userActivity(
                                          candidate_ownerName,
                                          userId,
                                          component,
                                          activityDo,
                                          activityOn,
                                          userRole,
                                          reData
                                        );
                                        handleActivitySubmit &&
                                          handleActivitySubmit(
                                            value?._id,
                                            `View resume of ${value?.candidateId?.firstName} ${value?.candidateId?.lastName}`,
                                            new Date().getTime(),
                                            candidate_ownerName,
                                            userId
                                          );
                                        handleSubmissionPreviewShow(
                                          setSubmission(value),
                                          setId(value._id)
                                        );
                                      }}
                                    />
                                  </Tooltip>
                                </Box>
                              </TableCell>
                              <TableCell
                                style={{ fontWeight: "bold" }}
                                className="nowrap_content"
                              >
                                <span>
                                  <Link
                                    to={`/details/candidateview/${value?.candidateId?._id}`}
                                    target="_blank"
                                  >
                                    {!value?.uniqueSubmissionId
                                      ? "N/A"
                                      : `TKS-${value?.uniqueSubmissionId}`}
                                  </Link>
                                </span>
                              </TableCell>

                              <TableCell className="nowrap_content">
                                <span>
                                  <Link
                                    to={
                                      `/details/candidateview/${value?.candidateId?._id}`
                                      // (moduleData &&
                                      //   moduleData.edit === "none") ||
                                      // (moduleData?.edit === "ownrecords" &&
                                      //   String(value?.candidateId?._id) !==
                                      //     String(userId)) ||
                                      // (moduleData?.edit ===
                                      //   "ownandteamrecords" &&
                                      //     !(
                                      //       String(value?.candidateId?._id) === String(userId) || // Check if user is the owner
                                      //       (Array.isArray(userTeamData) && userTeamData.includes(String(value?.candidateId?._id))) // Check if submission is from a team member
                                      //     )
                                      //   )?`/details/candidateview/${value?.candidateId?._id}`: "#"
                                      // (moduleData &&
                                      //   moduleData.view == "none") ||
                                      // (moduleData?.view == "ownrecords" &&
                                      //   value?.candidateId?._id !== userId)
                                      //   ? "#"
                                      //   : `/details/candidateview/${value?.candidateId?._id}`
                                    }
                                    target="_blank"
                                  >
                                    {!value?.candidateId?.firstName
                                      ? "N/A"
                                      : value?.candidateId?.firstName}
                                  </Link>
                                </span>
                              </TableCell>
                              {submheaderOrders &&
                                submheaderOrders?.map((key) => {
                                  if (key == "lastName") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.lastName
                                          ? "N/A"
                                          : value?.candidateId?.lastName}
                                      </TableCell>
                                    );
                                  } else if (key == "jobtitle") {
                                    const truncatedText =
                                      value?.jobId?.jobtitle &&
                                      value?.jobId?.jobtitle?.length > 40
                                        ? `${value?.jobId?.jobtitle.slice(0, 40)}...`
                                        : value?.jobId?.jobtitle;
                                    return (
                                      <TableCell className="nowrap_content">
                                        <Link
                                          to={`/details/jobpostview/${value?.jobId?._id}`}
                                          target="_blank"
                                        >
                                          <Tooltip
                                            label={value?.jobId?.jobtitle}
                                          >
                                            {!value?.jobId?.jobtitle
                                              ? "N/A"
                                              : truncatedText}
                                          </Tooltip>
                                        </Link>
                                      </TableCell>
                                    );
                                  } else if (key == "status") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.status ? "N/A" : value?.status}{" "}
                                        {(moduleData &&
                                          moduleData.edit === "none") ||
                                          (moduleData?.edit === "ownrecords" &&
                                            String(value?.submissionById) !==
                                              String(userId)) ||
                                          (moduleData?.edit ===
                                            "ownandteamrecords" &&
                                          !(
                                            (
                                              String(value?.submissionById) ===
                                                String(userId) || // Check if user is the owner
                                              (Array.isArray(userTeamData) &&
                                                userTeamData.includes(
                                                  String(value?.submissionById)
                                                ))
                                            ) // Check if submission is from a team member
                                          ) ? null : (
                                            <Tooltip
                                              hasArrow
                                              label="Change Status"
                                              bg="gray.300"
                                              color="black"
                                            >
                                              <EditIcon
                                                style={{
                                                  fontSize: "15px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  handleEditSubmissionStatusShow();
                                                  setSubmission({
                                                    ...value,
                                                    isStatusChange: true,
                                                  }),
                                                    setSubmissionId &&
                                                      setSubmissionId(
                                                        value?._id
                                                      );
                                                  setId(value._id);
                                                  setChangeStatus &&
                                                    setChangeStatus({
                                                      ...changeStatus,
                                                      candidateId:
                                                        value?.candidateId?._id,
                                                    });
                                                  setOldJobId &&
                                                    setOldJobId(
                                                      value?.jobId?._id
                                                    );
                                                }}
                                              />
                                            </Tooltip>
                                          ))}
                                        {/* {(moduleData &&
                                          moduleData?.edit === "none") ||
                                        (moduleData?.edit === "ownrecords" &&
                                          value?.candidateId?._id !==
                                            userId) ?  null : (
                                          <Tooltip
                                            hasArrow
                                            label="Change Status"
                                            bg="gray.300"
                                            color="black"
                                          >
                                            <EditIcon
                                              style={{
                                                fontSize: "15px",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                handleEditSubmissionStatusShow(
                                                  setSubmission({
                                                    ...value,
                                                    isStatusChange: true,
                                                  }),
                                                  setId(value._id)
                                                );
                                                setChangeStatus &&
                                                  setChangeStatus({
                                                    ...changeStatus,
                                                    candidateId:
                                                      value?.candidateId?._id,
                                                  });
                                                setOldJobId &&
                                                  setOldJobId(
                                                    value?.jobId?._id
                                                  );
                                              }}
                                            />
                                          </Tooltip>
                                        )} */}
                                      </TableCell>
                                    );
                                  } else if (key == "client") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        <Link
                                          to={`/details/accountview/${value?.clientId?._id}`}
                                          target="_blank"
                                        >
                                          {!value?.clientId?.accountName
                                            ? "N/A"
                                            : value?.clientId?.accountName}
                                        </Link>
                                      </TableCell>
                                    );
                                  } else if (key == "clientcontactname") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        <Link
                                          to={`/details/contactview/${value?.contactPerson?._id}`}
                                          target="_blank"
                                        >
                                          {!value?.contactPerson
                                            ?.accountOwnerName
                                            ? !value?.submitterName
                                              ? "N/A"
                                              : value?.submitterName
                                            : value?.contactPerson
                                                ?.accountOwnerName}
                                        </Link>
                                      </TableCell>
                                    );
                                  } else if (key == "owner_name") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {value?.candidate_ownerName &&
                                        typeof value.candidate_ownerName ===
                                          "object" &&
                                        value.candidate_ownerName.firstName
                                          ? `${value?.candidate_ownerName?.firstName} ${value?.candidate_ownerName?.lastName}`
                                          : "N/A"}
                                      </TableCell>
                                    );
                                  } else if (key == "currentCandidateCTCType") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.currentCandidateCTCType
                                          ? "N/A"
                                          : value?.currentCandidateCTCType}
                                      </TableCell>
                                    );
                                  } else if (key == "currentCandidateCTC") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.currentCandidateCTC
                                          ? "N/A"
                                          : value?.currentCandidateCTC}
                                      </TableCell>
                                    );
                                  } else if (key == "city") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.city
                                          ? "N/A"
                                          : value?.candidateId?.city}
                                      </TableCell>
                                    );
                                  } else if (key == "state") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.state
                                          ? "N/A"
                                          : value?.candidateId?.state}
                                      </TableCell>
                                    );
                                  } else if (key == "submission_at") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.createdAt
                                          ? "N/A"
                                          : new Date(
                                              value?.createdAt
                                            ).toLocaleDateString("en-GB", {
                                              day: "2-digit",
                                              month: "2-digit",
                                              year: "numeric",
                                            })}
                                      </TableCell>
                                    );
                                  } else if (
                                    key == "expectedCandidateCTCType"
                                  ) {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.expectedCandidateCTCType
                                          ? "N/A"
                                          : value?.expectedCandidateCTCType}
                                      </TableCell>
                                    );
                                  } else if (key == "expectedCandidateCTC") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.expectedCandidateCTC
                                          ? "N/A"
                                          : value?.expectedCandidateCTC}
                                      </TableCell>
                                    );
                                  } else if (key == "noticePeriod") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {isInvalidExperience(
                                          value?.noticePeriod
                                        )
                                          ? "N/A"
                                          : value?.noticePeriod}
                                      </TableCell>
                                    );
                                  } else if (key == "phone") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.phone
                                          ? "N/A"
                                          : value?.candidateId?.phone}
                                      </TableCell>
                                    );
                                  } else if (key == "address") {
                                    const truncatedText =
                                      value?.candidateId?.address &&
                                      value.candidateId?.address?.length > 20
                                        ? `${value.candidateId?.address.slice(0, 20)}...`
                                        : value.candidateId?.address;
                                    return (
                                      <TableCell className="nowrap_content">
                                        <Tooltip
                                          label={value?.candidateId?.address}
                                        >
                                          {!value?.candidateId?.address
                                            ? "N/A"
                                            : truncatedText}
                                        </Tooltip>
                                      </TableCell>
                                    );
                                  } else if (key == "DOB") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.dateofBirth
                                          ? "N/A"
                                          : value?.candidateId?.dateofBirth}
                                      </TableCell>
                                    );
                                  } else if (key == "pancardnumber") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.PancardNumber
                                          ? "N/A"
                                          : value?.candidateId?.PancardNumber}
                                      </TableCell>
                                    );
                                  } else if (key == "aadharcardnumber") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.AadharcardNumber
                                          ? "N/A"
                                          : value?.candidateId
                                              ?.AadharcardNumber}
                                      </TableCell>
                                    );
                                  } else if (key == "experience") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {isInvalidExperience(
                                          value?.relevantExperiance
                                        )
                                          ? "N/A"
                                          : value?.relevantExperiance}
                                      </TableCell>
                                    );
                                  } else if (key == "designation") {
                                    const truncatedText =
                                      value?.candidateId?.designation &&
                                      value?.candidateId?.designation?.length >
                                        40
                                        ? `${value?.candidateId?.designation.slice(0, 40)}...`
                                        : value?.candidateId?.designation;
                                    return (
                                      <TableCell className="nowrap_content">
                                        <Tooltip
                                          label={
                                            value?.candidateId?.designation
                                              ?.length > 40 &&
                                            value?.candidateId?.designation
                                          }
                                        >
                                          {!value?.candidateId?.designation
                                            ? "N/A"
                                            : truncatedText}
                                        </Tooltip>
                                      </TableCell>
                                    );
                                  } else if (key == "gender") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.gender
                                          ? "N/A"
                                          : value?.candidateId?.gender}
                                      </TableCell>
                                    );
                                  } else if (key === "educationDetails") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId
                                          ?.educationDetails ||
                                        value?.candidateId?.educationDetails
                                          .length === 0
                                          ? "N/A"
                                          : value.candidateId?.educationDetails
                                              .map((edu) => ({
                                                ...edu,
                                                startDate: edu.startDate
                                                  ? new Date(
                                                      edu.startDate.replace(
                                                        /(\d{2})\/(\d{2})\/(\d{4})/,
                                                        "$2/$1/$3"
                                                      )
                                                    )
                                                  : null,
                                                endDate: edu.endDate
                                                  ? new Date(
                                                      edu.endDate.replace(
                                                        /(\d{2})\/(\d{2})\/(\d{4})/,
                                                        "$2/$1/$3"
                                                      )
                                                    )
                                                  : null,
                                              }))
                                              .sort(
                                                (a, b) =>
                                                  (b.endDate || new Date(0)) -
                                                  (a.endDate || new Date(0))
                                              )
                                              .slice(0, 1)
                                              .map((latestDegree, index) => (
                                                <span key={index}>
                                                  {latestDegree?.degree
                                                    ? latestDegree?.degree
                                                    : "N/A"}
                                                </span>
                                              ))}
                                      </TableCell>
                                    );
                                  } else if (key === "workExperience") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId ||
                                        value?.candidateId?.workExperience
                                          .length === 0
                                          ? "N/A"
                                          : value?.candidateId?.workExperience
                                              .map((exp) => ({
                                                ...exp,
                                                startDate: exp.startDate
                                                  ? new Date(exp.startDate)
                                                  : null,
                                                endDate: exp.endDate
                                                  ? exp.endDate.toLowerCase() ===
                                                    "present"
                                                    ? new Date()
                                                    : new Date(exp.endDate)
                                                  : null,
                                              }))
                                              .sort((a, b) => {
                                                if (
                                                  a.endDate &&
                                                  typeof a.endDate ===
                                                    "string" &&
                                                  a.endDate.toLowerCase() ===
                                                    "present"
                                                ) {
                                                  return -1;
                                                } else if (
                                                  b.endDate &&
                                                  typeof b.endDate ===
                                                    "string" &&
                                                  b.endDate.toLowerCase() ===
                                                    "present"
                                                ) {
                                                  return 1;
                                                }
                                                return (
                                                  (b.endDate || 0) -
                                                  (a.endDate || 0)
                                                );
                                              })
                                              .slice(0, 1)
                                              .map(
                                                (latestExperience, index) => (
                                                  <span key={index}>
                                                    {latestExperience?.companyname
                                                      ? latestExperience?.companyname
                                                      : "N/A"}
                                                  </span>
                                                )
                                              )}
                                      </TableCell>
                                    );
                                  } else if (key == "interviewScheduleDate") {
                                    return (
                                      componentName &&
                                      (componentName == "interview" ||
                                        componentName == "submission" ||
                                        componentName == "all") && (
                                        <TableCell className="nowrap_content">
                                          {!value?.interviewScheduleDate
                                            ? "N/A"
                                            : new Date(
                                                value?.interviewScheduleDate
                                              ).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                              })}
                                        </TableCell>
                                      )
                                    );
                                  } else if (key == "interviewScheduleTime") {
                                    return (
                                      componentName &&
                                      (componentName == "interview" ||
                                        componentName == "submission" ||
                                        componentName == "all") && (
                                        <TableCell className="nowrap_content">
                                          {!value?.interviewScheduleTime
                                            ? "N/A"
                                            : value?.interviewScheduleTime}
                                        </TableCell>
                                      )
                                    );
                                  } else if (
                                    key == "interviewScheduleLocation"
                                  ) {
                                    const truncatedText =
                                      value?.interviewScheduleLocation &&
                                      value?.interviewScheduleLocation?.length >
                                        30
                                        ? `${value?.interviewScheduleLocation.slice(0, 30)}...`
                                        : value?.interviewScheduleLocation;
                                    return (
                                      componentName &&
                                      (componentName == "interview" ||
                                        componentName == "submission" ||
                                        componentName == "all") && (
                                        <TableCell className="nowrap_content">
                                          <Tooltip
                                            label={
                                              value?.interviewScheduleLocation
                                            }
                                          >
                                            {!value?.interviewScheduleLocation
                                              ? "N/A"
                                              : truncatedText}
                                          </Tooltip>
                                        </TableCell>
                                      )
                                    );
                                  } else if (key == "interviewScheduleLink") {
                                    const truncatedText =
                                      value?.interviewScheduleLink &&
                                      value?.interviewScheduleLink?.length > 20
                                        ? `${value?.interviewScheduleLink.slice(0, 20)}...`
                                        : value?.interviewScheduleLink;
                                    return (
                                      componentName &&
                                      (componentName == "interview" ||
                                        componentName == "submission" ||
                                        componentName == "all") && (
                                        <TableCell className="nowrap_content">
                                          <Tooltip
                                            label={value?.interviewScheduleLink}
                                          >
                                            {!value?.interviewScheduleLink
                                              ? "N/A"
                                              : truncatedText}
                                          </Tooltip>
                                        </TableCell>
                                      )
                                    );
                                  } else if (key == "joinDate") {
                                    return (
                                      componentName &&
                                      (componentName == "join" ||
                                        componentName == "all") && (
                                        <TableCell className="nowrap_content">
                                          {!value?.joinDate
                                            ? "N/A"
                                            : !moment(
                                                  value?.joinDate,
                                                  "DD/MM/YYYY",
                                                  true
                                                ).isValid()
                                              ? moment(value?.joinDate).format(
                                                  "DD/MM/YYYY"
                                                )
                                              : value?.joinDate}
                                        </TableCell>
                                      )
                                    );
                                  } else if (key == "actualCTC") {
                                    return (
                                      componentName &&
                                      (componentName == "join" ||
                                        componentName == "all") && (
                                        <TableCell className="nowrap_content">
                                          {!value?.actualCTC
                                            ? "N/A"
                                            : value?.actualCTC}
                                        </TableCell>
                                      )
                                    );
                                  } else if (key == "expectedJoinDate") {
                                    return (
                                      componentName &&
                                      (componentName == "Selected" ||
                                        componentName == "all") && (
                                        <TableCell className="nowrap_content">
                                          {!value?.expectedJoinDate
                                            ? "N/A"
                                            : new Date(
                                                value?.expectedJoinDate
                                              ).toLocaleDateString("en-GB", {
                                                day: "2-digit",
                                                month: "2-digit",
                                                year: "numeric",
                                              })}
                                        </TableCell>
                                      )
                                    );
                                  } else if (key == "offerCTC") {
                                    return (
                                      componentName &&
                                      (componentName == "Selected" ||
                                        componentName == "all") && (
                                        <TableCell className="nowrap_content">
                                          {!value?.offerCTC
                                            ? "N/A"
                                            : value?.offerCTC}
                                        </TableCell>
                                      )
                                    );
                                  } else if (key == "submissionBy") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {value?.submissionBy &&
                                        typeof value.submissionBy ===
                                          "object" &&
                                        value.submissionBy.firstName
                                          ? `${value.submissionBy.firstName} ${value.submissionBy.lastName}`
                                          : "N/A"}
                                      </TableCell>
                                    );
                                  } else if (key == "email") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.email
                                          ? "N/A"
                                          : value?.candidateId?.email}
                                      </TableCell>
                                    );
                                  } else if (key == "updated_at") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.updatedAt
                                          ? "N/A"
                                          : new Date(
                                              value?.updatedAt
                                            ).toLocaleDateString("en-GB", {
                                              day: "2-digit",
                                              month: "2-digit",
                                              year: "numeric",
                                            })}
                                      </TableCell>
                                    );
                                  } else if (key == "updatedBy") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {value?.updatedBy &&
                                        typeof value.updatedBy === "object" &&
                                        value.updatedBy.firstName
                                          ? `${value.updatedBy.firstName} ${value.updatedBy.lastName}`
                                          : "N/A"}
                                      </TableCell>
                                    );
                                  } else if (key == "fullName") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.firstName
                                          ? "N/A"
                                          : `${value?.candidateId?.firstName} ${value?.candidateId?.lastName}`}
                                      </TableCell>
                                    );
                                  } else if (key == "industry") {
                                    return (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.industry
                                          ? "N/A"
                                          : `${value?.candidateId?.industry}`}
                                      </TableCell>
                                    );
                                  } else if (key == "interviewRescheduleDate") {
                                    return (
                                      componentName &&
                                      (componentName == "interview" ||
                                        componentName == "submission" ||
                                        componentName == "all") && (
                                        <TableCell className="nowrap_content">
                                          {!value?.interviewRescheduleDate
                                            ? "N/A"
                                            : !moment(
                                                  value?.interviewRescheduleDate,
                                                  "DD/MM/YYYY",
                                                  true
                                                ).isValid()
                                              ? moment(
                                                  value?.interviewRescheduleDate
                                                ).format("DD/MM/YYYY")
                                              : value?.interviewRescheduleDate}
                                        </TableCell>
                                      )
                                    );
                                  } else if (key == "testRescheduleDate") {
                                    return (
                                      componentName &&
                                      (componentName == "interview" ||
                                        componentName == "submission" ||
                                        componentName == "all") && (
                                        <TableCell className="nowrap_content">
                                          {!value?.testRescheduleDate
                                            ? "N/A"
                                            : !moment(
                                                  value?.testRescheduleDate,
                                                  "DD/MM/YYYY",
                                                  true
                                                ).isValid()
                                              ? moment(
                                                  value?.testRescheduleDate
                                                ).format("DD/MM/YYYY")
                                              : value?.testRescheduleDate}
                                        </TableCell>
                                      )
                                    );
                                  } else if (key == "backoutDate") {
                                    return (
                                      componentName &&
                                      (componentName == "backout" ||
                                        componentName == "all") && (
                                        <TableCell className="nowrap_content">
                                          {!value?.backoutDate
                                            ? "N/A"
                                            : !moment(
                                                  value?.backoutDate,
                                                  "DD/MM/YYYY",
                                                  true
                                                ).isValid()
                                              ? moment(
                                                  value?.backoutDate
                                                ).format("DD/MM/YYYY")
                                              : value?.backoutDate}
                                        </TableCell>
                                      )
                                    );
                                  } else if (key == "source") {
                                    return (
                                      // componentName == "all" && (
                                      <TableCell className="nowrap_content">
                                        {!value?.candidateId?.source
                                          ? "N/A"
                                          : value?.candidateId?.source}
                                      </TableCell>
                                    );
                                    // );
                                  }
                                })}
                            </TableRow>
                          ))}
                      </>
                    )}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <CommonPagination
            rowsPerPage={rowsPerPage}
            page={page}
            totalPages={Math.ceil(
              (displaysubmissions?.totalSubmissionCount ||
                displaysubmissions?.totalQACheckCount ||
                displaysubmissions?.totalInterviewScheduleCount ||
                displaysubmissions?.totalInterviewAttainedCount ||
                displaysubmissions?.totalInterviewCompleteCount ||
                displaysubmissions?.totalSelectedCount ||
                displaysubmissions?.totalJoinedCount ||
                displaysubmissions?.totalRejectedCount ||
                displaysubmissions?.totalBackoutLeftCount ||
                1) / rowsPerPage
            )}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            count={
              displaysubmissions?.totalSubmissionCount ||
              displaysubmissions?.totalQACheckCount ||
              displaysubmissions?.totalInterviewScheduleCount ||
              displaysubmissions?.totalInterviewAttainedCount ||
              displaysubmissions?.totalInterviewCompleteCount ||
              displaysubmissions?.totalSelectedCount ||
              displaysubmissions?.totalJoinedCount ||
              displaysubmissions?.totalRejectedCount ||
              displaysubmissions?.totalBackoutLeftCount ||
              0
            }
          />
        </Paper>
      </div>
      <CandidatePreview
        isOpenPreview={isOpenPreview}
        setIsOpenPreview={setIsOpenPreview}
        handleCandidatePreviewClose={handleCandidatePreviewClose}
        perviewCandidateId={perviewCandidateId}
        setPerviewCandidateId={setPerviewCandidateId}
      />
    </>
  );
};

export default CommonSubmissionFile;

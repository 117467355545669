import React from "react";
import {
  Box,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import EventNoteIcon from "@mui/icons-material/EventNote";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

const AddNote = ({
  handleSaveNoteEdit,
  getnotes,
  handleEditNotes,
  handleNoteDelete,
  handleEditNoteChange,
  editedNoteIndex,
  handleCancelEdit,
  noteSubmitLoading,
}) => {
  return (
    <React.Fragment>
      <Box style={{ maxHeight: "400px", overflow: "auto" }}>
        {getnotes &&
          getnotes?.map((val, index) => {
            return (
              <Box className="nt_box" key={index}>
                <Box className="note_box">
                  <Box className="note_b">
                    <Box className="note_icon_box">
                      <EventNoteIcon style={{ color: "#718096" }} />
                    </Box>
                    <Text
                      style={{
                        fontWeight: "bold",
                        color: "#718096",
                      }}
                    >
                      Notes
                    </Text>
                  </Box>
                  <Box>
                    <Menu>
                      <MenuButton>
                        <MoreVertIcon
                          style={{
                            color: "#718096",
                            cursor: "pointer",
                          }}
                        />
                      </MenuButton>
                      <MenuList
                        style={{
                          marginLeft: "-5rem",
                          fontSize: "15px",
                          width: "fit-content",
                          minWidth: "auto",
                        }}
                      >
                        <MenuItem
                          icon={
                            <BorderColorIcon
                              style={{
                                color: "#718096",
                                fontSize: "17px",
                              }}
                            />
                          }
                          onClick={() => handleEditNotes(index)}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          icon={
                            <DeleteIcon
                              style={{
                                color: "#718096",
                                fontSize: "17px",
                              }}
                            />
                          }
                          onClick={() => {
                            val?.candidateId
                              ? handleNoteDelete(val?._id, "candidate")
                              :
                            val?.jobId
                              ? handleNoteDelete(val?._id, "job")
                              : handleNoteDelete(val?._id, "contact");
                          }}
                        >
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Box>
                </Box>
                
                <Text className="notes_detail">
                  {editedNoteIndex === index ? (
                    <>
                      <textarea
                        rows="4"
                        cols="50"
                        value={val?.candidateNote || val?.jobNote || val?.contactNote}
                        onChange={(e) =>
                          handleEditNoteChange(e.target.value, index)
                        }
                        style={{ padding: "1rem" }}
                      />
                      <Box
                        style={{
                          display: "flex",
                          gap: "1rem",
                          marginTop: "1rem",
                        }}
                      >
                        <Button className="cancel" onClick={handleCancelEdit}>
                          Cancel
                        </Button>
                        <Button
                          className="addnote_btn"
                          onClick={() => {
                            handleSaveNoteEdit(val);
                          }}
                          isDisabled={noteSubmitLoading}
                          isLoading={noteSubmitLoading}
                        >
                          Save
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      {val?.jobNote
                        ? val.jobNote.split("\n").map((line, index) => (
                            <Text key={index}>
                              {line}
                              <br />
                            </Text>
                          ))
                        : val?.contactNote ? val?.contactNote.split("\n").map((line, index) => (
                            <Text key={index}>
                              {line}
                              <br />
                            </Text>
                          )): val?.candidateNote ? val?.candidateNote.split("\n").map((line, index) => (
                            <Text key={index}>
                              {line}
                              <br />
                            </Text> 
                          )): null}
                    </>
                  )}
                </Text>
                <Text className="notes_detail">{`${
                  val?.noteSubmitter
                } on ${moment(
                  val?.updated_at ? val?.updated_at : val?.created_at
                ).format(
                  "DD/MM/YYYY"
                )} at ${moment(val?.updated_at ? val?.updated_at : val?.created_at).format("h:mm A")}`}</Text>
              </Box>
            );
          })}
      </Box>
    </React.Fragment>
  );
};

export default AddNote;
